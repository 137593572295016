import { QueueAction } from '../components2.0/PlayerQueueClip'
import { WhizzardSearchResultClip } from './clip'
import { numberToDouble } from './time'

export const hideEditClipActionOnMobile = (actions: QueueAction[], isMobile: boolean) => {
  if (isMobile) {
    return actions.filter(item => item.title !== 'Edit clip')
  }
  return actions
}

export const createVideoString = (clips: WhizzardSearchResultClip[]) => {
  return clips.reduce((accumulator: string, clip: WhizzardSearchResultClip, index: number) => {
    return `${accumulator}id=${clip.assetId}&o=${numberToDouble(Number(clip.startTime))}&d=${numberToDouble(
      Number(clip.duration)
    )}${index < clips.length - 1 ? '&' : ''}`
  }, '')
}
