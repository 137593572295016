import { Box, SxProps, Typography, useTheme } from '@mui/material'

import { DatePicker } from '../low-level/DatePicker'
import { DefaultDatePickerInput } from '../low-level/DatePicker/DatePickerStyledComponents'

interface DateRange {
  startDate: Date | null
  endDate: Date | null
}

interface CustomDateRangeProps {
  dateRange: DateRange
  handleDateRangeChange: (newValue: unknown, startOrEnd: string) => void
  sx?: SxProps
}

export const CustomDateRange = ({ dateRange, handleDateRangeChange, sx }: CustomDateRangeProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...sx }}
      data-testid="date-pickers"
    >
      <DatePicker
        value={dateRange.startDate}
        onChange={newValue => handleDateRangeChange(newValue, 'start')}
        renderInput={props => <DefaultDatePickerInput {...props} />}
        maxDate={dateRange.endDate}
      />
      <Typography sx={{ color: theme.palette.grey[400], mx: 1 }}>to</Typography>
      <DatePicker
        value={dateRange.endDate}
        onChange={newValue => handleDateRangeChange(newValue, 'end')}
        renderInput={props => <DefaultDatePickerInput {...props} />}
        minDate={dateRange.startDate}
      />
    </Box>
  )
}
