// Names from https://chir.ag/projects/name-that-color/

import { Palette } from '@mui/material'
import { skyTheme } from '../design-system/themes'

const colours = (palette: Palette) => ({
  mobileLanding1: palette.secondary[800],
  mobileLanding2: palette.secondary[700],
  martinique: palette.secondary.dark,
  lightMartinique: palette.secondary[700],
  lighterMartinique: palette.secondary[800],
  scooter: palette.primary.main,
  metallicSeaweed: palette.primary[800],
  persianGreen: palette.success.main,
  successGreen: palette.success.light,
  darkRed: palette.error[900],
  red: palette.error.dark,
  redOrange: palette.error[500],
  buttercup: palette.warning[600],
  creamCan: palette.warning[300],
  white: palette.common.white,
  alabaster: palette.grey[100],
  gallery: palette.grey[200],
  alto: palette.grey[300],
  frenchGray: palette.grey[400],
  darkGrey: palette.grey[500],
  tuna: palette.grey[800],
  dune: palette.grey[900],
  codGray: palette.common.black,
  black: palette.common.black
})

export default colours(skyTheme.palette)
