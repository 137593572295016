import { AnyAction } from 'redux'

import { Playlist } from '../../../types'
import { IVideoClip } from '../../../utils'

import {
  PLAYLIST_ADD,
  PLAYLIST_ADD_SHARED,
  PLAYLIST_CLIP_ADD,
  PLAYLIST_CLIP_REMOVE,
  PLAYLIST_CLIP_UPDATE,
  PLAYLIST_DELETE,
  PLAYLIST_INITIAL_SET,
  PLAYLIST_ORDER_UPDATE,
  PLAYLIST_SHARED_FETCHED,
  PLAYLIST_SHARED_INITIAL_SET,
  PLAYLIST_TITLE_SET,
  PLAYLIST_UNFOLLOW_SHARED
} from './actions'

export interface PlaylistState {
  playlists: Playlist[]
  userPlaylistsFetched: boolean
  playlistsShared: Playlist[]
  userPlaylistsSharedFetched: boolean
}

const initialState: PlaylistState = {
  playlists: [],
  playlistsShared: [],
  userPlaylistsFetched: false,
  userPlaylistsSharedFetched: false
}

const reducer = (state: PlaylistState = initialState, action: AnyAction): PlaylistState => {
  switch (action.type) {
    case PLAYLIST_ADD:
      return {
        ...state,
        playlists: [...state.playlists, action.payload]
      }
    case PLAYLIST_SHARED_FETCHED:
      return {
        ...state,
        userPlaylistsSharedFetched: true
      }

    case PLAYLIST_ADD_SHARED:
      if (!action.playlist) {
        return state
      }
      return {
        ...state,
        playlists: [
          ...state.playlists,

          {
            ...action.playlist,
            isShared: true
          }
        ]
      }
    case PLAYLIST_TITLE_SET:
      if (!action.id || !action.title) {
        return state
      }

      return {
        ...state,
        playlists: state.playlists.map((playlist: Playlist) => {
          if (playlist.id !== action.id) {
            return playlist
          }

          return {
            ...playlist,
            title: action.title
          }
        })
      }

    case PLAYLIST_ORDER_UPDATE:
      if (!action.id) {
        return state
      }

      return {
        ...state,
        playlists: state.playlists.map((playlist: Playlist) => {
          if (playlist.id !== action.playlistId) {
            return playlist
          }

          const clips = [...playlist.clips]
          const [removed] = clips.splice(action.oldIndex, 1)

          clips.splice(action.newIndex, 0, removed)

          return {
            ...playlist,
            clips
          }
        })
      }

    case PLAYLIST_DELETE:
      if (!action.id) {
        return state
      }

      return {
        ...state,
        playlists: state.playlists.filter((playlist: Playlist) => playlist.id !== action.id)
      }
    case PLAYLIST_UNFOLLOW_SHARED:
      if (!action.id) {
        return state
      }
      return {
        ...state,
        playlistsShared: state.playlistsShared.filter((playlistShared: Playlist) => playlistShared.id !== action.id)
      }

    case PLAYLIST_INITIAL_SET:
      if (!action.playlists) {
        return state
      }
      return {
        ...state,
        playlists: action.playlists,
        userPlaylistsFetched: true
      }

    case PLAYLIST_SHARED_INITIAL_SET:
      if (!action.playlistsShared) {
        return state
      }
      return {
        ...state,
        playlistsShared: action.playlistsShared,
        userPlaylistsSharedFetched: true
      }

    case PLAYLIST_CLIP_ADD:
      if (!action.playlistIds || !action.clip) {
        return state
      }

      return {
        ...state,
        playlists: state.playlists.map((playlist: Playlist) => {
          if (!action.playlistIds.includes(playlist.id)) {
            return playlist
          }

          if (playlist.clips.findIndex((clip: IVideoClip) => clip.id === action.clip.id) > 0) {
            return playlist
          }

          return {
            ...playlist,
            clips: [...playlist.clips, action.clip]
          }
        })
      }

    case PLAYLIST_CLIP_UPDATE:
      return {
        ...state,
        playlists: state.playlists.map((playlist: Playlist) => {
          if (playlist.id !== action.playlistId) {
            return playlist
          }

          return {
            ...playlist,
            clips: playlist.clips.map((clip: IVideoClip) => {
              if (clip.id !== action.id) {
                return clip
              }

              return {
                ...clip,
                title: action.title || clip.title,
                offset: Number(action.offset) || Number(clip.startTime),
                duration: Number(action.duration) || Number(clip.duration)
              }
            })
          }
        })
      }

    case PLAYLIST_CLIP_REMOVE:
      return {
        ...state,
        playlists: state.playlists.map((playlist: Playlist) => {
          if (playlist.id !== action.playlistId) {
            return playlist
          }

          return {
            ...playlist,
            clips: playlist.clips.filter((clip: IVideoClip) => clip.id !== action.id)
          }
        })
      }

    default:
      return state
  }
}

export default reducer
export { initialState }
