import { createUseStyles } from 'react-jss'
import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'
import { ButtonSize, Variant } from './types'

interface IButtonStylesProps {
  isActive: boolean
  buttonText: string
  isIconLeft: boolean
  buttonSize: ButtonSize
  color: string
  variant: Variant
  disabled?: boolean
}

const themeStyle = (props: Partial<IButtonStylesProps>) => {
  return {
    solid: {
      color: colours.white,
      border: '1px solid transparent',
      backgroundColor: props.color,
      minWidth: 80,
      transition: '0.3s ease',
      '&:hover': {
        backgroundColor: props.color,
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 10%)'
      }
    },
    text: {
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '6px',
      color: props.color,
      transition: '0.2s ease',
      '&:hover': {
        backgroundColor: `${props.color}10`
      }
    },
    outlined: {
      color: props.color,
      backgroundColor: 'transparent',
      border: `1px solid ${props.color}`,
      transition: '0.2s ease',
      '&:hover': {
        backgroundColor: `${props.color}10`
      }
    },
    icon: {
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      border: 'none'
    }
  }
}

const size = () => {
  return {
    small: {
      fontSize: toResponsiveUnitFromPixels(12)
    },
    default: {
      fontSize: toResponsiveUnitFromPixels(14)
    },
    large: {
      fontSize: toResponsiveUnitFromPixels(18)
    }
  }
}

const useButtonStyles = createUseStyles({
  root: (props: Partial<IButtonStylesProps>) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    opacity: props.disabled ? 0.2 : 1,
    padding: '8px 16px',
    borderRadius: '6px',
    ...(props.buttonSize && size()[props.buttonSize]),
    ...(props.variant && themeStyle(props)[props.variant])
  })
})

export default useButtonStyles
