import { createSelector } from 'reselect'

import { Playlist } from '../../types'
import { IVideoClip } from '../../utils'
import { selectPlaylists } from '../slices/playlists'
import { selectPlaylistsModalClip } from '../slices/playlistsModal'

const selectPlaylistModalAvailablePlaylists = createSelector(
  [selectPlaylists, selectPlaylistsModalClip],
  (playlists: Playlist[], currentClip: IVideoClip | null): Playlist[] => {
    if (currentClip) {
      return playlists.filter(
        (playlist: Playlist) => playlist.clips.findIndex((clip: IVideoClip) => clip.id === currentClip.id) < 0
      )
    }

    return []
  }
)

export { selectPlaylistModalAvailablePlaylists }
