import { CircularProgress } from '@mui/material'
import { fontSize } from '@mui/system'
import useProfileMenuStyles from './ProfileMenu.styles'
import { IProfileMenu } from './types'

export const ProfileMenu = ({ name, handleLogout, isLoading = false }: IProfileMenu.Props) => {
  const classes = useProfileMenuStyles()

  return (
    <div className={classes.profileMenuContainer}>
      <img className={classes.profileIconImage} alt="" src={'/images/profile.png'} />
      <p className={classes.emailAddress}>{name}</p>
      <button onClick={handleLogout} className={classes.logoutButton}>
        <p className={classes.logoutText}>
          {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Log out'}
        </p>
      </button>
    </div>
  )
}
