import { useCallback } from 'react'
import Box from '@mui/material/Box'
import { InputBase, useTheme, Divider, Button, Input, FormControl, InputAdornment, IconButton } from '@mui/material'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SearchIcon from '@mui/icons-material/Search'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { useLocation } from 'react-router-dom'
import { Dropdown, Option } from '../low-level/Dropdown'
import { getSearchBarStyles } from './SearchBar.styles'

import { TextField } from '../low-level/TextField/TextField'
import { ROUTES } from '../../RouteConfig/constants'

export interface SearchBarProps {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dropdownValue: string[]
  dropdownOnChange: (values: string[]) => void
  dropdownOptions: Option[]
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onReset: (event: React.FormEvent<HTMLFormElement>) => void
  onClear?: () => void
}

export const SearchBar = ({
  onSubmit,
  onReset,
  onChange,
  value,
  dropdownValue,
  dropdownOnChange,
  dropdownOptions,
  onClear
}: SearchBarProps) => {
  const theme = useTheme()
  const location = useLocation()
  const searchBarStyles = getSearchBarStyles(theme)
  const isLandingPage = location.pathname === ROUTES.SEARCH

  const onSubmitHandler = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      onSubmit(event)
    },
    [onSubmit]
  )

  return (
    <>
      <Box
        component="form"
        onSubmit={onSubmitHandler}
        onReset={onReset}
        sx={searchBarStyles.searchBar}
        data-testid="search-bar">
        <Box sx={searchBarStyles.inputsContainer}>
          <InputBase
            placeholder="Search"
            sx={searchBarStyles.input}
            value={value}
            onChange={onChange}
            startAdornment={<SearchRoundedIcon />}
            data-testid="search-input"
          />
          <Divider orientation="vertical" sx={searchBarStyles.divider} />
          <FormControl sx={searchBarStyles.dropdown}>
            <Dropdown
              multiple
              fullWidth
              options={dropdownOptions}
              title="Search Type"
              selectAll="All insights"
              value={dropdownValue}
              onSelection={dropdownOnChange}
              input={<Input disableUnderline />}
              data-testid="search-type-dropdown"
            />
          </FormControl>
        </Box>
        <Box sx={searchBarStyles.buttons}>
          <Button variant="text" type="reset" disabled={!value.length && isLandingPage} data-testid="clear-button">
            Clear
          </Button>
          <Button variant="contained" type="submit" disabled={!value.length} data-testid="search-button">
            Search
          </Button>
        </Box>
      </Box>
      <Box component="form" sx={searchBarStyles.mobileAndTabletContainer} onSubmit={onSubmitHandler} onReset={onReset}>
        <TextField
          sx={{ width: '100%', mb: 1 }}
          value={value}
          onChange={onChange}
          InputProps={{
            placeholder: 'Search',
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: theme.palette.grey[value ? 800 : 350] }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClear}>
                  <ClearOutlinedIcon sx={{ display: value.length ? 'block' : 'none' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Dropdown
          sx={{ width: '100%', mb: 1 }}
          multiple
          fullWidth
          options={dropdownOptions}
          title="Filter"
          selectAll="All insights"
          value={dropdownValue}
          onSelection={dropdownOnChange}
        />

        <Button
          sx={{
            width: '100%',
            '&.Mui-disabled': { backgroundColor: theme.palette.primary.main, color: theme.palette.common.white }
          }}
          variant="contained"
          type="submit"
          disabled={!value.length}>
          Search
        </Button>
        <Button sx={{ width: '100%' }} variant="text" type="reset" disabled={!value.length && isLandingPage}>
          Clear
        </Button>
      </Box>
    </>
  )
}
