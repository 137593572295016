import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import { videojs } from 'linius-video-player'
import RouteConfig from './RouteConfig/RouteConfig'
import { messageServiceWorker } from './utils/service-worker'
import { lvsApi } from './store/apis/lvs'
import { useRegisterSharedPlaylistMutation } from './store/apis/middleware'
import { selectApiKey, selectUserDetails } from './store/slices/user'
import { selectAccessToken } from './store/slices/auth'

const App = () => {
  const gtmContainer = process.env.REACT_APP_GTM_CONTAINER

  // TODO: Move this to a hook and refactor.
  const dispatch = useDispatch()
  const userDetails = useSelector(selectUserDetails)
  const accessToken = useSelector(selectAccessToken)
  const apiKey = useSelector(selectApiKey)
  const tenantId = userDetails?.tenantId
  const userId = userDetails?.id
  const userRole = userDetails?.role
  const tenantName = userDetails?.tenantName

  const [registerSharedPlaylist] = useRegisterSharedPlaylistMutation()

  const tagManagerArgs = {
    gtmId: gtmContainer,
    dataLayer: {
      user_properties: {
        lvs_tenant_id: tenantId,
        lvs_user_id: userId,
        whizzard_org_name: tenantName
      },
      tenant_id: tenantId,
      user_id: userId,
      tenant_name: tenantName,
      user_role: userRole
    }
  }

  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    if (accessToken) {
      // if we are already signed in go and get the payload categories
      // TODO move this call somewhere more sensible when we rework the
      //      sign in / app bootstrap logic
      dispatch(lvsApi.endpoints.getPayloadCategories.initiate(String(tenantId)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tenantId) {
      registerSharedPlaylist({
        tenantId
      })
    }
  }, [tenantId, registerSharedPlaylist])

  useEffect(() => {
    // pass the access token and api key to the service worker instance
    if (accessToken && apiKey) messageServiceWorker({ accessToken, apiKey })

    const vhs = (videojs as any).Vhs

    // also add the access token and api key to assembly requests from the player,
    // we do this belt and braces approach as the service worker approach is
    // required for iOS browsers but is not 100% reliable
    vhs.xhr.beforeRequest = (options: { uri: string }) => {
      if (options.uri.includes('/assembly/hls')) {
        return {
          ...options,
          headers: {
            'Content-Type': 'application/x-mpegURL',
            Authorization: `Bearer ${accessToken}`,
            'x-api-key': apiKey
          }
        }
      }

      return options
    }
  }, [accessToken, apiKey])

  return <RouteConfig />
}

export default App
