export interface CategoryMap {
  /** flag to include in primary search types or additional filters */
  primary?: boolean
  /** include category but mark as disabled */
  disabled?: boolean | string[]
  /** exclude category entirely */
  exclude?: boolean
  /** custom text instead of basic "FOO_BAR" > "Foo bar" mapping */
  text?: string
}

type Mapping = Record<string, CategoryMap>

export const categoryMapping: Mapping = {
  TRANSCRIPT: {
    primary: true,
    text: 'Transcripts'
  },
  OCR: {
    text: 'On-screen texts',
    primary: true
  },
  PEOPLE: {
    primary: true
  },
  NBN_ARCHIVE_TYPE: {
    text: 'NBN Archive Type'
  },
  ASSET_START_DATE: {
    exclude: true // handled by date range input
  }
}
