import { Theme } from '@mui/material'

export const getTopnavStyles = (theme: Theme) => ({
  avatar: {
    backgroundColor: 'transparent',
    width: '40px',
    height: '40px',
    border: `2px solid ${theme.palette.common.white}`,
    fontSize: 15,
    cursor: 'pointer',
    color: theme.palette.common.white,

    '&:hover': {
      background: theme.palette.primary.main
    }
  }
})
