import { FunctionComponent } from 'react'

import { WhizzardLogoProps } from './WhizzardLogo.models'

export const WhizzardLogo: FunctionComponent<WhizzardLogoProps> = ({ width }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="159.599"
    height="47.979"
    viewBox="0 0 159.599 47.979"
    data-testid="whizzard-logo">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.345"
        y1="0.595"
        x2="1.379"
        y2="0.148"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#3dbad7" />
        <stop offset="1" stopColor="#064d69" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.139"
        y1="-0.142"
        x2="0.834"
        y2="1.096"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#064d69" />
        <stop offset="0.298" stopColor="#3dbad7" />
        <stop offset="0.791" stopColor="#3dbad7" />
        <stop offset="1" stopColor="#064d69" />
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="0.58" y1="0.415" x2="-0.107" y2="1.085">
        <stop offset="0" stopColor="#3dbad7" />
        <stop offset="1" stopColor="#064d69" />
      </linearGradient>
    </defs>
    <g id="Group_3518" data-name="Group 3518" transform="translate(-690.813 -443.748)">
      <path
        id="Path_69940"
        data-name="Path 69940"
        d="M713.453,489.325H699.669V471.982h-8.856V495.72h8.856v0h13.784Z"
        transform="translate(0 -17.897)"
        fill="#fff"
      />
      <path
        id="Path_69941"
        data-name="Path 69941"
        d="M758.974,471.982V495.72h8.886V477.4Z"
        transform="translate(-43.205 -17.897)"
        fill="#fff"
      />
      <path
        id="Path_69942"
        data-name="Path 69942"
        d="M952.9,473.69v23.738h-8.886V479.107Z"
        transform="translate(-160.499 -18.979)"
        fill="#fff"
      />
      <path
        id="Path_69943"
        data-name="Path 69943"
        d="M1065.365,473.69c14.418,0,13.451,7.3,13.451,7.3h-9.759s.352-2.374-3.692-2.374-3.6,1.582-3.6,1.582-.242,1.385,4.572,2.022c5.806.769,13.364.967,13.364,6.946s-4.308,8.264-13.8,8.264-13.363-2.725-13.363-7.561h10.2s.088,2.286,3.385,2.286,3.56-1.319,3.56-1.934-.351-1.407-3.56-1.846-13.144-.7-13.144-7.385S1059.913,473.69,1065.365,473.69Z"
        transform="translate(-229.284 -18.98)"
        fill="#fff"
      />
      <g id="Group_3516" data-name="Group 3516" transform="translate(727.957 443.748)">
        <path
          id="Path_69944"
          data-name="Path 69944"
          d="M804.02,503.835v0a17.483,17.483,0,0,1-3.9-19.077,17.317,17.317,0,0,1,3.7-5.6h0a17.4,17.4,0,0,1,2.435-2.057q.2-.143.411-.279l-1.261-2.144-.016-.026a6.706,6.706,0,0,0-2.081-2.189c-.047-.031-.094-.061-.142-.091a6.994,6.994,0,0,0-4.646-.963,6.436,6.436,0,0,0-5.354,4.773,26.181,26.181,0,0,0,3.5,21.276c1.872,2.813,4.5,4.454,7.359,6.382Z"
          transform="translate(-792.267 -461.238)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_69945"
          data-name="Path 69945"
          d="M838.777,483.861a23.14,23.14,0,0,0,2.838-3.509,26.133,26.133,0,0,0,3.631-8.072,6.719,6.719,0,0,1-10.053,4.012c-.048-.03-.1-.06-.142-.092a6.687,6.687,0,0,1-1.89-1.884,6.581,6.581,0,0,1-.378-.643l-14.136-24.029a11.127,11.127,0,0,0-17.562-2.126l-.037.038a26.037,26.037,0,0,0-6.477,11.588,6.718,6.718,0,0,1,10.053-4.011c.048.03.1.06.142.091a6.706,6.706,0,0,1,2.081,2.189l.016.026,1.261,2.144,3.027,5.145,5.329,9.059.034.058.893,1.519,3.749,6.372a11.15,11.15,0,0,0,17.4,2.342Q838.666,483.972,838.777,483.861Z"
          transform="translate(-793.727 -444.006)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_69946"
          data-name="Path 69946"
          d="M898.835,443.748a26.149,26.149,0,0,1,10.736,28.126,6.837,6.837,0,0,1-8.235,4.958,6.715,6.715,0,0,1-1.856-.8c-.048-.03-.1-.06-.142-.092a6.688,6.688,0,0,1-1.89-1.884l-1.459-2.48c.136-.089.269-.18.4-.273a17.447,17.447,0,0,0,2.435-2.057h0a23.093,23.093,0,0,0,3.7-5.6,21.957,21.957,0,0,0,.75-2.256,17.363,17.363,0,0,0-.071-9.032c-.8-2.871-2.32-6.488-4.412-8.608"
          transform="translate(-858.014 -443.748)"
          fill="url(#linear-gradient-3)"
        />
      </g>
      <path
        id="Path_69947"
        data-name="Path 69947"
        d="M1000.822,476.165v-2.476h-8.654v.466c-.006.065-.008.137-.008.218q.034,6.675,0,13.35c-.011,2.186-1.064,3.314-3.23,3.6a9.9,9.9,0,0,1-2.5-.007,3.1,3.1,0,0,1-3.079-2.937,7.784,7.784,0,0,1-.068-1.136q-.008-6.5,0-13.008c0-.071,0-.134-.007-.19v-.358h-8.636v.416c.03,5-.141,10.007.077,15,.2,4.567,2.541,6.784,6.549,7.646a27.488,27.488,0,0,0,12.86.007,9.791,9.791,0,0,0,5.029-2.884,7.551,7.551,0,0,0,1.59-4.733c.144-2.451.022-4.905.06-6.906,0-2.214-.007-4.174,0-6.072Z"
        transform="translate(-179.892 -18.979)"
        fill="#fff"
      />
      <g id="Group_3517" data-name="Group 3517" transform="translate(783.623 485.148)">
        <path
          id="Path_69948"
          data-name="Path 69948"
          d="M944.308,556.825h.9l1.579,4.858c.033.109.077.373.088.406a2.927,2.927,0,0,1,.11-.406l1.557-4.858h.548l1.557,4.858c.044.12.109.406.109.406a3.121,3.121,0,0,1,.088-.406l1.579-4.858h.855l-2.27,6.579h-.548L948.9,558.9c-.033-.088-.1-.329-.11-.362-.011.022-.076.274-.109.362l-1.557,4.507h-.548Z"
          transform="translate(-944.308 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69949"
          data-name="Path 69949"
          d="M978.123,556.825H979v2.741h3.63v-2.741h.877V563.4h-.877v-3.1H979v3.1h-.877Z"
          transform="translate(-965.742 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69950"
          data-name="Path 69950"
          d="M1003.882,556.825h.877V563.4h-.877Z"
          transform="translate(-982.071 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69951"
          data-name="Path 69951"
          d="M1016.431,562.9l3.454-5.34h-3.454v-.734h4.529v.5l-3.454,5.34h3.454v.735h-4.529Z"
          transform="translate(-990.025 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69952"
          data-name="Path 69952"
          d="M1038.086,562.9l3.454-5.34h-3.454v-.734h4.528v.5l-3.454,5.34h3.454v.735h-4.528Z"
          transform="translate(-1003.752 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69953"
          data-name="Path 69953"
          d="M1061.731,556.825h.571l2.829,6.579h-.9l-.789-1.842h-2.895l-.79,1.842h-.855Zm1.415,4.024-1.152-2.708-1.151,2.708Z"
          transform="translate(-1016.947 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69954"
          data-name="Path 69954"
          d="M1085.23,556.939a.114.114,0,0,1,.114-.114h2.057a1.875,1.875,0,0,1,2.149,1.93,1.762,1.762,0,0,1-1.36,1.831l1.868,2.638a.114.114,0,0,1-.093.18h-.739a.114.114,0,0,1-.094-.049l-1.852-2.67h-1.173v2.605a.114.114,0,0,1-.114.114h-.649a.114.114,0,0,1-.114-.114Zm2.171,3.011a1.2,1.2,0,1,0,0-2.39h-1.294v2.39Z"
          transform="translate(-1033.635 -556.825)"
          fill="#3dbad7"
        />
        <path
          id="Path_69955"
          data-name="Path 69955"
          d="M1107.843,556.939a.114.114,0,0,1,.114-.114h1.925c2.555,0,3.662,1.48,3.662,3.29s-1.108,3.29-3.662,3.29h-1.925a.114.114,0,0,1-.114-.114Zm2.051,5.7c2.138,0,2.741-1.206,2.741-2.522s-.6-2.522-2.741-2.522h-1.173v5.044Z"
          transform="translate(-1047.969 -556.825)"
          fill="#3dbad7"
        />
      </g>
    </g>
  </svg>
)
