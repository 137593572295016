import { createUseStyles } from 'react-jss'
import colours from '../../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../../theme/toResponsiveUnitFromPixels'

interface useIntergrationsTableHeaderStylesProps {
  direction: string
  divider?: boolean
  gridTemplateColumns?: string
}

const useTableHeaderStyles = createUseStyles({
  headerContent: {
    display: 'grid',
    gridTemplateColumns: ({ gridTemplateColumns }: useIntergrationsTableHeaderStylesProps) => gridTemplateColumns,
    flexDirection: ({ direction }: useIntergrationsTableHeaderStylesProps) => direction,
    justifyContent: 'flex-start',
    alignItems: ({ direction }: useIntergrationsTableHeaderStylesProps) => (direction === 'row' ? 'center' : ''),
    whiteSpace: 'nowrap',
    paddingLeft: toResponsiveUnitFromPixels(40),
    borderBottom: ({ divider }: useIntergrationsTableHeaderStylesProps) =>
      divider && `${toResponsiveUnitFromPixels(1)} solid ${colours.alto}`
  },
  header: {
    fontSize: toResponsiveUnitFromPixels(14),
    fontWeight: 'bold',
    textAlign: 'start',
    paddingTop: toResponsiveUnitFromPixels(10),
    paddingBottom: toResponsiveUnitFromPixels(10)
  },
  headerCheckbox: {
    marginRight: toResponsiveUnitFromPixels(50)
  }
})

export default useTableHeaderStyles
