import React from 'react'

import { IExternalLink } from './types'
import { useExternalLinkStyles } from './ExternalLink.styles'
import classNames from 'classnames'

const ExternalLink = ({ children, to, colour, onClick, fontSize = 12, className }: IExternalLink.Props) => {
  const classes = useExternalLinkStyles({ colour, fontSize })

  return (
    <a href={to} onClick={onClick} target="_blank" rel="noreferrer" className={classNames(classes.linkText, className)}>
      {children}
    </a>
  )
}

export { ExternalLink }
