import React from 'react'
import { Link } from 'react-router-dom'

import { WhizzardLogo } from '../../design-system-legacy/core/WhizzardLogo'
import { ROUTES } from '../../RouteConfig/constants'

import useSuccessfullyRegisteredStyles from '../../pages/SuccessfullyRegistered/SuccessfullyRegistered.styles'
import { IAuthLayout } from './types'

export const AuthLayout = ({ children }: IAuthLayout.Props) => {
  const classes = useSuccessfullyRegisteredStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.routerLink}>
        <Link to={ROUTES.SEARCH}>
          <WhizzardLogo />
        </Link>
      </div>
      {children}
    </div>
  )
}
