import { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CsvValidation } from '../types'

import { RequiredHeadings } from '../constants'
import {
  assetStartDateValidation,
  genericValidation,
  headingsValidationCheck,
  presenterValidation,
  titleValidation,
  toFindDuplicates,
  videoUrlValidation,
  vocabularyListValidation
} from '../utils'

export const useCsvValidator = (file?: CsvValidation.CsvFile | null) => {
  const [validatedHeadings, setValidatedHeadings] = useState<CsvValidation.HeadingValidation[]>([])
  const [allValidatedHeadings, setAllValidatedHeadings] = useState<CsvValidation.HeadingValidation[]>([])
  const [requiredHeadingIndexes, setRequiredHeadingIndexes] = useState<CsvValidation.HeadingIndexes>()
  const [duplicateHeaders, setDuplicateHeaders] = useState<string[]>([])
  const [duplicateElements, setDuplicateElements] = useState<string[]>([])
  const [isMissingRequiredHeadings, setIsMissingRequiredHeadings] = useState<boolean>(false)
  const [json, setJson] = useState<any>() // type to be confirmed
  const [allErrors, setAllErrors] = useState<any>([])
  const { VIDEO_URL, TITLE, ASSET_START_DATE, VOCABULARY_LIST_NAME, PRESENTER } = RequiredHeadings

  const totalErrorsCount =
    allValidatedHeadings.filter(item => item.isError).length +
    allErrors.length +
    duplicateElements.length +
    duplicateHeaders.length +
    isMissingRequiredHeadings

  useEffect(() => {
    if (file?.data.length) {
      const [headers] = file.data
      const requiredHeaders = [VIDEO_URL, TITLE, ASSET_START_DATE]
      const isMissingHeaders = !requiredHeaders.every(v => headers.includes(v))
      setIsMissingRequiredHeadings(isMissingHeaders)

      const filterRequiredHeaders: string[] = file?.data[0].filter(
        item => item === VIDEO_URL || item === TITLE || item === ASSET_START_DATE
      ) || ['']
      const duplicateRequiredHeaders = toFindDuplicates(filterRequiredHeaders.map(header => header))
      setDuplicateHeaders(duplicateRequiredHeaders)

      const localAllValidatedHeadings = file?.data[0]?.flatMap((item, index) => {
        return headingsValidationCheck(item, index) || []
      })
      // No duplicates so the returned headings on the table have no repeated rows, which there would be with more than 1 error
      const noDuplicates = localAllValidatedHeadings.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      )
      // Required headings can be in any order, so we need to grab the index so we know what rules to apply for each column
      const findRequiredHeadingsIndex = (heading: string) => noDuplicates.findIndex(x => x?.title === heading)
      const videoUrlIndex = findRequiredHeadingsIndex(VIDEO_URL)
      const titleIndex = findRequiredHeadingsIndex(TITLE)
      const assetStateDateIndex = findRequiredHeadingsIndex(ASSET_START_DATE)
      const vocabListIndex = findRequiredHeadingsIndex(VOCABULARY_LIST_NAME)
      const presenterIndex = findRequiredHeadingsIndex(PRESENTER)
      setRequiredHeadingIndexes({
        VIDEO_URL: videoUrlIndex,
        TITLE: titleIndex,
        ASSET_START_DATE: assetStateDateIndex,
        VOCABULARY_LIST_NAME: vocabListIndex,
        PRESENTER: presenterIndex
      })

      setValidatedHeadings(noDuplicates)
      setAllValidatedHeadings(localAllValidatedHeadings)
      return
    }
    setValidatedHeadings([])
    setAllValidatedHeadings([])
  }, [file, VIDEO_URL, TITLE, ASSET_START_DATE, VOCABULARY_LIST_NAME, PRESENTER])

  useEffect(() => {
    if (file && requiredHeadingIndexes) {
      const arr: CsvValidation.ValidatedResult[] = []
      const indexes = requiredHeadingIndexes

      file.data.slice(1).flatMap((item, row) =>
        item.forEach((element, index) => {
          if (
            index !== indexes.VIDEO_URL &&
            index !== indexes.ASSET_START_DATE &&
            index !== indexes.TITLE &&
            index !== indexes.VOCABULARY_LIST_NAME &&
            index !== indexes.PRESENTER
          ) {
            // row + 2 because of 0 index and headers take up row 1
            arr.push(...genericValidation(element, row + 2, index, uuidv4()))
          }

          switch (index) {
            case indexes.VOCABULARY_LIST_NAME:
              arr.push(...vocabularyListValidation(element, row + 2, index, uuidv4()))
              break
            case indexes.PRESENTER:
              arr.push(...presenterValidation(element, row + 2, index, uuidv4()))
              break
            case indexes.VIDEO_URL:
              arr.push(...videoUrlValidation(element.toLowerCase(), row + 2, index, uuidv4()))
              break
            case indexes.ASSET_START_DATE:
              arr.push(...assetStartDateValidation(element, row + 2, index, uuidv4()))
              break
            case indexes.TITLE:
              arr.push(...titleValidation(element, row + 2, index, uuidv4()))
              break
            default:
          }
        }))

      const duplicateUrls = toFindDuplicates(arr.filter(({ isUrl }) => isUrl).map(({ element }) => element))

      setDuplicateElements(duplicateUrls)
      setAllErrors(arr.filter(item => item.isError) || [])

      return
    }
    setDuplicateElements([])
    setAllErrors([])
  }, [file, requiredHeadingIndexes])

  // --------------------------------------- Json converter, not related to csv validator ----------------------------------------------- //
  const convertToJSON = useCallback(
    ({ data } = {}) => {
      if (data && requiredHeadingIndexes) {
        const finalResult: CsvValidation.FinalResult[] = []

        const keys = data[0]
        const copy = [...keys]
        copy.splice(keys.indexOf(ASSET_START_DATE), 1, 'start_date')
        const items = data.slice(1)

        const result = items.map((item: string[]) => {
          return item.reduce((accum, current, index) => {
            const key = copy[index]
            const lowercaseKey = key.toLowerCase()
            const required = ['video_url', 'title', 'start_date', 'vocabulary_list_name']

            if (!key) {
              return accum
            }

            const res = {
              ...accum,
              [lowercaseKey]: current.split('; ')
            }

            const filteredResult = Object.keys(res)
              .filter(k => !required.includes(k))
              .reduce((obj, k) => {
                return { ...obj, [k]: res[key] }
              }, {})

            return {
              ...filteredResult
            }
          }, {})
        })
        const resultRequired = items.map(item => {
          return item.reduce((accum, current, index) => {
            const key = copy[index]
            const lowercaseKey = key.toLowerCase()
            const required = ['video_url', 'title', 'start_date', 'vocabulary_list_name']
            const convertValue = (currentValue: string) => {
              if (lowercaseKey === 'vocabulary_list_name') {
                return currentValue.split(',')
              }
              if (lowercaseKey === 'start_date') {
                // console.log(currentValue)
                // const date = new Date('2020/09/19').toUTCString()

                // console.log(`${format(date, 'yyyy-MM-dd')}`)

                // console.log(date)
                // const dateFormatted = format(parseISO(date.toUTCString()), 'yyyy-MM-dd hh:mm:ss')

                // console.log(`${format(date, 'yyyy-MM-dd hh:mm:ss').replaceAll(' ', '')}Z`)

                const toDashes = currentValue.replaceAll('/', '-').split('').reverse().join('')
                return toDashes.length === 10 ? `${toDashes}T00:00:00Z` : toDashes
              }
              return currentValue
            }
            if (!key) {
              return accum
            }
            const res = {
              ...accum,
              [lowercaseKey]: convertValue(current)
            }

            const filteredResult = Object.keys(res)
              .filter(k => required.includes(k))
              .reduce((obj, k) => {
                return { ...obj, [k]: res[key] }
              }, {})

            return {
              ...filteredResult
            }
          }, {})
        })
        result.forEach((element, index) => {
          finalResult.push({ meta: { keywords: element }, ...resultRequired[index] })
        })

        setJson(finalResult)
      }
    },

    [requiredHeadingIndexes, ASSET_START_DATE]
  )

  return {
    validatedHeadings,
    allValidatedHeadings,
    isMissingRequiredHeadings,
    headingsValidationCheck,
    requiredHeadingIndexes,
    allErrors,
    duplicateElements,
    duplicateHeaders,
    totalErrorsCount,
    convertToJSON,
    json
  }
}
