import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import lvp, { videojs, VideoJsPlayer, VideoJsPlayerOptions } from 'linius-video-player'

import { PlayerContext } from './types'

const config: VideoJsPlayerOptions = {
  nativeControlsForTouch: false,
  html5: {
    vhs: {
      // native hls implementations are recommended however we currently rely
      // on vhs's parsing of the manifest for building a segment timeline view
      overrideNative: true
    }
  },
  plugins: {
    ClipBarPlugin: {}
  }
}

const Context = createContext<PlayerContext.Value>({ state: 'idle' })

export const usePlayer = () => useContext(Context)

export const PlayerProvider = ({ children }: PlayerContext.Props) => {
  const [ref, setRef] = useState(null)
  const [state, setState] = useState<PlayerContext.State>()
  const [player, setPlayer] = useState<VideoJsPlayer>()

  useEffect(() => {
    player?.on(videojs.browser.IS_SAFARI ? ['loadstart'] : ['loadstart', 'seeking', 'waiting', 'stalled'], () => {
      setState('loading')
    })
    player?.on('canplay', () => {
      setState('canplay')
    })
    player?.on('playing', () => {
      setState('playing')
    })
    player?.on('pause', () => {
      setState('paused')
    })
    player?.on('ended', () => {
      setState('ended')
    })
    player?.on('emptied', () => {
      setState('idle')
    })
    player?.on('error', () => {
      setState('error')
      console.error(player.error())
    })
  }, [player])

  useEffect(() => {
    setPlayer(prev => {
      prev?.dispose()

      return ref ? lvp.player(ref, config) : undefined
    })
  }, [ref])

  const value = useMemo(() => {
    return { player, setRef, state } as PlayerContext.Value
  }, [player, setRef, state])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
