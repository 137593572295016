import { Theme } from '@mui/material'

export const getPlayerQueueClipStyles = (theme: Theme): StylesProps => ({
  card: {
    display: 'flex',
    px: 2,
    py: 1,
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey[25]
    },
    [theme.breakpoints.down('lg')]: { px: 1 },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  cardActionArea: {
    borderRadius: 1,
    position: 'relative',
    minWidth: 152,
    width: 152,
    height: 110,
    [theme.breakpoints.down('sm')]: {
      height: 0,
      minWidth: '100%',
      overflow: 'hidden',
      padding: '56.25% 0 0 0'
    }
  },
  cardMedia: {
    height: 110,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: 'auto'
    }
  },
  clipInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    ml: 1,
    [theme.breakpoints.down('sm')]: {
      ml: 0,
      mt: 1,
      minHeight: 110
    }
  },
  metaData: {
    color: theme.palette.grey[450],
    whiteSpace: 'nowrap',
    fontSize: '0.688rem'
  },
  playPauseIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) ',
    cursor: 'pointer',
    color: theme.palette.common.white,
    fontSize: 40
  },
  title: {
    overflow: 'hidden',
    textOverflow: ' ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    height: '2.4rem',
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
    mb: 0.5,
    wordBreak: 'break-word'
  },
  metaDataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'start'
    }
  },
  metaDataDivider: {
    fontSize: 2,
    mr: 1,
    [theme.breakpoints.down('lg')]: { display: 'none' }
  },
  clipLength: {
    ...theme.typography.body50,
    borderRadius: 1,
    backgroundColor: ' rgba(31, 31, 31, 0.8) ',
    color: theme.palette.common.white,
    position: 'absolute',
    bottom: 4,
    right: 4,
    height: 18,
    '& .MuiChip-label': {
      paddingLeft: 0.5,
      paddingRight: 0.5
    }
  }
})
