import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { userApi } from '../../apis/user'
import { UserSlice } from './types'

export const name = 'user'

const initialState: UserSlice.State = {
  hasInitialWarningShown: false,
  userDetails: null,
  apiKey: null
}

export const user = createSlice({
  name,
  initialState,
  reducers: {
    setHasInitialWarningShown(state, action: PayloadAction<boolean>) {
      state.hasInitialWarningShown = action.payload
    }
  },
  extraReducers: builder => {
    builder.addMatcher(userApi.endpoints.getUserDetails.matchFulfilled, (state, action) => {
      state.userDetails = action.payload
    })
    builder.addMatcher(userApi.endpoints.getTenantApiKeys.matchFulfilled, (state, action) => {
      const { apiKeys } = action.payload
      const preferredApiKey = apiKeys.sort((a, b) => {
        if (a === b) {
          return 0
        }
        if (!a.free) return -1
        return 1
      })?.[0]
      const apiKey = preferredApiKey.value
      state.apiKey = apiKey
    })
  }
})

export const selectAuthSlice = (state: { auth: UserSlice.State }): UserSlice.State => state[name]

export const selectHasInitialWarningShown = createSelector(selectAuthSlice, state => state.hasInitialWarningShown)
export const selectApiKey = createSelector(selectAuthSlice, state => state.apiKey)
export const selectUserDetails = createSelector(selectAuthSlice, state => state.userDetails)

export const { setHasInitialWarningShown } = user.actions
