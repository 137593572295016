interface ITimelineSegment {
  discontinuity: boolean
  duration: string
  uri: string
  timeline: string
  resolvedUri: string
  videoTimingInfo: {
    transmuxerPrependedSeconds: number
    transmuxedPresentationStart: number
    transmuxedDecodeStart: number
    transmuxedPresentationEnd: number
    transmuxedDecodeEnd: number
    baseMediaDecodeTime: number
  }
  audioTimingInfo: {
    transmuxerPrependedSeconds: number
    transmuxedPresentationStart: number
    transmuxedDecodeStart: number
    transmuxedPresentationEnd: number
    transmuxedDecodeEnd: number
    baseMediaDecodeTime: number
  }
  start: number
  end: number
}

export const getIndexFromCurrentTime = (currentTime: number, segments: number[]): number => {
  let time = 0
  let index = 0

  segments.forEach((segment: number, i: number) => {
    if (time <= currentTime) {
      time += segment
      index = i
    }
  })

  return index
}

export const getTimeFromIndex = (index: number, segments: number[]): number => {
  return segments.reduce((accumulator: number, segment: number, i: number) => {
    return i < index ? accumulator + segment : accumulator
  }, 0)
}

export const getSegmentsFromTimeline = (timeline: ITimelineSegment[]): number[] => {
  const segments: number[] = []

  let index = 0

  timeline.forEach((item: ITimelineSegment) => {
    const duration = parseFloat(item.duration)

    if (parseInt(item.timeline, 10) !== index) {
      index += 1

      segments.push(duration)
    } else {
      segments[segments.length - 1] += duration
    }
  })

  return segments
}
