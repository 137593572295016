import { useEffect, useRef } from 'react'
import classnames from 'classnames'
import useModalStyles from './Modal.styles'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { IModal } from './types'

export const Modal = ({
  children,
  isOpen,
  setOpen,
  classes = {},
  modalType = 'default',
  closeOnBlur = true
}: IModal.Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [isOpen])

  const styles = useModalStyles({ modalType })

  useOnClickOutside(ref, () => {
    if (closeOnBlur) {
      setOpen(false)
    }
  })

  if (!isOpen) {
    return null
  }

  return (
    <>
      <div className={classnames(styles.rootBackground, classes.background)} data-testid="modal-background" />
      <div className={classnames(styles.rootContent, classes.container)} ref={ref} data-testid="modal">
        {children}
      </div>
    </>
  )
}
