import { ThemeProvider } from '@mui/material'
import { Route } from 'react-router-dom'
import { defaultTheme } from '../../../design-system/themes'

interface PublicRouteProps {
  children: React.ReactNode
  path: string
}

export const PublicRoute = ({ path, children }: PublicRouteProps): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Route path={path}>{children}</Route>
    </ThemeProvider>
  )
}
