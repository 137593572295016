import { AnyAction } from 'redux'
import { AUTH_USER_CLEAR_HELP } from '.'

import { IUserBillingData, IUserData, IUserListData, IUserSubscription } from '../../../types/Auth'

import {
  AUTH_LOADING,
  AUTH_API_KEY_SET,
  AUTH_SUBSCRIPTION_SET,
  ACCESS_TOKEN_SET,
  AUTH_USER_BILLING_INFO_SET,
  AUTH_USER_CLEAR,
  AUTH_USER_CLEAR_TOKEN,
  AUTH_USER_LIST_SET,
  AUTH_PAGINATED_USER_LIST_SET,
  AUTH_USER_LOGOUT,
  AUTH_USER_SET,
  TENANT_ID_SET,
  REFRESH_TOKEN_SET,
  REFRESH_TOKEN_REFRESHING
} from './actions'

export interface AuthState {
  authLoading: boolean
  accessToken: string
  refreshToken: string
  refreshingToken: boolean
  tenantId: string | null
  user: IUserData | null
  userList: IUserListData
  paginatedUserList: IUserListData
  apiKey: string
  userBillingInfo: IUserBillingData | null
  userSubscription: IUserSubscription
  helpPrompt: true | false
}
const jsonUserId = localStorage.getItem('userId')
const initialUserId = jsonUserId ? parseInt(jsonUserId) : null

const initialState: AuthState = {
  authLoading: false,
  accessToken: '',
  refreshToken: '',
  refreshingToken: false,
  tenantId: null,
  user: {
    id: initialUserId,
    dateAdded: '',
    creationDate: ''
  },
  userList: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    number: 0,
    size: 10
  },
  paginatedUserList: {
    content: [],
    totalElements: 0,
    totalPages: 0,
    number: 0,
    size: 10
  },
  apiKey: '',
  userBillingInfo: null,
  userSubscription: {
    fetched: false
  },
  helpPrompt: false
}

const reducer = (state: AuthState = initialState, action: AnyAction): AuthState => {
  switch (action.type) {
    case TENANT_ID_SET:
      return {
        ...state,
        tenantId: action.payload
      }
    case ACCESS_TOKEN_SET:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        helpPrompt: true
      }
    case REFRESH_TOKEN_REFRESHING:
      return {
        ...state,
        refreshingToken: true
      }
    case REFRESH_TOKEN_SET:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        refreshingToken: false
      }
    case AUTH_USER_SET:
      return {
        ...state,
        user: action.user
      }
    case AUTH_USER_LIST_SET:
      return {
        ...state,
        userList: action.userList
      }
    case AUTH_PAGINATED_USER_LIST_SET:
      return {
        ...state,
        paginatedUserList: action.paginatedUserList
      }
    case AUTH_API_KEY_SET:
      return {
        ...state,
        apiKey: action.apiKey
      }
    case AUTH_USER_BILLING_INFO_SET:
      return {
        ...state,
        userBillingInfo: action.userBillingInfo
      }
    case AUTH_SUBSCRIPTION_SET:
      return {
        ...state,
        userSubscription: action.error ? 'ERROR' : action.userSubscription
      }
    case AUTH_USER_CLEAR:
      return {
        ...state,
        accessToken: '',
        apiKey: '',
        user: null
      }
    case AUTH_USER_CLEAR_TOKEN:
      return {
        ...state,
        accessToken: '',
        apiKey: ''
      }
    case AUTH_USER_CLEAR_HELP:
      return {
        ...state,
        helpPrompt: false
      }
    case AUTH_USER_LOGOUT:
      // This is supplementary to the action being caught in src/redux/index.ts. it's necessary as the initialState of the
      // redux store grabs the values for authToken, apiKey and userId from localstorage to allow for page refreshing.
      // TODO: Implement redux-persist so we can remove our custom localstorage logic.
      return {
        ...state,
        accessToken: '',
        apiKey: '',
        user: null
      }
    case AUTH_LOADING:
      return {
        ...state,
        authLoading: action.loading
      }
    default:
      return state
  }
}

export default reducer
export { initialState }
