import { createSelector } from 'reselect'

import { State } from '../../index'

import { ISideNavState } from './reducer'

const sideNavState = (state: State): ISideNavState => state.sideNav

const selectSideNavIsOpen = createSelector(sideNavState, (state: ISideNavState): boolean => state.isOpen)

const selectSideNavActiveItem = createSelector(sideNavState, (state: ISideNavState): string => state.activeItem)

const selectSideNavSecondaryActiveItem = createSelector(
  sideNavState,
  (state: ISideNavState): string => state.activeItemSecondary
)

export { selectSideNavIsOpen, selectSideNavActiveItem, selectSideNavSecondaryActiveItem }
