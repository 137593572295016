import { Theme } from '@mui/material'

export const getPlaylistCardStyles = (theme: Theme): StylesProps => ({
  card: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[150]}`,
    borderRadius: 2,
    '&:hover': { backgroundColor: theme.palette.grey[25] }
  },
  title: {
    overflow: 'hidden',
    textOverflow: ' ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    height: '3.1rem',
    maxHeight: '3.1rem',
    lineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) ',
    cursor: 'pointer',
    color: theme.palette.common.white,
    fontSize: 64
  },
  duration: {
    ...theme.typography.body50,
    borderRadius: 1,
    backgroundColor: ' rgba(31, 31, 31, 0.8) ',
    color: theme.palette.common.white,
    position: 'absolute',
    bottom: 8,
    right: 8,
    height: 18,
    '& .MuiChip-label': {
      paddingLeft: 0.5,
      paddingRight: 0.5
    }
  },
  cardContent: {
    paddingX: 2,
    paddingY: 2,
    '&:last-child': { paddingBottom: 2 }
  }
})
