import { createUseStyles } from 'react-jss'

import { ITimelineProps } from './Timeline'

const useTimelineStyles = createUseStyles({
  container: ({ zoomLevel, secondToggle }: ITimelineProps) => ({
    justifyContent: 'space-between',
    display: 'flex',
    fontSize: 12,
    paddingTop: 1,
    width: `${60 + Math.pow(zoomLevel, 2)}%`,
    left: `-${40 * (secondToggle / 100)}%`,
    position: 'relative',
    minWidth: '100%'
  })
})

export default useTimelineStyles
