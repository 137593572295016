import { useState, MouseEvent } from 'react'
import { Button, Menu, MenuItem, SxProps, useTheme } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'

export interface Action {
  title: string
  onClick: () => void
  color?: string
}

interface ExtraActionsProps {
  actions: Action[]
  buttonSize?: 'small' | 'medium'
  horizontalIcon?: boolean
  sx?: SxProps
}

export const ExtraActions = ({ actions, buttonSize = 'small', horizontalIcon = false, sx = {} }: ExtraActionsProps) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        size={buttonSize}
        sx={{ padding: 0, minWidth: 32, height: 32, borderRadius: '50%', color: theme.palette.grey[800], ...sx }}
        id="extra-actions-button"
        aria-controls={open ? 'extra-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {horizontalIcon ? <MoreHorizOutlinedIcon fontSize={buttonSize} /> : <MoreVertIcon fontSize={buttonSize} />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'extra-actions-button'
        }}>
        {actions.map(action =>
          (action ? (
            <MenuItem
              sx={{
                color: action.color ? action.color : 'inherit'
              }}
              key={action?.title}
              onClick={e => {
                e.stopPropagation()
                action?.onClick()
                handleClose()
              }}>
              {action?.title}
            </MenuItem>
          ) : null))}
      </Menu>
    </>
  )
}
