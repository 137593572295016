import { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

import { Player, usePlayer } from '../../components/Player'
import { TabObject, Tabs } from '../../components2.0/low-level/Tabs/Tabs'
import { PageHeader } from '../../components2.0/PageHeader'
import { InfinitePagination } from '../../components2.0/InfinitePagination'
import { PlayerQueueClip, QueueAction } from '../../components2.0/PlayerQueueClip'
import { getTimeFromIndex } from '../../utils'
import { createVideoString, generateTempClipId, WhizzardSearchResultClip } from '../../utils/clip'

import { sendVideoPlay } from '../../utils/googleAnalytics'
import { hideEditClipActionOnMobile } from '../../utils/search'

import { selectQueueCurrentIndex, selectVideoPlayerSegments } from '../../redux/selectors'
import { lvsApi } from '../../store/apis/lvs'
import { selectSearchResults } from '../../store/slices/search'
import { doSetQueueCurrentIndex } from '../../redux/actions'
import { ROUTES } from '../../RouteConfig/constants'
import {
  AddToPlaylistClip,
  AddToPlaylistDialog
} from '../../components2.0/PlaylistsDialogs/AddToPlaylistDialog/AddToPlaylistDialog'
import { PlayerQueueClipInsights } from '../../components2.0/PlayerQueueClipInsights'
import { useIsMobileDevice } from '../../hooks/useIsMobileDevice'

const SCROLL_BUFFER = 15

export const SearchResultsPlayAll = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()

  const currentIndex = useSelector(selectQueueCurrentIndex)
  const [fetchSearchResults] = lvsApi.endpoints.getBufferedSearch.useLazyQuery()
  const searchResults = useSelector(selectSearchResults)
  const [addToPlaylistDialogOpen, setAddToPlaylistDialogOpen] = useState(false)
  const [addToPlaylistClip, setAddToPlaylistClip] = useState<AddToPlaylistClip>()

  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))
  const { isMobileOrTablet } = useIsMobileDevice()

  const location = useLocation<{ page: number }>()
  const [currentPage, setCurrentPage] = useState(location.state?.page || 0)

  const clips = searchResults.count
    ? ((searchResults.pages[currentPage] || searchResults.pages[0]) as WhizzardSearchResultClip[])
    : undefined

  const videoString = createVideoString(clips)

  const { player, state } = usePlayer()

  const setDialogOpenState = useCallback((open: boolean) => {
    setAddToPlaylistDialogOpen(open)
  }, [])

  const scrollBodyRef = useRef<HTMLDivElement>(null)

  const handlePagination = useCallback(
    (direction: -1 | 1) => {
      setCurrentPage(currentPage + direction)
      player?.currentTime(0)
      dispatch(doSetQueueCurrentIndex(0))

      if (isBelowLgBreakpoint) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      } else {
        scrollBodyRef.current?.scroll({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    [currentPage, dispatch, isBelowLgBreakpoint, player]
  )

  const handlePaginationFetch = useCallback(() => {
    fetchSearchResults(false)
  }, [fetchSearchResults])

  useEffect(() => {
    if (!clips) {
      history.replace(ROUTES.SEARCH)
    }
    // I only want this useEffect to fire on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const segments = useSelector(selectVideoPlayerSegments)

  const scrollToClip = useCallback(
    (index: number, isMobile?: boolean) => {
      setTimeout(() => {
        const $itemToScrollTo = document.getElementById(`clip-${clips?.[index].id}`)
        const anchorPoint =
          ($itemToScrollTo?.offsetTop as number) - (scrollBodyRef.current?.offsetTop as number) - SCROLL_BUFFER

        if (isMobile) $itemToScrollTo?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        else {
          anchorPoint >= -SCROLL_BUFFER &&
            scrollBodyRef.current?.scroll({
              top: anchorPoint,
              left: 0,
              behavior: 'smooth'
            })
        }
      }, 0)
    },
    [clips]
  )

  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (_, newValue: number) => {
    scrollToClip(currentIndex, isBelowLgBreakpoint)
    setTabValue(newValue)
  }

  const src = `${process.env.REACT_APP_LVS_API}/v3/assembly/hls?${videoString}`

  const selectClip = useCallback(
    (id: string) => {
      if (!clips) return

      const index = clips.findIndex(result => result.id === id)
      if (index === currentIndex) {
        if (state === 'playing') {
          player?.pause()
          return
        }
        player?.play()
      } else {
        dispatch(doSetQueueCurrentIndex(index))

        if (segments.length) {
          player?.currentTime(getTimeFromIndex(index, segments))
        }

        player?.play()
      }
    },
    [currentIndex, dispatch, player, clips, segments, state]
  )
  const addClipToPlaylist = useCallback((clip: AddToPlaylistClip) => {
    setAddToPlaylistClip(clip)
    setAddToPlaylistDialogOpen(true)
  }, [])

  const playFullVideo = useCallback(
    (clip: WhizzardSearchResultClip): void => {
      const seekQuery = clip.startTime ? `?seekToTime=${clip.startTime.toFixed()}` : ''
      sendVideoPlay(clip.assetId, clip.title, clip.startTime, clip.endTime, 'play_full_video')
      const baseUrl = generatePath(ROUTES.SEARCH_RESULTS_PLAY_ALL_PLAY_FULL_VIDEO, {
        assetId: clip.assetId
      })
      history.push(`${baseUrl}${seekQuery}`)
    },
    [history]
  )

  const editClip = useCallback(
    (clip: WhizzardSearchResultClip): void => {
      const { startTime, duration } = clip
      sendVideoPlay(clip.assetId, clip.title, clip.startTime, clip.endTime, 'edit_video')

      history.push(
        generatePath(ROUTES.PLAY_ALL_EDIT_CLIP, {
          temporaryClipId: generateTempClipId(clip.assetId, startTime, duration)
        })
      )
    },
    [history]
  )

  useEffect(() => {
    scrollToClip(currentIndex)
    if (clips) {
      const playing_clip = clips[currentIndex]
      sendVideoPlay(
        playing_clip.assetId,
        playing_clip.title,
        playing_clip.startTime,
        playing_clip.endTime,
        'search_result',
        currentIndex
      )
    }
  }, [currentIndex, scrollToClip, clips])

  const getQueueItemActions = (
    clip: WhizzardSearchResultClip,
    index: number,
    queueItemType?: 'clip' | 'insight'
  ): QueueAction[] => {
    const isInsight = queueItemType === 'insight'
    const viewRespectiveItemTitle = isInsight ? 'View clip details' : 'View clip insights'

    const viewRespectiveItem = () => {
      setTabValue(isInsight ? 1 : 0)
      scrollToClip(index, isBelowLgBreakpoint)
    }

    return [
      {
        title: 'Add clip to playlist',
        onClick: () =>
          addClipToPlaylist({
            assetId: Number(clip.assetId),
            offset: clip.startTime,
            duration: clip.duration,
            title: clip.title,
            description: ''
          })
      },
      { title: 'Play full video', onClick: () => playFullVideo(clip) },
      { title: viewRespectiveItemTitle, onClick: () => viewRespectiveItem() },
      { title: 'Edit clip', onClick: () => editClip(clip) }
    ]
  }

  const searchResultsClipTitles: string[] = []

  const results = clips
    ? clips.map((result, index) => {
        searchResultsClipTitles.push(result.title)

        const actions = getQueueItemActions(result, index, 'clip')
        const tagLabels = result.tags.map(tag => (typeof tag === 'string' ? tag : (tag as { label: string }).label))

        return (
          <Grid xs={12} md={6} lg={12} item id={`clip-${result.id}`} key={result.id}>
            <PlayerQueueClip
              assetId={result.assetId}
              isActive={index === currentIndex}
              isPlaying={index === currentIndex && state === 'playing'}
              title={result.title}
              creationDate={result.createdDate}
              startsAtTime={result.startTime}
              actions={hideEditClipActionOnMobile(actions, isMobileOrTablet)}
              tags={tagLabels}
              insights={result.insights}
              duration={result.duration}
              playClip={() => selectClip(result.id)}
            />
          </Grid>
        )
      })
    : null

  const insights = clips
    ? clips.map((clip, index) => {
        const actions = getQueueItemActions(clip, index, 'insight')

        return (
          <Grid sm={12} md={6} lg={12} item id={`clip-${clip.id}`} key={clip.id}>
            <PlayerQueueClipInsights
              queueIndex={index + 1}
              insights={clip.insights}
              isActive={index === currentIndex}
              actions={hideEditClipActionOnMobile(actions, isMobileOrTablet)}
              duration={clip.duration}
              playClip={() => selectClip(clip.id)}
            />
          </Grid>
        )
      })
    : null

  const clipQueueTabs: TabObject[] = [
    {
      title: 'Insights',
      component: (
        <Grid
          ref={scrollBodyRef}
          sx={{
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'calc(100vh - 180px)',
              overflowY: 'scroll'
            }
          }}
          container>
          {insights}
          <InfinitePagination
            page={currentPage}
            currentTotal={searchResults.pages.length}
            onPageChange={handlePagination}
            onFetch={handlePaginationFetch}
            complete={searchResults.complete}
          />
        </Grid>
      )
    },
    {
      title: 'Video clips',
      component: (
        <Grid
          ref={scrollBodyRef}
          sx={{
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'calc(100vh - 180px)',
              overflowY: 'scroll'
            }
          }}
          container>
          {results}
          <InfinitePagination
            page={currentPage}
            currentTotal={searchResults.pages.length}
            onPageChange={handlePagination}
            onFetch={handlePaginationFetch}
            complete={searchResults.complete}
          />
        </Grid>
      )
    }
  ]

  return (
    <>
      <PageHeader />
      <Container
        sx={{
          [theme.breakpoints.down('lg')]: { paddingLeft: 0, paddingRight: 0 }
        }}>
        <Grid
          sx={{ '& > .MuiGrid-item': { [theme.breakpoints.down('lg')]: { paddingTop: 0 } } }}
          container
          columnSpacing={2}>
          <Grid item xs={12} lg={7} sx={{ paddingLeft: 0 }}>
            <Player autoplay src={src} />
            <Typography ml={2} mt={isBelowLgBreakpoint ? 3 : 4} variant={isBelowLgBreakpoint ? 'body1' : 'body2'}>
              Playing clip {currentIndex + 1}
            </Typography>
            <Typography ml={2} mt={1} mb={1} variant={isBelowLgBreakpoint ? 'head400' : 'head500'}>
              {searchResultsClipTitles[currentIndex]}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={5}>
            <Tabs
              sx={{
                px: 1,
                pt: 1,
                [theme.breakpoints.up('lg')]: { border: `1px solid ${theme.palette.grey[50]}` }
              }}
              value={tabValue}
              handleChange={handleTabChange}
              tabs={clipQueueTabs}
            />
          </Grid>
        </Grid>
      </Container>
      {addToPlaylistClip && (
        <AddToPlaylistDialog
          open={addToPlaylistDialogOpen}
          setClose={() => setDialogOpenState(false)}
          clip={addToPlaylistClip}
        />
      )}
    </>
  )
}
