import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ThemeProvider, Box, useTheme, useMediaQuery } from '@mui/material'
import { defaultTheme } from '../../../design-system/themes'
import { UoNWarning } from '../../../components2.0/UoNWarning'
import Sidenav from '../../../components/Sidenav/Sidenav'
import Topnav from '../../../components/Topnav/TopNavigation'
import { ROUTES } from '../../constants'
import { selectAccessToken } from '../../../store/slices/auth'

export interface Props extends RouteProps {
  children: React.ReactNode
  path: string
}

export const PrivateRoute = ({ children, path, ...routeProps }: Props) => {
  const accessToken = useSelector(selectAccessToken)

  const [openTopNav, setOpenTopNav] = useState(true)

  const openTopNavToggle = useCallback(() => {
    setOpenTopNav(!openTopNav)
  }, [openTopNav])

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const render = useCallback(
    ({ location }) => {
      if (!accessToken) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
      if (location.pathname === ROUTES.ROOT) {
        return (
          <Redirect
            to={{
              pathname: ROUTES.SEARCH,
              state: { from: location }
            }}
          />
        )
      }
      return (
        <ThemeProvider theme={defaultTheme}>
          <UoNWarning />
          {isMobile ? <Topnav /> : <Sidenav openTopNavToggle={openTopNavToggle} />}
          <Box
            sx={{
              marginLeft: isMobile ? 0 : 9 /* temp while we have sidenav */
            }}
          >
            {children}
          </Box>
        </ThemeProvider>
      )
    },
    [accessToken, isMobile, openTopNavToggle, children]
  )

  return <Route {...routeProps} path={path} render={render} />
}
