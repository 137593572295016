import { StrictMode } from 'react'

import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import Notifications from 'react-notify-toast'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material'

import App from './App'
import { persistor, store } from './store'
import history from './history'
import { registerServiceWorker } from './utils/service-worker'
import { PlayerProvider } from './components/Player'
import { skyTheme } from './design-system/themes'

registerServiceWorker()

ReactDOM.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={skyTheme}>
        <CssBaseline />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Notifications options={{ zIndex: 100000 }} />
            <Router history={history}>
              <PlayerProvider>
                <App />
              </PlayerProvider>
            </Router>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>,
  document.getElementById('root')
)

// expose store when run in Cypress
// @ts-ignore
if (window.Cypress) window.store = store
