import { Box, Skeleton, SxProps, useTheme } from '@mui/material'

import { getPlaylistCardStyles } from './PlaylistCard.styles'

interface Props {
  sx?: SxProps
}

export const PlaylistCardSkeleton = ({ sx = {} }: Props) => {
  const theme = useTheme()
  const styles = getPlaylistCardStyles(theme)
  return (
    <Box sx={{ ...styles.card, ...sx } as SxProps}>
      <Skeleton variant="text" width="auto" sx={{ backgroundColor: theme.palette.grey[250], aspectRatio: '16/9' }} />
      <Box px={1} py={2}>
        <Skeleton variant="text" width="95%" height={20} sx={{ mb: 2, backgroundColor: theme.palette.grey[150] }} />
        <Skeleton variant="text" width="95%" height={50} sx={{ mb: 1.25, backgroundColor: theme.palette.grey[200] }} />
        <Skeleton variant="text" width={134} height={10} sx={{ mb: 2, backgroundColor: theme.palette.grey[25] }} />
      </Box>
    </Box>
  )
}
