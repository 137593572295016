import { common } from '@mui/material/colors'

export const colors = {
  white: common.white,
  black: common.black,
  SCOO: {
    900: '#1B6068',
    800: '#2897AB',
    500: '#34BCDA',
    200: '#59D0E8',
    50: '#E1F7FC'
  },
  MART: {
    900: '#292E4D',
    800: '#343B61',
    700: '#3B4773',
    500: '#4A5987',
    200: '#A1ABC2',
    50: '#E8EBF0'
  },
  STEE: {
    700: '#5A7EBF'
  },
  CARN: {
    900: '#C61623',
    800: '#D51F2E',
    500: '#e84242',
    400: '#FD5158',
    200: '#F79B9F',
    50: '#FEECEF'
  },
  SAFF: {
    800: '#EC8B10',
    600: '#EDAC1A',
    400: '#EFC432',
    300: '#f6c459',
    200: '#F5DC84',
    50: '#FCF7E1'
  },
  PERS: {
    800: '#006949',
    400: '#00A685',
    200: '#7BCBB6',
    50: '#DEF2EE'
  },
  DEYO: {
    500: '#7DC189'
  },
  AZUR: {
    800: '#0063F7',
    400: '#00AAFF',
    200: '#87CEFF',
    50: '#E2F3FF'
  }
}

export const brandColours = {
  legacyPurple: {
    900: '#292E4D',
    800: '#343B61',
    700: '#3B4773',
    500: '#4A5987',
    200: '#A1ABC2',
    50: '#E8EBF0'
  },
  liniusBlue: {
    900: '#063A55',
    800: '#0A5680',
    700: '#0D73AA',
    600: '#1090D5',
    500: '#40A6DD',
    400: '#70BCE6',
    300: '#88C8EA',
    200: '#9FD3EE',
    100: '#CFE9F7',
    50: '#E7F4FB'
  }
}

export const semanticColours = {
  errorRed: {
    900: '#7F1D1D',
    800: '#991B1B',
    700: '#B91C1C',
    600: '#DC2626',
    500: '#EF4444',
    400: '#F87171',
    300: '#FCA5A5',
    200: '#FECACA',
    100: '#FEE2E2',
    50: '#FEF2F2'
  },
  warningYellow: {
    900: '#78350F',
    800: '#92400E',
    700: '#B45309',
    600: '#D97706',
    500: '#F59E0B',
    400: '#FBBF24',
    300: '#FCD34D',
    200: '#FDE68A',
    100: '#FEF3C7',
    50: '#FFFBEB'
  },
  successGreen: {
    900: '#14532D',
    800: '#166534',
    700: '#15803D',
    600: '#16A34A',
    500: '#22C55E',
    400: '#4ADE80',
    300: '#86EFAC',
    200: '#BBF7D0',
    100: '#DCFCE7',
    50: '#F0FDF4'
  },
  infoBlue: {
    900: '#1E3A8A',
    800: '#1E40AF',
    700: '#1D4ED8',
    600: '#2563EB',
    500: '#3B82F6',
    400: '#60A5FA',
    300: '#93C5FD',
    200: '#BFDBFE',
    100: '#DBEAFE',
    50: '#EFF6FF'
  }
}

export const greyScaleColours = {
  defualt: {
    900: '#0F0F0F',
    800: '#1F1F1F',
    700: '#292929',
    600: '#333333',
    550: '#3D3D3D',
    500: '#424242',
    450: '#616161',
    400: '#8A8A8A',
    350: '#949494',
    300: '#B3B3B3',
    250: '#C7C7C7',
    200: '#E0E0E0',
    150: '#EBEBEB',
    100: '#F0F0F0',
    50: '#F5F5F5',
    25: '#FAFAFA',
    white: '##FFFFFF'
  }
}
