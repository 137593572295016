import { NavOptions } from '../NavOptions/NavOptions'
import usenavOptionListStyles from './NavOption.styles'
import { INavOption } from './types'

export const NavOption = ({ text, buttonClick, isActive, locked, subOptions, isHidden }: INavOption.Props) => {
  const classes = usenavOptionListStyles({ isActive, locked, isHidden })

  const handleButtonClick = () => {
    if (!locked) {
      buttonClick()
    }
  }

  return (
    <>
      <button data-testid="navOption" onClick={handleButtonClick} className={classes.button}>
        {text}
      </button>

      {subOptions && (
        <div className={classes.subOption}>
          <NavOptions navOptions={subOptions} />
        </div>
      )}
    </>
  )
}
