import { createSelector } from 'reselect'

import { Playlist, Video } from '../../types'
import { IVideoClip, numberToDouble } from '../../utils'
import { selectAssets } from '../slices/assets'
import { selectAllPlaylists } from '../slices/playlists'
import { selectQueueCurrentIndex, selectQueueCurrentPlaylistId } from '../slices/queue'
import { selectSearchResultsPlaylist } from '../slices/search'

export const selectQueuePlaylist = createSelector(
  [selectSearchResultsPlaylist, selectAllPlaylists, selectQueueCurrentPlaylistId],
  (searchPlaylist: Playlist, playlists: Playlist[], playlistId: string): Playlist => {
    const playlist = playlists.find((playlist: Playlist) => playlist.id === playlistId)

    if (playlist) {
      return playlist
    }

    return searchPlaylist
  }
)

export const selectQueueClips = createSelector(
  selectQueuePlaylist,
  (playlist: Playlist): IVideoClip[] => playlist.clips || []
)

export const selectQueueLength = createSelector(selectQueueClips, (clips: IVideoClip[]): number => clips.length)

export const selectQueueCurrentItem = createSelector(
  [selectQueueClips, selectQueueCurrentIndex],
  (clips: IVideoClip[], index: number): IVideoClip | undefined => {
    return clips?.[index] || undefined
  }
)

export const selectQueueCurrentItemAssetId = createSelector(selectQueueCurrentItem, (clip: IVideoClip | undefined):
  | string
  | null => {
  if (clip) {
    return clip.assetId
  }

  return null
})

export const selectQueueCurrentItemId = createSelector(selectQueueCurrentItem, (clip: IVideoClip | undefined):
  | string
  | null => {
  if (clip) {
    return clip.id
  }

  return null
})

export const selectCurrentAsset = createSelector(
  [selectAssets, selectQueueCurrentItemAssetId],
  (videos: Video[], assetId: string | null): Video | undefined => {
    if (assetId) {
      return videos.find((video: Video) => video.id === assetId)
    }

    return undefined
  }
)

export const selectAllAssetVideoStrings = createSelector([selectQueueCurrentItemAssetId], (assetId: string | null):
  | string
  | null => {
  if (assetId) {
    return `id=${assetId}&o=0&d=-1`
  }

  return null
})

export const selectAllQueueVideoStrings = createSelector([selectQueueClips], (clips: IVideoClip[]): string => {
  return clips.reduce((accumulator: string, clip: IVideoClip, index: number) => {
    return `${accumulator}id=${clip.assetId}&o=${numberToDouble(Number(clip.startTime))}&d=${numberToDouble(
      Number(clip.duration)
    )}${index < clips.length - 1 ? '&' : ''}`
  }, '')
})

export const selectQueueTotalDuration = createSelector(selectQueueClips, (clips: IVideoClip[]): number => {
  return clips.reduce((accumulator: number, clip: IVideoClip) => {
    return accumulator + clip.duration
  }, 0)
})
