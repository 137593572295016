import { createSelector, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import { authApi } from '../../apis/auth'
import { AuthSlice } from './types'

export const name = 'authSlice'

const initialState: AuthSlice.State = {
  accessToken: null,
  refreshToken: null
}

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearStore(state) {
      state.accessToken = null
      state.refreshToken = null
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    }
  },
  extraReducers: builder => {
    const { authenticate, putCode } = authApi.endpoints
    const authenticated = isAnyOf(authenticate.matchFulfilled, putCode.matchFulfilled)

    builder.addMatcher(authenticated, (state, action) => {
      state.accessToken = action.payload.token
      state.refreshToken = action.payload.refreshToken
    })
  }
})

export const selectAuthSlice = (state: { search: AuthSlice.State }): AuthSlice.State => state[name]

export const selectAccessToken = createSelector(selectAuthSlice, state => state.accessToken)
export const selectRefreshToken = createSelector(selectAuthSlice, state => state.refreshToken)

export const { setAccessToken, setRefreshToken, clearStore } = authSlice.actions
