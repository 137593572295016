import { NavOption } from '../NavOption'
import { usenavOptionsStyles } from './NavOptions.styles'
import { INavOptions } from './types'

export const NavOptions = ({ navOptions }: INavOptions.Props) => {
  const classes = usenavOptionsStyles({})
  return (
    <div data-testid={'navOptions'} className={classes.navOptionsContainer}>
      {navOptions.map((navOption, index) => (
        <NavOption key={index} {...navOption} />
      ))}
    </div>
  )
}
