import { AnyAction } from 'redux'

import { Video } from '../../../types'

const ASSET_ITEM_ADD = 'assets/item/add'
const CLIP_CORRECT_DURATIONS_ADDED = 'assets/duration/add'

const addAssetItem = (payload: Video): AnyAction => {
  return {
    type: ASSET_ITEM_ADD,
    payload
  }
}

const addClipCorrectDuration = (payload: number[]): AnyAction => {
  return {
    type: CLIP_CORRECT_DURATIONS_ADDED,
    payload
  }
}

export { ASSET_ITEM_ADD, CLIP_CORRECT_DURATIONS_ADDED, addAssetItem, addClipCorrectDuration }
