import { createUseStyles } from 'react-jss'

import { Breakpoint } from '../../design-system-legacy/breakpoints'
import background from '../../design-system-legacy/whizzardBackground.svg'
import mobileBackGround from '../../design-system-legacy/whizzardBackgroundMobile.svg'
import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'

const useLoginStyles = createUseStyles({
  resetPasswordLink: {
    color: colours.white,
    textDecoration: 'none',
    fontWeight: 'bold',
    marginBottom: toResponsiveUnitFromPixels(20)
  },
  wrapper: {
    backgroundColor: colours.white,
    position: 'relative',
    backgroundSize: 'cover',
    color: 'black',
    [Breakpoint.XS]: {
      backgroundColor: colours.martinique
    }
  },
  termsText: {
    fontSize: toResponsiveUnitFromPixels(14, true),
    color: colours.black,
    [Breakpoint.XS]: {
      color: colours.white
    }
  },
  registerFormContainer: {
    position: 'relative'
  },
  header: {
    position: 'fixed',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'space-between',
    color: 'black',
    width: '100%',
    padding: toResponsiveUnitFromPixels([10, 20]),
    alignItems: 'center'
  },
  logo: {
    [Breakpoint.XS]: {
      visibility: 'collapse'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '85%'
  },
  h3: {
    color: colours.black,
    margin: 0,
    fontSize: toResponsiveUnitFromPixels(20, true),
    [Breakpoint.MOBILELANDSCAPE]: { color: colours.white },
    [Breakpoint.XS]: { color: colours.white }
  },

  p: {
    color: colours.black,
    fontWeight: 'normal',
    fontSize: toResponsiveUnitFromPixels(16, true),
    margin: 0,
    [Breakpoint.MD]: { fontSize: toResponsiveUnitFromPixels(14, true) }
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column'
  },
  formInput: {
    outline: 'none',
    marginBottom: toResponsiveUnitFromPixels(20),
    display: 'block',
    width: '100%',
    height: toResponsiveUnitFromPixels(40),
    borderRadius: toResponsiveUnitFromPixels(6),
    fontSize: toResponsiveUnitFromPixels(16, true),
    padding: toResponsiveUnitFromPixels([18, 15, 15]),
    fontFamily: 'Muli,sans-serif',
    color: colours.black,
    backgroundColor: `${colours.alabaster}`,
    border: toResponsiveUnitFromPixels(`1px solid  ${colours.gallery}`),

    '::placeholder': {
      color: colours.frenchGray
    },
    ':focus-within': {
      outline: 'none',
      boxShadow: toResponsiveUnitFromPixels('0 0 0 1px #777777')
    }
  },
  loginButton: {
    margin: 'auto',
    color: colours.white,
    border: 'none',
    backgroundColor: colours.scooter,
    borderRadius: toResponsiveUnitFromPixels(6),
    fontSize: toResponsiveUnitFromPixels(14, true),
    padding: toResponsiveUnitFromPixels([10, 30]),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
    ':focus-within': {
      outline: 'none',
      boxShadow: toResponsiveUnitFromPixels([0, 0, 0, 2])
    }
  },
  link: {
    textDecoration: 'none',
    color: `${colours.darkGrey}`,
    display: 'block',
    textAlign: 'right',
    fontWeight: 'normal',
    border: 'none',
    fontSize: toResponsiveUnitFromPixels(14, true)
  },
  routerLink: {
    fontSize: toResponsiveUnitFromPixels(12),
    textDecoration: 'none',
    color: `${colours.scooter}`,
    outline: 'none',
    fontWeight: 'bold',
    marginLeft: toResponsiveUnitFromPixels(4)
  },
  form: {
    marginTop: toResponsiveUnitFromPixels(20),
    maxWidth: toResponsiveUnitFromPixels(400),
    width: '85%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [Breakpoint.MD]: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
    [Breakpoint.SM]: {
      marginTop: 0,
      paddingTop: toResponsiveUnitFromPixels(10)
    }
  },
  loginWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  hero: {
    backgroundColor: colours.martinique,
    overflow: 'hidden',
    position: 'relative',
    background: `url(${background}) no-repeat center `,
    backgroundSize: 'cover',
    width: '100%',
    color: 'white',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [Breakpoint.MOBILELANDSCAPE]: {
      display: 'none'
    },
    [Breakpoint.MD]: {
      width: '100%'
    },
    [Breakpoint.XS]: {
      display: 'none'
    }
  },
  loginSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 40px',
    [Breakpoint.MOBILELANDSCAPE]: {
      minHeight: '100vh',
      justifyContent: 'space-around',
      padding: toResponsiveUnitFromPixels([80, 0, 20, 0]),
      background: `url(${mobileBackGround}) center no-repeat `,
      backgroundColor: colours.martinique,
      backgroundSize: 'cover'
    },

    [Breakpoint.XS]: {
      minHeight: '100vh',
      justifyContent: 'space-between',
      padding: toResponsiveUnitFromPixels([40, 0]),
      backgroundColor: colours.martinique,
      background: `url(${mobileBackGround}) center no-repeat `,
      backgroundSize: 'cover'
    }
  },
  section: {
    flexBasis: '50%',

    [Breakpoint.XS]: { flexBasis: 'unset' }
  },
  heroHeading: {
    color: `${colours.white}`,
    fontSize: toResponsiveUnitFromPixels(48, true),
    paddingBottom: toResponsiveUnitFromPixels(8),
    marginBottom: toResponsiveUnitFromPixels(10)
  },
  heroContentContainer: {
    maxWidth: toResponsiveUnitFromPixels(920),
    padding: toResponsiveUnitFromPixels([0, 30, 0, 60]),

    [Breakpoint.MD]: { paddingTop: toResponsiveUnitFromPixels(50) }
  },
  copyRegisteredContainer: {
    textAlign: 'left',
    color: 'white',
    position: 'relative',
    marginTop: -24
  },
  label: {
    display: 'flex',
    marginBottom: toResponsiveUnitFromPixels(30)
  },
  checkbox: {
    height: toResponsiveUnitFromPixels(20),
    marginRight: toResponsiveUnitFromPixels(15),
    marginTop: toResponsiveUnitFromPixels(14)
  },
  passwordContainer: {
    width: '100%',
    display: 'flex',
    position: 'relative'
  },
  registerButton: {
    margin: 'auto',
    color: colours.white,
    border: 'none',
    backgroundColor: colours.scooter,
    borderRadius: toResponsiveUnitFromPixels(6),
    fontSize: toResponsiveUnitFromPixels(16, true),
    width: '100%',
    padding: toResponsiveUnitFromPixels([10, 30]),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    ':focus-within': {
      outline: 'none',
      boxShadow: toResponsiveUnitFromPixels([0, 0, 0, 2])
    }
  },

  termAndConditions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: toResponsiveUnitFromPixels(-20),
    marginBottom: toResponsiveUnitFromPixels(34),
    color: `${colours.darkGrey}`
  },
  error: {
    border: toResponsiveUnitFromPixels('1px solid red')
  },
  success: {
    border: toResponsiveUnitFromPixels('1px solid green')
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerLinkContainer: {
    // display: 'none',
    fontSize: toResponsiveUnitFromPixels(12, true),
    color: colours.black,
    [Breakpoint.MOBILELANDSCAPE]: {
      color: colours.white,
      display: 'block'
    },

    [Breakpoint.SM]: {
      color: colours.white,
      display: 'block'
    }
  },
  lightBlue: {
    color: `${colours.scooter}`,
    textDecoration: 'none'
  },
  inputContainer: {
    position: 'relative'
  },
  promptContainer: {
    margin: toResponsiveUnitFromPixels([10, 0, 20, 0])
  },
  termsLabel: {
    display: 'flex',
    flexDirection: 'row'
  },
  bottomErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: toResponsiveUnitFromPixels(20),
    justifyContent: 'space-between',
    [Breakpoint.SM]: {
      justifyContent: 'center'
    }
  },
  layoutContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100% - ${toResponsiveUnitFromPixels(90)})`,
    flexDirection: 'column',
    padding: 0,
    [Breakpoint.MOBILELANDSCAPE]: {
      height: '100vh'
    },
    [Breakpoint.SM]: {
      padding: toResponsiveUnitFromPixels(35)
    }
  },
  white: {
    color: 'white'
  },
  resetPasswordContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: toResponsiveUnitFromPixels(15)
  },
  errorContainerFull: {
    position: 'absolute',
    top: toResponsiveUnitFromPixels(-30)
  },
  relative: {
    position: 'relative'
  },
  justifyContent: 'space-between',
  [Breakpoint.XS]: { justifyContent: 'center' },
  hideOnMobile: {
    [Breakpoint.MOBILELANDSCAPE]: { display: 'none' },
    [Breakpoint.XS]: { display: 'none' }
  },
  resetPasswordHeadingContainer: {
    '& > h3': {
      color: colours.white,
      fontSize: '1.5rem',
      margin: toResponsiveUnitFromPixels([0, 0, 10, 0])
    },
    [Breakpoint.SM]: { marginBottom: toResponsiveUnitFromPixels(20) }
  },
  resetPasswordTextContainer: {
    color: colours.white,
    fontSize: '1rem',
    width: toResponsiveUnitFromPixels(580),
    marginBottom: toResponsiveUnitFromPixels(10),
    '& > p': {
      margin: 0
    }
  },
  verificationEmailLink: {
    textDecoration: 'underline',
    color: colours.scooter,
    cursor: 'pointer',
    paddingTop: toResponsiveUnitFromPixels(30),
    fontSize: toResponsiveUnitFromPixels(16, true),
    [Breakpoint.MD]: { fontSize: toResponsiveUnitFromPixels(14, true) },
    [Breakpoint.SM]: { paddingTop: toResponsiveUnitFromPixels(10) }
  },
  linkContainer: {
    marginBottom: toResponsiveUnitFromPixels(35),
    color: colours.white,
    alignItems: 'center',
    display: 'flex',
    fontSize: toResponsiveUnitFromPixels(14, true)
  },
  emailSent: {
    paddingLeft: toResponsiveUnitFromPixels(5)
  },
  modalContainer: {
    width: '50%',
    background: colours.white,
    maxWidth: 800
  },
  modalContent: {
    fontSize: '0.875em',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    minWidth: 100
  },
  modalClose: { position: 'absolute', top: 20, right: 5 },
  modalHeading: {
    textAlign: 'center',
    marginTop: 15
  },
  modalSectionContent: {
    textAlign: 'center',
    padding: '0 0 3em 0'
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3em 0 0 0',
    '& button': {
      fontWeight: 600
    }
  },
  modalCheckMark: {
    position: 'absolute',
    top: 0,
    left: '0.625rem',
    height: '20px',
    width: '20px',
    backgroundColor: colours.frenchGray,
    transform: 'translateX(-50%)',
    border: ' 1px solid #94969A',
    borderRadius: '3px',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none'
    }
  },
  modalCheckMarkLabel: {
    paddingLeft: '1.7em !important'
  },
  invalidError: {
    visibility: (props: any) => (props?.error ? 'visible' : 'hidden')
  },
  logoContainer: {
    display: 'none',
    [Breakpoint.XS]: { display: 'block' }
  },
  resend: {
    marginTop: 30
  },
  backButton: {
    marginTop: 8
  }
})

export default useLoginStyles
