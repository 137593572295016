import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { notify } from 'react-notify-toast'
import qs from 'qs'

import { Player, usePlayer } from '../../components/Player'
import { TextField } from '../../components2.0/low-level/TextField/TextField'
import { TabObject, Tabs } from '../../components2.0/low-level/Tabs/Tabs'
import { PageHeader } from '../../components2.0/PageHeader'
import { VideoTranscriptItem } from '../../components2.0/VideoTranscriptItem'

import { useGetAssetTranscriptQuery } from '../../store/apis/lvs'
import { formatTime } from '../../utils'
import { formatDateWithoutTimezone } from '../../utils/date'
import { ExtraActions } from '../../components2.0/low-level/ExtraActions/ExtraActions'
import { DATE_FORMATS } from '../../constants'
import { ROUTES } from '../../RouteConfig/constants'

export const PlayFullVideo = () => {
  const theme = useTheme()
  const history = useHistory()
  const { player } = usePlayer()
  const location = useLocation<{ prevPagePlaylistId: string }>()
  const { assetId } = useParams<{ assetId: string }>()
  const { seekToTime } = qs.parse(location.search.replace('?', ''))
  const playerSrc = `${process.env.REACT_APP_LVS_API}/v3/assembly/hls?id=${assetId}&o=0&d=-1`
  const shareURL = `${window.location.origin}/play-full-video/${assetId}`
  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))
  const { data: assetData, isLoading, isError } = useGetAssetTranscriptQuery(Number(assetId))

  const breadCrumbOveride = location.state && {
    to: { 1: `/playlists/view-playlist/${location.state.prevPagePlaylistId}` }
  }

  const [playerCurrentTime, setPlayerCurrentTime] = useState<number>(0)
  player?.on(['timeupdate', 'seeking'], () => {
    setPlayerCurrentTime(player.currentTime())
  })

  const handleShare = useCallback(() => {
    notify.show('Link copied to clipboard', 'success')
    navigator.clipboard.writeText(shareURL)
  }, [shareURL])

  const setSeekTo = useCallback(
    (time: number) => {
      player?.currentTime(time)
    },
    [player]
  )

  useEffect(() => {
    if (seekToTime) {
      // If seekToTime parameter is provided in querystring then seek to that time
      setSeekTo(Number(seekToTime))
    }
  }, [seekToTime, setSeekTo])

  const [searchValue, setSearchValue] = useState<string>('')
  const handleSearchInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }, [])

  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = useCallback((_, newValue: number) => {
    setTabValue(newValue)
  }, [])

  useEffect(() => {
    if (!isError) return
    notify.show('Full video not found', 'error')
    history.push(ROUTES.ROOT)
  }, [history, isError])

  const clipQueueTabs: TabObject[] = [
    {
      title: 'Transcript',
      component: (
        <Grid sx={{ p: 2, [theme.breakpoints.down('lg')]: { p: 1, pt: 2 } }} container>
          <TextField
            sx={{ mb: 2 }}
            placeholder="Search"
            fullWidth
            multiline
            onChange={handleSearchInput}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchValue('')}>
                    <ClearOutlinedIcon sx={{ display: searchValue.length ? 'block' : 'none' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {isLoading || !assetData ? (
            <Skeleton width={500} height={400} />
          ) : (
            <Box
              sx={{
                [theme.breakpoints.up('lg')]: {
                  maxHeight: 'calc(100vh - 348px)',
                  overflowY: 'auto'
                }
              }}
              data-testid="transcript"
            >
              {assetData.assetTranscript
                .filter(item => item.value.includes(searchValue.toLowerCase()))
                .map(item => {
                  const startTimeInSeconds = item.startTime / 1000
                  const endTimeInSeconds = item.endTime / 1000
                  return (
                    <VideoTranscriptItem
                      key={item.value}
                      onClick={() => player?.currentTime(startTimeInSeconds)}
                      isActive={playerCurrentTime >= startTimeInSeconds && playerCurrentTime <= endTimeInSeconds}
                      text={item.value}
                      timeStamp={startTimeInSeconds}
                      textToHighlight={[searchValue]}
                    />
                  )
                })}
            </Box>
          )}
        </Grid>
      )
    }
  ]

  return (
    <>
      <PageHeader breadCrumbOveride={breadCrumbOveride}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            {isLoading || !assetData ? (
              <Skeleton width={400} height={32} sx={{ mb: 1 }} />
            ) : (
              <Typography
                variant={isBelowLgBreakpoint ? 'head600' : 'head800'}
                color={theme.palette.grey[800]}
                mb={1}
                data-testid="page-title"
              >
                {assetData.asset ? assetData.asset.name : ''}
              </Typography>
            )}

            <Box display="flex" alignItems="center">
              {isLoading || !assetData ? (
                <Skeleton width={400} height={24} />
              ) : (
                <>
                  <Box display="flex" alignItems="center" data-testid="recorded-on-datetime">
                    <Typography
                      variant={isBelowLgBreakpoint ? 'body1' : 'body2'}
                      color={theme.palette.grey[500]}
                      sx={{ mr: 0.5, [theme.breakpoints.down('md')]: { display: 'none' } }}
                    >
                      Recorded on:
                    </Typography>
                    <Typography variant={isBelowLgBreakpoint ? 'body1' : 'body2'} color={theme.palette.grey[500]}>
                      {formatDateWithoutTimezone(assetData.asset.startDate, DATE_FORMATS.CALENDAR_WITH_TIME)}
                    </Typography>
                  </Box>
                  <CircleIcon sx={{ fontSize: 5, mx: 2, [theme.breakpoints.down('lg')]: { mr: 1 } }} />

                  <Box display="flex" alignItems="center">
                    <Typography
                      variant={isBelowLgBreakpoint ? 'body1' : 'body2'}
                      color={theme.palette.grey[500]}
                      sx={{ mr: 0.5, [theme.breakpoints.down('md')]: { display: 'none' } }}
                    >
                      Duration:
                    </Typography>
                    <Typography
                      variant={isBelowLgBreakpoint ? 'body1' : 'body2'}
                      color={theme.palette.grey[500]}
                      data-testid="duration"
                    >
                      {formatTime(assetData.asset.duration)}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Button
            variant="contained"
            sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}
            onClick={handleShare}
          >
            <ShareOutlinedIcon sx={{ fontSize: 20, mr: 1.5 }} />
            Share
          </Button>
          <ExtraActions
            sx={{ display: 'none', [theme.breakpoints.down('md')]: { display: 'flex' } }}
            actions={[{ title: 'Share', onClick: handleShare }]}
          />
        </Box>
      </PageHeader>

      <Container
        sx={{
          [theme.breakpoints.down('lg')]: { paddingLeft: 0, paddingRight: 0 }
        }}
      >
        <Grid
          sx={{ '& > .MuiGrid-item': { [theme.breakpoints.down('lg')]: { paddingTop: 0 } } }}
          container
          columnSpacing={2}
        >
          <Grid item sm={12} lg={6}>
            {isLoading || !assetData ? <Skeleton height={320} width={568} /> : <Player autoplay src={playerSrc} />}
          </Grid>
          <Grid item sm={12} lg={6}>
            <Tabs
              sx={{
                px: 1,
                pt: 1,
                [theme.breakpoints.up('lg')]: { border: `1px solid ${theme.palette.grey[50]}` }
              }}
              value={tabValue}
              handleChange={handleTabChange}
              tabs={clipQueueTabs}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
