import { SvgIcon, SvgIconProps } from '@mui/material'

/* eslint-disable max-len */

export const TranscriptIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.87868 3.87868C2.44129 3.31607 3.20435 3 4 3H20C20.7956 3 21.5587 3.31607 22.1213 3.87868C22.6839 4.44129 23 5.20435 23 6V18C23 18.7956 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7956 21 20 21H4C3.20435 21 2.44129 20.6839 1.87868 20.1213C1.31607 19.5587 1 18.7956 1 18V6C1 5.20435 1.31607 4.44129 1.87868 3.87868ZM4 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V18C21 18.2652 20.8946 18.5196 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19H4C3.73478 19 3.48043 18.8946 3.29289 18.7071C3.10536 18.5196 3 18.2652 3 18V6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5ZM5.29289 15.2929C5.10536 15.4804 5 15.7348 5 16C5 16.2652 5.10536 16.5196 5.29289 16.7071C5.48043 16.8946 5.73478 17 6 17H14C14.2652 17 14.5196 16.8946 14.7071 16.7071C14.8946 16.5196 15 16.2652 15 16C15 15.7348 14.8946 15.4804 14.7071 15.2929C14.5196 15.1054 14.2652 15 14 15H6C5.73478 15 5.48043 15.1054 5.29289 15.2929ZM18.7071 11.2929C18.5196 11.1054 18.2652 11 18 11H10C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H18C18.2652 13 18.5196 12.8946 18.7071 12.7071C18.8946 12.5196 19 12.2652 19 12C19 11.7348 18.8946 11.4804 18.7071 11.2929ZM16.2929 15.2929C16.1054 15.4804 16 15.7348 16 16C16 16.2652 16.1054 16.5196 16.2929 16.7071C16.4804 16.8946 16.7348 17 17 17H18C18.2652 17 18.5196 16.8946 18.7071 16.7071C18.8946 16.5196 19 16.2652 19 16C19 15.7348 18.8946 15.4804 18.7071 15.2929C18.5196 15.1054 18.2652 15 18 15H17C16.7348 15 16.4804 15.1054 16.2929 15.2929ZM7.70711 11.2929C7.51957 11.1054 7.26522 11 7 11H6C5.73478 11 5.48043 11.1054 5.29289 11.2929C5.10536 11.4804 5 11.7348 5 12C5 12.2652 5.10536 12.5196 5.29289 12.7071C5.48043 12.8946 5.73478 13 6 13H7C7.26522 13 7.51957 12.8946 7.70711 12.7071C7.89464 12.5196 8 12.2652 8 12C8 11.7348 7.89464 11.4804 7.70711 11.2929Z"
          fill="#1F1F1F"
        />
      </svg>
    </SvgIcon>
  )
}

export const OCRIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 4.91667V3.25H2V19.9167H7V18.25H3.66667V4.91667H7Z" fill="#1F1F1F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.79625 13.6169C6.10975 13.9206 6.58 14.0725 7.207 14.0725C7.834 14.0725 8.3018 13.9206 8.6104 13.6169C8.9239 13.3083 9.08065 12.8724 9.08065 12.309V10.8469C9.08065 10.2786 8.9239 9.84268 8.6104 9.53898C8.3018 9.23527 7.834 9.08342 7.207 9.08342C6.58 9.08342 6.10975 9.23527 5.79625 9.53898C5.48765 9.84268 5.33335 10.2786 5.33335 10.8469V12.309C5.33335 12.8724 5.48765 13.3083 5.79625 13.6169ZM8.05198 13.132C7.86094 13.323 7.57928 13.4185 7.207 13.4185C6.83962 13.4185 6.55796 13.323 6.36202 13.132C6.17098 12.936 6.07546 12.6544 6.07546 12.287V10.8689C6.07546 10.4966 6.17098 10.215 6.36202 10.0239C6.55796 9.83288 6.83962 9.73736 7.207 9.73736C7.57928 9.73736 7.86094 9.83288 8.05198 10.0239C8.24302 10.215 8.33854 10.4966 8.33854 10.8689V12.287C8.33854 12.6544 8.24302 12.936 8.05198 13.132Z"
          fill="#1F1F1F"
        />
        <path
          d="M10.5611 13.6243C10.8795 13.9231 11.3424 14.0725 11.9498 14.0725C12.3417 14.0725 12.6821 14.0039 12.9711 13.8667C13.2601 13.7296 13.4757 13.5386 13.6177 13.2936C13.7647 13.0438 13.8235 12.7524 13.7941 12.4193C13.7843 12.3507 13.7549 12.3164 13.7059 12.3164H13.1254C13.0715 12.3164 13.047 12.3433 13.0519 12.3972C13.0862 12.7156 13.0054 12.9679 12.8095 13.154C12.6184 13.3353 12.3368 13.4259 11.9645 13.4259C11.5971 13.4259 11.3155 13.3304 11.1195 13.1393C10.9236 12.9483 10.8256 12.6691 10.8256 12.3017V10.8542C10.8256 10.4868 10.9236 10.2076 11.1195 10.0166C11.3155 9.82553 11.5971 9.73001 11.9645 9.73001C12.3319 9.73001 12.6086 9.82064 12.7948 10.0019C12.9809 10.1831 13.0666 10.4354 13.0519 10.7587C13.047 10.8126 13.0715 10.8395 13.1254 10.8395H13.7132C13.7426 10.8395 13.7622 10.8297 13.772 10.8101C13.7818 10.7905 13.7892 10.766 13.7941 10.7366C13.843 10.3938 13.7941 10.0998 13.6471 9.85492C13.5051 9.6051 13.2871 9.41406 12.9932 9.28181C12.6993 9.14955 12.3539 9.08342 11.9571 9.08342C11.3497 9.08342 10.8844 9.23527 10.5611 9.53898C10.2427 9.83778 10.0835 10.2762 10.0835 10.8542V12.309C10.0835 12.8822 10.2427 13.3206 10.5611 13.6243Z"
          fill="#1F1F1F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9381 13.9843C14.8842 13.9843 14.8572 13.9574 14.8572 13.9035V9.25242C14.8572 9.19853 14.8842 9.17159 14.9381 9.17159H16.4076C17.015 9.17159 17.4608 9.28671 17.7449 9.51693C18.029 9.74716 18.171 10.0876 18.171 10.5383C18.171 10.886 18.0902 11.1775 17.9286 11.4126C17.7669 11.6429 17.5318 11.8021 17.2232 11.8902V11.8976L18.4355 13.8888C18.4502 13.9133 18.4527 13.9353 18.4429 13.9549C18.4331 13.9745 18.411 13.9843 18.3768 13.9843H17.7008C17.6567 13.9843 17.6248 13.9672 17.6053 13.9329L16.4443 11.9931H15.5993V13.9035C15.5993 13.9574 15.5724 13.9843 15.5185 13.9843H14.9381ZM15.5993 11.4053H16.3562C16.7333 11.4053 17.0052 11.3392 17.1717 11.2069C17.3432 11.0746 17.4289 10.864 17.4289 10.575C17.4289 10.2958 17.3505 10.0974 17.1938 9.97983C17.037 9.86227 16.7725 9.80349 16.4002 9.80349H15.5993V11.4053Z"
          fill="#1F1F1F"
        />
        <path d="M17 18.25V19.9167H22V3.25H17V4.91667H20.3333V18.25H17Z" fill="#1F1F1F" />
      </svg>
    </SvgIcon>
  )
}

export const PeopleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 4C15.213 4 17.982 5.908 19.254 8.648C18.9451 8.50179 18.6461 8.33543 18.359 8.15C17.95 7.892 17.486 7.599 16.899 7.378C16.23 7.123 15.499 7 14.665 7C13.831 7 13.1 7.123 12.431 7.377C11.844 7.6 11.38 7.893 10.959 8.158C10.581 8.395 10.256 8.601 9.856 8.752C9.41 8.921 8.926 9 8.33 9C7.735 9 7.251 8.921 6.806 8.752C6.406 8.601 6.078 8.394 5.7 8.154C5.539 8.053 5.36 7.946 5.18 7.841C6.587 5.542 9.113 4 12 4ZM12 20C7.589 20 4 16.411 4 12C4 11.19 4.123 10.41 4.348 9.673C4.442 9.731 4.533 9.783 4.631 9.846C5.042 10.106 5.507 10.4 6.097 10.622C6.766 10.877 7.496 11 8.33 11C9.163 11 9.894 10.877 10.565 10.623C11.152 10.4 11.616 10.107 12.037 9.842C12.415 9.605 12.74 9.399 13.14 9.247C13.585 9.079 14.069 9 14.665 9C15.261 9 15.745 9.079 16.19 9.248C16.589 9.398 16.915 9.604 17.304 9.85C17.713 10.108 18.177 10.401 18.764 10.623C19.127 10.761 19.512 10.852 19.917 10.914C19.966 11.271 20 11.631 20 12C20 16.411 16.411 20 12 20Z"
          fill="#1F1F1F"
        />
        <path
          d="M8.5 15C9.32843 15 10 14.3284 10 13.5C10 12.6716 9.32843 12 8.5 12C7.67157 12 7 12.6716 7 13.5C7 14.3284 7.67157 15 8.5 15Z"
          fill="#1F1F1F"
        />
        <path
          d="M15.5 15C16.3284 15 17 14.3284 17 13.5C17 12.6716 16.3284 12 15.5 12C14.6716 12 14 12.6716 14 13.5C14 14.3284 14.6716 15 15.5 15Z"
          fill="#1F1F1F"
        />
      </svg>
    </SvgIcon>
  )
}

export const SearchPromptIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 161 131" {...props}>
      <svg width="161" height="131" viewBox="0 0 161 131" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="62.5322" cy="62.0322" r="60.5322" fill="white" stroke="#C7C7C7" strokeWidth="3" />
        <g filter="url(#filter0_b_219_16670)">
          <rect
            x="32.6809"
            y="42.1809"
            width="61.0675"
            height="39.9244"
            rx="1.9245"
            stroke="#B3B3B3"
            strokeWidth="2.36188"
          />
          <rect x="39.5" y="73.3018" width="32.5718" height="2.34924" fill="#B3B3B3" />
          <rect x="39.5" y="77.4131" width="46.2863" height="1.76193" fill="#B3B3B3" />
          <path
            d="M57.7852 68.1887V56.4277C57.7852 55.1502 59.2419 54.4193 60.2661 55.183L67.6487 60.6881C68.4493 61.2851 68.4863 62.4718 67.7243 63.1175L60.3417 69.3733C59.3328 70.2282 57.7852 69.5111 57.7852 68.1887Z"
            fill="#34BBDA"
          />
        </g>
        <path
          d="M138.406 54.1413V48.6501C138.406 46.6986 139.181 44.8271 140.561 43.4471C141.941 42.0672 143.813 41.292 145.764 41.292C147.716 41.292 149.587 42.0672 150.967 43.4471C152.347 44.8271 153.123 46.6986 153.123 48.6501V54.1413C153.122 54.4033 153.018 54.6546 152.833 54.8399C152.647 55.0252 152.396 55.1294 152.134 55.1297H139.395C139.133 55.1294 138.881 55.0252 138.696 54.8399C138.511 54.6546 138.407 54.4033 138.406 54.1413Z"
          fill="black"
        />
        <path d="M156.644 125.603L154.048 126.321L150.043 116.653L153.873 115.593L156.644 125.603Z" fill="#FFB8B8" />
        <path
          d="M151.991 126.093L156.996 124.708L157.869 127.859L149.712 130.116C149.597 129.702 149.566 129.27 149.618 128.844C149.671 128.418 149.807 128.006 150.018 127.632C150.23 127.259 150.513 126.931 150.852 126.666C151.19 126.402 151.577 126.207 151.991 126.093Z"
          fill="#1F1F1F"
        />
        <path d="M129.591 127.599H126.898L125.617 117.212H129.592L129.591 127.599Z" fill="#FFB8B8" />
        <path
          d="M124.973 126.829H130.166V130.099H121.703C121.703 129.67 121.788 129.244 121.952 128.848C122.116 128.451 122.357 128.09 122.661 127.787C122.964 127.483 123.325 127.242 123.722 127.078C124.118 126.914 124.544 126.829 124.973 126.829Z"
          fill="#1F1F1F"
        />
        <path
          d="M126.339 69.5512C126.532 69.8106 126.778 70.0248 127.062 70.1786C127.346 70.3323 127.66 70.422 127.983 70.4412C128.305 70.4604 128.628 70.4087 128.928 70.2897C129.228 70.1708 129.499 69.9875 129.72 69.7527L136.878 72.9723L136.227 68.9458L129.53 66.5057C129.11 66.1595 128.579 65.979 128.036 65.9985C127.492 66.018 126.975 66.2361 126.582 66.6115C126.189 66.9869 125.947 67.4935 125.902 68.0353C125.857 68.5771 126.013 69.1164 126.339 69.5512H126.339Z"
          fill="#FFB8B8"
        />
        <path
          d="M141.217 93.764C138.968 93.764 136.64 93.429 134.683 92.4326C133.644 91.9141 132.723 91.1868 131.978 90.2961C131.233 89.4055 130.68 88.3707 130.354 87.2566C129.334 84.0279 130.62 80.856 131.863 77.7887C132.633 75.8879 133.361 74.0927 133.55 72.3231L133.616 71.6948C133.91 68.8729 134.164 66.4357 135.57 65.5217C136.299 65.0478 137.279 65.0208 138.566 65.4388L150.657 69.3664L150.212 92.3173L150.138 92.3419C150.075 92.3631 145.813 93.764 141.217 93.764Z"
          fill="#1F1F1F"
        />
        <path
          d="M138.352 58.2949C138.352 58.2949 144.282 56.5377 148.895 57.1967C148.895 57.1967 146.259 71.6933 147.138 76.5255C148.016 81.3578 131.872 78.3925 135.167 73.78L136.265 68.2888C136.265 68.2888 134.069 66.0924 136.045 63.4566L138.352 58.2949Z"
          fill="#34BBDA"
        />
        <path
          d="M135.434 93.3287L128.125 91.9582L130.677 72.3204C130.848 71.7721 134.801 59.3298 136.597 58.6566C137.9 58.1976 139.233 57.8272 140.587 57.5481L140.847 57.4961L139.381 59.6945L133.546 73.6759L135.434 93.3287Z"
          fill="#3D3D3D"
        />
        <path
          d="M131.627 125.193L122.184 123.619L127.483 101.978L135.369 72.3838L135.447 72.9093C135.453 72.9485 136.19 76.8146 147.121 75.0991L147.218 75.0841L147.244 75.1777L160.456 121.861L149.696 123.654L139.881 89.5944L131.627 125.193Z"
          fill="#1F1F1F"
        />
        <path
          d="M144.715 97.0051L144.721 96.8389C144.727 96.6545 145.377 78.3068 145.16 70.9279C144.942 63.5238 147.339 57.1119 147.364 57.048L147.383 56.9965L147.436 56.9813C150.537 56.0953 153.228 58.7396 153.255 58.7665L153.293 58.8042L152.409 66.0912L156.191 92.5897L144.715 97.0051Z"
          fill="#333333"
        />
        <path
          d="M144.254 55.0024C147.234 55.0024 149.649 52.5871 149.649 49.6076C149.649 46.6282 147.234 44.2129 144.254 44.2129C141.275 44.2129 138.859 46.6282 138.859 49.6076C138.859 52.5871 141.275 55.0024 144.254 55.0024Z"
          fill="#FFB8B8"
        />
        <path
          d="M136.668 49.0892C136.67 47.546 137.284 46.0665 138.375 44.9753C139.466 43.8841 140.945 43.2703 142.489 43.2686H143.587C145.13 43.2703 146.61 43.8841 147.701 44.9753C148.792 46.0665 149.406 47.546 149.407 49.0892V49.199H147.087L146.295 46.9824L146.137 49.199H144.937L144.538 48.0806L144.458 49.199H136.668V49.0892Z"
          fill="black"
        />
        <path
          d="M143.722 55.7476C143.613 55.6 143.548 55.4247 143.534 55.2418C143.52 55.0589 143.559 54.8758 143.644 54.7137C144.808 52.5001 146.438 48.4098 144.275 45.8873L144.119 45.7061H150.398V55.1332L144.694 56.1397C144.637 56.1499 144.578 56.1551 144.519 56.1551C144.364 56.1551 144.21 56.1182 144.071 56.0473C143.933 55.9765 143.813 55.8737 143.722 55.7476Z"
          fill="black"
        />
        <path
          d="M118.442 16.825C114.048 13.1175 108.672 10.7669 102.966 10.0594C97.2611 9.35193 91.4731 10.318 86.3068 12.84C81.1404 15.362 76.8186 19.3313 73.867 24.2648C70.9154 29.1984 69.4614 34.8834 69.682 40.6283C69.9025 46.3731 71.7881 51.9299 75.1092 56.6226C78.4303 61.3154 83.0438 64.9416 88.3883 67.0601C93.7327 69.1787 99.5778 69.6981 105.212 68.5553C110.846 67.4124 116.027 64.6566 120.124 60.6231L156.501 91.3182C157.055 91.7861 157.771 92.015 158.494 91.9545C159.216 91.894 159.884 91.5491 160.352 90.9956C160.82 90.4421 161.049 89.7254 160.989 89.0031C160.928 88.2809 160.583 87.6122 160.03 87.1443L160.026 87.1409L123.648 56.4458C127.874 50.3807 129.683 42.9561 128.72 35.627C127.757 28.2978 124.092 21.5925 118.442 16.825ZM116.097 53.4901C113.263 56.8487 109.497 59.2915 105.275 60.5096C101.052 61.7277 96.5637 61.6664 92.3762 60.3334C88.1888 59.0004 84.4906 56.4557 81.7495 53.0209C79.0083 49.5862 77.3472 45.4158 76.9762 41.037C76.6052 36.6582 77.5411 32.2677 79.6654 28.4208C81.7896 24.5738 85.0069 21.4432 88.9105 19.4248C92.814 17.4064 97.2284 16.5908 101.595 17.0812C105.962 17.5716 110.086 19.346 113.445 22.18H113.445C115.675 24.0616 117.512 26.3641 118.852 28.956C120.193 31.5478 121.009 34.3782 121.255 37.2856C121.502 40.1931 121.173 43.1205 120.288 45.9009C119.403 48.6812 117.979 51.26 116.097 53.4901Z"
          fill="#34BBDA"
        />
        <path
          opacity="0.3"
          d="M84.7869 56.1419C80.5244 52.5449 77.7705 47.4765 77.0721 41.9431C76.3736 36.4096 77.7816 30.8158 81.0165 26.2725C80.5917 26.7003 80.181 27.1484 79.7844 27.6167C77.9028 29.8467 76.4787 32.4255 75.5937 35.2059C74.7086 37.9862 74.3799 40.9137 74.6262 43.8211C74.8725 46.7285 75.6891 49.5589 77.0292 52.1508C78.3694 54.7426 80.207 57.0451 82.437 58.9268C84.667 60.8085 87.2458 62.2325 90.0262 63.1175C92.8066 64.0026 95.734 64.3313 98.6414 64.085C101.549 63.8387 104.379 63.0222 106.971 61.682C109.563 60.3418 111.865 58.5042 113.747 56.2742C114.143 55.8053 114.515 55.3251 114.865 54.8336C110.931 58.7867 105.653 61.1157 100.081 61.3581C94.5092 61.6005 89.0497 59.7385 84.7869 56.1419Z"
          fill="#25AECE"
        />
        <path
          d="M145.985 78.9829C146.298 79.0596 146.625 79.0666 146.942 79.0034C147.258 78.9401 147.557 78.8083 147.817 78.617C148.078 78.4258 148.293 78.1798 148.447 77.8964C148.602 77.6131 148.693 77.2992 148.714 76.977L156.277 74.8777L153.202 72.1973L146.492 74.6017C145.948 74.6033 145.424 74.8043 145.018 75.1665C144.613 75.5288 144.355 76.0271 144.292 76.5672C144.23 77.1072 144.367 77.6514 144.679 78.0966C144.991 78.5419 145.456 78.8572 145.985 78.9829Z"
          fill="#FFB8B8"
        />
        <path
          d="M149.128 77.8721L148.668 73.4997L154.986 70.0141L150.867 65.0281L151.539 59.4272L153.212 58.71L153.264 58.7769C154.054 59.7917 160.975 68.7226 160.975 69.8267C160.975 70.9606 159.651 74.2906 157.839 75.1964C156.092 76.0702 149.53 77.7683 149.252 77.8403L149.128 77.8721Z"
          fill="#333333"
        />
        <g filter="url(#filter1_b_219_16670)">
          <ellipse cx="99.0953" cy="39.5073" rx="22.6773" ry="22.4361" fill="white" fillOpacity="0.2" />
        </g>
        <defs>
          <filter
            id="filter0_b_219_16670"
            x="21.5182"
            y="31.0182"
            width="83.3932"
            height="62.2497"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImage" stdDeviation="4.99089" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_219_16670" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_219_16670" result="shape" />
          </filter>
          <filter
            id="filter1_b_219_16670"
            x="70.6777"
            y="11.331"
            width="56.836"
            height="56.3526"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImage" stdDeviation="2.87014" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_219_16670" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_219_16670" result="shape" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export const NoPlaylistClipsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 160 160" {...props}>
      <svg width="160" height="160" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.2347 81.4626C11.4004 68.1229 1.56692 45.6782 9.70082 25.6616V25.6567C16.8495 8.0729 60.3019 -1.12851 76.3082 15.1064C92.3098 31.3318 95.7744 57.2499 91.8616 71.0134C87.9487 84.7722 80.3191 97.1483 54.7314 92.2345C49.4505 91.218 45.066 90.4424 41.3913 89.7968L41.346 89.7888C27.259 87.2904 23.677 86.6551 20.2347 81.4626Z"
          fill="#F0F0F0"
        />
        <circle cx="49.7221" cy="49.1669" r="27.5" stroke="black" strokeWidth="1.11111" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.9447 78.3338C67.4394 78.3338 80.0003 65.7729 80.0003 50.2782C80.0003 34.7836 67.4394 22.2227 51.9447 22.2227C36.4501 22.2227 23.8892 34.7836 23.8892 50.2782C23.8892 65.7729 36.4501 78.3338 51.9447 78.3338ZM41.6669 46.8893C41.6669 44.6188 43.5075 42.7782 45.778 42.7782H58.1114C60.3819 42.7782 62.2225 44.6188 62.2225 46.8893V55.1115C62.2225 57.382 60.3819 59.2227 58.1114 59.2227H45.778C43.5075 59.2227 41.6669 57.382 41.6669 55.1115V46.8893Z"
          fill="#E0E0E0"
        />
        <circle cx="49.7221" cy="49.1669" r="27.5" stroke="black" strokeWidth="1.11111" />
        <mask id="path-5-inside-1_1342_19452" fill="white">
          <path d="M40.4792 27.5702C40.3426 27.2485 40.4925 26.8762 40.8175 26.7478C45.9608 24.7166 51.6335 24.4481 56.9456 25.9845C57.2813 26.0816 57.4657 26.4381 57.3601 26.7712V26.7712C57.2546 27.1044 56.8991 27.288 56.5632 27.1914C51.5391 25.7457 46.1773 25.9994 41.3122 27.9132C40.987 28.0411 40.6157 27.8919 40.4792 27.5702V27.5702Z" />
        </mask>
        <path
          d="M40.4792 27.5702C40.3426 27.2485 40.4925 26.8762 40.8175 26.7478C45.9608 24.7166 51.6335 24.4481 56.9456 25.9845C57.2813 26.0816 57.4657 26.4381 57.3601 26.7712V26.7712C57.2546 27.1044 56.8991 27.288 56.5632 27.1914C51.5391 25.7457 46.1773 25.9994 41.3122 27.9132C40.987 28.0411 40.6157 27.8919 40.4792 27.5702V27.5702Z"
          stroke="black"
          strokeWidth="2.22222"
          mask="url(#path-5-inside-1_1342_19452)"
        />
        <mask id="path-6-inside-2_1342_19452" fill="white">
          <path d="M62.1843 28.8971C62.3591 28.5945 62.7466 28.4901 63.0447 28.6724C65.0236 29.8828 66.8245 31.3626 68.3954 33.0694C68.6321 33.3266 68.6048 33.727 68.3417 33.957V33.957C68.0787 34.1871 67.6795 34.1598 67.4425 33.903C65.9635 32.3003 64.2706 30.9092 62.4117 29.7688C62.1138 29.5861 62.0096 29.1998 62.1843 28.8971V28.8971Z" />
        </mask>
        <path
          d="M62.1843 28.8971C62.3591 28.5945 62.7466 28.4901 63.0447 28.6724C65.0236 29.8828 66.8245 31.3626 68.3954 33.0694C68.6321 33.3266 68.6048 33.727 68.3417 33.957V33.957C68.0787 34.1871 67.6795 34.1598 67.4425 33.903C65.9635 32.3003 64.2706 30.9092 62.4117 29.7688C62.1138 29.5861 62.0096 29.1998 62.1843 28.8971V28.8971Z"
          stroke="black"
          strokeWidth="2.22222"
          mask="url(#path-6-inside-2_1342_19452)"
        />
        <rect
          x="63.547"
          y="74.8985"
          width="6.52162"
          height="23.4913"
          rx="3.26081"
          transform="rotate(-33.2297 63.547 74.8985)"
          fill="#E0E0E0"
          stroke="black"
          strokeWidth="1.11111"
        />
        <path d="M83.333 6.66602L83.333 15.5549" stroke="black" strokeWidth="1.11111" strokeLinecap="round" />
        <path d="M87.7773 11.1113H78.8885" stroke="black" strokeWidth="1.11111" strokeLinecap="round" />
        <path
          d="M55.8888 41.666H43.5554C41.2849 41.666 39.4443 43.5066 39.4443 45.7771V53.9993C39.4443 56.2699 41.2849 58.1105 43.5554 58.1105H55.8888C58.1593 58.1105 59.9999 56.2699 59.9999 53.9993V45.7771C59.9999 43.5066 58.1593 41.666 55.8888 41.666Z"
          stroke="black"
          strokeWidth="1.11111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.8888 41.666H43.5554C41.2849 41.666 39.4443 43.5066 39.4443 45.7771V53.9993C39.4443 56.2699 41.2849 58.1105 43.5554 58.1105H55.8888C58.1593 58.1105 59.9999 56.2699 59.9999 53.9993V45.7771C59.9999 43.5066 58.1593 41.666 55.8888 41.666Z"
          stroke="black"
          strokeWidth="1.11111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.8054 49.888L47.6665 46.8047V52.9714L52.8054 49.888Z"
          stroke="black"
          strokeWidth="1.11111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="24.4042" y="72.2525" width="6" height="5" transform="rotate(33.8985 24.4042 72.2525)" stroke="black" />
      </svg>
    </SvgIcon>
  )
}

export const LogoWithText = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 161 50" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="159.599"
        height="47.979"
        viewBox="0 0 159.599 47.979"
        data-testid="whizzard-logo"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.345"
            y1="0.595"
            x2="1.379"
            y2="0.148"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#3dbad7" />
            <stop offset="1" stopColor="#064d69" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="0.139"
            y1="-0.142"
            x2="0.834"
            y2="1.096"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#064d69" />
            <stop offset="0.298" stopColor="#3dbad7" />
            <stop offset="0.791" stopColor="#3dbad7" />
            <stop offset="1" stopColor="#064d69" />
          </linearGradient>
          <linearGradient id="linear-gradient-3" x1="0.58" y1="0.415" x2="-0.107" y2="1.085">
            <stop offset="0" stopColor="#3dbad7" />
            <stop offset="1" stopColor="#064d69" />
          </linearGradient>
        </defs>
        <g id="Group_3518" data-name="Group 3518" transform="translate(-690.813 -443.748)">
          <path
            id="Path_69940"
            data-name="Path 69940"
            d="M713.453,489.325H699.669V471.982h-8.856V495.72h8.856v0h13.784Z"
            transform="translate(0 -17.897)"
            fill="#fff"
          />
          <path
            id="Path_69941"
            data-name="Path 69941"
            d="M758.974,471.982V495.72h8.886V477.4Z"
            transform="translate(-43.205 -17.897)"
            fill="#fff"
          />
          <path
            id="Path_69942"
            data-name="Path 69942"
            d="M952.9,473.69v23.738h-8.886V479.107Z"
            transform="translate(-160.499 -18.979)"
            fill="#fff"
          />
          <path
            id="Path_69943"
            data-name="Path 69943"
            d="M1065.365,473.69c14.418,0,13.451,7.3,13.451,7.3h-9.759s.352-2.374-3.692-2.374-3.6,1.582-3.6,1.582-.242,1.385,4.572,2.022c5.806.769,13.364.967,13.364,6.946s-4.308,8.264-13.8,8.264-13.363-2.725-13.363-7.561h10.2s.088,2.286,3.385,2.286,3.56-1.319,3.56-1.934-.351-1.407-3.56-1.846-13.144-.7-13.144-7.385S1059.913,473.69,1065.365,473.69Z"
            transform="translate(-229.284 -18.98)"
            fill="#fff"
          />
          <g id="Group_3516" data-name="Group 3516" transform="translate(727.957 443.748)">
            <path
              id="Path_69944"
              data-name="Path 69944"
              d="M804.02,503.835v0a17.483,17.483,0,0,1-3.9-19.077,17.317,17.317,0,0,1,3.7-5.6h0a17.4,17.4,0,0,1,2.435-2.057q.2-.143.411-.279l-1.261-2.144-.016-.026a6.706,6.706,0,0,0-2.081-2.189c-.047-.031-.094-.061-.142-.091a6.994,6.994,0,0,0-4.646-.963,6.436,6.436,0,0,0-5.354,4.773,26.181,26.181,0,0,0,3.5,21.276c1.872,2.813,4.5,4.454,7.359,6.382Z"
              transform="translate(-792.267 -461.238)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_69945"
              data-name="Path 69945"
              d="M838.777,483.861a23.14,23.14,0,0,0,2.838-3.509,26.133,26.133,0,0,0,3.631-8.072,6.719,6.719,0,0,1-10.053,4.012c-.048-.03-.1-.06-.142-.092a6.687,6.687,0,0,1-1.89-1.884,6.581,6.581,0,0,1-.378-.643l-14.136-24.029a11.127,11.127,0,0,0-17.562-2.126l-.037.038a26.037,26.037,0,0,0-6.477,11.588,6.718,6.718,0,0,1,10.053-4.011c.048.03.1.06.142.091a6.706,6.706,0,0,1,2.081,2.189l.016.026,1.261,2.144,3.027,5.145,5.329,9.059.034.058.893,1.519,3.749,6.372a11.15,11.15,0,0,0,17.4,2.342Q838.666,483.972,838.777,483.861Z"
              transform="translate(-793.727 -444.006)"
              fill="url(#linear-gradient-2)"
            />
            <path
              id="Path_69946"
              data-name="Path 69946"
              d="M898.835,443.748a26.149,26.149,0,0,1,10.736,28.126,6.837,6.837,0,0,1-8.235,4.958,6.715,6.715,0,0,1-1.856-.8c-.048-.03-.1-.06-.142-.092a6.688,6.688,0,0,1-1.89-1.884l-1.459-2.48c.136-.089.269-.18.4-.273a17.447,17.447,0,0,0,2.435-2.057h0a23.093,23.093,0,0,0,3.7-5.6,21.957,21.957,0,0,0,.75-2.256,17.363,17.363,0,0,0-.071-9.032c-.8-2.871-2.32-6.488-4.412-8.608"
              transform="translate(-858.014 -443.748)"
              fill="url(#linear-gradient-3)"
            />
          </g>
          <path
            id="Path_69947"
            data-name="Path 69947"
            d="M1000.822,476.165v-2.476h-8.654v.466c-.006.065-.008.137-.008.218q.034,6.675,0,13.35c-.011,2.186-1.064,3.314-3.23,3.6a9.9,9.9,0,0,1-2.5-.007,3.1,3.1,0,0,1-3.079-2.937,7.784,7.784,0,0,1-.068-1.136q-.008-6.5,0-13.008c0-.071,0-.134-.007-.19v-.358h-8.636v.416c.03,5-.141,10.007.077,15,.2,4.567,2.541,6.784,6.549,7.646a27.488,27.488,0,0,0,12.86.007,9.791,9.791,0,0,0,5.029-2.884,7.551,7.551,0,0,0,1.59-4.733c.144-2.451.022-4.905.06-6.906,0-2.214-.007-4.174,0-6.072Z"
            transform="translate(-179.892 -18.979)"
            fill="#fff"
          />
          <g id="Group_3517" data-name="Group 3517" transform="translate(783.623 485.148)">
            <path
              id="Path_69948"
              data-name="Path 69948"
              d="M944.308,556.825h.9l1.579,4.858c.033.109.077.373.088.406a2.927,2.927,0,0,1,.11-.406l1.557-4.858h.548l1.557,4.858c.044.12.109.406.109.406a3.121,3.121,0,0,1,.088-.406l1.579-4.858h.855l-2.27,6.579h-.548L948.9,558.9c-.033-.088-.1-.329-.11-.362-.011.022-.076.274-.109.362l-1.557,4.507h-.548Z"
              transform="translate(-944.308 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69949"
              data-name="Path 69949"
              d="M978.123,556.825H979v2.741h3.63v-2.741h.877V563.4h-.877v-3.1H979v3.1h-.877Z"
              transform="translate(-965.742 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69950"
              data-name="Path 69950"
              d="M1003.882,556.825h.877V563.4h-.877Z"
              transform="translate(-982.071 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69951"
              data-name="Path 69951"
              d="M1016.431,562.9l3.454-5.34h-3.454v-.734h4.529v.5l-3.454,5.34h3.454v.735h-4.529Z"
              transform="translate(-990.025 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69952"
              data-name="Path 69952"
              d="M1038.086,562.9l3.454-5.34h-3.454v-.734h4.528v.5l-3.454,5.34h3.454v.735h-4.528Z"
              transform="translate(-1003.752 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69953"
              data-name="Path 69953"
              d="M1061.731,556.825h.571l2.829,6.579h-.9l-.789-1.842h-2.895l-.79,1.842h-.855Zm1.415,4.024-1.152-2.708-1.151,2.708Z"
              transform="translate(-1016.947 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69954"
              data-name="Path 69954"
              d="M1085.23,556.939a.114.114,0,0,1,.114-.114h2.057a1.875,1.875,0,0,1,2.149,1.93,1.762,1.762,0,0,1-1.36,1.831l1.868,2.638a.114.114,0,0,1-.093.18h-.739a.114.114,0,0,1-.094-.049l-1.852-2.67h-1.173v2.605a.114.114,0,0,1-.114.114h-.649a.114.114,0,0,1-.114-.114Zm2.171,3.011a1.2,1.2,0,1,0,0-2.39h-1.294v2.39Z"
              transform="translate(-1033.635 -556.825)"
              fill="#3dbad7"
            />
            <path
              id="Path_69955"
              data-name="Path 69955"
              d="M1107.843,556.939a.114.114,0,0,1,.114-.114h1.925c2.555,0,3.662,1.48,3.662,3.29s-1.108,3.29-3.662,3.29h-1.925a.114.114,0,0,1-.114-.114Zm2.051,5.7c2.138,0,2.741-1.206,2.741-2.522s-.6-2.522-2.741-2.522h-1.173v5.044Z"
              transform="translate(-1047.969 -556.825)"
              fill="#3dbad7"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}
