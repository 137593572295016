import { Box, Typography, useTheme } from '@mui/material'

import { formatTime } from '../../utils'
import { Insight } from '../../utils/clip'
import { ExtraActions } from '../low-level/ExtraActions/ExtraActions'
import { QueueAction } from '../PlayerQueueClip/PlayerQueueClip'
import { PlayerQueueClipInsight } from '../PlayerQueueClipInsight/PlayerQueueClipInsight'

interface PlayerQueueClipInsightsProps {
  insights: Insight[]
  isActive: boolean
  actions: QueueAction[]
  queueIndex: number
  duration: number
  playClip: () => void
}
export const PlayerQueueClipInsights = ({
  insights,
  isActive,
  actions,
  queueIndex,
  duration,
  playClip
}: PlayerQueueClipInsightsProps) => {
  const theme = useTheme()
  return (
    <Box
      onClick={playClip}
      sx={{
        p: 2,
        backgroundColor: isActive ? theme.palette.primary[50] : theme.palette.common.white,
        borderRadius: 1,
        [theme.breakpoints.down('md')]: { p: 1 }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} data-testid="clip-list-card">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2, fontWeight: 700, color: theme.palette.grey[800] }}>Clip {queueIndex}</Typography>
          <Typography sx={{ color: theme.palette.grey[800] }} data-testid="clip-duration">
            Duration {formatTime(duration)}
          </Typography>
        </Box>
        <ExtraActions actions={actions} />
      </Box>
      {insights.map(insight => (
        <PlayerQueueClipInsight insight={insight} />
      ))}
    </Box>
  )
}
