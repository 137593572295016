import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { notify } from 'react-notify-toast'
import { Box, Button, Container, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'

import { useGetAssetQuery } from '../../store/apis/lvs'
import { PageHeader } from '../../components2.0/PageHeader'
import { TextField } from '../../components2.0/low-level/TextField/TextField'
import { StyledInputLabel } from '../../components2.0/low-level/TextField/StyledComponents'
import VideoEditorSlider from '../../components/VideoEditorSlider/VideoEditorSlider'
import { Player, usePlayer } from '../../components/Player'
import { formatDateWithoutTimezone } from '../../utils/date'
import { DATE_FORMATS } from '../../constants'
import { AddToPlaylistDialog } from '../../components2.0/PlaylistsDialogs/AddToPlaylistDialog/AddToPlaylistDialog'
import { ROUTES } from '../../RouteConfig/constants'

const MAX_CLIP_NAME_LENGTH = 100
const MAX_CLIP_DESCRIPTION_LENGTH = 5000

export const EditSearchResultClip = () => {
  const theme = useTheme()
  const history = useHistory()

  const isBelowMDBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  const { temporaryClipId } = useParams<Record<string, string>>()

  const [assetId, startTime, endTime] = temporaryClipId.split('-')

  const playerSrc = `${process.env.REACT_APP_LVS_API}/v3/assembly/hls?id=${assetId}&o=0&d=-1`

  const [clipName, setClipName] = useState('')
  const [clipDescription, setClipDescription] = useState('')
  const [clipTimes, setClipTimes] = useState({ startTime: Number(startTime), endTime: Number(endTime) })
  const [isNameInputTouched, setNameInputTouched] = useState(false)
  const [isAddToPlaylistDialogOpen, setAddToPlaylistDialogOpen] = useState(false)

  const asset = useGetAssetQuery(Number(assetId))

  const { player } = usePlayer()

  useEffect(() => {
    player?.currentTime(Number(startTime))
  }, [clipTimes.startTime, player, startTime])

  const isAddClipToPlaylistDisabled =
    clipName.length === 0 ||
    clipName.length > MAX_CLIP_NAME_LENGTH ||
    clipDescription.length > MAX_CLIP_DESCRIPTION_LENGTH

  const handleClipNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setClipName(e.target.value)
  }, [])

  const handleClipDescriptionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setClipDescription(e.target.value)
  }, [])

  const setStartAndEndTimes = useCallback((times: number[]) => {
    const [start, end] = times
    setClipTimes({ startTime: start, endTime: end })
  }, [])

  useEffect(() => {
    if (!asset.isError && Number(endTime) > Number(startTime) && !(Number.isNaN(startTime) || Number.isNaN(endTime)))
      return
    notify.show('Sorry, something went wrong.', 'error')
    history.push(ROUTES.ROOT)
  }, [asset.isError, endTime, history, startTime])

  return (
    <>
      <PageHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ [theme.breakpoints.down('md')]: { flexDirection: 'column' } }}
        >
          <Box
            sx={{
              width: 'calc(100% - 400px)',
              [theme.breakpoints.down('md')]: { width: '100%', mb: 2, display: 'flex', justifyContent: 'center' }
            }}
          >
            {asset.isLoading || !asset ? (
              <Skeleton height={32} width={250} />
            ) : (
              <Typography
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                variant={isBelowMDBreakpoint ? 'head700' : 'head800'}
                color={theme.palette.grey[800]}
                data-testid="page-title"
              >
                {asset.data?.name}
              </Typography>
            )}
          </Box>
          <Box data-testid="button-container">
            <Button
              size={isBelowMDBreakpoint ? 'small' : 'medium'}
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => {
                history.goBack()
              }}
              data-testid="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              size={isBelowMDBreakpoint ? 'small' : 'medium'}
              sx={{ mr: 2 }}
              variant="contained"
              disabled={isAddClipToPlaylistDisabled}
              onClick={() => setAddToPlaylistDialogOpen(true)}
              data-testid="add-clip-to-playlist-btn"
            >
              <PlaylistAddIcon sx={{ mr: 1 }} /> Add clip to playlist
            </Button>
          </Box>
        </Box>
      </PageHeader>
      <Container sx={{ mb: 3 }}>
        <Box sx={{ mb: 3 }}>
          <TextField
            helperText={
              clipName.length === 0 && isNameInputTouched
                ? 'Clip name required'
                : `${clipName.length} / ${MAX_CLIP_NAME_LENGTH}`
            }
            error={(clipName.length === 0 || clipName.length > MAX_CLIP_NAME_LENGTH) && isNameInputTouched}
            placeholder="Enter clip name"
            value={clipName}
            onBlur={() => setNameInputTouched(true)}
            onChange={handleClipNameChange}
            sx={{
              mb: 3
            }}
            label="Clip name"
            fullWidth
            data-testid="clip-name"
          />
          <TextField
            error={clipDescription.length > MAX_CLIP_DESCRIPTION_LENGTH}
            helperText={`${clipDescription.length} / ${MAX_CLIP_DESCRIPTION_LENGTH}`}
            sx={{
              mb: 3
            }}
            value={clipDescription}
            onChange={handleClipDescriptionChange}
            placeholder="Enter clip description"
            multiline
            rows={4}
            label="Clip description (optional)"
            fullWidth
            data-testid="clip-description"
          />
          <StyledInputLabel>Clip</StyledInputLabel>
          <Player src={playerSrc} />

          {asset.data && (
            <>
              <VideoEditorSlider
                handleOffsetChange={setStartAndEndTimes}
                clip={clipTimes}
                assetDuration={Number(asset.data?.duration)}
              />
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  py: 2,
                  borderTop: `1px solid  ${theme.palette.grey[50]}`,
                  borderBottom: `1px solid  ${theme.palette.grey[50]}`
                }}
              >
                <Typography sx={{ width: '184px', fontWeight: 700, color: theme.palette.grey[450] }}>
                  Source video
                </Typography>
                <Box>
                  <Typography sx={{ mb: 2 }} variant="body2" data-testid="asset-name">
                    {asset.data?.name}
                  </Typography>
                  <Typography variant="body1" data-testid="asset-recorded-on">
                    Recorded on {formatDateWithoutTimezone(asset.data.startDate, DATE_FORMATS.CALENDAR_WITH_TIME)} (UTC)
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
      {asset.data && (
        <AddToPlaylistDialog
          open={isAddToPlaylistDialogOpen}
          setClose={() => setAddToPlaylistDialogOpen(false)}
          clip={{
            assetId: Number(asset.data?.id),
            duration: clipTimes.endTime - clipTimes.startTime,
            offset: clipTimes.startTime,
            title: clipName,
            description: clipDescription
          }}
        />
      )}
    </>
  )
}
