import { useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { notify } from 'react-notify-toast'
import Box from '@mui/material/Box'
import { DialogContentText, useTheme } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import { ExtraActions } from '../../low-level/ExtraActions/ExtraActions'
import { Row, Icon } from '../PlaylistTable.constants'
import { getPlaylistTableRowStyles } from './PlaylistTableRow.styles'
import { SharePlaylistDialog } from '../../PlaylistsDialogs/SharePlaylistDialog/SharePlaylistDialog'
import { useDeletePlaylistMutation } from '../../../store/apis/middleware'
import { DATE_FORMATS } from '../../../constants'
import { formatDate, formatDateWithoutTimezone } from '../../../utils/date'
import { formatTime } from '../../../utils'
import { formatCount, wordAsPluralOrSingular } from '../../../utils/count'
import { parseUsername } from '../../../utils/user'
import { MiddlewareApi } from '../../../store/apis/middleware/types'
import { ActionConfirmationDialog } from '../../ActionConfirmationDialog'
import { selectUserDetails } from '../../../store/slices/user'

interface PlaylistTableRowProps {
  playlist: MiddlewareApi.Playlist
  isShared: boolean
}

export const PlaylistTableRow = ({ playlist, isShared }: PlaylistTableRowProps) => {
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''
  const [deletePlaylist, { isLoading: isDeleting }] = useDeletePlaylistMutation()
  const history = useHistory()

  const theme = useTheme()
  const row = playlist

  const maxContentWidth = '200px'
  const [sharePlaylistModalOpen, setSharePlaylistModalOpen] = useState(false)
  const [deletePlaylistModalOpen, setDeletePlaylistModalOpen] = useState(false)

  const styles = getPlaylistTableRowStyles(theme, isShared)

  const setDeleteState = useCallback((open: boolean) => {
    setDeletePlaylistModalOpen(open)
  }, [])

  const setShareState = useCallback((open: boolean) => {
    setSharePlaylistModalOpen(open)
  }, [])

  const setDelete = useCallback(() => {
    if (tenantId) {
      deletePlaylist({ tenantId, playlistId: playlist.id })
        .then(() => notify.show('Playlist successfully deleted.', 'success'))
        .catch(err => console.error(err))
    }
  }, [deletePlaylist, playlist.id, tenantId])

  const playlistDuration = playlist?.clips?.reduce((accum, clip) => accum + Number(clip.duration), 0)

  const items: Row[] = [
    {
      item: row?.description || '',
      alignment: 'left',
      sx: {
        ...styles.rowItemLgBreakpoint,
        maxWidth: maxContentWidth
      }
    },
    {
      item: formatDateWithoutTimezone(row.updated_at, DATE_FORMATS.CALENDAR_WITH_TIME),
      alignment: 'left',
      sx: styles.rowItemMdBreakpoint,
      testid: 'last-modified-time'
    },
    {
      item: formatCount(row.views),
      alignment: 'center',
      sx: { ...styles.rowItemMdBreakpoint, display: isShared ? 'none' : 'table-cell' },
      testid: 'playlist-views'
    }
  ]

  const icons: Icon[] = [
    {
      icon: <CreateOutlinedIcon fontSize="small" />,
      sx: {
        display: isShared ? 'none' : 'table-cell',
        width: '10px',
        [theme.breakpoints.down('lg')]: { display: 'none' }
      },
      onClick: () =>
        history.push({ pathname: `/playlists/view-playlist/${playlist?.id}`, state: { tab: 1, isEditMode: true } })
    },
    {
      icon: <ShareOutlinedIcon fontSize="small" />,
      sx: {
        display: isShared ? 'none' : 'table-cell',
        width: '10px',
        [theme.breakpoints.down('lg')]: { display: 'none' }
      },
      onClick: () => setShareState(true)
    }
  ]

  const buttonActions = [
    {
      onClick: () => history.push({ pathname: `/playlists/view-playlist/${playlist?.id}` }),
      title: 'Play'
    },
    {
      onClick: () =>
        history.push({ pathname: `/playlists/view-playlist/${playlist?.id}`, state: { tab: 1, isEditMode: true } }),
      title: 'Edit'
    },
    {
      onClick: () => setShareState(true),
      title: 'Share'
    },
    {
      onClick: () => setDeleteState(true),
      title: 'Delete',
      color: theme.palette.error.main
    }
  ]
  const buttonActionsShared = [
    {
      onClick: () => history.push({ pathname: `/playlists/view-playlist/${playlist?.id}` }),
      title: 'Play'
    },
    {
      onClick: () => setShareState(true),
      title: 'Share'
    }
  ]

  const creator = parseUsername(playlist.user)

  return (
    <>
      <TableRow hover tabIndex={-1} data-testid="playlist-row">
        <TableCell
          key={row.id}
          padding="none"
          sx={{ width: '50px', [theme.breakpoints.down('md')]: { display: 'none' } }}>
          <IconButton
            onClick={() => history.push(`playlists/view-playlist/${playlist.id}`)}
            sx={{
              display: 'none',
              'tr:hover &': {
                display: 'inline-flex'
              }
            }}
            data-testid="play-playlist-btn">
            <PlayArrowOutlinedIcon />
          </IconButton>
          <IconButton
            disabled={!playlist.clips.length}
            sx={{
              display: 'inline-flex',
              'tr:hover &': {
                display: 'none'
              }
            }}
            data-testid="playlist-btn">
            <PlaylistPlayOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          id={row.id}
          scope="row"
          padding="none"
          align="left"
          sx={{
            py: 1,
            maxWidth: maxContentWidth,

            [theme.breakpoints.down('md')]: { pl: 2 }
          }}>
          <Link to={() => `playlists/view-playlist/${playlist.id}`} style={{ textDecoration: 'none' }}>
            <Typography
              variant="body2"
              sx={{
                margin: 0,
                mb: 0.5,
                padding: 0,
                height: '20px',
                fontWeight: 510,
                color: theme.palette.grey[800],
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                'tr:hover &': {
                  color: theme.palette.primary[500],
                  textDecoration: 'underline'
                }
              }}
              data-testid="playlist-name">
              {row.title}
            </Typography>
          </Link>

          <Box sx={styles.typographyDetailsContainer}>
            <Typography sx={styles.summaryList}>
              <span data-testid="num-clips">{wordAsPluralOrSingular(row.clips.length, 'clip')}</span>
              <span data-testid="playlist-duration">{formatTime(playlistDuration)}</span>
              <Typography component="span" sx={{ [theme.breakpoints.up('md')]: { display: 'none' } }}>
                {formatDate(row.updated_at, DATE_FORMATS.CALENDAR)}
              </Typography>
              {isShared && creator && (
                <Typography component="span" variant="body4" sx={styles.creatorName}>
                  Created by {creator}
                </Typography>
              )}
            </Typography>
          </Box>
        </TableCell>
        {items.map(({ item, alignment, sx, testid }: Row) => (
          <TableCell padding="none" align={alignment} sx={{ ...sx, pr: 1 }} key={item} data-testid={testid}>
            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item}</Typography>
          </TableCell>
        ))}
        {icons.map(({ icon, sx, onClick }: Icon) => (
          <TableCell key={icon.key} padding="none" sx={sx}>
            <IconButton onClick={onClick} sx={{ color: theme.palette.grey[800] }}>
              {icon}
            </IconButton>
          </TableCell>
        ))}
        {!isShared && (
          <TableCell padding="none" sx={{ width: '10px' }}>
            <ExtraActions buttonSize="small" actions={isShared ? buttonActionsShared : buttonActions} horizontalIcon />
          </TableCell>
        )}
      </TableRow>
      {sharePlaylistModalOpen && (
        <SharePlaylistDialog
          open={sharePlaylistModalOpen}
          setClose={() => setShareState(false)}
          key={row.id}
          playlistId={playlist.id}
          playlistTitle={playlist.title}
        />
      )}

      <ActionConfirmationDialog
        open={deletePlaylistModalOpen}
        title={isShared ? 'Remove Playlist' : 'Delete playlist'}
        actionCopy="Delete"
        isConfirmationLoading={isDeleting}
        handleConfirmAction={setDelete}
        handleClose={() => setDeleteState(false)}
        ariaLabelledBy="delete-playlist-confirmation-dialog"
        ariaDescribedBy="delete-playlist-confirmation-dialog-content">
        <DialogContentText mb={2}>
          Are you sure you want to {isShared ? 'remove' : 'delete'} the playlist{' '}
          <strong>&quot;{row.title}&quot;</strong> containing {wordAsPluralOrSingular(row.clips.length, 'clip')}?
        </DialogContentText>
        <DialogContentText>
          <strong>Note: </strong>
          {isShared
            ? 'Removing a playlist will unshare the playlist with you. It is a permanent action and can’t be undone.'
            : 'Deleting a playlist is a permanent action and can’t be undone.'}
        </DialogContentText>
      </ActionConfirmationDialog>
    </>
  )
}
