import { createUseStyles } from 'react-jss'
import { Breakpoint } from '../../design-system-legacy/breakpoints'
import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'

export const useSecondaryNavPanelStyles = createUseStyles({
  secondaryNavPanelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '65px 0px 75px 0px',
    minWidth: toResponsiveUnitFromPixels(320),
    backgroundColor: colours.lighterMartinique,
    [Breakpoint.SM]: {
      display: 'none'
    }
  },
  heading: {
    display: 'block',
    color: colours.white,
    marginBottom: 35,
    marginTop: 35,
    fontSize: toResponsiveUnitFromPixels(20),
    [Breakpoint.SM]: {
      display: 'none'
    }
  },
  separator: {
    width: 250,
    border: `1px solid ${colours.martinique}`,
    [Breakpoint.SM]: {
      display: 'none'
    }
  }
})
