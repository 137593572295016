import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import axios from 'axios'
import { AuthApi } from './auth/types'
import { clearStore, setAccessToken, setRefreshToken } from '../slices/auth'
import { messageServiceWorker } from '../../utils/service-worker'
import history from '../../history'
import { ROUTES } from '../../RouteConfig/constants'

interface PartialState {
  authSlice: {
    accessToken?: string
    refreshToken?: string
  }
  user: {
    apiKey?: string
  }
}

const createAuthenticatedBaseQuery = (baseUrl = '/') =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const store = getState() as PartialState
      const { accessToken } = store.authSlice
      const { apiKey } = store.user

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      if (apiKey) {
        headers.set('X-Api-Key', apiKey)
      }

      return headers
    }
  })

const mutex = new Mutex()

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
export const createQueryWithReauth = (baseUrl = '/') => {
  const authenticatedBaseQuery = createAuthenticatedBaseQuery(baseUrl)

  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
  ) => {
    await mutex.waitForUnlock()
    let result = await authenticatedBaseQuery(args, api, extraOptions)
    const {
      authSlice: { refreshToken }
    } = api.getState() as PartialState

    const isSignOut = api.endpoint === 'signOut'

    if (result.error && result.error.status === 401 && !isSignOut) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const refreshResult = await axios.post<AuthApi.AuthResponse>(
            `${process.env.REACT_APP_LVS_API}/v2/iam/auth/refresh`,
            new TextEncoder().encode(refreshToken),
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )

          if (refreshResult.data) {
            api.dispatch(setAccessToken(refreshResult.data.token))
            api.dispatch(setRefreshToken(refreshResult.data.refreshToken))
            messageServiceWorker({ accessToken: refreshResult.data.token })
            result = await authenticatedBaseQuery(args, api, extraOptions)
          } else {
            history.push({ pathname: ROUTES.LOGIN, state: undefined })
            api.dispatch(clearStore())
          }
        } finally {
          release()
        }
      } else {
        await mutex.waitForUnlock()
        result = await authenticatedBaseQuery(args, api, extraOptions)
      }
    }

    return result
  }

  return baseQueryWithReauth
}
