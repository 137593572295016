import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { notify } from 'react-notify-toast'
import {
  Avatar,
  Box,
  FormGroup,
  InputAdornment,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  DialogActions,
  DialogContent
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

import { TextField } from '../../low-level/TextField/TextField'
import { parseUsername } from '../../../utils/user'
import { useSharePlaylistMutation, useGetPlaylistSharesQuery } from '../../../store/apis/middleware'
import { selectUserDetails } from '../../../store/slices/user'

export interface SharePlaylistDialogProps {
  open: boolean
  setClose: () => void
  playlistId: string
  playlistTitle: string
}

export const SharePlaylistDialog = ({ open, setClose, playlistId, playlistTitle }: SharePlaylistDialogProps) => {
  const theme = useTheme()
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''
  const isBelowMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  const [email, setEmail] = useState<string>('')

  const [sharePlaylist, { isLoading, isError, isSuccess }] = useSharePlaylistMutation()
  const playlistShares = useGetPlaylistSharesQuery({ tenantId, playlistId })
  const sharedUsers = playlistShares?.data?.shares.map(share => share.user) || []

  const setSharePlaylist = () => {
    if (tenantId) {
      sharePlaylist({
        tenantId,
        playlistId,
        userEmail: encodeURIComponent(email)
      })
    }
  }

  useEffect(() => {
    if (isError) {
      notify.show('Something went wrong', 'error')
    }
    if (isSuccess) {
      notify.show('The playlist has been shared.', 'success')
    }
  }, [isError, isSuccess])

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      aria-labelledby="share-playlist-dialog"
      aria-describedby="share-playlist-dialog-content"
      data-testid="share-playlist-dialog"
    >
      <DialogTitle id="share-playlist-dialog">
        <Typography
          component="p"
          sx={{
            width: 'calc(100% - 80px)',
            mb: 1,
            fontWeight: 700,
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
          data-testid="modal-heading"
          variant="head700"
        >
          Share &#34;{playlistTitle}&#34;
        </Typography>

        <IconButton onClick={setClose} sx={{}}>
          <CloseOutlinedIcon sx={{ color: theme.palette.common.black }} />
        </IconButton>
      </DialogTitle>

      <DialogContent id="share-playlist-dialog-content">
        <Typography component="p" color={theme.palette.grey[450]} mb={1} data-testid="email-input-label">
          Provide the email address of a user you would like to share this playlist with
        </Typography>
        <DialogActions
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: { flexDirection: 'column' }
          }}
        >
          <TextField
            fullWidth
            placeholder="Enter email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlinedIcon />
                </InputAdornment>
              )
            }}
            data-testid="email-input-field"
          />

          <Button
            onClick={setSharePlaylist}
            variant="contained"
            disabled={isLoading || email.length === 0}
            sx={{ ml: 2, mt: 0, [theme.breakpoints.down('md')]: { mt: 1, ml: 0 } }}
            fullWidth={isBelowMdBreakpoint}
            data-testid="share-playlist-button"
          >
            {isLoading ? <CircularProgress size={24} /> : 'Share'}
          </Button>
        </DialogActions>
        <Typography sx={{ pb: 1, pt: 3 }} color={theme.palette.grey[450]} data-testid="user-list-label">
          Following users can access this playlist
        </Typography>

        <Box
          height="228px"
          sx={{ overflowY: 'auto', border: `1px solid ${theme.palette.grey[150]}`, p: 1.5 }}
          data-testid="user-list"
        >
          <FormGroup>
            <Typography variant="body2" sx={{ ml: 1, mb: 1, textTransform: 'capitalize' }}>
              {userDetails?.firstName && userDetails.lastName
                ? `${userDetails.firstName} ${userDetails.lastName} (you)`
                : ''}
            </Typography>
            {playlistShares.isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
            {sharedUsers?.map(user => (
              <Box
                sx={{ display: 'flex', alignItems: 'center', padding: 0.5 }}
                key={user.email}
                data-testid="users-shared-with"
              >
                <Avatar sx={{ mr: 1 }}>
                  <PersonOutlineOutlinedIcon />
                </Avatar>
                <Typography>{parseUsername(user)}</Typography>
              </Box>
            ))}
          </FormGroup>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(`${window.location.origin}/playlists/view-playlist/${playlistId}`)
            notify.show('Copied to clipboard.', 'success')
          }}
          data-testid="copy-link-url-button"
          variant="outlined"
        >
          Copy link URL
        </Button>
      </DialogActions>
    </Dialog>
  )
}
