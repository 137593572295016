import { createUseStyles } from 'react-jss'
import { Breakpoint } from '../../design-system-legacy/breakpoints'

export const usenavOptionsStyles = createUseStyles({
  navOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    // marginBottom: 20,

    '& > *': {
      marginBottom: 20
    },
    [Breakpoint.SM]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      '& > *': {
        marginBottom: 0
      }
    }
  }
})
