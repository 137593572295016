import { AnyAction, Dispatch } from 'redux'

import { Playlist } from '../../types'
import { selectPlaylists, selectQueueClips, selectQueueCurrentIndex } from '../selectors'
import { State } from '../'

import {
  doUpdatePlaylistClip,
  setQueueCurrentIndex,
  setQueueCurrentPlaylistId,
  setVideoPlayerEditRange,
  updateSearchResultsItem
} from './'

const doSetQueueCurrentIndex = (index: number) => {
  return (dispatch: Dispatch<any>, getState: () => State) => {
    const currentIndex = selectQueueCurrentIndex(getState())
    // @ts-ignore
    const clips = selectQueueClips(getState())

    if (index !== currentIndex) {
      dispatch(setQueueCurrentIndex(index))
    }
  }
}

const doConfirmEditClip = (
  id: string,
  playlistId: string,
  options: {
    title?: string
    offset?: number
    duration?: number
  }
) => {
  return (dispatch: Dispatch<AnyAction>) => {
    if (id && options.offset !== undefined && options.duration !== undefined) {
      if (!playlistId) {
        // TODO update this to update the correct results slice, or simply refetch results?
        dispatch(updateSearchResultsItem(id, options.offset, options.duration))
      } else {
        // @ts-ignore
        // TODO: Check type errors here
        dispatch(doUpdatePlaylistClip(id, playlistId, options))
      }
    }
  }
}

const doEditQueueClip = (index: number) => {
  return (dispatch: Dispatch<any>, getState: () => State) => {
    // @ts-ignore
    const clips = selectQueueClips(getState())
    const clip = clips[index] || null

    if (clip) {
      dispatch(setQueueCurrentIndex(index))
      dispatch(setVideoPlayerEditRange(Number(clip.startTime), Number(clip.duration)))
    }
  }
}

const doSetQueueCurrentPlaylistId = (playlistId: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setQueueCurrentPlaylistId(playlistId))
  }
}

const doEditClip = (index: number, playlistId: string) => {
  return (dispatch: Dispatch<any>, getState: () => State) => {
    const playlists = selectPlaylists(getState())
    const playlist = playlists.find((playlist: Playlist) => playlist.id === playlistId)

    if (playlist) {
      const clip = playlist.clips[index] || null

      if (clip) {
        dispatch(doSetQueueCurrentPlaylistId(playlistId))
        dispatch(doEditQueueClip(index))
      }
    }
  }
}

export { doEditClip, doEditQueueClip, doSetQueueCurrentIndex, doConfirmEditClip, doSetQueueCurrentPlaylistId }
