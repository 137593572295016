import React from 'react'

const CogIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30">
      <g id="cog-line" transform="translate(-2 -2)">
        <path
          id="Path_68682"
          data-name="Path 68682"
          d="M18.1,11a7,7,0,1,0,7,7A6.957,6.957,0,0,0,18.1,11Zm0,12a5,5,0,1,1,5-5A4.951,4.951,0,0,1,18.1,23Z"
          transform="translate(-1.011 -1)"
          fill="#fff"
        />
        <path
          id="Path_68683"
          data-name="Path 68683"
          d="M30.875,13.906l-2.625-.844-.562-1.406L29,9.219a1.576,1.576,0,0,0-.281-1.781l-2.25-2.25a1.576,1.576,0,0,0-1.781-.281L22.25,6.219l-1.406-.562L20,3.031A1.524,1.524,0,0,0,18.594,2H15.406a1.315,1.315,0,0,0-1.312,1.125L13.25,5.75a4.142,4.142,0,0,0-1.5.562L9.313,5a1.576,1.576,0,0,0-1.781.281l-2.25,2.25A1.576,1.576,0,0,0,5,9.313l1.219,2.344c-.187.469-.375,1.031-.562,1.5L3.031,14A1.524,1.524,0,0,0,2,15.406v3.188A1.493,1.493,0,0,0,3.125,20l2.625.844.562,1.406L5,24.688a1.576,1.576,0,0,0,.281,1.781l2.25,2.25A1.576,1.576,0,0,0,9.313,29l2.438-1.312,1.406.563L14,30.969A1.524,1.524,0,0,0,15.406,32h3.188A1.524,1.524,0,0,0,20,30.969l.844-2.719,1.406-.562L24.688,29a1.576,1.576,0,0,0,1.781-.281l2.25-2.25A1.576,1.576,0,0,0,29,24.688L27.688,22.25l.563-1.406L30.969,20A1.524,1.524,0,0,0,32,18.594V15.406a1.6,1.6,0,0,0-1.125-1.5Zm-.75,4.406L26.75,19.344l-.094.469-.844,1.969-.281.469,1.688,3.094-1.875,1.875L22.25,25.531l-.469.281a8.9,8.9,0,0,1-1.969.844l-.469.094-1.031,3.375H15.688L14.656,26.75l-.469-.094-1.969-.844-.469-.281L8.656,27.219,6.781,25.344,8.469,22.25l-.281-.469a8.9,8.9,0,0,1-.844-1.969l-.094-.469L3.875,18.313V15.688l3.188-.937.188-.469a7.958,7.958,0,0,1,.844-2.062l.281-.469L6.781,8.656,8.656,6.781l3,1.688.469-.281a7.958,7.958,0,0,1,2.063-.844l.469-.187,1.031-3.281h2.625l1.031,3.281.469.188a8.9,8.9,0,0,1,1.969.844l.469.281,3.094-1.688,1.875,1.875L25.531,11.75l.281.469a8.9,8.9,0,0,1,.844,1.969l.094.469,3.375,1.031Z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default CogIcon
