export const useValidation = () => {
  const validateEmail = (value: string) => {
    // eslint-disable-next-line
    const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return value.match(validRegex)
  }

  return {
    validateEmail
  }
}
