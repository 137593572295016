import { TextFieldProps } from '@mui/material'

import { StyledInputLabel, StyledTextField } from './StyledComponents'

export const TextField = ({ onChange, label, id, ...rest }: TextFieldProps) => {
  return (
    <>
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <StyledTextField {...rest} onChange={onChange} id={id} />
    </>
  )
}
