import { useState, useCallback, useEffect } from 'react'

export const useDebounce = (delay: number = 500) => {
  const [timer, setTimer] = useState<number>()

  useEffect(() => () => window.clearTimeout(timer), [timer])

  return useCallback(
    (callback: () => unknown) => {
      setTimer(window.setTimeout(callback, delay))
    },
    [delay]
  )
}
