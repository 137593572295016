import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

interface ModalProps {
  children?: React.ReactNode
  title: string
  subtitle?: string
  open: boolean
  setClose: () => void
  testid: string
}

export const Modal = ({ children, title, subtitle, open, setClose, testid }: ModalProps) => {
  const theme = useTheme()
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={setClose}
      data-testid={testid}
      sx={{ height: '100vh', '& .MuiPaper-root': { width: 660 } }}>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[50]}`,
          display: 'flex',
          justifyContent: 'space-between',
          pt: 1,
          pr: 1,
          pb: 1
        }}>
        <DialogTitle
          color={theme.palette.grey[800]}
          sx={{ pb: 0.5, ...theme.typography.head700, fontWeight: 700 }}
          data-testid="dialog-title">
          {title}
        </DialogTitle>
        {subtitle && (
          <Typography sx={{ pl: 3 }} variant="body1">
            {subtitle}
          </Typography>
        )}

        <DialogActions>
          <IconButton onClick={setClose} data-testid="modal-x-btn">
            <CloseOutlinedIcon />
          </IconButton>
        </DialogActions>
      </Box>
      <DialogContent sx={{ m: 0, p: 3 }}> {children}</DialogContent>
    </Dialog>
  )
}
