import { DraggableProvided } from 'react-beautiful-dnd'
import { Box, Card, CardActionArea, CardMedia, Chip, Skeleton, Typography, useTheme } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import CircleIcon from '@mui/icons-material/Circle'
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined'
import { getPlayerQueueClipStyles } from './PlayerQueueClip.styles'
import { ExtraActions } from '../low-level/ExtraActions/ExtraActions'
import { ClipTagList } from '../low-level/ClipTagList'
import { formatTime } from '../../utils'
import { InsightsCount } from '../InsightsCount'
import { Insight } from '../../utils/clip'
import { useGetAssetThumbnailQuery } from '../../store/apis/lvs'
import { VIDEO_POSTER_DEFAULT } from '../../constants'

export interface QueueAction {
  title: string
  onClick: () => void
  color?: string
}

interface PlayerQueueClipProps {
  isActive: boolean
  isPlaying: boolean
  assetId?: string
  duration: number
  startsAtTime: number
  creationDate: string
  title?: string
  tags: string[]
  insights?: Insight[]
  actions: QueueAction[]
  playClip: () => void
  provided?: DraggableProvided
}

export const PlayerQueueClip = ({
  isActive = false,
  isPlaying = false,
  assetId,
  duration,
  startsAtTime,
  creationDate,
  title = 'No clip title defined',
  tags,
  insights,
  actions,
  playClip,
  provided
}: PlayerQueueClipProps) => {
  const theme = useTheme()

  const clipCardStyles = getPlayerQueueClipStyles(useTheme())

  const { data: imageUrl, isLoading: isThumbnailLoading, isError: isThumbnailError } = useGetAssetThumbnailQuery(
    Number(assetId)
  )

  return (
    <Card
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      sx={{
        backgroundColor: isActive ? theme.palette.primary[50] : theme.palette.common.white,
        ...clipCardStyles.card
      }}
      data-testid="clip-list-card"
    >
      {provided && (
        <Box {...provided.dragHandleProps} sx={{ alignSelf: 'center', mr: 2 }}>
          <DragHandleOutlinedIcon />
        </Box>
      )}
      <CardActionArea onClick={playClip} sx={clipCardStyles.cardActionArea} data-testid="clip-list-card-play">
        {isThumbnailLoading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <>
            <CardMedia
              sx={clipCardStyles.cardMedia}
              component="img"
              image={isThumbnailError ? VIDEO_POSTER_DEFAULT : imageUrl}
              alt="clip thumbnail"
            />
            {isPlaying ? (
              <PauseCircleOutlineIcon sx={clipCardStyles.playPauseIcon} />
            ) : (
              <PlayCircleOutlineIcon sx={clipCardStyles.playPauseIcon} />
            )}
            <Chip sx={clipCardStyles.clipLength} label={formatTime(duration)} data-testid="clip-duration" />
          </>
        )}
      </CardActionArea>

      <Box sx={clipCardStyles.clipInfoContainer} data-testid="clip-info">
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} minHeight={110}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" sx={clipCardStyles.title} data-testid="clip-title">
              {title}
            </Typography>
            <ExtraActions actions={actions} />
          </Box>

          <Box sx={clipCardStyles.metaDataContainer}>
            {!!creationDate && (
              <>
                <Typography variant="body50" mr={1} sx={clipCardStyles.metaData} data-testid="clip-recorded-date">
                  Recorded on {creationDate}
                </Typography>

                <CircleIcon sx={clipCardStyles.metaDataDivider} />
              </>
            )}

            <Typography variant="body50" sx={clipCardStyles.metaData} data-testid="clip-start">
              Starts at {formatTime(startsAtTime)}
            </Typography>
          </Box>

          <ClipTagList sx={{ mb: -1, minHeight: 24 }} tagSize="small" tags={tags} />
        </Box>

        {insights && <InsightsCount sx={{ mt: 1 }} insights={insights} isActive={isActive} />}
      </Box>
    </Card>
  )
}
