export * from './endpoints'
export * from './images'
export * from './subscriptions'
export * from './dates'
export * from './timeSpan'

// TODO: Move to relevant files.
const testDate = 'December 17, 1971'
const numberOfFiltersLimit = 4
const FORGOT_PASSWORD_LINK = 'https://linius.atlassian.net/servicedesk/customer/portal/1/group/1/create/10'
const TUTORIAL_VIDEO_LINK = 'https://support.linius.com/whizzard-user-walkthrough.mp4'

export { FORGOT_PASSWORD_LINK, TUTORIAL_VIDEO_LINK, testDate, numberOfFiltersLimit }
