import { createUseStyles } from 'react-jss'

import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'

const useAdminConsoleStyles = createUseStyles({
  adminConsoleContainer: {
    display: 'flex',
    backgroundColor: colours.white,
    height: '100vh',
    overflowY: 'hidden'
  },
  flexContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  adminConsoleMainContentContainer: {
    overflowY: 'auto',
    height: '100%'
  },
  backToHeader: {
    width: '100%',
    display: 'flex',
    padding: toResponsiveUnitFromPixels([34, 51, 26]),
    borderBottom: toResponsiveUnitFromPixels(`1px solid ${colours.alto}`),
    zIndex: 1,
    backgroundColor: colours.white
  },
  backToHeading: {
    margin: 0,
    fontWeight: 'normal',
    fontSize: toResponsiveUnitFromPixels(12),
    color: colours.dune,
    textDecoration: 'none'
  },
  zoomIntegrationContent: {
    overflow: 'hidden',
    backgroundColor: colours.white,
    width: '100%'
  },
  subHeader: {
    padding: toResponsiveUnitFromPixels([30, 51]),
    width: '100%',
    zIndex: 1,
    backgroundColor: colours.white
  },
  tabsListClasses: {
    zIndex: 1,
    margin: toResponsiveUnitFromPixels([0, 51]),
    // minWidth: 1000,
    backgroundColor: colours.white
  },
  tabContentClasses: {
    minWidth: 950,
    margin: toResponsiveUnitFromPixels([20, 51]),
    zIndex: 'unset'
  },
  p: {
    margin: 0,
    display: 'inline'
  },
  span: {
    display: 'block'
  }
})

export default useAdminConsoleStyles
