import { createUseStyles } from 'react-jss'
import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'
import { Breakpoint } from '../../design-system-legacy/breakpoints'

const usePaginationStyles = createUseStyles({
  disabled: {
    pointerEvents: 'none',
    '& $arrow:before': {
      borderRight: ' 0.12em solid rgba(0, 0, 0, 0.43)',
      borderTop: ' 0.12em solid rgba(0, 0, 0, 0.43)'
    },
    '& :hover': {
      backgroundColor: 'transparent',
      cursor: 'default'
    },
    '& $arrowLeft:before': {
      borderLeft: ' 0.12em solid rgba(0, 0, 0, 0.43)',
      borderTop: ' 0.12em solid rgba(0, 0, 0, 0.43)'
    }
  },
  currentPage: {
    border: `${toResponsiveUnitFromPixels(1)} solid ${colours.frenchGray}`,
    borderRadius: toResponsiveUnitFromPixels(3),
    padding: `0 ${toResponsiveUnitFromPixels(6)}`,
    margin: `0 ${toResponsiveUnitFromPixels(2)}`
  },
  countPrefixText: {
    fontWeight: 'bold',
    margin: 0,
    position: 'absolute',
    left: toResponsiveUnitFromPixels(15),
    top: '50%',
    transform: 'translateY(-50%)'
  },
  pageSizeText: {
    fontWeight: 'bold',
    margin: 0,
    position: 'absolute',
    right: toResponsiveUnitFromPixels(15),
    top: '50%',
    transform: 'translateY(-50%)'
  },
  myContentPaginationContainer: {
    position: 'relative',
    padding: toResponsiveUnitFromPixels(15)
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  right: {
    transform: 'rotate(45deg) translate(0px, -1px)'
  },
  dots: {
    backgroundColor: 'transparent',
    cursor: 'default'
  },
  left: {
    transform: 'rotate(-135deg) translate(-1px, -1px)'
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    cursor: 'default'
  },
  arrow: {
    '&:before': {
      position: 'relative',
      content: '""',
      display: 'inline-block',
      width: '0.4em',
      height: '0.4em',
      borderRight: '0.12em solid rgba(0, 0, 0, 0.87)',
      borderTop: '0.12em solid rgba(0, 0, 0, 0.87)',
      '& $left': {
        transform: 'rotate(-135deg)  '
      },
      '& $right': {
        transform: 'rotate(45deg)'
      }
    }
  },
  arrowLeft: {
    '&:before': {
      position: 'relative',
      content: '""',
      display: 'inline-block',
      width: '0.4em',
      height: '0.4em',
      borderLeft: '0.12em solid rgba(0, 0, 0, 0.87)',
      borderTop: '0.12em solid rgba(0, 0, 0, 0.87)',
      transform: 'rotate(-45deg)',
      '& $left': {
        transform: 'rotate(-135deg) translate(-50%) '
      },
      '& $right': {
        transform: 'rotate(45deg)'
      }
    }
  },

  paginationContainer: {
    display: 'flex',
    listStyleType: 'none',
    [Breakpoint.SM]: {
      [Breakpoint.LANDSCAPE]: {
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '-0.5em',
        right: '1em'
      }
    }
  },
  paginationItem: {
    padding: `0 ${toResponsiveUnitFromPixels(12)}`,
    height: toResponsiveUnitFromPixels(32),
    textAlign: 'center',
    margin: `auto ${toResponsiveUnitFromPixels(3)}`,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    letterSpacing: ' 0.01071em',
    borderRadius: toResponsiveUnitFromPixels(16),
    lineHeight: '1.42',
    fontSize: toResponsiveUnitFromPixels(13),
    minWidth: toResponsiveUnitFromPixels(32),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer'
    },
    [Breakpoint.MD]: {
      [Breakpoint.LANDSCAPE]: {
        display: 'none'
      }
    }
  },
  prevNext: {
    [Breakpoint.MD]: {
      [Breakpoint.LANDSCAPE]: {
        display: 'flex'
      }
    }
  },
  hidden: {
    visibility: 'hidden',
    [Breakpoint.SM]: {
      [Breakpoint.LANDSCAPE]: {
        display: 'none'
      }
    }
  }
})
export default usePaginationStyles
