import { useState } from 'react'

export const useSetCurrentErrorMessage = () => {
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string>('')

  const onMouseEnter = (formik, type: string) => {
    if (formik.errors[type]) {
      setCurrentErrorMessage(formik.errors[type])
    }
  }
  const onMouseLeave = () => {
    setCurrentErrorMessage('')
  }

  return { onMouseEnter, onMouseLeave, currentErrorMessage }
}
