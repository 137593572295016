import { AnyAction } from 'redux'

const VIDEO_EDIT_MODE_SET = 'video/editmode/set'
const VIDEO_TIMELINE_SET = 'video/timeline/set'
const VIDEO_RESET = 'video/reset'

const setVideoPlayerEditRange = (offset: number, duration: number): AnyAction => {
  return {
    type: VIDEO_EDIT_MODE_SET,
    offset,
    duration
  }
}

const setVideoPlayerTimeline = (payload: number[]): AnyAction => {
  return {
    type: VIDEO_TIMELINE_SET,
    payload
  }
}

const resetVideoPlayer = (): AnyAction => {
  return { type: VIDEO_RESET }
}

export {
  VIDEO_EDIT_MODE_SET,
  VIDEO_TIMELINE_SET,
  VIDEO_RESET,
  setVideoPlayerEditRange,
  setVideoPlayerTimeline,
  resetVideoPlayer
}
