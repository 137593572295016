import { createUseStyles } from 'react-jss'

import colours from '../../theme/colours'
import { Breakpoint } from '../../design-system-legacy/breakpoints'

export const useStyles = createUseStyles({
  playerControls: {
    display: 'flex',
    width: '100%',
    backgroundColor: colours.martinique,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 2,
    paddingRight: 10,
    [Breakpoint.SM]: {
      [Breakpoint.LANDSCAPE]: {
        // this is a bit of a safety net against users who shrink their
        // browser viewport, actual devices would be using native controls
        position: 'absolute',
        bottom: '0.5rem',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        width: 'calc(100% - 2em)'
      }
    }
  },
  playPause: {
    color: colours.white,
    padding: 10,
    border: 'none',
    backgroundColor: 'transparent',
    width: 50,
    marginLeft: 10,
    cursor: 'pointer'
  },
  volumeContainer: {
    display: 'flex',
    width: '20%'
  },
  volumeSliderWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingRight: 10
  },
  volumeButton: {
    margin: '0 10px 0 20px',
    width: 30,
    color: colours.white,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  expandButton: {
    color: colours.white,
    cursor: 'pointer'
  },
  volumeSlider: {
    '-webkit-appearance': 'none',
    appearance: 'none',
    width: '100%',
    height: 4,
    backgroundColor: colours.scooter,
    outline: 'none',
    borderRadius: 10,
    transition: 'opacity .2s',
    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      appearance: 'none',
      borderRadius: 100,
      width: 15,
      height: 15,
      background: colours.white,
      cursor: 'pointer'
    }
  }
})
