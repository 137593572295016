import { createUseStyles } from 'react-jss'
import { lighten } from '@mui/material/styles'

import colours from '../../theme/colours'
import { IUserListData } from '../../types/Auth'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'

interface IAdminConsoleUserManagerStylesProps {
  paginatedUserList: IUserListData
}

const paginationControlsButton = {
  color: colours.tuna,
  background: 'transparent',
  '&:hover': {
    backgroundColor: lighten(colours.alabaster, 0.1)
  },
  border: 'none',
  borderRadius: '5px',
  outline: 0,
  padding: 0,
  transition: '100ms background-color, 100ms opacity'
}

const useAdminConsoleUserManagerStyles = createUseStyles({
  userManagerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px 40px 0px 40px',
    marginTop: 20,
    minWidth: toResponsiveUnitFromPixels(900)
  },
  userManagerTableHeadings: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 700,
    margin: '10px 0px',
    paddingLeft: '30px'
  },
  headingSearchContainer: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between'
  },
  searchUsersInput: {
    border: `1px solid ${colours.frenchGray}`,
    paddingLeft: '15px',
    width: '200px',
    height: '30px',
    borderRadius: 25,
    backgroundColor: colours.alabaster,
    color: colours.black,
    outline: 'none',
    '&::placeholder': {
      color: colours.black
    }
  },
  userManagerHeading: {
    fontSize: 25,
    margin: 0
  },
  text: {
    margin: 0,
    width: '125px'
  },
  flexContainer: {
    display: 'flex'
  },
  addUserInputContainer: {
    color: colours.frenchGray,
    borderBottom: '1px solid'
  },
  addUserInput: {
    backgroundColor: colours.alabaster,
    height: '30px',
    minWidth: 225,
    maxWidth: 225,
    mt: 1,
    border: 'none',
    borderRadius: 0,
    color: colours.codGray,
    fontSize: '13px',
    '&:focus': {
      outline: 'none'
    },
    '&::placeholder': {
      color: colours.frenchGray
    }
  },
  dateAndSendContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 30
  },
  mockDate: {
    // this all needs a refactor, should not be using margins for table columns widths
    width: '130px',
    marginRight: '110px',
    margin: 0,
    color: colours.frenchGray,
    fontSize: 12
  },
  addUserRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    borderBottom: '2px solid white'
  },
  userManagerTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 5,
    borderRadius: 10,
    backgroundColor: colours.alabaster,
    padding: '10px 30px 10px 30px'
  },
  paginationContainer: {
    padding: '0px 20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  paginationControls: {
    display: 'flex',
    justifyContent: 'space-around',
    width: 200
  },
  userLimitWarning: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': { textAlign: 'center', marginBottom: 30 }
  },
  paginationControlsButtonLeft: ({ paginatedUserList }: IAdminConsoleUserManagerStylesProps) => ({
    ...paginationControlsButton,
    opacity: paginatedUserList.number === 0 ? 0.25 : 1,
    cursor: paginatedUserList.number === 0 ? 'not-allowed' : 'pointer'
  }),
  paginationControlsButtonRight: ({ paginatedUserList }: IAdminConsoleUserManagerStylesProps) => ({
    ...paginationControlsButton,
    opacity: paginatedUserList.number === paginatedUserList.totalPages - 1 ? 0.25 : 1,
    cursor: paginatedUserList.number === paginatedUserList.totalPages - 1 ? 'not-allowed' : 'pointer'
  })
})

export default useAdminConsoleUserManagerStyles
