import { applyMiddleware, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension'

import { lvsApi } from './store/apis/lvs'
import reducer from './redux'
import { middlewareApi } from './store/apis/middleware'
import { authApi } from './store/apis/auth'
import { userApi } from './store/apis/user'

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  actionsBlacklist: ['video/event/timeupdate']
})

export const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(thunk, lvsApi.middleware),
    applyMiddleware(thunk, middlewareApi.middleware),
    applyMiddleware(authApi.middleware),
    applyMiddleware(userApi.middleware)
  )
)

export const persistor = persistStore(store)
