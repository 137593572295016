import { createUseStyles } from 'react-jss'
import { Breakpoint } from '../../design-system-legacy/breakpoints'
import colours from '../../theme/colours'

const useProfileMenuStyles = createUseStyles({
  profileMenuContainer: {
    display: 'flex',
    position: 'fixed',
    border: '2px solid',
    marginLeft: '70px',
    borderRadius: 10,
    backgroundColor: colours.lightMartinique,
    borderColor: colours.white,
    color: 'white',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 40,
    flexDirection: 'row',
    padding: '20px 10px'
  },
  profileIconImage: {
    backgroundColor: colours.white,
    borderRadius: '100%',
    width: 25,
    marginRight: 10,
    marginLeft: 5
  },
  emailAddress: {
    padding: '0px 10px',
    display: 'block',
    [Breakpoint.MD]: {
      display: 'none'
    }
  },
  logoutText: {
    padding: 1,
    fontWeight: 'bold',
    minWidth: '60px',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  logoutButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: colours.white,

    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

export default useProfileMenuStyles
