import useErrorMessageStyles from './ErrorMessage.styles'
import classnames from 'classnames'
import { IErrorMessage } from './types'

export const ErrorMessage = ({ error, className }: IErrorMessage.Props) => {
  const classes = useErrorMessageStyles()
  if (!error) return null

  return (
    <div className={classnames(classes.errorMessage, className)} data-testid="form-validation-error">
      {error}
    </div>
  )
}
