import TagManager from 'react-gtm-module'

export const sendSearchTerm = (searchTerm: string, searchResultCount: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'search',
      search_term: searchTerm.replace(/~1/g, ''),
      search_result_count: searchResultCount
    }
  })
}

export const addToClipMetric = (
  lvsAssetId: string,
  lvsAssetName: string,
  videClipStartTime: string,
  videoClipEndTime: string,
  playlistId: string[]
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_playlist',
      lvs_asset_id: lvsAssetId,
      lvs_asset_name: lvsAssetName,
      video_clip_start_time: videClipStartTime,
      video_clip_end_time: videoClipEndTime,
      whizzard_playlist_id: playlistId.join(';')
    }
  })
}

export const sendSharePlaylist = (playlistId: string) => {
  TagManager.dataLayer({
    dataLayer: {
      whizzard_playlist_id: playlistId,
      event: 'share_playlist'
    }
  })
}

export const sendVideoPlay = (
  lvsAssetId: string,
  lvsAssetName: string,
  startTime: number,
  endTime: number,
  videoPlaybackMode?: string,
  clip_index_value?: number
) => {
  TagManager.dataLayer({
    dataLayer: {
      lvs_asset_id: lvsAssetId,
      lvs_asset_name: lvsAssetName,
      video_timecode_range: `${startTime}-${endTime}`,
      video_clip_start_time: startTime,
      video_clip_end_time: endTime,
      clip_index_value,
      video_playback_mode: videoPlaybackMode,
      event: 'video_play'
    }
  })
}
