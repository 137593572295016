interface User {
  first_name?: string
  last_name?: string
  email?: string
}

export const parseUsername = (user?: User) => {
  const { email, first_name, last_name } = user || {}

  if (!first_name || !last_name) {
    return email
  }

  if (first_name === 's' && last_name === 's') {
    // for some reason Whizzard defaults newly registered user
    // names both to `s`, in this case just display the email
    return email
  }

  return `${first_name} ${last_name}`
}
