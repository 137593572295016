import { JSXElementConstructor, ReactElement } from 'react'
import { useTheme } from '@mui/material/styles'
import { Checkbox, ListItemIcon, ListItemText, Typography, Select, SelectProps, SelectChangeEvent } from '@mui/material'

import { defaultMenuProps } from './Dropdown.constants'
import { DropdownLabel, StyledFormHelperText, StyledMenuItem, StyledOutlinedInput } from './StyledComponents'

export interface Option {
  key?: string
  text: string
  disabled?: boolean
  secondaryText?: string
  icon?: ReactElement<any, string | JSXElementConstructor<any>>
  check?: boolean
}

export interface DropdownProps extends SelectProps {
  options: Option[]
  title?: string
  helperText?: string
  selectAll?: string
  onSelection?: (value: string[]) => void
}

export const Dropdown = ({
  title,
  options,
  input = <StyledOutlinedInput />,
  helperText,
  label,
  multiple,
  value = [],
  onSelection,
  selectAll,
  MenuProps = defaultMenuProps,
  ...rest
}: DropdownProps) => {
  const theme = useTheme()
  const typedValue = value as string[]

  const activeOptions = options.filter(o => !o.disabled)
  const allSelected = activeOptions.length === typedValue.length

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const inputVal = event.target.value as string

    if (!inputVal.length || inputVal.includes('*')) {
      return onSelection?.(activeOptions.map(o => o.key || o.text))
    }

    return onSelection?.(typeof inputVal === 'string' ? inputVal.split(',') : inputVal)
  }

  return (
    <>
      {label && <DropdownLabel>{label}</DropdownLabel>}
      <Select
        {...rest}
        displayEmpty
        multiple={multiple}
        value={value}
        onChange={handleSelectChange}
        input={input}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': title ? `select ${title}` : 'select' }}
        renderValue={selected => {
          const typedSelected = selected as string[]
          if (selectAll && multiple && typedSelected.length === activeOptions.length) {
            return (
              <Typography color={theme.palette.grey[350]} variant="body2">
                {selectAll}
              </Typography>
            )
          }
          if (typedSelected.length === 0) {
            return (
              <Typography color={theme.palette.grey[350]} variant="body2">
                {title ?? 'Select'}
              </Typography>
            )
          }
          if (typedSelected.length === 1) {
            return (
              <Typography color={theme.palette.grey[800]} variant="body2">
                {options.find(o => o.key === typedSelected[0])?.text || typedSelected}
              </Typography>
            )
          }
          if (multiple) {
            return (
              <Typography color={theme.palette.grey[800]} variant="body2">
                {typedSelected.length} {title}s
              </Typography>
            )
          }
          return (
            <Typography color={theme.palette.grey[800]} variant="body2">
              {typedSelected}
            </Typography>
          )
        }}
      >
        {selectAll && multiple && (
          <StyledMenuItem disableRipple value="*" sx={{ padding: 0 }}>
            <ListItemIcon>
              <Checkbox indeterminate={allSelected} checked={allSelected} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {selectAll}
              </Typography>
            </ListItemText>
          </StyledMenuItem>
        )}
        {options.map(option => (
          <StyledMenuItem
            disableRipple
            key={option.text}
            disabled={option.disabled}
            value={option.key || option.text}
            sx={{
              padding: option.check ? '0px' : `${theme.spacing(1)} ${theme.spacing(2)}`
            }}
          >
            {option.check && (
              <ListItemIcon>
                <Checkbox checked={typedValue.includes(option.key || option.text)} />
              </ListItemIcon>
            )}
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            <ListItemText disableTypography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {option.text}
              </Typography>
            </ListItemText>
            <Typography variant="body50" color={theme.palette.grey[400]}>
              {option.secondaryText}
            </Typography>
          </StyledMenuItem>
        ))}
      </Select>
      {helperText && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
    </>
  )
}
