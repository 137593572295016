import { createSelector } from 'reselect'

import { Video } from '../../../types'
import { State } from '../../'

import { AssetsState } from './reducer'

const selectAssetsState = (state: State): AssetsState => state.assets

const selectAssets = createSelector(
  selectAssetsState,
  (state: AssetsState): Video[] => state.items // replace with :Video[] or IVideoClip[]
)

const selectCorrectDurations = createSelector(selectAssetsState, (state: AssetsState): number[] => state.durations)

export { selectAssets, selectCorrectDurations }
