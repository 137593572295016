import { ReactNode } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

interface ActionConfirmationDialogProps {
  open: boolean
  children: ReactNode
  actionCopy: string
  title: string
  handleClose: () => void
  handleConfirmAction: () => void
  isConfirmationLoading?: boolean
  ariaLabelledBy: string
  ariaDescribedBy: string
}

export const ActionConfirmationDialog = ({
  children,
  open,
  title,
  actionCopy,
  handleClose,
  handleConfirmAction,
  isConfirmationLoading,
  ariaLabelledBy,
  ariaDescribedBy
}: ActionConfirmationDialogProps) => {
  return (
    <Dialog maxWidth="md" fullWidth open={open} aria-labelledby={ariaLabelledBy} aria-describedby={ariaDescribedBy}>
      <DialogTitle id={ariaLabelledBy}>
        <Typography component="p" sx={{ fontWeight: 700 }} variant="head700">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent id={ariaDescribedBy}>{children}</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose} disabled={isConfirmationLoading}>
          Cancel
        </Button>
        <Button color="error" variant="contained" onClick={handleConfirmAction} autoFocus>
          {isConfirmationLoading ? <CircularProgress size={24} /> : actionCopy}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
