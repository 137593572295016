import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { notify } from 'react-notify-toast'
import useQueryString from '../../hooks/useQueryString'
import { ROUTES } from '../../RouteConfig/constants'
import { clearStore, selectAccessToken } from '../../store/slices/auth'
import { authApi } from '../../store/apis/auth'
import { lvsApi } from '../../store/apis/lvs'
import { userApi } from '../../store/apis/user'

interface AuthParams {
  code?: string
  error?: string
  error_description?: string
}

// TODO find a better way to handle tenant IDs...
const tenantId = window.location.origin.includes('/whizzard.linius.com')
  ? String(process.env.REACT_APP_UON_TENANT_ID)
  : '61d7ff93a2c8170f55c188ac' // tenant on STAGE with external users enabled

export const OAuthCallback = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccessToken)

  const [putCode, codeResult] = authApi.endpoints.putCode.useLazyQuery()
  const [getTenantApiKeys, apiKeyResult] = userApi.endpoints.getTenantApiKeys.useLazyQuery()
  const [getUserDetails, userResult] = userApi.endpoints.getUserDetails.useLazyQuery()

  const { code, error: codeError, error_description } = useQueryString() as AuthParams
  const history = useHistory()
  const location: any = useLocation()

  const isSuccess = codeResult.isSuccess && userResult.isSuccess && apiKeyResult.isSuccess
  const isError = codeResult.isError || userResult.isError || apiKeyResult.isError

  const [isLoading, setIsLoading] = useState(false)

  const originalPath = window.localStorage.getItem('original path')

  useEffect(() => {
    if (accessToken && code && !isLoading) {
      dispatch(clearStore())
    }
  }, [accessToken, code, dispatch, isLoading])

  useEffect(() => {
    if (codeError) {
      notify.show(codeError, 'error')
      console.error(error_description)
    }
  }, [codeError, error_description])

  useEffect(() => {
    if (!code || isLoading) return

    setIsLoading(true)

    const requestBody = {
      code,
      redirectUrl: `${window.location.origin}${ROUTES.OAUTH_CALLBACK}`,
      tenantId
    }

    putCode(requestBody)
      .unwrap()
      .then(({ token }) => {
        const { userId }: Record<string, number> = jwtDecode(token)

        getUserDetails(userId)
          .unwrap()
          .then(async userDetailsResponse => {
            if (!userDetailsResponse?.tenantId) {
              throw Error('Could not get user details')
            }
            await getTenantApiKeys(userDetailsResponse.tenantId)
            dispatch(lvsApi.endpoints.getPayloadCategories.initiate(userDetailsResponse.tenantId))
          })
      })
      .catch(console.error)
  }, [code, dispatch, accessToken, putCode, getTenantApiKeys, getUserDetails, isLoading])

  useEffect(() => {
    if (codeError || isError) {
      notify.show('There was a problem signing in, please try again.', 'error')
      history.replace(ROUTES.LOGIN)
    }
  }, [codeError, history, isError])

  useEffect(() => {
    if (isSuccess) {
      history.replace(originalPath || ROUTES.SEARCH)
      window.localStorage.removeItem('original path')
    }
  }, [history, isSuccess, location, originalPath])

  return null
}
