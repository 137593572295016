import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

import { getSearchResultsCardStyles } from './SearchResultsCard.styles'
import { SearchResultsCardSkeleton } from './SearchResultsCardSkeleton'
import { formatTime } from '../../utils'
import { ClipTagList } from '../low-level/ClipTagList'
import { ExtraActions } from '../low-level/ExtraActions/ExtraActions'
import { CustomWidthTooltip } from '../low-level/CustomWidthTooltip/CustomWidthTooltip'
import { useGetAssetThumbnailQuery } from '../../store/apis/lvs'
import { VIDEO_POSTER_DEFAULT } from '../../constants'
import { InsightsCount } from '../InsightsCount'
import { Insight } from '../../utils/clip'

export interface Action {
  title: string
  onClick: () => void
}

export interface SearchResultsCardProps {
  assetId?: string
  isLoading?: boolean
  title: string
  creationDate: string
  insight: string
  startsAt: number
  duration: number
  actions: Action[]
  playOnClick: () => void
  tags: string[]
  insights: Insight[]
}

export const SearchResultsCard = ({
  title,
  creationDate,
  startsAt,
  insight,
  assetId,
  duration,
  actions,
  tags,
  insights,
  playOnClick,
  isLoading
}: SearchResultsCardProps) => {
  const theme = useTheme()

  const styles = getSearchResultsCardStyles(useTheme())

  const { data: imageUrl, isLoading: isThumbnailLoading, isError: isThumbnailError } = useGetAssetThumbnailQuery(
    Number(assetId)
  )

  const isBelowMDBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  if (isLoading || isThumbnailLoading) return <SearchResultsCardSkeleton />

  return (
    <Card sx={styles.card} data-testid="search-results-card">
      <CardActionArea onClick={playOnClick} sx={{ position: 'relative' }}>
        <Box sx={{ width: '100%', aspectRatio: '16/9' }}>
          <CardMedia component="img" image={isThumbnailError ? VIDEO_POSTER_DEFAULT : imageUrl} alt="clip thumbnail" />
          <PlayCircleOutlineIcon sx={styles.playIcon} />
          <Chip sx={styles.clipLength} label={formatTime(duration)} data-testid="clip-duration" />
        </Box>
      </CardActionArea>

      <CardContent sx={styles.cardContent}>
        <Box sx={styles.clipMetadataContainer}>
          <CustomWidthTooltip title="Date when the source video was recorded">
            <Box display="flex">
              <Typography
                variant="body1"
                color={theme.palette.grey[400]}
                sx={{ mb: 2, mr: 0.5, [theme.breakpoints.up('lg')]: { display: 'none' } }}
              >
                Recorded on
              </Typography>
              <Typography mb={2} variant="body1" color={theme.palette.grey[400]} data-testid="clip-recorded-date">
                {creationDate}
              </Typography>
            </Box>
          </CustomWidthTooltip>

          <CustomWidthTooltip title="Clip start time in the source video">
            <Typography
              sx={{ [theme.breakpoints.down('lg')]: { display: 'none' } }}
              mb={2}
              variant="body1"
              color={theme.palette.grey[400]}
              data-testid="clip-start"
            >
              {formatTime(startsAt)}
            </Typography>
          </CustomWidthTooltip>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={styles.title} variant="head500" color={theme.palette.grey[800]} data-testid="clip-title">
            {title}
          </Typography>
          <ExtraActions actions={actions} />
        </Box>

        <Typography variant="body2" color={theme.palette.grey[400]} sx={styles.insight} data-testid="featured-insight">
          {insight}
        </Typography>

        <InsightsCount sx={{ mb: 2 }} insights={insights} />

        <ClipTagList tagSize={isBelowMDBreakpoint ? 'small' : 'medium'} tags={tags} />
      </CardContent>
    </Card>
  )
}
