import { createUseStyles } from 'react-jss'

import { Breakpoint } from '../../design-system-legacy/breakpoints'
import colours from '../../theme/colours'

interface IVideoEditorSliderStyleProps {
  zoomLevel: number
  secondToggle: number
}

const useVideoEditorSliderStyles = createUseStyles({
  container: {
    borderRadius: 8,
    flexDirection: 'column',
    marginTop: 3,
    [Breakpoint.XS]: {
      marginTop: 2
    },
    overflow: 'hidden'
  },
  sliderContainer: {
    flexDirection: 'column',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: '3px 3px',
    backgroundColor: 'transparent',
    position: 'relative'
  },
  zoomLevelSlider: {
    webkitAppearance: 'none',
    height: 8,
    appearance: 'none',
    borderRadius: 20,
    background: colours.alto,
    '&::-webkit-slider-thumb': {
      webkitAppearance: 'none',
      appearance: 'none',
      width: '12px',
      height: '10px',
      borderRadius: 50,
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing',
        borderColor: colours.scooter,
        boxShadow: `0 0 5px ${colours.scooter}`
      },
      background: colours.martinique
    }
  },
  rangeSlider: ({ zoomLevel, secondToggle }: IVideoEditorSliderStyleProps) => ({
    alignItems: 'center',
    width: `${60 + Math.pow(zoomLevel, 2)}%`,
    left: `-${40 * (secondToggle / 100)}%`,
    position: 'relative',
    minWidth: '100%'
  }),
  zoomLevelContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 40px 0 5px'
  },
  leftZoomTag: {
    fontSize: 8,
    textAlign: 'center',
    marginRight: 3
  },
  rightZoomTag: {
    fontSize: 8,
    textAlign: 'center',
    marginLeft: 3
  },
  zoomSlidersContainer: {
    display: 'flex',
    padding: '2px 1px 2px 1px',
    alignItems: 'center'
  },
  playlistButtons: { margin: 4, marginBottom: 6 },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  playlistButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  updateInPlaylistButton: {
    borderRadius: 18,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colours.codGray,
    backgroundColor: colours.white,
    color: colours.codGray,
    fontWeight: 'bold',
    cursor: 'pointer',
    outline: 0,
    transition: '100ms background-color, 100ms border-color',
    '&:hover': {
      backgroundColor: colours.alabaster,
      borderColor: colours.codGray
    },
    '&:active': {
      backgroundColor: colours.alto,
      borderColor: colours.codGray
    },
    '&:disabled': {
      cursor: 'default',
      borderColor: colours.frenchGray,
      color: colours.frenchGray,
      '&:hover': {
        backgroundColor: colours.white,
        borderColor: colours.frenchGray
      },
      '&:active': {
        backgroundColor: colours.white,
        borderColor: colours.frenchGray
      }
    },
    fontSize: 16,
    padding: '0 18px'
  },
  saveAndAddToPlaylistButton: {
    borderRadius: 18,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colours.codGray,
    backgroundColor: colours.white,
    color: colours.codGray,
    fontWeight: 'bold',
    cursor: 'pointer',
    outline: 0,
    transition: '100ms background-color, 100ms border-color',
    '&:hover': {
      backgroundColor: colours.alabaster,
      borderColor: colours.codGray
    },
    '&:active': {
      backgroundColor: colours.alto,
      borderColor: colours.codGray
    },
    '&:disabled': {
      cursor: 'default',
      borderColor: colours.frenchGray,
      color: colours.frenchGray,
      '&:hover': {
        backgroundColor: colours.white,
        borderColor: colours.frenchGray
      },
      '&:active': {
        backgroundColor: colours.white,
        borderColor: colours.frenchGray
      }
    },
    padding: '0 18px',
    fontSize: 16,
    marginLeft: '10px'
  }
})

export default useVideoEditorSliderStyles
