import { createUseStyles } from 'react-jss'
import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'
import { INavOption } from './types'

const usenavOptionListStyles = createUseStyles({
  button: ({ isActive, locked, isHidden }: INavOption.StyleProps) => ({
    display: isHidden ? 'none' : 'auto',
    fontSize: 16,
    fontWeight: 'bold',
    opacity: isActive ? 1 : 0.5,
    color: isActive ? colours.scooter : locked ? colours.darkGrey : colours.alto,
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    justifyContent: 'center',
    cursor: locked ? 'initial' : 'pointer'
  }),
  subOption: {
    margin: 0,
    marginLeft: toResponsiveUnitFromPixels(30)
  }
})

export default usenavOptionListStyles
