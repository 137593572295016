import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectLoggedIn } from '../redux/selectors'

export const useRedirectIfLoggedIn = (to: string) => {
  const loggedIn = useSelector(selectLoggedIn)
  const history = useHistory()

  if (loggedIn) {
    history.push(to)
  }
}
