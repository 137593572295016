import axios, { AxiosResponse } from 'axios'

import axiosInstance from '../axios'
import { IConfirmationData, IUserBillingData, IUserListData } from '../types/Auth'
import { IResetPasswordRequest } from '../pages/PasswordReset/types'

export const getUserBillingInfo = async (apiKey: string): Promise<AxiosResponse<any>> => {
  const userFreeTrialInfo = await axiosInstance.get<IUserBillingData>(
    `${process.env.REACT_APP_LVS_API}/v2/billing/report/getApiKeyInfo/${apiKey}`
  )
  return userFreeTrialInfo
}

export const getUserList = async (
  tenantId: string | undefined,
  apiKey: string | undefined
): Promise<AxiosResponse<IUserListData>> => {
  const userList = await axiosInstance.get<IUserListData>(
    `${process.env.REACT_APP_LVS_API}/v2/iam/user?tenantId=${tenantId}`,
    {
      headers: {
        'X-Api-Key': apiKey || ''
      }
    }
  )
  return userList
}

export const getPaginatedUserList = async (
  tenantId: string | undefined,
  apiKey: string | undefined,
  page?: number,
  searchTerm?: string
): Promise<AxiosResponse<IUserListData>> => {
  const paginatedUserList = await axiosInstance.get<IUserListData>(
    `${process.env.REACT_APP_LVS_API}/v2/iam/user?tenantId=${tenantId}&size=10&page=${page}&term=${searchTerm}`,
    {
      headers: {
        'X-Api-Key': apiKey || ''
      }
    }
  )
  return paginatedUserList
}

export const confirmUser: any = async (code: string, userName: string): Promise<AxiosResponse<IConfirmationData>> => {
  return axios.post<IConfirmationData>(`${process.env.REACT_APP_LVS_API}/v2/iam/auth/signup/confirm`, {
    userName,
    code
  })
}
export const requestPaswordLink = (userName: string): Promise<AxiosResponse<IResetPasswordRequest.Props>> => {
  return axios.post(`${process.env.REACT_APP_LVS_API}./v2/iam/auth/resetPassword`, { userName })
}
