import { MyContent } from './MyContent'

const MyContentPage = () => {
  const pageSize = 25
  return (
    <>
      <div style={{ padding: '30px' }}>
        <MyContent pageSize={pageSize} />
      </div>
    </>
  )
}

export default MyContentPage
