import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../RouteConfig/constants'
import { lvsApi } from '../../../store/apis/lvs'
import {
  clearSearchQuery,
  clearSearchResults,
  clearSearchFilters,
  selectCategories,
  selectSearchQuery,
  setSearchCategory,
  setSearchTerm,
  selectSearchTerm
} from '../../../store/slices/search'
import { SearchSlice } from '../../../store/slices/search/types'
import { createLuceneString } from '../../../store/slices/search/helpers'
import { sendSearchTerm } from '../../../utils/googleAnalytics'

const getCompositeQueryKey = ({ value, category }: SearchSlice.Query) => {
  return `${value}${category.join('')}`
}

export const useSearchBar = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const searchTerm = useSelector(selectSearchTerm)
  const searchQuery = useSelector(selectSearchQuery)
  const searchCategories = useSelector(selectCategories)

  const [queryKey, setQueryKey] = useState(getCompositeQueryKey(searchQuery))

  const categoryOptions = searchCategories.map(({ key, disabled, text }) => {
    return { check: true, key, disabled, text }
  })
  const [
    fetchSearchResults,
    { isFetching: isFetchingInitialResults }
  ] = lvsApi.endpoints.getBufferedSearch.useLazyQuery()

  const handleSearchSubmit = useCallback(() => {
    dispatch(clearSearchResults())
    history.push(ROUTES.SEARCH_RESULTS)
    const newQueryKey = getCompositeQueryKey(searchQuery)

    if (queryKey !== newQueryKey) {
      // new base search query so clear any now irrelevant filters

      dispatch(clearSearchFilters())
      setQueryKey(newQueryKey)
    }

    fetchSearchResults(true)
      .unwrap()
      .then(res => {
        sendSearchTerm(createLuceneString(searchQuery), res.totalResults)
      })
  }, [dispatch, history, fetchSearchResults, searchQuery, queryKey])

  const handleSearchTermChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setSearchTerm(event.target.value))
    },
    [dispatch]
  )

  const handleSearchReset = useCallback(() => {
    dispatch(clearSearchQuery())
    dispatch(clearSearchResults())
    history.push(ROUTES.SEARCH)
    setQueryKey(getCompositeQueryKey(searchQuery))
  }, [dispatch, history, searchQuery])

  const categoryOnChange = useCallback(
    (values: string[]) => {
      dispatch(setSearchCategory(values))
    },
    [dispatch]
  )

  const handleSearchOnClear = useCallback(() => {
    dispatch(clearSearchQuery())
  }, [dispatch])

  return {
    searchTerm,
    searchQuery,
    categoryOptions,
    isFetchingInitialResults,
    handleSearchSubmit,
    handleSearchTermChange,
    handleSearchReset,
    categoryOnChange,
    handleSearchOnClear
  }
}
