import { AnyAction } from 'redux'

import {
  SIDENAV_ACTIVE_ITEM_SET,
  SIDENAV_OPEN_SET,
  SIDENAV_OPEN_TOGGLE,
  SIDENAV_SECONDARY_ACTIVE_ITEM_SET
} from './actions'
export interface ISideNavState {
  isOpen: boolean
  activeItem: string
  activeItemSecondary: string
}

const initialState = {
  isOpen: false,
  activeItem: '',
  activeItemSecondary: ''
}

const reducer = (state: ISideNavState = initialState, action: AnyAction): ISideNavState => {
  switch (action.type) {
    case SIDENAV_OPEN_SET:
      return {
        ...state,
        isOpen: action.isOpen
      }

    case SIDENAV_OPEN_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen
      }

    case SIDENAV_ACTIVE_ITEM_SET:
      return {
        ...state,
        activeItem: action.activeItem
      }
    case SIDENAV_SECONDARY_ACTIVE_ITEM_SET:
      return {
        ...state,
        activeItemSecondary: action.activeItemSecondary
      }

    default:
      return state
  }
}

export default reducer
export { initialState }
