import { Components } from '@mui/material'
import { Theme } from '@mui/material/styles/createTheme'
import type {} from '@mui//x-date-pickers/themeAugmentation'

export const defaultComponents: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&:hover': { boxShadow: 'none' }
      },
      sizeSmall: {
        height: '32px',
        fontSize: '0.875rem',
        padding: '8px 10px'
      },
      sizeMedium: {
        height: '48px',
        fontSize: '1rem',
        padding: '12px 16px'
      },
      sizeLarge: {
        height: '56px',
        fontSize: '1.125rem',
        padding: '16px 24px'
      },
      containedSecondary: ({ theme }) => ({
        backgroundColor: theme.palette.primary[50],
        border: `1px solid ${theme.palette.primary[100]}`,
        color: theme.palette.primary[600],
        '&:hover': { backgroundColor: theme.palette.primary[100], border: `1px solid ${theme.palette.primary[300]}` }
      })
    }
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: ({ theme }) => ({ '& div ': { ...(theme as Theme).typography.body2 } }),
      viewTransitionContainer: ({ theme }) => ({
        '& div span': { ...(theme as Theme).typography.body50 },
        '& div .PrivatePickersSlideTransition-root': { minHeight: 205 }
      })
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      root: ({ theme }) => ({ ...(theme as Theme).typography.body1, borderRadius: '4px' })
    }
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 590,
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.primary.main
      })
    }
  },
  MuiPagination: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .Mui-selected': {
          color: theme.palette.primary.main
        }
      })
    }
  },
  MuiCheckbox: {
    defaultProps: {
      disableFocusRipple: true,
      disableRipple: true
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        margin: 9,
        padding: 0,
        borderRadius: 4,
        '&.Mui-focusVisible': {
          outline: `2px solid ${theme.palette.focus.main}`
        },
        ...(ownerState.color === 'error' && {
          color: theme.palette.error.main
        })
      })
    }
  },
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        fontSize: '0.75rem',
        ...(ownerState.color === 'warning' && {
          backgroundColor: theme.palette.warning[50],
          color: theme.palette.warning[600]
        }),
        ...(ownerState.color === 'error' && {
          backgroundColor: theme.palette.error[50],
          color: theme.palette.error.dark
        }),
        ...(ownerState.color === 'success' && {
          backgroundColor: theme.palette.success[50],
          color: theme.palette.success[600]
        }),
        ...(ownerState.color === 'primary' && {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.grey[500]
        })
      })
    }
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      switchBase: ({ ownerState, theme }) => ({
        padding: 8,
        '&.Mui-checked': {
          color: theme.palette.common.white,
          transform: ownerState.size === 'medium' ? 'translateX(18px)' : 'translateX(13px) !important'
        },
        '&.Mui-focusVisible+.MuiSwitch-track': {
          boxShadow: `0px 0px 0px 2px ${theme.palette.focus.main}`
        },
        '&.Mui-disabled': { color: theme.palette.grey[25] },
        '&.Mui-checked+.MuiSwitch-track': { opacity: 1 },
        '&.Mui-disabled+.MuiSwitch-track': { opacity: 1, backgroundColor: theme.palette.grey[150] },
        '&.Mui-checked.Mui-disabled': { color: theme.palette.grey[25] }
      }),
      sizeSmall: {
        width: '45px',
        height: '30px',
        padding: 4
      },
      sizeMedium: {
        width: '58px',
        height: '38px',
        padding: 4
      },
      thumb: ({ ownerState }) => ({
        width: 24,
        height: 24,
        boxShadow: 'none',
        marginTop: ownerState.size === 'medium' ? 0 : 4,
        marginLeft: ownerState.size === 'medium' ? 0 : 4
      }),
      track: ({ ownerState, theme }) => ({
        height: ownerState.size === 'small' ? 24 : 32,
        width: ownerState.size === 'small' ? 39 : 52,
        opacity: 1,
        backgroundColor: theme.palette.grey[450],
        borderRadius: ownerState.size === 'small' ? 12 : 16
      })
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.grey[25]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      })
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        marginTop: theme.spacing(3)
      })
    }
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.common.black,
        fontSize: theme.typography.body2.fontSize
      })
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        backgroundColor: theme.palette.grey[25]
      })
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.grey[800]
      })
    }
  },
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave'
    },
    styleOverrides: {
      root: {
        transform: 'none'
      }
    }
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        head900: 'h1',
        head800: 'h1',
        head700: 'h1',
        head600: 'h2',
        head500: 'h2',
        head400: 'h3',
        head300: 'h4',
        body50: 'p',
        body50semi: 'p',
        body3: 'p'
      }
    }
  }
}

export const skyComponents: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      containedSizeSmall: {
        fontSize: '0.75rem'
      },
      contained: ({ theme }) => ({
        '&:hover': { backgroundColor: theme.palette.primary.light }
      }),
      containedSizeLarge: {
        fontSize: '1rem'
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        '--color-lvp': '#1090D5'
      },
      body: {
        fontSize: '1rem',
        lineHeight: 1.3,
        fontFamily: 'Muli'
      },
      h1: { marginTop: 0 }
    }
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        head900: 'h1',
        head800: 'h1',
        head700: 'h1',
        head600: 'h2',
        head500: 'h2',
        head400: 'h3',
        head300: 'h4',
        head200: 'h5',
        head100: 'h6',
        body3: 'span',
        body4: 'span'
      }
    }
  }
}
