import { createUseStyles } from 'react-jss'

import { Breakpoint } from '../../design-system-legacy/breakpoints'

const useAdminRouteStyles = createUseStyles({
  adminRouteChildrenContainer: {
    paddingLeft: '70px',
    paddingBottom: 0,
    [Breakpoint.MD]: {
      paddingLeft: 0,
      paddingBottom: '70px'
    }
  }
})

export default useAdminRouteStyles
