import { createSelector } from 'reselect'

import { IUserBillingData, IUserData, IUserListData } from '../../../types/Auth'
import { State } from '../../'

import { AuthState } from './reducer'

const selectAuthState = (state: State): AuthState => state.auth

const selectTenantId = createSelector(selectAuthState, (state: AuthState): string | undefined => state.user?.tenantId)

const selectState = createSelector(selectAuthState, (state: AuthState): any => state)

const selectTenantName = createSelector(selectAuthState, (state: AuthState): string => state.user?.tenantName || '')

const selectUser = createSelector(selectAuthState, (state: AuthState): IUserData | null => state.user)
const selectPaginatedUserList = createSelector(
  selectAuthState,
  (state: AuthState): IUserListData => state.paginatedUserList
)

const selectUserList = createSelector(selectAuthState, (state: AuthState): IUserListData => state.userList)

const selectUserBillingInfo = createSelector(
  selectAuthState,
  (state: AuthState): IUserBillingData | null => state.userBillingInfo
)

const selectUserId = createSelector(selectAuthState, (state: AuthState): number | null => state.user?.id || null)
const selectUserRole = createSelector(selectAuthState, (state: AuthState): string | null => state.user?.role || '')

const selectUserName = createSelector(selectAuthState, (state: AuthState): string | '' => state.user?.userName || '')

const selectLoggedIn = createSelector(selectAuthState, (state: AuthState): boolean => state.accessToken.length > 0)
const selectTokens = createSelector(selectAuthState, (state: AuthState) => ({
  accessToken: state.accessToken,
  refreshToken: state.refreshToken
}))
const selectApiKey = createSelector(selectAuthState, (state: AuthState): string => state.apiKey)

const selectUserIsSubscribed = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state?.userSubscription?.hasOwnProperty('id') || false
)

const selectUserSubscriptionFetched = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state?.userSubscription?.fetched
)

const selectShowHelp = createSelector(selectAuthState, (state: AuthState): boolean => state?.helpPrompt)

const selectAuthLoading = createSelector(selectAuthState, (state: AuthState): boolean => state?.authLoading)

export {
  selectAuthLoading,
  selectTenantId,
  selectTenantName,
  selectUser,
  selectUserList,
  selectPaginatedUserList,
  selectUserId,
  selectUserRole,
  selectLoggedIn,
  selectUserName,
  selectTokens,
  selectApiKey,
  selectUserBillingInfo,
  selectUserIsSubscribed,
  selectUserSubscriptionFetched,
  selectState,
  selectShowHelp
}
