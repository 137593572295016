import { Box, Skeleton, useTheme } from '@mui/material'
import { getSearchResultsCardStyles } from './SearchResultsCard.styles'

export const SearchResultsCardSkeleton = () => {
  const theme = useTheme()
  const styles = getSearchResultsCardStyles(theme)
  return (
    <Box sx={{ ...styles.card, height: '100%' }} data-testid="skeleton-card">
      <Skeleton variant="text" width="auto" sx={{ backgroundColor: theme.palette.grey[250], aspectRatio: '16/9' }} />
      <Box px={1} py={2}>
        <Skeleton variant="text" width="95%" height={20} sx={{ mb: 2, backgroundColor: theme.palette.grey[150] }} />
        <Skeleton variant="text" width="95%" height={24} sx={{ mb: 3, backgroundColor: theme.palette.grey[200] }} />
        <Skeleton variant="text" width="95%" height={10} sx={{ mb: 2, backgroundColor: theme.palette.grey[25] }} />
        <Skeleton variant="text" width="60%" height={9} sx={{ mb: 3, backgroundColor: theme.palette.grey[25] }} />
        <Skeleton variant="text" width={134} height={10} sx={{ mb: 3, backgroundColor: theme.palette.grey[25] }} />
        <Skeleton variant="text" width={134} height={10} sx={{ mb: 6, backgroundColor: theme.palette.grey[25] }} />
      </Box>
    </Box>
  )
}
