import { createSelector } from 'reselect'

import { State } from '../..'

import { QueueState } from './reducer'

const selectQueueState = (state: State): QueueState => state.queue

const selectQueueCurrentIndex = createSelector(selectQueueState, (state: QueueState): number => state.currentIndex)

const selectQueueCurrentPlaylistId = createSelector(
  selectQueueState,
  (state: QueueState): string => state.currentPlaylistId
)

export { selectQueueCurrentIndex, selectQueueCurrentPlaylistId }
