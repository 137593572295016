import { AnyAction } from 'redux'

import { Video } from '../../../types'

const INDIVIDUAL_ASSET_ITEM_SET = 'individualAsset/item/set'
const INDIVIDUAL_ASSET_ITEM_RESET = 'individualAsset/item/reset'

const setIndividualAssetItem = (payload: Video): AnyAction => {
  return {
    type: INDIVIDUAL_ASSET_ITEM_SET,
    payload
  }
}

const resetIndividualAssetItem = (): AnyAction => {
  return {
    type: INDIVIDUAL_ASSET_ITEM_RESET
  }
}

export {
  INDIVIDUAL_ASSET_ITEM_SET,
  INDIVIDUAL_ASSET_ITEM_RESET,
  setIndividualAssetItem,
  resetIndividualAssetItem
}
