import ReactTooltip from 'react-tooltip'
import { useFormik } from 'formik'
import { ErrorMessage } from '../../../components/ErrorMessage'

import useLoginStyles from '../../Login/Login.styles'
import { ResetPasswordSchema } from '../../../schemas/RequestPasswordSchema'
import { useSetCurrentErrorMessage } from '../../../hooks/useSetCurrentErrorMessage'
import colours from '../../../theme/colours'
import { useResetPassword } from '../usePasswordReset/usePasswordReset'

export const PasswordResetRequestForm = ({ setIsSubmitting }) => {
  const classes = useLoginStyles()
  const { handleSendRequestPasswordLink } = useResetPassword()

  const { currentErrorMessage, onMouseEnter, onMouseLeave } = useSetCurrentErrorMessage()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async values => {
      handleSendRequestPasswordLink(values.email.split('@').join('_at_'))
      setIsSubmitting(true)
    }
  })

  const hasErrors = Object.keys(formik.errors).length

  return (
    <form
      id="password-request"
      className={classes.form}
      onSubmit={formik.handleSubmit}
      noValidate
      data-testid="login-form">
      <div className={classes.relative}>
        <div className={classes.errorContainerFull}>
          <ErrorMessage error={hasErrors ? 'Invalid field.' : ''} />
        </div>
      </div>
      <div className={classes.inputs}>
        <div className={classes.inputContainer}>
          <div className={classes.passwordContainer}>
            <input
              style={{ position: 'relative' }}
              className={`${classes.formInput} ${
                formik.errors.email ? classes.error : (formik.touched.email && classes.success) || null
              }`}
              onMouseEnter={() => onMouseEnter(formik, 'email')}
              onMouseLeave={onMouseLeave}
              name="email"
              placeholder="Enter your email"
              data-for="email"
              data-tip={currentErrorMessage}
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <ReactTooltip backgroundColor={colours.tuna} effect="solid" id="email" />
          </div>
        </div>
      </div>
      <button className={classes.loginButton} type="submit" disabled={formik.isSubmitting}>
        Reset password
      </button>
    </form>
  )
}
