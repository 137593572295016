import { useTheme } from '@mui/material'
import { DatePicker as MUIDatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const DatePicker = (props: DatePickerProps) => {
  const theme = useTheme()

  const {
    InputAdornmentProps = {
      sx: { '& .MuiButtonBase-root': { color: theme.palette.primary.main, '& svg': { fontSize: 16 } } }
    },
    ...rest
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        {...rest}
        inputFormat="dd/MM/yyyy"
        InputAdornmentProps={InputAdornmentProps}
        components={{
          LeftArrowIcon: ArrowBackIcon,
          RightArrowIcon: ArrowForwardIcon
        }}
      />
    </LocalizationProvider>
  )
}
