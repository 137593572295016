import { styled, TextField } from '@mui/material'

export const DefaultDatePickerInput = styled(TextField)(({ theme }) => ({
  '&.MuiFormControl-root': {
    '& p ': {
      '&.MuiFormHelperText-root': {
        ...theme.typography.body50,
        color: theme.palette.grey[400],
        textTransform: 'none',
        margin: `${theme.spacing(0.5)} 0px 0px 0px`
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    ...theme.typography.body1,
    fontWeight: 500,
    height: '32px',
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.grey[25] },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[25],
      color: theme.palette.grey[250],
      '& fieldset': {
        border: `1px solid ${theme.palette.primary[100]}`
      }
    },
    '&.MuiInputBase-sizeSmall': {
      ...theme.typography.body1,
      height: '40px'
    },
    '& fieldset': {
      border: `1px solid ${theme.palette.primary[100]}`
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.primary[300]}`
    },

    '&:hover fieldset .Mui-error': {
      border: `1px solid ${theme.palette.primary.main}`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      boxShadow: `0px 0px 0px 2px ${theme.palette.focus.main}`
    },
    '&.Mui-error': {
      '&:hover fieldset': {
        border: `1px solid ${theme.palette.error.main}`
      }
    },
    '&.MuiFormHelperText-root': {
      color: 'green'
    }
  }
}))
