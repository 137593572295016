import { AnyAction } from 'redux'

import { VIDEO_EDIT_MODE_SET, VIDEO_TIMELINE_SET, VIDEO_RESET } from './actions'

// TODO move timeline into queue slice
// TODO move clip props into a new editor slice
export interface VideoPlayerState {
  timeline: any[]
  clipOffset: number | null
  clipDuration: number | null
}

const initialState: VideoPlayerState = {
  timeline: [],
  clipOffset: null,
  clipDuration: null
}

const reducer = (state: VideoPlayerState = initialState, action: AnyAction): VideoPlayerState => {
  switch (action.type) {
    case VIDEO_EDIT_MODE_SET:
      return {
        ...state,
        clipOffset: action.offset,
        clipDuration: action.duration,
        timeline: []
      }

    case VIDEO_TIMELINE_SET:
      return {
        ...state,
        timeline: action.payload
      }

    case VIDEO_RESET:
      return initialState

    default:
      return state
  }
}

export default reducer
export { initialState }
