import { useCallback, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { notify } from 'react-notify-toast'
import {
  Button,
  DialogActions,
  InputLabel,
  DialogContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Radio,
  RadioGroup,
  useTheme,
  Box,
  Typography,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  CircularProgress,
  IconButton
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'

import {
  useAppendClipMutation,
  useCreatePlaylistMutation,
  useGetSearchPlaylistsQuery
} from '../../../store/apis/middleware'
import { wordAsPluralOrSingular } from '../../../utils/count'
import { MAX_PLAYLIST_DESCRIPTION_LENGTH, MAX_PLAYLIST_TITLE_LENGTH } from '../../../pages/ViewPlaylist/ViewPlaylist'
import { selectUserDetails } from '../../../store/slices/user'
import { TextField } from '../../low-level/TextField/TextField'

export interface AddToPlaylistClip {
  assetId: number
  offset: number
  duration: number
  title: string
  description: string
}

interface AddToPlaylistDialogProps {
  open: boolean
  setClose: () => void
  clip: AddToPlaylistClip
}

const CONTAINER_HEIGHT = 305
const MAX_CLIPS_IN_PLAYLISTS = 100
const MAX_PLAYLIST_COUNT = 500

export const AddToPlaylistDialog = ({ open, setClose, clip }: AddToPlaylistDialogProps) => {
  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()

  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''

  const [isCreateNewPlaylist, setIsCreateNewPlaylist] = useState<boolean>(true)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [playlistName, setPlaylistName] = useState<string>('')
  const [playlistDescription, setPlaylistDescription] = useState<string>('')
  const [isCheckedPlaylist, setIsCheckedPlaylist] = useState<string[]>([])
  const [isNameInputTouched, setNameInputTouched] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const { data: playlistsData, isLoading: isPlaylistsDataLoading } = useGetSearchPlaylistsQuery({
    tenantId: tenantId || '',
    searchQuery,
    page: 1,
    pageLimit: 100
  })

  const isEditSearchResultPage = location.pathname.includes('search-results') && location.pathname.includes('edit-clip')

  const playlists = playlistsData?.playlists

  const [createPlaylist, { isLoading: isCreatingPlaylist }] = useCreatePlaylistMutation()
  const [appendClip, { isLoading: isAppendingClip }] = useAppendClipMutation()

  const handleClose = useCallback(() => {
    setClose()
    setIsCreateNewPlaylist(true)
    setSearchQuery('')
    setPlaylistName('')
    setPlaylistDescription('')
    setIsCheckedPlaylist([])
    setNameInputTouched(false)
  }, [setClose])

  const handleClick = e => {
    const { id, checked } = e.target

    setIsCheckedPlaylist([...isCheckedPlaylist, id])

    if (!checked) {
      setIsCheckedPlaylist(isCheckedPlaylist.filter(item => item !== id))
    }
  }

  const submitCreateNewPlaylist = (title: string, description: string) => {
    if (title && tenantId) {
      createPlaylist({
        tenantId,
        body: {
          title,
          description,
          private: false
        }
      })
        .unwrap()
        .then(createPlaylistResponse => {
          if (clip && tenantId) {
            appendClip({
              tenantId,
              playlistId: createPlaylistResponse.id,
              body: {
                asset_id: clip.assetId,
                offset: clip.offset,
                duration: clip.duration,
                title: clip.title,
                description: clip.description
              }
            }).then(() => {
              notify.show('Clip successfully added to new playlist.', 'success')
              handleClose()
              if (isEditSearchResultPage) history.goBack()
            })
          }
        })
        .catch(() => notify.show('Sorry, something went wrong.', 'error'))
    }
  }

  const addToExistingPlaylist = () => {
    if (tenantId && playlists && isCheckedPlaylist) {
      isCheckedPlaylist.forEach((id: string) => {
        const numberOfClips = playlists.find(item => item.id === id)?.clips.length
        const title = playlists.find(item => item.id === id)?.title
        if (numberOfClips && numberOfClips >= MAX_CLIPS_IN_PLAYLISTS) {
          notify.show(
            `A playlist cannot contain more than ${MAX_CLIPS_IN_PLAYLISTS} clips. Please remove at least ${wordAsPluralOrSingular(
              numberOfClips - MAX_CLIPS_IN_PLAYLISTS + 1,
              'clip'
            )} from '${title}'`,
            'warning'
          )
          return
        }

        appendClip({
          tenantId,
          playlistId: id,
          body: {
            asset_id: clip.assetId,
            offset: clip.offset,
            duration: clip.duration,
            title: clip.title,
            description: clip.description
          }
        })
          .unwrap()
          .then(() => {
            notify.show(
              `Clip successfully added to ${wordAsPluralOrSingular(isCheckedPlaylist.length, 'existing playlist')}`,
              'success'
            )
            handleClose()
            if (isEditSearchResultPage) history.goBack()
          })
          .catch(() => notify.show('Sorry, something went wrong.', 'error'))
      })
    }
  }

  const handleSubmit = () => {
    if (isCreateNewPlaylist) {
      return submitCreateNewPlaylist(playlistName, playlistDescription)
    }
    return addToExistingPlaylist()
  }

  const isSaveDisabled =
    (isPlaylistsDataLoading && !isCreateNewPlaylist) ||
    isCreatingPlaylist ||
    isAppendingClip ||
    (isCreateNewPlaylist && !playlistName.length) ||
    (isCreateNewPlaylist && playlistName.length > MAX_PLAYLIST_TITLE_LENGTH) ||
    (isCreateNewPlaylist && playlistDescription.length > MAX_PLAYLIST_DESCRIPTION_LENGTH) ||
    (!isCreateNewPlaylist && !isCheckedPlaylist.length)

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      aria-labelledby="add-to-playlist-dialog-title-id"
      aria-describedby="add-to-playlist-dialog-content-id"
      data-testid="add-clip-to-playlist-dialog"
    >
      <DialogTitle id="add-to-playlist-dialog-title-id" data-testid="dialog-title">
        <Typography component="p" sx={{ fontWeight: 700 }} variant="head700">
          Add clip to playlist
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseOutlinedIcon sx={{ color: theme.palette.common.black }} />
        </IconButton>
      </DialogTitle>

      <DialogContent id="add-to-playlist-dialog-content-id">
        <RadioGroup defaultValue="createNewPlaylist" sx={{ pb: 1 }}>
          <FormControlLabel
            value="createNewPlaylist"
            control={<Radio />}
            label="Create new playlist"
            onClick={() => setIsCreateNewPlaylist(true)}
            data-testid="create-new-playlist"
          />
          <FormControlLabel
            value="addToPlaylist"
            control={<Radio />}
            label="Add to existing playlist(s)"
            onClick={() => setIsCreateNewPlaylist(false)}
            data-testid="add-to-existing-playlist"
          />
        </RadioGroup>

        {isCreateNewPlaylist ? (
          <Box
            height={CONTAINER_HEIGHT}
            sx={{ border: `1px solid ${theme.palette.grey[50]}`, p: 2, borderRadius: 1 }}
            data-testid="input-container"
          >
            {playlists && playlistsData?.total_items >= MAX_PLAYLIST_COUNT && (
              <Alert severity="warning" sx={{ mb: 1, border: `1px solid ${theme.palette.warning[300]}` }}>
                <AlertTitle sx={{ fontSize: theme.typography.body2 }}>
                  You cannot create more than {MAX_PLAYLIST_COUNT} playlists.
                </AlertTitle>
                <Typography> Please delete at least one before creating another.</Typography>
              </Alert>
            )}
            <InputLabel sx={{ color: theme.palette.grey[800], fontWeight: 510, mb: 0.25 }}>Playlist name</InputLabel>
            <TextField
              onBlur={() => setNameInputTouched(true)}
              value={playlistName}
              error={
                (playlistName.length === 0 || playlistName.length > MAX_PLAYLIST_TITLE_LENGTH) && isNameInputTouched
              }
              helperText={
                playlistName.length === 0 && isNameInputTouched
                  ? 'Playlist name required'
                  : `${playlistName.length} / ${MAX_PLAYLIST_TITLE_LENGTH}`
              }
              disabled={playlists && playlistsData?.total_items >= MAX_PLAYLIST_COUNT}
              fullWidth
              placeholder="Enter playlist name"
              sx={{ mb: 1, fontSize: '100px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlaylistName(e.target.value)}
              data-testid="playlist-name-field"
            />
            <InputLabel sx={{ color: theme.palette.grey[800], fontWeight: 510, mb: 0.25 }}>
              Description (optional)
            </InputLabel>
            <TextField
              value={playlistDescription}
              error={playlistDescription.length > MAX_PLAYLIST_DESCRIPTION_LENGTH}
              sx={{ mb: 2 }}
              helperText={`${playlistDescription.length} / ${MAX_PLAYLIST_DESCRIPTION_LENGTH}`}
              multiline
              disabled={playlists && playlistsData?.total_items >= MAX_PLAYLIST_COUNT}
              fullWidth
              rows={3}
              placeholder="Enter playlist description"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlaylistDescription(e.target.value)}
              data-testid="playlist-description-field"
            />
          </Box>
        ) : (
          <Box
            height={CONTAINER_HEIGHT}
            sx={{
              overflowY: 'auto',
              border: `1px solid ${theme.palette.grey[50]}`,
              padding: 1.5
            }}
          >
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              placeholder="Search my playlists"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              data-testid="search-my-playlists"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                )
              }}
            />
            <FormGroup data-testid="playlists-table">
              {isPlaylistsDataLoading || !playlists ? (
                <div>Loading...</div>
              ) : (
                playlists.map(({ title, id }) => (
                  <FormControlLabel
                    control={<Checkbox id={id} checked={isCheckedPlaylist?.includes(id)} onClick={handleClick} />}
                    label={title}
                    id={id}
                    ref={ref}
                  />
                ))
              )}
            </FormGroup>
          </Box>
        )}
      </DialogContent>

      <DialogActions data-testid="button-container">
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={isCreatingPlaylist || isAppendingClip}
          data-testid="cancel-btn"
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={isSaveDisabled} autoFocus data-testid="save-btn">
          {isCreatingPlaylist || isAppendingClip ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
