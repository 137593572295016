import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../RouteConfig/constants'

export const useRoutes = () => {
  const { push, location } = useHistory()

  const setRoute = useCallback(
    (path: string, dynamicPathVariables?: string[], search?: string) => {
      const pathVariables = dynamicPathVariables ?? []
      const pathname = path
        .split('/')
        .map(partPath => {
          if (partPath.startsWith(':')) {
            return pathVariables.shift()
          }
          return partPath
        })
        .join('/')

      push({
        pathname,
        ...(search && { search })
      })
    },
    [push]
  )

  return { ...ROUTES, pathname: location.pathname, setRoute }
}
