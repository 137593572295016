import { createApi } from '@reduxjs/toolkit/query/react'
import { createQueryWithReauth } from '../authenticated-query'
import { MiddlewareApi } from './types'

export const middlewareApi = createApi({
  reducerPath: 'middlewareApi',
  baseQuery: createQueryWithReauth(process.env.REACT_APP_MIDDLEWARE_ENDPOINT),
  tagTypes: ['playlist', 'playlist-clips', 'playlist-shares', 'playlists', 'playlists-shared'],
  endpoints: ({ query, mutation }) => ({
    registerSharedPlaylist: mutation<void, { tenantId: string }>({
      query: ({ tenantId }) => ({
        url: `playlists/tenants/${tenantId}/users/me/playlists/shares/register`,
        method: 'PUT'
      }),
      invalidatesTags: ['playlists-shared']
    }),
    createPlaylist: mutation<MiddlewareApi.CreatePlaylistResponse, MiddlewareApi.CreatePlaylist>({
      query: ({ tenantId, body }) => ({
        url: `playlists/tenants/${tenantId}/users/me/playlists`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['playlists']
    }),
    getPlaylist: query<MiddlewareApi.Playlist, { tenantId: string; playlistId: string }>({
      query: ({ tenantId, playlistId }) => `playlists/tenants/${tenantId}/playlists/${playlistId}`,
      transformResponse: (res: MiddlewareApi.Playlist) => {
        // ensure an empty description is represented by an empty string
        // this is required due to an issue in the playlists migration
        // script which resulted in null values for description
        return {
          ...res,
          description: res.description || ''
        }
      },
      providesTags: ['playlist']
    }),
    getPlaylistShares: query<MiddlewareApi.PlaylistShares, { tenantId: string; playlistId: string }>({
      query: ({ tenantId, playlistId }) => `playlists/tenants/${tenantId}/playlists/${playlistId}/shares`,
      providesTags: ['playlist-shares']
    }),
    getSearchPlaylists: query<
      MiddlewareApi.PlaylistResponse,
      { tenantId: string; searchQuery: string; page: number; pageLimit?: number }
    >({
      query: ({ tenantId, searchQuery = '', page, pageLimit = 10 }) =>
        `playlists/tenants/${tenantId}/users/me/playlists?title=${searchQuery}&page=${page}&limit=${pageLimit}`,
      providesTags: ['playlists']
    }),
    getPlaylistClip: query<MiddlewareApi.PlaylistClip, { tenantId: string; playlistId: string; clipId: string }>({
      query: ({ tenantId, playlistId, clipId }) =>
        `playlists/tenants/${tenantId}/playlists/${playlistId}/clips/${clipId}`,
      providesTags: ['playlist-clips']
    }),
    sharePlaylist: mutation<void, { tenantId: string; playlistId: string; userEmail: string }>({
      query: ({ tenantId, playlistId, userEmail }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}/shares?email=${userEmail}`,
        method: 'PUT'
      }),
      invalidatesTags: ['playlist-shares']
    }),
    getSearchSharedPlaylists: query<
      MiddlewareApi.PlaylistResponse,
      { tenantId: string; searchQuery: string; page: number; pageLimit?: number }
    >({
      query: ({ tenantId, searchQuery = '', page, pageLimit = 10 }) =>
        `playlists/tenants/${tenantId}/users/me/playlists/shares?title=${searchQuery}&page=${page}&limit=${pageLimit}`,
      providesTags: ['playlists-shared']
    }),
    deletePlaylist: mutation<void, { tenantId: string; playlistId: string }>({
      query: ({ tenantId, playlistId }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['playlists']
    }),
    updatePlaylist: mutation<
      MiddlewareApi.CreatePlaylistResponse,
      {
        tenantId: string
        playlistId: string
        body: {
          description: string
          title: string
          private: boolean
        }
      }
    >({
      query: ({ tenantId, playlistId, body }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['playlist', 'playlists']
    }),
    removeClip: mutation<void, { tenantId: string; playlistId: string; clipId: string }>({
      query: ({ tenantId, playlistId, clipId }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}/clips/${clipId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['playlists', 'playlist', 'playlist-clips']
    }),
    updateClip: mutation<void, MiddlewareApi.UpdateClip>({
      query: ({ tenantId, playlistId, body, clipId }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}/clips/${clipId}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['playlist', 'playlist-clips']
    }),
    updateClipIndex: mutation<any, { tenantId: string; playlistId: string; clipId: string; index: number }>({
      query: ({ tenantId, playlistId, clipId, index }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}/clips/${clipId}/index?index=${index}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['playlists', 'playlist', 'playlist-clips']
    }),
    appendClip: mutation<any, MiddlewareApi.AppendClip>({
      query: ({ tenantId, playlistId, body }) => ({
        url: `playlists/tenants/${tenantId}/playlists/${playlistId}/clips`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['playlists', 'playlist', 'playlist-clips']
    })
  })
})

export const {
  useCreatePlaylistMutation,
  useGetPlaylistQuery,
  useGetPlaylistSharesQuery,
  useGetPlaylistClipQuery,
  useGetSearchPlaylistsQuery,
  useDeletePlaylistMutation,
  useAppendClipMutation,
  useUpdateClipMutation,
  useUpdatePlaylistMutation,
  useRemoveClipMutation,
  useSharePlaylistMutation,
  useGetSearchSharedPlaylistsQuery,
  useRegisterSharedPlaylistMutation,
  useUpdateClipIndexMutation
} = middlewareApi
