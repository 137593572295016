import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Typography, Container, Grid, Button, CircularProgress, useMediaQuery, Box, useTheme } from '@mui/material'
import { generatePath, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { InView } from 'react-intersection-observer'

import { selectFilters, selectSearchResults, setSearchFilter } from '../../store/slices/search'
import { lvsApi } from '../../store/apis/lvs'
import { doSetQueueCurrentIndex } from '../../redux/actions'

import { PageHeader } from '../../components2.0/PageHeader'
import { SearchBar } from '../../components2.0/SearchBar'
import { FilterBar } from '../../components2.0/FilterBar'
import { SearchResultsCard } from '../../components2.0/SearchResultsCard'
import { useDebounce } from '../../hooks/useDebounce/useDebounce'
import { QueueAction } from '../../components2.0/PlayerQueueClip'
import { NoResultsMessage } from '../../components2.0/NoResultsMessage/NoResultsMessage'
import { sendVideoPlay } from '../../utils/googleAnalytics'
import { ROUTES } from '../../RouteConfig/constants'
import {
  AddToPlaylistClip,
  AddToPlaylistDialog
} from '../../components2.0/PlaylistsDialogs/AddToPlaylistDialog/AddToPlaylistDialog'
import { useSearchBar } from '../../components2.0/SearchBar/useSearchBar'
import { generateTempClipId, WhizzardSearchResultClip } from '../../utils/clip'
import { useIsMobileDevice } from '../../hooks/useIsMobileDevice'
import { hideEditClipActionOnMobile } from '../../utils/search'
import { SearchPromptIcon } from '../../design-system/icons'
import { SearchSlice } from '../../store/slices/search/types'
import { SearchResultsCardSkeleton } from '../../components2.0/SearchResultsCard/SearchResultsCardSkeleton'

interface SearchResultsCardsProps {
  searchResults: SearchSlice.Buffer
  setAddToPlaylistClip: Dispatch<SetStateAction<AddToPlaylistClip | undefined>>
  setAddToPlaylistDialogOpen: Dispatch<SetStateAction<boolean>>
  setShouldLoadMore: Dispatch<SetStateAction<boolean>>
}

const SearchResultsCards = memo(
  ({ searchResults, setAddToPlaylistClip, setAddToPlaylistDialogOpen, setShouldLoadMore }: SearchResultsCardsProps) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { isMobileOrTablet } = useIsMobileDevice()

    const sendPlayEvent = useCallback((clip: WhizzardSearchResultClip, mode: string) => {
      sendVideoPlay(clip.assetId, clip.title, clip.startTime, clip.endTime, mode)
    }, [])

    const selectClip = useCallback(
      (index: number, page?: number) => {
        dispatch(doSetQueueCurrentIndex(index))
        history.push({
          pathname: ROUTES.SEARCH_RESULTS_PLAY_ALL,
          state: { page }
        })
      },
      [history, dispatch]
    )

    const playFullVideo = useCallback(
      (clip: WhizzardSearchResultClip): void => {
        const seekQuery = clip.startTime ? `?seekToTime=${clip.startTime.toFixed()}` : ''
        sendPlayEvent(clip, 'play_full_video')
        sendVideoPlay(clip.assetId, clip.title, clip.startTime, clip.endTime, 'play_full_video')

        const baseUrl = generatePath(ROUTES.SEARCH_RESULTS_PLAY_FULL_VIDEO, {
          assetId: clip.assetId
        })
        history.push(`${baseUrl}${seekQuery}`)
      },
      [history, sendPlayEvent]
    )

    const addClipToPlaylist = useCallback(
      (clip: WhizzardSearchResultClip) => {
        setAddToPlaylistClip({
          assetId: Number(clip.assetId),
          offset: clip.startTime,
          duration: clip.duration,
          title: clip.title,
          description: ''
        })
        setAddToPlaylistDialogOpen(true)
      },
      [setAddToPlaylistClip, setAddToPlaylistDialogOpen]
    )

    const editClip = useCallback(
      (clip: WhizzardSearchResultClip): void => {
        const { startTime, duration } = clip
        sendPlayEvent(clip, 'edit_video')

        history.push(
          generatePath(ROUTES.SEARCH_RESULTS_EDIT_CLIP, {
            temporaryClipId: generateTempClipId(clip.assetId, startTime, duration)
          })
        )
      },
      [history, sendPlayEvent]
    )

    const getQueueItemActions = (clip: WhizzardSearchResultClip): QueueAction[] => {
      return [
        { title: 'Add clip to playlist', onClick: () => addClipToPlaylist(clip) },
        { title: 'Play full video', onClick: () => playFullVideo(clip) },
        { title: 'Edit clip', onClick: () => editClip(clip) }
      ]
    }

    return (
      <>
        {searchResults.pages.flatMap((results: WhizzardSearchResultClip[], page: number) => {
          return results.map((result: WhizzardSearchResultClip, index: number) => {
            const releventInsight = result.insights[0]

            const actions = getQueueItemActions(result)
            const tagLabels = result.tags.map(tag => (typeof tag === 'string' ? tag : (tag as { label: string }).label))

            const lastPage = page === searchResults.pages.length - 1
            const lastItem = lastPage && index === results.length - 1

            // TODO refactor this so we are not creating a new InView component for each item
            return (
              <InView
                key={result.id}
                onChange={(inView = false) => {
                  setShouldLoadMore(inView && !searchResults.complete)
                }}
              >
                {({ ref }) => (
                  <Grid item sm={12} md={6} lg={3} key={result.id} ref={lastItem ? ref : null}>
                    <SearchResultsCard
                      assetId={result.assetId}
                      title={result.title}
                      insight={releventInsight.value}
                      creationDate={result.createdDate}
                      startsAt={result.startTime}
                      actions={hideEditClipActionOnMobile(actions, isMobileOrTablet)}
                      tags={tagLabels}
                      insights={result.insights}
                      duration={result.duration}
                      playOnClick={() => selectClip(index, page)}
                    />
                  </Grid>
                )}
              </InView>
            )
          })
        })}
      </>
    )
  }
)

interface MoreResultsLoaderProps {
  searchResults: SearchSlice.Buffer
}

const MoreResultsSkeleton = ({ searchResults }: MoreResultsLoaderProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('lg', 'md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const getSkeletonCardCount = (): number => {
    if (isTablet) {
      return 2 - (searchResults.uniqueResults.length % 2)
    }
    if (isMobile) {
      return 1
    }
    return 4 - (searchResults.uniqueResults.length % 4)
  }

  const skeletonCardCount = getSkeletonCardCount()

  return (
    <>
      {Array(skeletonCardCount)
        .fill(0)
        .map((_, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item sm={12} md={6} lg={3} key={index}>
              <SearchResultsCardSkeleton />
            </Grid>
          )
        })}
    </>
  )
}
export const SearchResults = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const debounce = useDebounce()
  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))

  const searchFilters = useSelector(selectFilters)
  const searchResults = useSelector(selectSearchResults)

  const [shouldLoadMore, setShouldLoadMore] = useState(false)
  const [addToPlaylistDialogOpen, setAddToPlaylistDialogOpen] = useState(false)
  const [addToPlaylistClip, setAddToPlaylistClip] = useState<AddToPlaylistClip>()

  const [fetchSearchResults] = lvsApi.endpoints.getBufferedSearch.useLazyQuery()
  const [fetchPayloadValues] = lvsApi.endpoints.getPayloadValues.useLazyQuery()

  useEffect(() => {
    if (shouldLoadMore) {
      fetchSearchResults(false)
      setShouldLoadMore(false)
    }
  }, [shouldLoadMore, fetchSearchResults])

  const {
    searchTerm,
    searchQuery,
    categoryOptions,
    isFetchingInitialResults,
    handleSearchSubmit,
    handleSearchTermChange,
    handleSearchReset,
    categoryOnChange,
    handleSearchOnClear
  } = useSearchBar()

  const handleFiltersSelect = useCallback(
    (key: string, value: string[]) => {
      debounce(() => {
        dispatch(setSearchFilter([key, value]))
        fetchSearchResults(true)
      })
    },
    [dispatch, debounce, fetchSearchResults]
  )

  const handleFiltersChange = useCallback(
    (category: string, value = '') => {
      return fetchPayloadValues({ category, value }).unwrap()
    },
    [fetchPayloadValues]
  )

  const handlePlayAll = useCallback(() => {
    dispatch(doSetQueueCurrentIndex(0))
    history.push(ROUTES.SEARCH_RESULTS_PLAY_ALL)
  }, [history, dispatch])

  const shouldShowFilters =
    (searchQuery.value.length || !!Object.keys(searchQuery.filter).length) && !!categoryOptions.length

  const { spellingSuggestions } = searchResults

  const applySpellingSuggestions = useCallback(() => {
    if (spellingSuggestions) {
      const value = spellingSuggestions.map(s => s.suggestion || s.word).join(' ')
      handleSearchTermChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>)
      handleSearchSubmit()
    }
  }, [spellingSuggestions, handleSearchSubmit, handleSearchTermChange])

  return (
    <>
      <PageHeader>
        <SearchBar
          value={searchTerm}
          onSubmit={handleSearchSubmit}
          onChange={handleSearchTermChange}
          onReset={handleSearchReset}
          onClear={handleSearchOnClear}
          dropdownValue={searchQuery.category}
          dropdownOnChange={categoryOnChange}
          dropdownOptions={categoryOptions}
        />
        {shouldShowFilters && (
          <FilterBar
            filters={searchFilters}
            query={searchQuery}
            onSelect={handleFiltersSelect}
            onChange={handleFiltersChange}
          />
        )}
      </PageHeader>
      <Container
        sx={{
          [theme.breakpoints.down('lg')]: { paddingLeft: 2, paddingRight: 2 }
        }}
      >
        <Grid container spacing={0} rowSpacing={3}>
          <Grid
            item
            xs={8}
            md={spellingSuggestions ? 2 : 8}
            order={{ xs: 0 }}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography variant={isBelowLgBreakpoint ? 'head600' : 'head700'}>
              {searchResults.count || !searchResults.complete ? 'Your video clips' : '0 video clips'}
            </Typography>
          </Grid>
          {!!spellingSuggestions && (
            <Grid
              item
              xs={12}
              md={searchResults.count ? 6 : 10}
              order={{ xs: 2, md: 1 }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                  justifyContent: searchResults.count ? 'start' : 'end'
                },
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start'
                }
              }}
            >
              <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '100%' }}>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{
                    maxWidth: '100%',
                    [theme.breakpoints.down('md')]: { padding: 0 }
                  }}
                  onClick={applySpellingSuggestions}
                  data-testid="spelling-suggestion-tool"
                >
                  <span style={{ whiteSpace: 'nowrap' }} data-testid="spelling-suggestion-prefix">
                    Did you mean
                  </span>
                  <Typography
                    variant="body2"
                    color="primary"
                    component="span"
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {spellingSuggestions.map(({ word, suggestion }) => {
                      return (
                        <span key={word} data-testid="spelling-suggestion">
                          &nbsp;{suggestion ? <em>{suggestion}</em> : word}
                        </span>
                      )
                    })}
                  </Typography>
                  ?
                </Button>
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={4}
            order={{ xs: 1, md: 2 }}
            sx={{
              display: 'flex',
              justifyContent: 'end'
            }}
          >
            <Button
              sx={{
                visibility:
                  isFetchingInitialResults || (!searchResults.count && !searchResults.complete) || !searchResults.count
                    ? 'hidden'
                    : 'visible'
              }}
              variant="contained"
              onClick={handlePlayAll}
              data-testid="play-all-button"
            >
              Play all
            </Button>
          </Grid>

          <Grid item xs={12} order={{ xs: 3 }} data-testid="search-results-placeholder">
            {!searchResults.count && searchResults.complete && !isFetchingInitialResults && (
              <NoResultsMessage
                sx={{ backgroundColor: theme.palette.grey[25] }}
                Icon={SearchPromptIcon}
                header="No matching video clips found"
                description="There are no video clips with these search criteria and filters. Try changing the search term or filters."
              />
            )}
            <Grid sx={{ mb: 4 }} container spacing={2} data-testid="search-results-grid">
              {isFetchingInitialResults || (!searchResults.count && !searchResults.complete) ? (
                <>
                  {Array(8)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item sm={12} md={6} lg={3} key={index}>
                          <SearchResultsCardSkeleton />
                        </Grid>
                      )
                    })}
                </>
              ) : (
                <>
                  <SearchResultsCards
                    searchResults={searchResults}
                    setAddToPlaylistClip={setAddToPlaylistClip}
                    setAddToPlaylistDialogOpen={setAddToPlaylistDialogOpen}
                    setShouldLoadMore={setShouldLoadMore}
                  />
                  {!searchResults.complete && <MoreResultsSkeleton searchResults={searchResults} />}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!searchResults.complete && !isFetchingInitialResults && (
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Container>
      {addToPlaylistClip && (
        <AddToPlaylistDialog
          open={addToPlaylistDialogOpen}
          setClose={() => setAddToPlaylistDialogOpen(false)}
          clip={addToPlaylistClip}
        />
      )}
    </>
  )
}
