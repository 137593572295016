import { Theme } from '@mui/material'

export const getAllFiltersStyles = (theme: Theme): StylesProps => ({
  drawer: {
    height: '100%',
    position: 'relative',
    '& .MuiDrawer-paper': {
      width: 448,
      [theme.breakpoints.down('md')]: { width: 340 }
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    p: 2.5,
    backgroundColor: theme.palette.grey[25]
  },
  drawerContentContainer: {
    minHeight: 'calc(100% - 64px - 80px)',
    width: '100%',
    overflowX: 'hidden',
    px: 4.5,
    py: 2,
    [theme.breakpoints.down('md')]: { px: 2 }
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    p: 2,
    backgroundColor: theme.palette.grey[25]
  }
})
