import { Box, Container, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { Breadcrumbs } from '../Breadcrumbs'
import { getStyles } from './PageHeader.styles'

interface Props {
  children?: React.ReactNode
  breadCrumbOveride?: Record<string, string | {}>
}

export const PageHeader = ({ children = null, breadCrumbOveride }: Props) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const isBelowMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box sx={styles.wrapper}>
      <Container sx={styles.container}>
        <Breadcrumbs maxItems={isBelowMdBreakpoint ? 2 : undefined} overrides={breadCrumbOveride} />
      </Container>
      <Container>{children}</Container>
    </Box>
  )
}
