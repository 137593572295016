import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Slider from 'rc-slider'

import './styles.css'
import 'rc-tooltip/assets/bootstrap.css'

import { numberToTwoDigits, timeToHours, timeToMinutes, timeToSeconds } from '../../utils'
import { usePlayer } from '../Player'
import Timeline from './Timeline/Timeline'
import useVideoEditorSliderStyles from './VideoEditorSlider.style'

export interface VideoEditorSliderProps {
  assetDuration: number
  handleOffsetChange: (times: number[]) => void
  clip: {
    startTime: number
    endTime: number
  }
}

const VideoEditorSlider: FunctionComponent<VideoEditorSliderProps> = ({ assetDuration, clip, handleOffsetChange }) => {
  const { createSliderWithTooltip } = Slider
  const Range = createSliderWithTooltip(Slider.Range)
  const [secondToggle, setSecondToggle] = useState<number>(0)
  const [zoomLevel, setZoomLevel] = useState<number>(6)
  const rangeEl = useRef(null)
  const dispatch = useDispatch()

  const startTime = clip.startTime
  const endTime = clip.endTime

  const classes = useVideoEditorSliderStyles({ zoomLevel, secondToggle })

  const { player, state } = usePlayer()
  const [playerTime, setPlayerTime] = useState(player?.currentTime() || 0)

  useEffect(() => {
    let mounted = true

    player?.ready(() => {
      player.on(['timeupdate', 'seeking'], () => {
        if (mounted) {
          setPlayerTime(player.currentTime())
        }
      })
    })

    return () => {
      mounted = false
    }
  }, [player, endTime, startTime])

  useEffect(() => {
    if (playerTime > endTime) {
      player?.currentTime(startTime)
    }
  }, [playerTime, startTime, endTime, dispatch, player])

  return (
    <div className={classes.container}>
      <div className={classes.sliderContainer}>
        <Timeline zoomLevel={zoomLevel} secondToggle={secondToggle} assetDuration={assetDuration} />
        <Range
          className={classes.rangeSlider}
          min={0}
          step={2}
          max={assetDuration}
          disabled={state === 'playing'}
          ref={rangeEl}
          onAfterChange={handleOffsetChange}
          defaultValue={[startTime, endTime]}
          allowCross={false}
          tipFormatter={value =>
            `${numberToTwoDigits(timeToHours(value))}:${numberToTwoDigits(timeToMinutes(value))}:${numberToTwoDigits(
              timeToSeconds(value)
            )}`
          }
        />
        <div className={classes.zoomSlidersContainer}>
          <div className={classes.zoomLevelContainer}>
            <p className={classes.leftZoomTag}>1x</p>
            <input
              type="range"
              className={classes.zoomLevelSlider}
              min={6}
              max={36}
              value={zoomLevel}
              onChange={e => setZoomLevel(parseInt(e.target.value))}
            />
            <p className={classes.rightZoomTag}>5x</p>
          </div>
          <Slider
            className="x-axis-zoom"
            min={0}
            max={2.42 * Math.pow(zoomLevel, 2)}
            defaultValue={0}
            onChange={num => setSecondToggle(num)}
          />
        </div>
      </div>
    </div>
  )
}
export default VideoEditorSlider
