import { AnyAction } from 'redux'

import { Video } from '../../../types'

import { ASSET_ITEM_ADD, CLIP_CORRECT_DURATIONS_ADDED } from './actions'

export interface AssetsState {
  items: Video[]
  durations: number[]
}

const initialState: AssetsState = {
  items: [],
  durations: []
}

const reducer = (state: AssetsState = initialState, action: AnyAction): AssetsState => {
  switch (action.type) {
    case ASSET_ITEM_ADD:
      const exists = state.items.findIndex((asset: Video) => asset.id === action.payload.id) > -1

      if (exists) {
        return state
      }

      return {
        ...state,
        items: [
          ...state.items,
          {
            ...action.payload,
            createdDate: new Date(action.payload.created)
          }
        ]
      }

    case CLIP_CORRECT_DURATIONS_ADDED:
      return {
        ...state,
        durations: action.payload
      }
    default:
      return state
  }
}

export default reducer
export { initialState }
