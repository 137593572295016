import { Theme } from '@mui/material'

export const getSearchBarStyles = (theme: Theme): StylesProps => ({
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    padding: 1.5,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: { display: 'none' }
  },
  divider: {
    margin: '0px 16px',
    height: '31px',
    background: theme.palette.grey[200],
    width: '2px',
    borderRadius: 10
  },
  input: {
    ...theme.typography.body2,
    borderBottom: '0px',
    width: '70%',
    color: theme.palette.grey[450],
    marginTop: 0.25,
    '& svg': { marginLeft: 1, marginTop: 0.25 },
    '& input': { paddingLeft: 2 }
  },
  dropdown: { width: '30%' },
  inputsContainer: { display: 'flex', width: 'calc(100% - 162px)', alignItems: 'center' },
  buttons: { display: 'flex', width: 162, justifyContent: 'space-between' },
  mobileAndTabletContainer: {
    width: '100%',
    [theme.breakpoints.up('lg')]: { display: 'none' }
  }
})
