import { Login } from '../pages/Login/Login'

import { AdminConsole } from '../pages/AdminConsole/AdminConsole'

import { ROUTES } from './constants'

import { PasswordResetRequested } from '../pages/PasswordReset/PasswordResetRequest'
import { NewPasswordLinkSent } from '../pages/PasswordReset/NewPasswordLinkSent/NewPasswordLinkSent'
import { MediaUpload } from '../pages/MediaUpload/MediaUpload'
import { SearchResults } from '../pages/SearchResults'
import { SearchResultsPlayAll } from '../pages/SearchResultsPlayAll'
import { ViewPlaylist } from '../pages/ViewPlaylist/ViewPlaylist'
import { Search } from '../pages/Search'
import { PlayFullVideo } from '../pages/PlayFullVideo'
import { Playlists } from '../pages/Playlists'
import { EditPlaylistClip } from '../pages/EditPlaylistClip'
import { EditSearchResultClip } from '../pages/EditSearchResultClip/EditSearchResultClip'
import { OAuthCallback } from '../pages/OAuthCallback/OAuthCallback'

export const routes = [
  {
    path: ROUTES.RESET_PASSWORD_REQUESTED,
    component: PasswordResetRequested
  },
  {
    path: ROUTES.ACCOUNT_NOT_VERIFIED,
    component: NewPasswordLinkSent,
    heading: 'Account has not been verified',
    subHeading: `Each account needs to be verified before you can log in.\n
      Please use the button below to re-send your verification email.`,
    isResendVerifcation: true
  },
  {
    path: ROUTES.RESET_PASSWORD_REQUESTED_CONFIRMED,
    component: NewPasswordLinkSent,
    heading: 'Reset password email sent!',
    subHeading: 'If you do not receive the email within the next 10 minutes, please use the button below to try again.',
    link: ROUTES.RESET_PASSWORD_REQUESTED,
    linkCopy: 'Reset password',
    isResendVerifcation: false
  },
  {
    path: ROUTES.OAUTH_CALLBACK,
    component: OAuthCallback
  }
]

export const adminRoutes = [
  {
    path: ROUTES.ADMIN_USERMANAGER,
    component: AdminConsole,
    isUserManagerPage: true
  },
  {
    path: ROUTES.ADMIN_INTEGRATIONS,
    component: AdminConsole,
    isIntegrationsPage: true
  },
  {
    path: ROUTES.ADMIN_MY_CONTENT,
    component: AdminConsole,
    isMyContentPage: true
  },
  {
    path: ROUTES.ADMIN_MEDIA_UPLOAD,
    component: MediaUpload,
    isMediaUploadPage: true
  }
]

export const betaRoutes = [
  {
    path: ROUTES.LOGIN,
    component: Login
  }
]

export const betaPrivateRoutes = [
  {
    path: '/',
    component: Search
  },
  {
    path: ROUTES.SEARCH,
    component: Search
  },
  {
    path: ROUTES.SEARCH_RESULTS,
    component: SearchResults
  },
  {
    path: ROUTES.SEARCH_RESULTS_PLAY_ALL,
    component: SearchResultsPlayAll
  },
  {
    path: ROUTES.SEARCH_RESULTS_PLAY_FULL_VIDEO,
    component: PlayFullVideo
  },
  {
    path: ROUTES.SEARCH_RESULTS_PLAY_ALL_PLAY_FULL_VIDEO,
    component: PlayFullVideo
  },
  {
    path: ROUTES.PLAYLISTS_VIEW_PLAYLIST_PLAY_FULL_VIDEO,
    component: PlayFullVideo
  },
  {
    path: ROUTES.PLAY_FULL_VIDEO,
    component: PlayFullVideo
  },
  {
    path: ROUTES.PLAYLIST_VIEW_PLAYLIST,
    component: ViewPlaylist
  },
  {
    path: ROUTES.PLAYLISTS,
    component: Playlists
  },
  {
    path: ROUTES.SEARCH_RESULTS_EDIT_CLIP,
    component: EditSearchResultClip
  },
  {
    path: ROUTES.PLAY_ALL_EDIT_CLIP,
    component: EditSearchResultClip
  },
  {
    path: ROUTES.VIEW_PLAYLIST_EDIT_CLIP,
    component: EditPlaylistClip
  }
]

export const externalRedirects = [
  {
    path: ROUTES.SUPPORT,
    to: 'https://linius-whizzard.zendesk.com/hc/en-us'
  }
]
