import classNames from 'classnames'
import colourTranslation from '../../design-system/buttonPalette'
import useButtonStyles from './Button.styles'
import { IButton } from './types'

export const Button = ({
  children,
  tooltipText,
  tooltipId,
  testId,
  isActive = false,
  className,
  buttonSize = 'default',
  name,
  color = 'primary',
  variant = 'solid',
  ...rest
}: IButton.Props) => {
  const { disabled } = rest
  const classes = useButtonStyles({ isActive, buttonSize, color: colourTranslation[color], variant, disabled })
  const buttonProps = {
    ...(tooltipText && { 'data-tip': true, 'data-for': tooltipId })
  }

  return (
    <button
      {...buttonProps}
      {...rest}
      className={classNames(classes.root, className)}
      aria-label={name}
      data-testid={testId}>
      {children}
    </button>
  )
}
