import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notify } from 'react-notify-toast'
import jwtDecode from 'jwt-decode'
import { useFormik } from 'formik'
import { Box, Button, CircularProgress, IconButton, InputAdornment, Link, Typography, useTheme } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { TextField } from '../../components2.0/low-level/TextField/TextField'
import { LoginLayout } from '../../layouts/LoginLayout'
import LoginSchema from '../../schemas/LoginSchema'
import { authApi } from '../../store/apis/auth'
import { ROUTES } from '../../RouteConfig/constants'
import { LogoWithText } from '../../design-system/icons'
import { lvsApi } from '../../store/apis/lvs'
import { userApi } from '../../store/apis/user'
import { clearStore } from '../../store/slices/auth'

const validUoNDomains = [
  'newcastle.edu.au',
  'uon.edu.au',
  'nuwalliance.edu.au',
  'crccare.com',
  'uon.org.au',
  'uonstaff.edu.au'
]

export const Login = () => {
  const theme = useTheme()
  const history = useHistory()
  const location: any = useLocation()

  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [isPasswordFieldVisible, setIsPasswordFieldVisible] = useState(false)
  const [getUserType, { isFetching: isGetUserTypeFetching }] = authApi.endpoints.getUserType.useLazyQuery()
  const [
    authenticate,
    { isError: isAuthError, isSuccess: isAuthSuccess }
  ] = authApi.endpoints.authenticate.useLazyQuery()
  const [
    getTenantApiKeys,
    { isError: isApiKeyError, isSuccess: isApiKeySuccess }
  ] = userApi.endpoints.getTenantApiKeys.useLazyQuery()
  const [
    getUserDetails,
    { isError: isUserError, isSuccess: isUserSuccess }
  ] = userApi.endpoints.getUserDetails.useLazyQuery()

  const endpoint = process.env.REACT_APP_AUTH0_ENDPOINT
  const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID
  const UoNOrginizationId = process.env.REACT_APP_UON_ORG_ID

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async values => {
      await authenticate({
        email: /.+(@|_at_).+\..+/.test(values.email) ? values.email.toLowerCase() : values.email,
        password: values.password
      })
        .unwrap()
        .then(async authResponse => {
          if (!authResponse?.token) throw Error()
          const userToken: any = jwtDecode(authResponse.token)
          await getUserDetails(userToken.userId)
            .unwrap()
            .then(async userDetailsResponse => {
              if (!userDetailsResponse?.tenantId) throw Error()
              await getTenantApiKeys(userDetailsResponse.tenantId)
              dispatch(lvsApi.endpoints.getPayloadCategories.initiate(userDetailsResponse.tenantId))
            })
        })
    }
  })

  useEffect(() => {
    if (isAuthSuccess && isApiKeySuccess && isUserSuccess) {
      history.push(location?.state?.from?.pathname || ROUTES.SEARCH)
    }
  }, [history, isApiKeySuccess, isAuthSuccess, isUserSuccess, location?.state?.from?.pathname])

  useEffect(() => {
    if (isUserError) {
      notify.show('Unable to fetch user details, please try again.', 'error')
      dispatch(clearStore())
    }
  }, [dispatch, isUserError])

  useEffect(() => {
    if (isApiKeyError) {
      notify.show('Unable to fetch api key, please try again.', 'error')
      dispatch(clearStore())
    }
  }, [dispatch, isApiKeyError])

  const redirectToAuth0 = (organizationId: string) => {
    if (!client_id || !endpoint) {
      console.error('SSO not configured')
      return
    }
    const params = new URLSearchParams({
      organization: organizationId,
      client_id,
      redirect_uri: `${window.location.origin}/oauth/callback`,
      response_type: 'code',
      scope: 'openid profile email'
    })
    if (location?.state?.from?.pathname) {
      window.localStorage.setItem('original path', location?.state?.from?.pathname)
    }
    window.location.href = `${endpoint}/authorize?${params}`
  }
  const handleUserTypeVerification = (email: string) => {
    getUserType(email, true)
      .unwrap()
      .then(response => {
        if (response.internalOnly) setIsPasswordFieldVisible(true)
        else if (response.items[0].organizations.length === 0) setIsPasswordFieldVisible(true)
        else redirectToAuth0(response.items[0].organizations[0].id)
      })
      .catch(err => {
        if ((err.status === 403 || err.status === 404) && UoNOrginizationId) {
          if (validUoNDomains.includes(formik.values.email.split('@')[1])) {
            return redirectToAuth0(UoNOrginizationId)
          }
          return setIsPasswordFieldVisible(true)
        }
        return console.error(err)
      })
  }

  return (
    <LoginLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 450,
          [theme.breakpoints.down('md')]: { width: '80vw' }
        }}
      >
        <LogoWithText sx={{ width: 200, height: 150, [theme.breakpoints.down('md')]: { width: 150, height: 100 } }} />
        <Typography variant="head800" sx={{ mb: 3, color: theme.palette.common.white }} data-testid="heading-text">
          Log in
        </Typography>
        <form id="login" onSubmit={formik.handleSubmit} style={{ width: '100%' }} data-testid="login-form">
          {!isPasswordFieldVisible && (
            <>
              <TextField
                fullWidth
                helperText={formik.errors.email === 'Email required.' ? 'Email required' : ''}
                error={formik.errors.email === 'Email required.'}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
                placeholder="Email"
                data-testid="email-input"
                sx={{ mb: 3 }}
              />
              <Button
                data-testid="continue-button"
                onClick={() => {
                  if (formik.touched.email && formik.errors.email !== 'Email required.') {
                    handleUserTypeVerification(formik.values.email)
                  } else {
                    formik.setErrors({ ...formik.errors, email: 'Email required.' })
                  }
                }}
                variant="contained"
                sx={{ mb: 3, width: '100%' }}
              >
                {isGetUserTypeFetching ? (
                  <CircularProgress size={24} sx={{ color: theme.palette.common.white }} />
                ) : (
                  'Continue'
                )}
              </Button>
            </>
          )}
          {isPasswordFieldVisible && (
            <>
              <TextField
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{ mb: 3 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.password && formik.errors.password === 'Password required.' ? 'Password required' : ''
                }
                error={formik.touched.password && formik.errors.password === 'Password required.'}
                name="password"
                value={formik.values.password}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                data-testid="password-input"
              />
              <Button
                data-testid="log-in-button"
                sx={{ mb: 3, width: '100%' }}
                variant="contained"
                onClick={() => {
                  if (formik.touched.password && formik.errors.password !== 'Password required.') {
                    formik.submitForm()
                  } else {
                    formik.setTouched({ ...formik.touched, password: true })
                  }
                }}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={24} sx={{ color: theme.palette.common.white }} />
                ) : (
                  'Log in'
                )}
              </Button>
              <Button
                onClick={() => {
                  formik.setValues({ email: formik.values.email, password: '' })
                  formik.setErrors({})
                  setIsPasswordFieldVisible(false)
                }}
                sx={{ mb: 3, width: '100%' }}
                variant="contained"
                color="secondary"
                data-testid="back-button"
              >
                Back
              </Button>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: { flexDirection: 'column' }
            }}
          >
            {!isPasswordFieldVisible ? (
              <Box sx={{ display: 'flex', alignItems: 'center', pt: 0.5 }} data-testid="no-account-label">
                <Typography variant="body2" color={theme.palette.common.white} sx={{ mb: 0.5 }}>
                  No account?
                </Typography>
                <Link
                  href="https://linius-whizzard.zendesk.com/hc/en-us/requests/new"
                  target="_blank"
                  sx={{ ml: 1, color: theme.palette.primary[300] }}
                  data-testid="contact-us-link"
                >
                  Contact Us
                </Link>
              </Box>
            ) : (
              <Typography
                sx={{ color: theme.palette.error.main, visibility: isAuthError ? 'visible' : 'hidden' }}
                data-testid="form-validation-error"
              >
                Incorrect username or password
              </Typography>
            )}

            <Button
              onClick={() => history.push(ROUTES.RESET_PASSWORD_REQUESTED)}
              sx={{ color: theme.palette.grey[25] }}
              data-testid="reset-password-button"
            >
              Reset password
            </Button>
          </Box>
        </form>
      </Box>
    </LoginLayout>
  )
}
