import { Theme } from '@mui/material'

export const getStyles = (theme: Theme): StylesProps => ({
  wrapper: {
    backgroundColor: theme.palette.grey[25],
    padding: `${theme.spacing(3)} 0`,
    marginBottom: 3
  },
  container: {
    marginBottom: 3
  }
})
