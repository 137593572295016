import { createSelector } from 'reselect'

import { IVideoClip } from '../../../utils'
import { State } from '../..'

import { PlaylistsModalState } from './reducer'

const selectPlaylistsModalState = (state: State): PlaylistsModalState =>
  state.playlistsModal

const selectIsPlaylistsModalOpen = createSelector(
  selectPlaylistsModalState,
  (state: PlaylistsModalState): boolean => state.isOpen
)

const selectPlaylistsModalClip = createSelector(
  selectPlaylistsModalState,
  (state: PlaylistsModalState): IVideoClip | null => state.clip
)

const selectStartAndEndTimes = createSelector(
  selectPlaylistsModalState,
  (state: PlaylistsModalState): { startTime: number; endTime: number } =>
    state.times
)

const selectClipId = createSelector(
  selectPlaylistsModalState,
  (state: PlaylistsModalState): string => state.clip?.id || ''
)

export {
  selectIsPlaylistsModalOpen,
  selectPlaylistsModalClip,
  selectStartAndEndTimes,
  selectClipId
}
