import {
  useTheme,
  Box,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Typography,
  CheckboxProps as MuiCheckboxProps,
  SxProps
} from '@mui/material'

export interface CheckboxProps extends MuiCheckboxProps {
  errorMessage?: string
  label?: string
  sx?: SxProps
}

export const Checkbox = ({ errorMessage, label, sx, ...rest }: CheckboxProps) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ...sx }}>
      <FormControlLabel label={label} control={<MuiCheckbox {...rest} />} />
      {errorMessage && (
        <Typography variant="body50" sx={{ color: theme.palette.error.main }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}
