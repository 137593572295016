import { AnyAction } from 'redux'

import { IVideoClip } from '../../../utils'

import {
  PLAYLIST_MODAL_CLIP_SET,
  PLAYLIST_MODAL_CLOSE,
  PLAYLIST_MODAL_EDIT_CLIP_TIMES,
  PLAYLIST_MODAL_OPEN,
  PLAYLIST_MODAL_OPEN_SET
} from './actions'

export interface PlaylistsModalState {
  clip: IVideoClip | null
  isOpen: boolean
  times: { startTime: number; endTime: number }
}

const initialState: PlaylistsModalState = {
  clip: null,
  isOpen: false,
  // TODO move into editor slice
  times: { startTime: -1, endTime: -1 }
}

const reducer = (state: PlaylistsModalState = initialState, action: AnyAction): PlaylistsModalState => {
  switch (action.type) {
    case PLAYLIST_MODAL_CLIP_SET:
      if (!action.payload) {
        return state
      }

      return {
        ...state,
        clip: action.payload
      }

    case PLAYLIST_MODAL_OPEN_SET:
      return {
        ...state,
        isOpen: !!action.payload
      }

    case PLAYLIST_MODAL_OPEN:
      return {
        ...state,
        isOpen: true
      }

    case PLAYLIST_MODAL_CLOSE:
      return {
        ...state,
        isOpen: false
      }

    case PLAYLIST_MODAL_EDIT_CLIP_TIMES:
      return {
        ...state,
        isOpen: false,
        times: action.times
      }

    default:
      return state
  }
}

export default reducer
export { initialState }
