const SW_PATH = '/service-worker.js'

interface AuthData {
  accessToken?: string
  apiKey?: string
}

export const registerServiceWorker = () => {
  navigator?.serviceWorker?.register(SW_PATH).catch(console.error)
}

export const messageServiceWorker = (data: AuthData) => {
  navigator?.serviceWorker?.ready.then(registration => {
    registration?.active?.postMessage(data)
  })
}
