import { Theme } from '@mui/material'

export const getPlaylistTableRowStyles = (theme: Theme, isShared: boolean): StylesProps => ({
  rowItemMdBreakpoint: {
    color: theme.palette.grey[450],
    [theme.breakpoints.down('md')]: { display: 'none' }
  },
  rowItemLgBreakpoint: {
    color: theme.palette.grey[450],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: { display: 'none' }
  },
  indentedTypography: {
    textIndent: '4px',
    [theme.breakpoints.down('md')]: { textIndent: 0 }
  },
  clickableTitle: {
    m: 0,
    p: 0,
    height: '20px',
    fontSize: '16px',
    color: theme.palette.grey[800],
    fontWeight: 510,
    transition: 'background-color 0ms',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    'tr:hover &': {
      color: theme.palette.primary[500],
      background: 'transparent'
    }
  },
  indentedTypographyMdShow: { textIndent: '4px', [theme.breakpoints.up('md')]: { display: 'none' } },
  indentedTypographyMdHidden: { textIndent: '4px', [theme.breakpoints.down('md')]: { display: 'none' } },
  typographyDetailsContainer: {
    color: theme.palette.grey[450],
    display: 'flex',
    [theme.breakpoints.down('md')]: { display: isShared ? 'block' : 'flex' }
  },
  summaryList: {
    pr: 1,
    '& span + span:before': {
      content: '" | "'
    },
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflowX: 'hidden'
    }
  },
  creatorName: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      color: theme.palette.grey[400],
      '&&:before': {
        content: '""'
      }
    }
  }
})
