import { AxiosResponse, AxiosError } from 'axios'
import { IResetPasswordRequest } from '../types'
import { useRoutes } from '../../../hooks/useRoutes'
import { requestPaswordLink } from '../../../utils/auth'

export const useResetPassword = () => {
  const { setRoute, RESET_PASSWORD_REQUESTED_CONFIRMED } = useRoutes()

  const handleSendRequestPasswordLink = (userName: string) => {
    if (userName) {
      requestPaswordLink(userName)
        .then((response: AxiosResponse<IResetPasswordRequest.Props>) => {
          if (response.data.passwordWasReset) {
            setRoute(RESET_PASSWORD_REQUESTED_CONFIRMED)
          } else {
            throw new Error('Response code not 200')
          }
        })
        .catch((error: AxiosError) => {
          console.error(error)
        })
    }
  }

  return { handleSendRequestPasswordLink }
}
