import * as Yup from 'yup'

export const ResetPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email address is required.')
    .max(70, 'Email address must be 70 characters or less.')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Please enter a valid email address.')
    .lowercase()
})

export const ResetPasswordConfirmSchema = Yup.object({
  password: Yup.string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters.')
    .max(128, 'Password must be 128 characters or less.')
    .matches(/[a-z]/, 'Password must contain at least one lower case letter.')
    .matches(/[A-Z]/, 'Password must contain at least one upper case letter.')
    .matches(/[0-9]/, 'Password must contain at least one number.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password doesn’t match.')
    .required('Password confirmation is required.')
})
