import { Theme } from '@mui/material'

export const getNavigationStyles = (theme: Theme) => ({
  navigationStyles: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 2,
    zIndex: 1001,
    width: '72px',
    backgroundColor: theme.palette.secondary[600] || 'rgb(41, 46, 77)'
  },
  profileMenuStyles: {
    zIndex: 1000,
    display: 'flex'
  },
  navLinkStyles: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    marginBottom: '20px'
  },
  listStyles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconButtonStyles: {
    width: '45px',
    height: '45px',
    borderRadius: '4px',
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.main
    },
    transition: '0.1s ease in out'
  },
  logoStyles: {
    borderBottom: '1px solid #343C60',
    pb: 2,
    mb: 2
  },
  avatarStyles: {
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    width: '40px',
    height: '40px',
    border: '2px solid white',
    fontSize: 15,
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.primary.main
    }
  }
})
