import { isMatch } from 'date-fns'
import { regexValidation } from './constants'
import { CsvValidation } from './types'

const {
  containsOnlyLettersAndUnderscore,
  isValidUrl,
  containsOnlyLettersAndSemiColons,
  containsOnlyLettersSpacesNumbersAndSpecificSpecialChars,
  containsOnlyLettersNumbersHyphensInvertCommasSpacesAndSemiColons,
  containsOnlyLettersSpacesNumbersSemiColonAndSpecificSpecialChars
} = regexValidation

export const headingsValidationCheck = (title: string, id: number) => {
  const validatedHeading: CsvValidation.HeadingValidation[] = []

  if (!containsOnlyLettersAndUnderscore.test(title) && title.length) {
    validatedHeading.push({ title, isError: true, errorType: 'Contains illegal character', id })
  }
  if (title !== title.toLocaleUpperCase()) {
    validatedHeading.push({ title, isError: true, errorType: 'Not all caps', id })
  }
  if (!validatedHeading.length) {
    validatedHeading.push({ title, isError: false, errorType: 'No Error', id })
  }

  return validatedHeading
}

export const videoUrlValidation = (element: string, row: number, index: number, id: string) => {
  const validatedUrl: CsvValidation.ValidatedResult[] = []
  if (!element.length) {
    validatedUrl.push({ element, isError: true, isUrl: true, errorType: 'Mandatory field missing', index, row, id })
    return validatedUrl
  }
  if (!isValidUrl.test(element)) {
    validatedUrl.push({ element, isError: true, isUrl: true, errorType: 'Invalid URL', row, index, id })
  }
  if (!element.endsWith('mp4') && !element.endsWith('mpeg4')) {
    validatedUrl.push({ element, isError: true, isUrl: true, errorType: 'Invalid video format', row, index, id })
  }
  if (!validatedUrl.length) {
    validatedUrl.push({ element, isError: false, isUrl: true, errorType: 'No Error', row, index, id })
  }

  return validatedUrl
}

export const titleValidation = (element: string, row: number, index: number, id: string) => {
  const validatedTitle: CsvValidation.ValidatedResult[] = []
  if (!element.length) {
    validatedTitle.push({ element, isError: true, isTitle: true, errorType: 'Mandatory field missing', row, index, id })
    return validatedTitle
  }
  if (!containsOnlyLettersSpacesNumbersAndSpecificSpecialChars.test(element) && element.length) {
    validatedTitle.push({
      element,
      isError: true,
      errorType: 'Contains illegal character',
      row,
      isTitle: true,
      index,
      id
    })
  }
  if (!validatedTitle.length) {
    validatedTitle.push({ element, isError: false, errorType: 'No Error', isTitle: true, row, index, id })
  }

  return validatedTitle
}

export const assetStartDateValidation = (element: string, row: number, index: number, id: string) => {
  const validatedDate: CsvValidation.ValidatedResult[] = []
  if (!element.length) {
    validatedDate.push({
      element,
      isError: true,
      errorType: 'Mandatory field missing',
      row,
      index,
      id
    })
    return validatedDate
  }
  if (!isMatch(element, 'dd/MM/yyyy HH:mm:ss') && !isMatch(element, 'dd/MM/yyyy')) {
    // dates can be in 2 formats either DD/MM/YYYY or DD/MM/YYYY HH:MM:SS
    validatedDate.push({ element, isError: true, errorType: 'Incorrect date formatting', row, index, id })
  }
  if (!validatedDate.length) {
    validatedDate.push({ element, isError: false, errorType: 'No Error', row, index, id })
  }
  return validatedDate
}
export const genericValidation = (element: string, row: number, index: number, id: string) => {
  const validatedElement: CsvValidation.ValidatedResult[] = []

  if (element.length && !containsOnlyLettersSpacesNumbersSemiColonAndSpecificSpecialChars.test(element)) {
    validatedElement.push({
      element,
      isError: true,
      errorType: 'Contains illegal character',
      row,
      index,
      id
    })
  }
  if (!validatedElement.length) {
    validatedElement.push({ element, isError: false, errorType: 'No Error', row, index, id })
  }
  return validatedElement
}

export const vocabularyListValidation = (element: string, row: number, index: number, id: string) => {
  const validatedCustomVocab: CsvValidation.ValidatedResult[] = []
  if (!containsOnlyLettersAndSemiColons.test(element) && element.length) {
    validatedCustomVocab.push({ element, isError: true, errorType: 'Contains illegal character', row, index, id })
  }
  if (!validatedCustomVocab.length) {
    validatedCustomVocab.push({ element, isError: false, errorType: 'No Error', row, index, id })
  }
  return validatedCustomVocab
}

export const presenterValidation = (element: string, row: number, index: number, id: string) => {
  const validatedCustomVocab: CsvValidation.ValidatedResult[] = []

  if (!containsOnlyLettersNumbersHyphensInvertCommasSpacesAndSemiColons.test(element) && element.length) {
    validatedCustomVocab.push({ element, isError: true, errorType: 'Contains illegal character', row, index, id })
  }
  if (!validatedCustomVocab.length) {
    validatedCustomVocab.push({ element, isError: false, errorType: 'No Error', row, index, id })
  }
  return validatedCustomVocab
}

export const toFindDuplicates = (arr: any[]) => arr.filter((item, index) => arr.indexOf(item) !== index)
