import axios, { AxiosResponse } from 'axios'
import axiosInstance from '../../axios'
import { LVSService } from './types'

type Res<T> = Promise<AxiosResponse<T>>

const endpoint = `${process.env.REACT_APP_LVS_API}`

export const lvsService = {
  reporting: {
    get: (apiKey: string): Promise<AxiosResponse<LVSService.Reporting[]>> =>
      axiosInstance.get(`${endpoint}/v3/reporting/clip/agg?aggregation=PAYLOAD_CATEGORY`, {
        headers: {
          'X-Api-Key': apiKey
        }
      })
  },
  billing: {
    get: (apiKey: string): Res<LVSService.IBillingResponse> =>
      axiosInstance.get(`${endpoint}/v2/billing/report/getApiKeyInfo/${apiKey}`)
  },
  billingUsage: {
    get: (date: string) => axiosInstance.get(`${endpoint}/v2/billing//report/usages?date=${date}`)
  },
  verificationResend: {
    get: (adminUsername: string) =>
      axios.post(`${endpoint}/v2/iam/auth/signup/new-code`, { userName: `${adminUsername}` })
  },
  asset: {
    content: {
      get: (apiKey: string | null, parameter?: string): Promise<AxiosResponse<LVSService.IAssetResponse>> =>
        axiosInstance.get(`${process.env.REACT_APP_LVS_API}/v3/asset${parameter}`, {
          headers: {
            'x-api-key': apiKey,
            sortMode: 'Created'
          }
        })
    },
    status: {
      get: (allIds: string[]): Promise<LVSService.IAssetStatus> =>
        axiosInstance.get(
          `${process.env.REACT_APP_LVS_API}/v2/billing/transaction/enrich/asset?ids=${allIds.join('&ids=')}`
        )
    }
  }
}
