import useLoginStyles from '../../Login/Login.styles'
import { IPasswordResetHeader } from '../types'

export const PasswordResetHeader = ({ heading, subheading }: IPasswordResetHeader.Props) => {
  const classes = useLoginStyles()

  const NewLineText = (text?: string) => {
    const newText = text && text.split('\n').map(str => <p key={text}>{str}</p>)

    return newText
  }

  return (
    <div className={classes.resetPasswordContentContainer}>
      <div className={classes.resetPasswordHeadingContainer}>
        <h3 data-testid="heading-text">{heading}</h3>
      </div>
      <div className={classes.resetPasswordTextContainer} data-testid="body-text">
        {NewLineText(subheading)}
      </div>
    </div>
  )
}
