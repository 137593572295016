import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { notify } from 'react-notify-toast'
import { useDispatch, useSelector } from 'react-redux'
import { doDeleteUser } from '../../redux/actions/auth'
import { selectUser } from '../../redux/selectors'
import { IUserData } from '../../types/Auth'
import { formatDate } from '../../utils/date'
import { ConfirmableAction } from '../ConfirmableAction'

import Bin from '../../design-system-legacy/icons/AdminConsole/bin.svg'
import Delete from '../../design-system-legacy/icons/AdminConsole/delete.svg'
import EmptyCircle from '../../design-system-legacy/icons/AdminConsole/empty-circle.svg'
import Locked from '../../design-system-legacy/icons/AdminConsole/locked.svg'
import Tick from '../../design-system-legacy/icons/AdminConsole/tick.svg'

import useAdminConsoleUserListItemStyles from './AdminConsoleUserListItem.styles'
import { selectUserDetails } from '../../store/slices/user'

interface IAdminConsoleUserListItemProps {
  user: IUserData
  index: number
  length: number
  setSearchTerm: Dispatch<SetStateAction<string>>
  setPaginationPageNumber: Dispatch<SetStateAction<number>>
}

const AdminConsoleUserListItem: React.FC<IAdminConsoleUserListItemProps> = ({
  user,
  index,
  length,
  setSearchTerm,
  setPaginationPageNumber
}) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectUser)
  const userDetails = useSelector(selectUserDetails)

  const removeUser = useCallback(
    async (userId: number | null) => {
      try {
        if (userDetails?.role === 'ADMIN' && userDetails.id !== userId) {
          setPaginationPageNumber(0)
          dispatch(doDeleteUser(userId))
        } else {
          notify.show('Only Admin can remove users', 'error')
        }
      } catch (error) {
        notify.show('Something went wrong', 'error')
      }
    },
    [userDetails, dispatch, setPaginationPageNumber]
  )

  const handleConfirmRemove = useCallback(() => {
    removeUser(user.id)
  }, [user.id, removeUser])

  const classes = useAdminConsoleUserListItemStyles({
    index,
    length,
    currentUser,
    user
  })

  return (
    <>
      <div className={classes.userListItemContainer} key={user.id}>
        <p className={classes.userEmail}>{user?.email}</p>
        <div className={classes.userDataFlexContainer}>
          <div className={classes.userDataItem}>
            {user?.verified ? <img src={Tick} alt="" /> : <img src={Delete} alt="" />}
          </div>
          <div className={classes.userDataItem}>{formatDate(new Date(user.creationDate)) || 'dd/mm/yyyy'}</div>
          <div className={classes.userDataItem}>
            {user.role === 'ADMIN' ? <img src={Tick} alt="" /> : <img src={EmptyCircle} alt="" />}
          </div>
          <img src={Locked} alt="" width="13px" />
          <ConfirmableAction
            Action={
              <button className={classes.userListItemDeleteButton}>
                <img src={Bin} alt="" width="13px" />
              </button>
            }
            onConfirm={handleConfirmRemove}
          >
            <h3>Are you sure?</h3>
            <p>This user's access will be removed.</p>
          </ConfirmableAction>
        </div>
      </div>
    </>
  )
}

export default AdminConsoleUserListItem
