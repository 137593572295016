import { createSelector } from 'reselect'

import { ISearchTerms, Playlist } from '../../../types'
import { IVideoClip } from '../../../utils'

import { SearchState } from './reducer'

const selectSearchState = (state: { search: SearchState }): SearchState => state.search
const selectRangeState = (state: { search: SearchState }): SearchState => state.search

export const selectSearchTerm = createSelector(selectSearchState, (state: SearchState): string => state.searchTerm)

export const selectSearchRange = createSelector(selectRangeState, state => state.searchRange)

export const selectSearchFilters = createSelector(selectSearchState, state => state.searchFilters)

export const selectSearchUserFilters = createSelector(selectSearchState, state => state.searchUserFilters)

export const selectSearchDates = createSelector(selectSearchState, state => state.searchDates)

export const selectSearchTerms = createSelector(
  selectSearchState,
  (state: SearchState): ISearchTerms => {
    return {
      search: state.searchTerm,
      subject: state.subject,
      presenter: state.presenter
    }
  }
)

export const selectSearchIsFetching = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.isFetching
)

export const selectSearchIsGrouped = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.searchIsGrouped
)

export const selectSearchIsExactMatch = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.searchIsExactMatch
)

export const selectSearchResults = createSelector(selectSearchState, (state: SearchState): IVideoClip[] => [])

export const selectSearchResultsLength = createSelector(
  selectSearchResults,
  (items: IVideoClip[]): number => items?.length
)

export const selectSearchBarOpen = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.searchBarOpen
)

export const selectSearchResultsPlaylist = createSelector(
  selectSearchResults,
  (clips: IVideoClip[]): Playlist => {
    return {
      id: '',
      title: '',
      created: '',
      clips
    }
  }
)

export const selectSearchClipDurationsUpdated = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.clipDurationsUpdated
)

export const selectSearchFocused = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.isSearchFocused
)
