import { FormHelperText, MenuItem, OutlinedInput, styled, Typography } from '@mui/material'

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  minWidth: 150,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[25],
    color: theme.palette.grey[250],
    '& fieldset': {
      border: `1px solid ${theme.palette.grey[200]}`
    }
  },
  '& .MuiSelect-select': {
    padding: `${theme.spacing(1.5)} 0px ${theme.spacing(1.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.grey.white
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[25]
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[250]
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300]
  },
  '&.Mui-focused': {
    boxShadow: `0px 0px 0px 2px ${theme.palette.focus.main}`
  },
  '& .MuiInputBase-inputSizeSmall': {
    padding: `${theme.spacing(1)} 0px ${theme.spacing(1)} ${theme.spacing(2)}`
  },
  '&.Mui-error': {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.error.main}`
    }
  }
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.grey.white,
  maxHeight: 40,
  borderLeft: '2px solid transparent',
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    backgroundColor: theme.palette.primary[50]
  },
  ':hover': {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary[50]
  }
}))

export const DropdownLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.grey[800],
  fontWeight: 510
}))

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  ...theme.typography.body50,
  color: theme.palette.grey[400],
  textTransform: 'none',
  margin: `${theme.spacing(0.5)} 0px 0px 0px`
}))
