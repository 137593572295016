import { TextField, InputLabel, styled } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  '&.MuiFormControl-root': {
    '& p ': {
      '&.MuiFormHelperText-root': {
        ...theme.typography.body50,
        color: error ? theme.palette.error.main : theme.palette.grey[400],
        textTransform: 'none',
        margin: `${theme.spacing(0.5)} 0px 0px 0px`
      }
    }
  },
  '& .MuiOutlinedInput-root': {
    ...theme.typography.body2,
    minHeight: '48px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    '&:hover': { backgroundColor: theme.palette.grey[25] },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[25],
      color: theme.palette.grey[250],
      '& fieldset': {
        border: `1px solid ${theme.palette.grey[200]}`
      }
    },
    '& input': { padding: `${theme.spacing(1.5)} ${theme.spacing(2)}` },
    '&.MuiInputBase-sizeSmall': {
      ...theme.typography.body1,
      height: '40px'
    },
    '& fieldset': {
      borderColor: theme.palette.grey[250]
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.grey[300]}`
    },

    '&:hover fieldset .Mui-error': {
      border: `1px solid ${theme.palette.primary.main}`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      boxShadow: error ? `0px 0px 0px 1px ${theme.palette.error.main}` : `0px 0px 0px 2px ${theme.palette.focus.main}`
    },
    '&.Mui-error': {
      '&:hover fieldset': {
        border: `1px solid ${theme.palette.error.main}`
      }
    }
  }
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.grey[800],
  fontWeight: 510
}))
