import { AnyAction } from 'redux'

import { IUserBillingData, IUserData, IUserListData, IUserSubscription } from '../../../types/Auth'

const TENANT_ID_SET = 'auth/tenantid/set'
const ACCESS_TOKEN_SET = 'auth/accessToken/set'
const AUTH_USER_SET = 'auth/user/set'
const AUTH_USER_LIST_SET = 'auth/userList/set'
const AUTH_PAGINATED_USER_LIST_SET = 'auth/paginatedUserList/set'
const AUTH_USER_CLEAR = 'auth/user/clear'
const AUTH_API_KEY_SET = 'auth/apiKey/set'
const AUTH_USER_BILLING_INFO_SET = 'auth/user/billingInfo/set'
const AUTH_SUBSCRIPTION_SET = 'auth/userSubscription/set'
const AUTH_USER_LOGOUT = 'auth/user/logout'
const AUTH_USER_CLEAR_TOKEN = 'auth/user/clear/token'
const AUTH_USER_CLEAR_HELP = 'auth/user/clear/help'
const REFRESH_TOKEN_REFRESHING = 'auth/refresh/token/refreshing'
const REFRESH_TOKEN_SET = 'auth/refresh/token/set'
const AUTH_LOADING = 'auth/loading'

const setTenantId = (payload: string | null): AnyAction => {
  return {
    type: TENANT_ID_SET,
    payload
  }
}

const setAccessToken = (accessToken: string, refreshToken: string): AnyAction => {
  return {
    type: ACCESS_TOKEN_SET,
    accessToken,
    refreshToken
  }
}

const setRefreshToken = (accessToken: string, refreshToken: string): AnyAction => {
  return {
    type: REFRESH_TOKEN_SET,
    accessToken,
    refreshToken
  }
}

const setAPIKey = (apiKey: string): AnyAction => {
  return {
    type: AUTH_API_KEY_SET,
    apiKey
  }
}

const setUser = (user: IUserData | null): AnyAction => {
  return {
    type: AUTH_USER_SET,
    user
  }
}

const setUserSubscription = (userSubscription: IUserSubscription): AnyAction => {
  return {
    type: AUTH_SUBSCRIPTION_SET,
    userSubscription
  }
}
const setUserList = (userList: IUserListData | null): AnyAction => {
  return {
    type: AUTH_USER_LIST_SET,
    userList
  }
}

const setPaginatedUserList = (paginatedUserList: IUserListData | null): AnyAction => {
  return {
    type: AUTH_PAGINATED_USER_LIST_SET,
    paginatedUserList
  }
}

const setUserBillingInfo = (userBillingInfo: IUserBillingData | null): AnyAction => {
  return {
    type: AUTH_USER_BILLING_INFO_SET,
    userBillingInfo
  }
}

const clearUser = (): AnyAction => {
  return {
    type: AUTH_USER_CLEAR
  }
}

const clearUserToken = (): AnyAction => {
  return {
    type: AUTH_USER_CLEAR_TOKEN
  }
}

const userLogout = (): AnyAction => {
  // This action is caught in src/redux/index.ts and the store is reset to its initial state
  return {
    type: AUTH_USER_LOGOUT
  }
}

const clearShowHelp = (): AnyAction => {
  return {
    type: AUTH_USER_CLEAR_HELP
  }
}

const setAuthLoading = (loading: boolean) => {
  return {
    type: AUTH_LOADING,
    loading
  }
}

export {
  TENANT_ID_SET,
  AUTH_LOADING,
  AUTH_USER_SET,
  AUTH_USER_CLEAR,
  AUTH_API_KEY_SET,
  AUTH_USER_LIST_SET,
  AUTH_PAGINATED_USER_LIST_SET,
  ACCESS_TOKEN_SET,
  AUTH_USER_BILLING_INFO_SET,
  AUTH_SUBSCRIPTION_SET,
  AUTH_USER_LOGOUT,
  AUTH_USER_CLEAR_TOKEN,
  AUTH_USER_CLEAR_HELP,
  REFRESH_TOKEN_REFRESHING,
  REFRESH_TOKEN_SET,
  setTenantId,
  setUser,
  setUserList,
  setPaginatedUserList,
  setAPIKey,
  setAccessToken,
  clearUser,
  setUserBillingInfo,
  setUserSubscription,
  userLogout,
  clearUserToken,
  clearShowHelp,
  setRefreshToken,
  setAuthLoading
}
