import React from 'react'
import { usePagination } from '../../../hooks/usePagination/usePagination'
import usePaginationStyles from '../Pagination.styles'
import { ITablePaginationProps } from '../types'

export const TablePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
  countPrefixText,
  numberOfElements
}: ITablePaginationProps.Props) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  const classes = usePaginationStyles({})

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1]

  const onNext = () => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
    }
  }

  return (
    <div className={`${classes.flexRow} ${classes.myContentPaginationContainer}`}>
      <div>
        <p className={classes.countPrefixText}>
          {countPrefixText ? countPrefixText : 'Total number of videos:'} {totalCount}
        </p>
      </div>
      <div>
        <div className={classes.flexRow}>
          <div
            data-testid="previous"
            onClick={onPrevious}
            className={`${classes.paginationItem} ${currentPage === 1 ? classes.disabled : ''}`}>
            <div className={`${classes.arrowLeft}`} />
          </div>
          <div className={classes.centered}>
            Page <div className={classes.currentPage}> {currentPage}</div> of {Math.ceil(totalCount / pageSize)}
          </div>

          <div
            data-testid="next"
            onClick={onNext}
            className={`${classes.paginationItem} ${currentPage === lastPage ? classes.disabled : ''}`}>
            <div className={`${classes.arrow} ${classes.right}`} />
          </div>
        </div>
      </div>
      <div>
        <p className={classes.pageSizeText}>{'Page Size: ' + numberOfElements}</p>
      </div>
    </div>
  )
}
