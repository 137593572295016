import { AnyAction } from 'redux'

const QUEUE_INDEX_CURRENT_SET = 'queue/currentindex/set'
const QUEUE_PLAYLIST_CURRENT_ID_SET = 'queue/playmode/set'

const setQueueCurrentPlaylistId = (payload: string): AnyAction => {
  return {
    type: QUEUE_PLAYLIST_CURRENT_ID_SET,
    payload
  }
}

const setQueueCurrentIndex = (payload: number): AnyAction => {
  return {
    type: QUEUE_INDEX_CURRENT_SET,
    payload
  }
}

export { QUEUE_PLAYLIST_CURRENT_ID_SET, QUEUE_INDEX_CURRENT_SET, setQueueCurrentPlaylistId, setQueueCurrentIndex }
