import { NavOptions } from '../NavOptions'
import { useSecondaryNavPanelStyles } from './SecondaryNavPanel.styles'
import { ISecondaryNavPanel } from './types'

export const SecondaryNavPanel = ({ title, navOptions, children }: ISecondaryNavPanel.Props) => {
  const classes = useSecondaryNavPanelStyles()
  return (
    <div className={classes.secondaryNavPanelContainer} data-testid={'secondary-nav'}>
      <div>
        <hr className={classes.separator} />
        <div style={{ marginLeft: 20 }}>
          <h2 className={classes.heading}>{title}</h2>
          <NavOptions navOptions={navOptions} />
        </div>
      </div>
      {children}
    </div>
  )
}
