export const snakeCaseToTitleCase = (source: string) => {
  return source
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ')
}

export const snakeCaseToUpperCase = (source: string) => {
  return source
    .split('_')
    .map(word => word.toUpperCase())
    .join(' ')
}

export const getMinIntegerDigitString = (number: number, minNumberOfDigits: number): string => {
  return number.toLocaleString('en-US', { minimumIntegerDigits: minNumberOfDigits, useGrouping: false })
}
