import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Tab, Tabs, Typography, useTheme, Box, InputAdornment } from '@mui/material'

import { useGetSearchPlaylistsQuery, useGetSearchSharedPlaylistsQuery } from '../../store/apis/middleware'
import { selectUserDetails } from '../../store/slices/user'
import { PlaylistTable } from '../../components2.0/PlaylistTable/PlaylistTable'
import { TextField } from '../../components2.0/low-level/TextField/TextField'

export const Playlists = () => {
  const theme = useTheme()

  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''
  const [tabValue, setTabValue] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchSharedQuery, setSearchSharedQuery] = useState('')
  const [myPlaylistsPage, setMyPlaylistsPage] = useState(1)
  const [sharedWithMePage, setSharedWithMePage] = useState(1)

  const { data: playlistsData, isLoading, isFetching, isError } = useGetSearchPlaylistsQuery({
    tenantId,
    searchQuery,
    page: myPlaylistsPage
  })

  const { data: sharedWithMePlaylistsData } = useGetSearchSharedPlaylistsQuery({
    tenantId,
    searchQuery: searchSharedQuery,
    page: sharedWithMePage
  })

  const playlists = playlistsData?.playlists
  const isSharedPlaylistTable = tabValue === 1

  const handleChange = useCallback((_, newValue: number) => {
    setTabValue(newValue)
  }, [])

  const setPlaylistPage = useCallback((e: React.ChangeEvent<HTMLElement>) => {
    setMyPlaylistsPage(parseInt(e.target.innerText, 10))
  }, [])
  const onMyPlaylistsPrevPage = useCallback(() => {
    setMyPlaylistsPage(page => page - 1)
  }, [])
  const onMyPlaylistsNextPage = useCallback(() => {
    setMyPlaylistsPage(page => page + 1)
  }, [])

  const handleSetSharedWithMePage = useCallback((e: React.ChangeEvent<HTMLElement>) => {
    setSharedWithMePage(parseInt(e.target.innerText, 10))
  }, [])
  const onSharedWithMePrevPage = useCallback(() => {
    setSharedWithMePage(page => page - 1)
  }, [])
  const onSharedWithMeNextPage = useCallback(() => {
    setSharedWithMePage(page => page + 1)
  }, [])

  if (isLoading) {
    return (
      <Box>
        <Typography
          variant="head800"
          color={theme.palette.grey[800]}
          sx={{ backgroundColor: theme.palette.grey[25] }}
          px={4}
          py={2}
          mb={1}
        >
          Loading...
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Typography
        variant="head800"
        color={theme.palette.grey[800]}
        sx={{ backgroundColor: theme.palette.grey[25] }}
        px={4}
        py={2}
        mb={1}
        data-testid="page-title"
      >
        Playlists
      </Typography>

      <Box sx={{ px: 4, pt: 1, [theme.breakpoints.down('lg')]: { paddingLeft: 2, paddingRight: 2 } }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          sx={{ mb: 2, borderBottom: `1px solid ${theme.palette.grey[150]}` }}
        >
          <Tab label="My playlists" />
          <Tab label="Shared with me" disabled={!sharedWithMePlaylistsData} />
        </Tabs>
        {tabValue === 1 ? (
          <TextField
            onChange={e => {
              setSearchSharedQuery(e.target.value)
              setSharedWithMePage(1)
            }}
            disabled={!sharedWithMePlaylistsData?.playlists?.length && !searchSharedQuery.length}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            helperText=""
            label=""
            value={searchSharedQuery}
            placeholder="Search shared playlists"
            fullWidth
            sx={{ mb: 2 }}
            data-testid="playlists-search-input"
          />
        ) : (
          <TextField
            onChange={e => {
              setSearchQuery(e.target.value)
              setMyPlaylistsPage(1)
            }}
            value={searchQuery}
            disabled={!playlists?.length && !searchQuery.length}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            helperText=""
            label=""
            placeholder="Search my playlists"
            fullWidth
            sx={{ mb: 2 }}
            data-testid="playlists-search-input"
          />
        )}
        {isError || !playlistsData ? (
          <Typography>We are unable to show you your list of playlists, please refresh the page.</Typography>
        ) : (
          <PlaylistTable
            setPage={isSharedPlaylistTable ? handleSetSharedWithMePage : setPlaylistPage}
            onPrevPage={isSharedPlaylistTable ? onSharedWithMePrevPage : onMyPlaylistsPrevPage}
            onNextPage={isSharedPlaylistTable ? onSharedWithMeNextPage : onMyPlaylistsNextPage}
            data={playlistsData}
            dataShared={sharedWithMePlaylistsData}
            isLoading={isLoading || isFetching}
            isSharedPlaylistTable={isSharedPlaylistTable}
          />
        )}
      </Box>
    </Box>
  )
}
