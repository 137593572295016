import { useLocation } from 'react-router-dom'
import { useRedirectIfLoggedIn } from '../../hooks/useRedirectIfLoggedIn'
import useTopLoadingBar from '../../hooks/useTopLoadingBar'
import useLoginStyles from '../Login/Login.styles'
import { AuthLayout } from '../../layouts/AuthLayout'
import { PasswordResetHeader } from './PasswordResetHeader/PasswordResetHeader'
import { PasswordResetRequestForm } from './PasswordResetRequestForm/PasswordResetRequestForm'

export const PasswordResetRequested = () => {
  useTopLoadingBar()
  const location: any = useLocation()
  useRedirectIfLoggedIn(location?.state?.from?.pathname || '/')

  const classes = useLoginStyles()

  const setIsSubmitting = () => {
    return false
  }
  return (
    <AuthLayout>
      <div className={classes.layoutContainer}>
        <PasswordResetHeader
          heading="Reset password request"
          subheading="Enter the email associated with your account"
        />
        <PasswordResetRequestForm setIsSubmitting={setIsSubmitting} />
      </div>
    </AuthLayout>
  )
}
