import { AnyAction } from 'redux'

import { Playlist } from '../../../types'
import { IVideoClip } from '../../../utils'

const PLAYLIST_ADD = 'playlist/add'
const PLAYLIST_ADD_SHARED = 'playlist/addShared'
const PLAYLIST_DELETE = 'playlist/delete'
const PLAYLIST_UNFOLLOW_SHARED = 'playlist/unfollow'
const PLAYLIST_TITLE_SET = 'playlist/title/set'
const PLAYLIST_ORDER_UPDATE = 'playlist/order/update'
const PLAYLIST_INITIAL_SET = 'playlist/initial/set'
const PLAYLIST_CLIP_ADD = 'playlist/clip/add'
const PLAYLIST_CLIP_REMOVE = 'playlist/clip/remove'
const PLAYLIST_CLIP_UPDATE = 'playlist/clip/update'
const PLAYLIST_SHARED_INITIAL_SET = 'playlist/initial/shared/set'
const PLAYLIST_SHARED_FETCHED = 'playlits/shared/fetched'

const deletePlaylist = (id: string): AnyAction => {
  return {
    type: PLAYLIST_DELETE,
    id
  }
}

const unfollowSharedPlaylist = (id: string): AnyAction => {
  return {
    type: PLAYLIST_UNFOLLOW_SHARED,
    id
  }
}

const addPlaylist = ({
  id,
  created,
  title,
  clips
}: {
  id: string
  created: string
  title: string
  clips?: IVideoClip[]
}): AnyAction => {
  return {
    type: PLAYLIST_ADD,
    payload: { id, title, created, clips }
  }
}

const addSharedPlaylist = (playlist: any): AnyAction => {
  return {
    type: PLAYLIST_ADD_SHARED,
    playlist
  }
}

const setInitialPlaylists = (
  playlists: { id: string; title: string }[]
): AnyAction => {
  return {
    type: PLAYLIST_INITIAL_SET,
    playlists
  }
}

const setInitialSharedPlaylists = (playlistsShared: Playlist[]): AnyAction => {
  return {
    type: PLAYLIST_SHARED_INITIAL_SET,
    playlistsShared
  }
}

const setPlaylistTitle = (id: string, title: string): AnyAction => {
  return {
    type: PLAYLIST_TITLE_SET,
    id,
    title
  }
}

const updatePlaylistOrder = (
  oldIndex: number,
  newIndex: number,
  playlistId: string
): AnyAction => {
  return {
    type: PLAYLIST_ORDER_UPDATE,
    oldIndex,
    newIndex,
    playlistId
  }
}

const addPlaylistClip = (
  clip: IVideoClip,
  playlistIds: string[]
): AnyAction => {
  return {
    type: PLAYLIST_CLIP_ADD,
    clip,
    playlistIds
  }
}

const removePlaylistClip = (id: string, playlistId: string): AnyAction => {
  return {
    type: PLAYLIST_CLIP_REMOVE,
    id,
    playlistId
  }
}

const updatePlaylistClip = (
  id: string,
  playlistId: string,
  title: string | null = null,
  offset: number | null = null,
  duration: number | null = null
): AnyAction => {
  return {
    type: PLAYLIST_CLIP_UPDATE,
    id,
    playlistId,
    title,
    offset,
    duration
  }
}

const doPlaylistsSharedFetched = (): AnyAction => {
  return {
    type: PLAYLIST_SHARED_FETCHED
  }
}

export {
  PLAYLIST_INITIAL_SET,
  PLAYLIST_ADD,
  PLAYLIST_ADD_SHARED,
  PLAYLIST_DELETE,
  PLAYLIST_TITLE_SET,
  PLAYLIST_CLIP_ADD,
  PLAYLIST_CLIP_REMOVE,
  PLAYLIST_CLIP_UPDATE,
  PLAYLIST_ORDER_UPDATE,
  PLAYLIST_SHARED_INITIAL_SET,
  PLAYLIST_SHARED_FETCHED,
  PLAYLIST_UNFOLLOW_SHARED,
  addPlaylist,
  addSharedPlaylist,
  setInitialPlaylists,
  deletePlaylist,
  setPlaylistTitle,
  addPlaylistClip,
  removePlaylistClip,
  updatePlaylistClip,
  updatePlaylistOrder,
  setInitialSharedPlaylists,
  doPlaylistsSharedFetched,
  unfollowSharedPlaylist
}
