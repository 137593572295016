import { AnyAction } from 'redux'

import { QUEUE_INDEX_CURRENT_SET, QUEUE_PLAYLIST_CURRENT_ID_SET } from './actions'

export interface QueueState {
  currentIndex: number
  currentPlaylistId: string
}

const initialState: QueueState = {
  currentIndex: -1,
  currentPlaylistId: ''
}

const reducer = (state: QueueState = initialState, action: AnyAction): QueueState => {
  switch (action.type) {
    case QUEUE_PLAYLIST_CURRENT_ID_SET:
      return {
        ...state,
        currentPlaylistId: action.payload
      }

    case QUEUE_INDEX_CURRENT_SET:
      return {
        ...state,
        currentIndex: action.payload
      }

    default:
      return state
  }
}

export default reducer
export { initialState }
