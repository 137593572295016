import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Box, Button, Container, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { notify } from 'react-notify-toast'

import { middlewareApi, useGetPlaylistQuery, useUpdateClipMutation } from '../../store/apis/middleware'
import { lvsApi } from '../../store/apis/lvs'
import { PageHeader } from '../../components2.0/PageHeader'
import { TextField } from '../../components2.0/low-level/TextField/TextField'
import { StyledInputLabel } from '../../components2.0/low-level/TextField/StyledComponents'
import VideoEditorSlider from '../../components/VideoEditorSlider/VideoEditorSlider'
import { Player, usePlayer } from '../../components/Player'
import { formatDateWithoutTimezone } from '../../utils/date'
import { DATE_FORMATS } from '../../constants'
import { ROUTES } from '../../RouteConfig/constants'
import { selectUserDetails } from '../../store/slices/user'

const MAX_CLIP_NAME_LENGTH = 100
const MAX_CLIP_DESCRIPTION_LENGTH = 5000

export const EditPlaylistClip = () => {
  const theme = useTheme()
  const history = useHistory()
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''
  const { playlistId, clipId } = useParams<Record<string, string>>()

  const isBelowMDBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  const [clipName, setClipName] = useState('')
  const [clipDescription, setClipDescription] = useState('')
  const [clipTimes, setClipTimes] = useState({ startTime: 0, endTime: 0 })

  const { data: playlist, isLoading: isPlaylistLoading, isError: isPlaylistError } = useGetPlaylistQuery({
    tenantId,
    playlistId
  })

  const [
    getPlaylist,
    { data: playlistClip, isLoading: isPlaylistClipLoading, isError: isPlaylistClipError }
  ] = middlewareApi.endpoints.getPlaylistClip.useLazyQuery()

  const [getAsset, { data: asset, isError: isAssetError }] = lvsApi.endpoints.getAsset.useLazyQuery()

  const isError = isAssetError || isPlaylistClipError || isPlaylistError

  useEffect(() => {
    getPlaylist({ tenantId, playlistId, clipId })
      .unwrap()
      .then(response => {
        getAsset(response.asset_id)
      })
  }, [clipId, getAsset, getPlaylist, playlistId, tenantId])

  const [updateClip, { isLoading: isUpdatingClip, isError: isUpdateClipError }] = useUpdateClipMutation()

  const { player } = usePlayer()

  useEffect(() => {
    if (!isUpdateClipError) return
    notify.show('Something went wrong.', 'error')
  }, [isUpdateClipError])

  const videoString = playlistClip && `id=${playlistClip.asset_id}&o=${0}&d=${-1}`
  const src = `${process.env.REACT_APP_LVS_API}/v3/assembly/hls?${videoString}`

  const isSaveDisabled =
    playlistClip &&
    (clipName.length === 0 ||
      clipName.length > MAX_CLIP_NAME_LENGTH ||
      clipDescription.length > MAX_CLIP_DESCRIPTION_LENGTH ||
      (clipName === playlistClip.title &&
        clipDescription === playlistClip.description &&
        clipTimes.startTime === playlistClip.offset &&
        clipTimes.endTime === playlistClip.offset + playlistClip.duration))

  const handleClipNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setClipName(e.target.value)
  }, [])

  const handleClipDescriptionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setClipDescription(e.target.value)
  }, [])

  const setStartAndEndTimes = useCallback((times: number[]) => {
    const [startTime, endTime] = times
    setClipTimes({ startTime, endTime })
  }, [])

  const handleClipUpdate = () => {
    updateClip({
      tenantId,
      playlistId,
      body: {
        offset: clipTimes.startTime,
        duration: clipTimes.endTime - clipTimes.startTime,
        title: clipName,
        description: clipDescription
      },
      clipId
    }).then(() => {
      notify.show('Clip successfully updated', 'success')
      history.goBack()
    })
  }

  useEffect(() => {
    if (!playlistClip) return
    player?.currentTime(playlistClip.offset)
    setClipName(playlistClip.title)
    setClipTimes({
      startTime: playlistClip.offset,
      endTime: playlistClip.offset + playlistClip.duration
    })
    if (playlistClip.description) setClipDescription(playlistClip.description)
  }, [player, playlistClip])

  useEffect(() => {
    if (isError) {
      history.push(
        generatePath(ROUTES.PLAYLIST_VIEW_PLAYLIST, {
          playlistId
        })
      )
      notify.show('Clip not found.', 'error')
    }
  }, [history, isError, playlistId])

  return (
    <>
      <PageHeader breadCrumbOveride={{ to: { 1: `/playlists/view-playlist/${playlistId}` } }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ [theme.breakpoints.down('md')]: { flexDirection: 'column' } }}
        >
          <Box
            sx={{
              width: 'calc(100% - 200px)',
              [theme.breakpoints.down('md')]: { width: '100%', mb: 2, display: 'flex', justifyContent: 'center' }
            }}
          >
            {isPlaylistLoading || !playlist ? (
              <Skeleton height={32} width={250} />
            ) : (
              <Typography
                variant={isBelowMDBreakpoint ? 'head700' : 'head800'}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color={theme.palette.grey[800]}
                data-testid="page-title"
              >
                {playlist.title}
              </Typography>
            )}
          </Box>
          <Box data-testid="button-container" sx={{ minWidth: 160 }}>
            <Button
              size={isBelowMDBreakpoint ? 'small' : 'medium'}
              disabled={isUpdatingClip}
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => {
                history.goBack()
              }}
              data-testid="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              size={isBelowMDBreakpoint ? 'small' : 'medium'}
              disabled={isSaveDisabled || isUpdatingClip}
              variant="contained"
              onClick={handleClipUpdate}
              data-testid="save-btn"
            >
              Save
            </Button>
          </Box>
        </Box>
      </PageHeader>
      <Container sx={{ mb: 3 }}>
        <Box sx={{ mb: 3 }}>
          {isPlaylistClipLoading ? (
            <>
              <Skeleton height={84} width="100%" sx={{ mb: 3 }} />
              <Skeleton height={158} width="100%" sx={{ mb: 3 }} />
              <Skeleton height={648} width="100%" />
            </>
          ) : (
            <>
              <TextField
                helperText={
                  clipName.length === 0 ? 'Clip name required' : `${clipName.length} / ${MAX_CLIP_NAME_LENGTH}`
                }
                error={clipName.length === 0 || clipName.length > MAX_CLIP_NAME_LENGTH}
                placeholder="Enter clip name"
                value={clipName}
                onChange={handleClipNameChange}
                sx={{
                  mb: 3
                }}
                label="Clip name"
                fullWidth
                data-testid="clip-name"
              />
              <TextField
                error={clipDescription.length > MAX_CLIP_DESCRIPTION_LENGTH}
                helperText={`${clipDescription.length} / ${MAX_CLIP_DESCRIPTION_LENGTH}`}
                sx={{
                  mb: 3
                }}
                value={clipDescription}
                onChange={handleClipDescriptionChange}
                placeholder="Enter clip description"
                multiline
                rows={4}
                label="Clip description (optional)"
                fullWidth
                data-testid="clip-description"
              />
              <StyledInputLabel>Clip</StyledInputLabel>
              <Player src={src} />
            </>
          )}

          {asset && (
            <>
              <VideoEditorSlider
                handleOffsetChange={setStartAndEndTimes}
                clip={clipTimes}
                assetDuration={Number(asset.duration)}
              />
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  py: 2,
                  borderTop: `1px solid  ${theme.palette.grey[50]}`,
                  borderBottom: `1px solid  ${theme.palette.grey[50]}`
                }}
              >
                <Typography sx={{ width: '184px', fontWeight: 700, color: theme.palette.grey[450] }}>
                  Source video
                </Typography>
                <Box>
                  <Typography sx={{ mb: 2 }} variant="body2" data-testid="asset-name">
                    {asset?.name}
                  </Typography>
                  <Typography variant="body1" data-testid="asset-recorded-on">
                    Recorded on {formatDateWithoutTimezone(asset.startDate, DATE_FORMATS.CALENDAR_WITH_TIME)} (UTC)
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  )
}
