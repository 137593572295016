export enum UserRole {
  ADMIN = 'ADMIN',
  CONSUMER = 'CONSUMER'
}
export enum UserType {
  ADMINISTRATOR = 'administrator'
}
export enum Authority {
  ROLE_ADMIN = 'ROLE_ADMIN'
}
enum UserScope {
  READ = 'read',
  WRITE = 'write'
}
enum PlatformName {
  WHIZZARDLEARN = 'WHIZZARDLEARN'
}
export enum UserSubscriptionState { // https://help.chargify.com/subscriptions/subscription-states.html
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  ON_HOLD = 'on_hold',
  PAST_DUE = 'past_due',
  SOFT_FAILURE = 'soft_failure',
  TRIALING = 'trialing',
  TRIAL_ENDED = 'trial_ended',
  UNPAID = 'unpaid',
  SUSPENDED = 'suspended',
  ERROR = 'error'
}
export interface IUser {
  id: number | null
  name: string | null
}

export interface ISignUpResponse {
  tenantId: string
  userId: number
  signedUp: boolean
  reason: string
  confirmationMedium: string
}

export interface IAdduserResponse {
  email: string
}

export interface IUserData {
  id: number | null
  tenantId?: string
  creationDate: number | string | Date
  userName?: string
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  role?: UserRole
  deactivated?: boolean
  tenantName?: string
  userType?: UserType
  verified?: boolean
  dateAdded: string
}

export interface IUserListData {
  content: IUserData[]
  totalElements: number
  totalPages: number
  number: number
  size: number
}

export interface IUserBillingData {
  active: boolean
  free: boolean
  quota: number
  quotaExceeded: boolean
  quotaFrame: number
  trialRemainingDays: number
}

interface IApiKey {
  id: string
  value: string
  name: string
  description: string
  createdDate: number
  free: boolean
  active: boolean
}
export interface ITenantDetails {
  id: number
  name: string
  creationDate: number
  address1: string | null
  address2: string | null
  address3: string | null
  zip: string | null
  country: string | null
  technicalContact: {
    firstName: string | null
    lastName: string | null
    email: string | null
  }
  premiumFeatures: any[]
  usersNumber: number
  apiKeys: IApiKey[]
  billing: string | null
}

export interface IConfirmationData {
  tenantId: string
  userId: number
  signedUp: boolean
  reason: any
  confirmationMedium: any
}

export interface IDecodedUserToken {
  aud: string[]
  user_name: string
  scope: UserScope[]
  tenantId: string
  userName: string
  platformName: PlatformName
  exp: number
  userId: number
  email: string
  deactivated: boolean
  authorities: Authority[]
  jti: string
  client_id: string
}

export interface IUserSubscription {
  id?: number
  state?: UserSubscriptionState
  fetched: boolean
}

export interface IUserSubscriptionData {
  id: number
  state: UserSubscriptionState
  trial_started_at: Date | null
  trial_ended_at: Date | null
  activated_at: Date | null
  created_at: Date | null
  updated_at: Date | null
  expires_at: Date | null
  balance_in_cents: 0
  current_period_ends_at: Date | null
  next_assessment_at: Date | null
  canceled_at: Date | null
  cancellation_message: null
  next_product_id: null
  next_product_handle: null
  cancel_at_end_of_period: boolean
  payment_collection_method: 'automatic' | string
  snap_day: null
  cancellation_method: null
  current_period_started_at: Date | null
  previous_state: UserSubscriptionState
  signup_payment_id: 497788887
  signup_revenue: string
  delayed_cancel_at: null
  coupon_code: null
  total_revenue_in_cents: 0
  product_price_in_cents: 0
  product_version_number: 1
  payment_type: 'credit_card' | string
  referral_code: null
  coupon_use_count: null
  coupon_uses_allowed: null
  reason_code: null
  automatically_resume_at: null
  coupon_codes: string[]
  offer_id: null
  payer_id: null
  current_billing_amount_in_cents: number
  receives_invoice_emails: null
  product_price_point_id: number
  next_product_price_point_id: null
  net_terms: null
  stored_credential_transaction_id: null
  reference: null
  currency: 'USD' | string
  on_hold_at: null
  scheduled_cancellation_at: null
  customer: {
    id: number
    first_name: string
    last_name: string
    organization: string
    email: string
    created_at: Date | null
    updated_at: Date | null
    reference: string
    address: null
    address_2: null
    city: null
    state: null
    state_name: null
    zip: null
    country: null
    country_name: null
    phone: null
    portal_invite_last_sent_at: Date | null
    portal_invite_last_accepted_at: null
    verified: boolean
    portal_customer_created_at: Date | null
    vat_number: null
    cc_emails: null
    tax_exempt: boolean
    parent_id: null
  }
  product: {
    id: number
    name: string
    handle: string
    description: string
    accounting_code: string
    request_credit_card: boolean
    expiration_interval: null
    expiration_interval_unit: string
    created_at: Date | null
    updated_at: Date | null
    price_in_cents: number
    interval: number
    interval_unit: string
    initial_charge_in_cents: null
    trial_price_in_cents: number
    trial_interval: number
    trial_interval_unit: string
    archived_at: null
    require_credit_card: boolean
    return_params: ''
    taxable: boolean
    update_return_url: ''
    tax_code: ''
    initial_charge_after_trial: boolean
    version_number: 1
    update_return_params: ''
    default_product_price_point_id: number
    request_billing_address: boolean
    require_billing_address: boolean
    require_shipping_address: boolean
    product_price_point_id: number
    product_price_point_name: string
    product_price_point_handle: string
    product_family: {
      id: number
      name: string
      description: string
      handle: string
      accounting_code: null
      created_at: Date | null
      updated_at: Date | null
    }
    public_signup_pages: [
      {
        id: number
        return_url: null
        return_params: string
        url: string
      }
    ]
  }
  credit_card: {
    id: number
    first_name: string
    last_name: string
    masked_card_number: string
    card_type: 'visa' | string
    expiration_month: number
    expiration_year: number
    customer_id: number
    current_vault: 'stripe_connect' | string
    vault_token: string
    billing_address: null
    billing_city: null
    billing_state: null
    billing_zip: null
    billing_country: null
    customer_vault_token: null
    billing_address_2: null
    payment_type: 'credit_card' | string
    disabled: boolean
    site_gateway_setting_id: number
  }
  component: {
    component: {
      component_id: number
      subscription_id: number
      component_handle: 'users' | string
      allocated_quantity: number
      name: 'users' | string
      kind: 'quantity_based_component' | string
      unit_name: 'users' | string
      pricing_scheme: 'per_unit' | string
      price_point_id: number
      price_point_handle: 'original' | string
    }
  }
}
