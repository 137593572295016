import { useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import Paper from '@mui/material/Paper'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import { Tooltip, Typography, useTheme } from '@mui/material'
import { PlaylistTableRow } from './PlaylistTableRow/PlaylistTableRow'
import { Header } from './PlaylistTable.constants'
import { PlaylistTablePagination } from './PlaylistTablePagination/PlaylistTablePagination'
import { getPlaylistTableStyles } from './PlaylistTable.styles'
import { MiddlewareApi } from '../../store/apis/middleware/types'

interface PlaylistsTableProps {
  isSharedPlaylistTable: boolean
  data: MiddlewareApi.PlaylistResponse
  dataShared?: MiddlewareApi.PlaylistResponse
  isLoading?: boolean
  setPage: (e) => void
  onPrevPage: () => void
  onNextPage: () => void
}

export const PlaylistTable = ({
  isSharedPlaylistTable = false,
  data,
  dataShared,
  isLoading,
  setPage,
  onPrevPage,
  onNextPage
}: PlaylistsTableProps) => {
  const theme = useTheme()

  const styles = getPlaylistTableStyles(theme, isSharedPlaylistTable)

  const returnPlaylistData = useCallback(
    (isShared: boolean) => {
      if (isShared) {
        return dataShared
      }
      return data
    },
    [data, dataShared]
  )

  useEffect(() => {
    const playlistData = returnPlaylistData(isSharedPlaylistTable)

    if (!playlistData) return

    if (playlistData.page > 1 && playlistData.playlists.length === 0) {
      onPrevPage()
    }
  }, [isSharedPlaylistTable, onPrevPage, returnPlaylistData])

  const tablePagination = (isShared: boolean) => {
    const playlistData = isShared ? dataShared : data

    if (playlistData && playlistData.last_page > 1) {
      return (
        <PlaylistTablePagination
          page={playlistData.page}
          count={10}
          totalCount={playlistData.total_items}
          setPage={setPage}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
        />
      )
    }

    return null
  }

  const headers: Header[] = [
    {
      item: 'Playlist name',
      alignment: 'left',
      sx: { [theme.breakpoints.down('md')]: { pl: 2 } },
      labelSx: styles.headerLabel
    },
    {
      item: 'Description',
      alignment: 'left',
      sx: styles.description,
      labelSx: styles.descriptionLabel
    },
    {
      item: 'Last modified on (UTC) ',
      alignment: 'left',
      sx: styles.sharedOnLastModified,
      labelSx: styles.sharedOnLastModifiedLabel,
      tooltipText: 'Times are in UTC so may not match your local time'
    },
    {
      item: 'Views',
      alignment: 'center',
      sx: styles.views,
      labelSx: styles.viewsLabel,
      tooltipText: 'No. of times this playlist has been played',
      icon: true
    }
  ]
  if (isLoading) return <div>Loading...</div>
  if (isSharedPlaylistTable && !dataShared?.playlists?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }}
        data-testid="page-placeholder-text"
      >
        <Typography>
          <strong>No shared playlists yet!</strong>
        </Typography>
      </Box>
    )
  }

  if (!isSharedPlaylistTable && !data?.playlists?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }}
        data-testid="page-placeholder-text"
      >
        <Typography>
          <strong>No playlists yet!</strong>
        </Typography>
        <Typography>
          Search for the moments that matter to you and you&apos;ll be able to create a new playlist from there.
        </Typography>
      </Box>
    )
  }

  return (
    <Box width="100%" height="100%">
      <Paper sx={styles.paper}>
        <TableContainer>
          <Table size="medium" sx={styles.table} data-testid="playlists-table">
            <TableHead>
              <TableRow>
                <TableCell padding="none" sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }} />
                {headers.map(({ item, alignment, sx, labelSx, tooltipText }) => (
                  <TableCell align={alignment} padding="none" sx={sx} key={item} data-testid="table-header">
                    <TableSortLabel sx={{ ...labelSx, cursor: 'default' }} hideSortIcon>
                      <Typography variant="body1" color={theme.palette.grey[450]}>
                        {item}
                      </Typography>
                      {tooltipText && (
                        <Tooltip title={tooltipText}>
                          <HelpOutlineOutlinedIcon
                            fontSize="large"
                            sx={{
                              mt: 0.1,
                              ml: 1,
                              width: '14px',
                              color: theme.palette.grey[450],
                              [theme.breakpoints.down('lg')]: { display: 'none' }
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {returnPlaylistData(isSharedPlaylistTable)?.playlists?.map(playlist => (
                <PlaylistTableRow
                  playlist={playlist}
                  isShared={isSharedPlaylistTable}
                  key={playlist.id + playlist.title}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {tablePagination(isSharedPlaylistTable)}
      </Paper>
    </Box>
  )
}
