import { createUseStyles } from 'react-jss'
import { IUserData } from '../../types/Auth'

interface IAdminConsoleUserlistItemStylesProps {
  index: number
  length: number
  currentUser: IUserData | null
  user: IUserData
}

const useAdminConsoleUserListItemStyles = createUseStyles({
  userListItemContainer: ({ index, length }: IAdminConsoleUserlistItemStylesProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: index < length - 1 ? '10px 0px' : '5px 0px 5px 0px',
    borderBottom: index < length - 1 ? '2px solid white' : 'none'
  }),
  userDataFlexContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 40
  },
  userEmail: {
    margin: 0,
    fontSize: 12
  },
  userDataItem: {
    fontSize: 12,
    width: '120px'
  },
  userListItemDeleteButton: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginLeft: 20,
    visibility: ({ currentUser, user }: IAdminConsoleUserlistItemStylesProps) =>
      currentUser?.id !== user.id ? 'visible' : 'hidden'
  }
})

export default useAdminConsoleUserListItemStyles
