import { AnyAction } from 'redux'

import { IVideoClip } from '../../../utils'

const PLAYLIST_MODAL_OPEN_SET = 'playlistModal/open/set'
const PLAYLIST_MODAL_OPEN = 'playlistModal/open'
const PLAYLIST_MODAL_CLOSE = 'playlistModal/close'
const PLAYLIST_MODAL_CLIP_SET = 'playlistModal/clip/set'
const PLAYLIST_MODAL_EDIT_CLIP_TIMES = 'playlistModal/clip/edit'

const setPlaylistModalOpen = (payload: boolean): AnyAction => {
  return {
    type: PLAYLIST_MODAL_OPEN_SET,
    payload
  }
}

const openPlaylistModal = (): AnyAction => {
  return {
    type: PLAYLIST_MODAL_OPEN
  }
}

const closePlaylistModal = (): AnyAction => {
  return {
    type: PLAYLIST_MODAL_CLOSE
  }
}

const setPlaylistModalClip = (payload: IVideoClip): AnyAction => {
  return {
    type: PLAYLIST_MODAL_CLIP_SET,
    payload
  }
}

const setPlaylistModalEditClipTimes = (payload: IVideoClip, times: { startTime: any; endTime: any }): AnyAction => {
  return {
    type: PLAYLIST_MODAL_EDIT_CLIP_TIMES,
    payload,
    times
  }
}

export {
  PLAYLIST_MODAL_OPEN_SET,
  PLAYLIST_MODAL_OPEN,
  PLAYLIST_MODAL_CLOSE,
  PLAYLIST_MODAL_CLIP_SET,
  PLAYLIST_MODAL_EDIT_CLIP_TIMES,
  setPlaylistModalClip,
  setPlaylistModalOpen,
  openPlaylistModal,
  closePlaylistModal,
  setPlaylistModalEditClipTimes
}
