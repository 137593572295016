import { useState } from 'react'
import Toolbar from '@mui/material/Toolbar'
import { WhizzardLogoNoText } from '../../design-system-legacy/core/WhizzardLogoNoText/WhizzardLogoNotext'
import WhizzardText from './whizzardLogoTextOnly.svg'
import { Box, IconButton, IconProps, useTheme } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined'
import { ROUTES } from '../../RouteConfig/constants'
import { useLocation } from 'react-router-dom'

export const useTopNav = () => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const theme = useTheme()
  const { pathname } = useLocation()

  const pages = [
    {
      route: ROUTES.SEARCH,
      icon: <SearchOutlinedIcon fontSize="large" />,
      text: 'Search',
      isActive: () => [ROUTES.SEARCH_RESULTS as string, ROUTES.SEARCH as string].includes(pathname)
    },
    {
      route: ROUTES.PLAYLISTS,
      icon: <PlaylistPlayOutlinedIcon fontSize="large" />,
      text: 'Playlists',
      isActive: () => [ROUTES.PLAYLISTS as string].includes(pathname)
    }
  ]

  const toolbar = (icon: IconProps, open: boolean) => {
    return (
      <Toolbar
        variant="dense"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 56,
          borderBottom: '1px solid #343C60'
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <WhizzardLogoNoText size="30px" />
          <img style={{ marginLeft: 10 }} src={WhizzardText} alt="WhizzardText" />
        </Box>
        <IconButton
          onClick={() => setOpenDrawer(open)}
          color="inherit"
          aria-label="menu"
          sx={{ cursor: 'pointer', color: theme.palette.common.white, px: 0 }}
        >
          {icon}
        </IconButton>
      </Toolbar>
    )
  }

  return {
    toolbar,
    setOpenDrawer,
    openDrawer,
    pages
  }
}
