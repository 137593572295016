import { useTheme } from '@mui/material'
import { AnyObject } from 'yup/lib/object'
import { useTagStyles } from './Tag.styles'

export enum TagType {
  Presenter = 'presenter',
  Topic = 'topic',
  People = 'people',
  Ocr = 'ocr',
  Custom = 'custom'
}

export interface ITagProps {
  label: string
  type: TagType
}

export interface ITagStyleProps {
  tagColor: string
}

const Tag = (props: ITagProps) => {
  const { label, type } = props

  const theme = useTheme()

  const tagTypeColors: AnyObject = {
    [TagType.Presenter]: theme.palette.tags?.presenter,
    [TagType.Topic]: theme.palette.tags?.topic,
    [TagType.People]: theme.palette.tags?.people,
    [TagType.Ocr]: theme.palette.tags?.ocr
  }

  const tagColor: string = tagTypeColors[type]

  const classes = useTagStyles({ tagColor })

  return (
    <span data-testid={'tag'} className={classes.tag}>
      {label}
    </span>
  )
}

export default Tag
