import { IconButton, Pagination, Typography, useTheme, Box } from '@mui/material'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { getPlaylistTablePaginationStyles } from './PlaylistTablePagination.styles'

interface PlaylistTablePaginationProps {
  page: number
  setPage: (e) => void
  onNextPage: (e) => void
  onPrevPage: (e) => void
  totalCount: number
  count: number
}

export const PlaylistTablePagination = ({
  page,
  setPage,
  count,
  totalCount,
  onNextPage,
  onPrevPage
}: PlaylistTablePaginationProps) => {
  const theme = useTheme()
  const totalPages = Math.ceil(totalCount / count)
  const styles = getPlaylistTablePaginationStyles()

  return (
    <Box sx={styles.playlistTablePagination} data-testid="playlist-table-pagination">
      <Typography sx={{ width: 250 }} data-testid="playlist-table-showing">
        Showing {count * (page - 1) + 1} - {count * page > totalCount ? totalCount : count * page} of {totalCount}
      </Typography>
      <Pagination
        sx={{ [theme.breakpoints.down('lg')]: { display: 'none' } }}
        count={totalPages}
        shape="rounded"
        hideNextButton
        hidePrevButton
        page={page}
        onChange={setPage}
        data-testid="playlist-table-page-numbers"
      />
      <Box sx={styles.buttonActions} data-testid="playlist-table-pagination-buttons">
        <Typography>
          Page {page} of {totalPages}
        </Typography>
        <IconButton onClick={onPrevPage} disabled={page === 1} data-testid="playlist-table-prev-btn">
          <ArrowBackIosOutlinedIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={onNextPage} disabled={page === totalPages} data-testid="playlist-table-next-btn">
          <ArrowForwardIosOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
