import { useEffect, useCallback } from 'react'
import { Box, Button } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface Props {
  page: number
  currentTotal: number
  onPageChange: (direction: -1 | 1) => void
  onFetch: () => void
  complete: boolean
}

export const InfinitePagination = ({ page, currentTotal, onPageChange, onFetch, complete }: Props) => {
  useEffect(() => {
    if (currentTotal - page <= 2 && !complete) {
      // eagerly fetch some more results
      onFetch()
    }
  }, [currentTotal, page, onFetch, complete])

  const handlePrev = useCallback(() => {
    onPageChange(-1)
  }, [onPageChange])

  const handleNext = useCallback(() => {
    onPageChange(1)
  }, [onPageChange])

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}>
      <Button
        sx={{ padding: 0, marginRight: 1, minWidth: 32, height: 32, borderRadius: '50%', pr: 0.25 }}
        onClick={handlePrev}
        variant="outlined"
        disabled={page === 0}>
        <ArrowBackIosNewIcon sx={{ fontSize: 15 }} />
      </Button>
      <Button
        sx={{ padding: 0, marginLeft: 1, minWidth: 32, height: 32, borderRadius: '50%', pl: 0.25 }}
        onClick={handleNext}
        variant="outlined"
        disabled={(page === currentTotal - 1 || currentTotal === 1) && complete}>
        <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
      </Button>
    </Box>
  )
}
