import React, { useEffect } from 'react' // eslint-disable-line @typescript-eslint/no-unused-vars
import TopBarProgress from 'react-topbar-progress-indicator'

import colours from '../theme/colours'

// If needed in class-based components we can export this function from a helper file and then import it here. For now it's fine just in here.
const getLoadingBarConfig = () => {
  return {
    barColors: {
      0: colours.scooter,
      '1.0': colours.scooter
    },
    shadowBlur: 5
  }
}

function useTopLoadingBar() {
  useEffect(() => {
    TopBarProgress.config(getLoadingBarConfig())
  }, [])
}

export default useTopLoadingBar
