import { useTheme, Box, Typography, SxProps } from '@mui/material'
import { Insight } from '../../utils/clip'
import { InsightCount } from '../low-level/InsightCount/InsightCount'

interface InsightsCountProps {
  insights: Insight[]
  isActive?: boolean
  sx?: SxProps
}

export const InsightsCount = ({ insights, isActive = false, sx = {} }: InsightsCountProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 1,
        borderRadius: 1,
        backgroundColor: isActive ? theme.palette.primary[100] : theme.palette.grey[25],
        ...sx
      }}
    >
      <Typography sx={{ color: isActive ? theme.palette.grey[800] : theme.palette.grey[450] }}>Insights</Typography>
      <Box sx={{ display: 'flex' }} data-testid="insights-count">
        {insights.map((insight, index) => {
          return (
            <Box
              key={insight.type}
              sx={{ mr: index === insights.length - 1 ? 0 : 1 }}
              data-testid={insight.type.toLowerCase().replaceAll(' ', '-')}
            >
              <InsightCount isActive={isActive} insightType={insight.type} count={insight.count} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
