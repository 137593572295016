import { createSelector } from 'reselect'

import { getSegmentsFromTimeline } from '../../../utils'
import { State } from '../../'

import { VideoPlayerState } from './reducer'

const selectVideoPlayer = (state: State): VideoPlayerState => state.videoPlayer

const selectVideoPlayerClipDuration = createSelector(
  selectVideoPlayer,
  (videoPlayer: VideoPlayerState): number => videoPlayer.clipDuration || 0
)

const selectVideoPlayerClipOffset = createSelector(
  selectVideoPlayer,
  (videoPlayer: VideoPlayerState): number => videoPlayer.clipOffset || 0
)

const selectVideoPlayerTimeline = createSelector(
  selectVideoPlayer,
  (videoPlayer: VideoPlayerState): number[] => videoPlayer.timeline
)

const selectVideoPlayerSegments = createSelector(selectVideoPlayerTimeline, (timeline: any[]): number[] =>
  getSegmentsFromTimeline(timeline)
)

export { selectVideoPlayerClipDuration, selectVideoPlayerClipOffset, selectVideoPlayerSegments }
