export const getPlaylistTablePaginationStyles = (): StylesProps => ({
  playlistTablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    py: 1,
    px: 2,
    alignItems: 'center'
  },
  buttonActions: { display: 'flex', alignItems: 'center', width: 250, justifyContent: 'flex-end' }
})
