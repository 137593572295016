import { createUseStyles } from 'react-jss'

import { IExternalLink } from './types'
import colours from '../../theme/colours'

export const useExternalLinkStyles = createUseStyles({
  linkText: {
    color: ({ colour = colours.scooter }: IExternalLink.StyleProps) => colour,
    fontSize: ({ fontSize }: IExternalLink.StyleProps) => fontSize,
    lineHeight: 1.4,
    textDecoration: 'none'
  }
})
