import { AnyAction } from 'redux'

export const SIDENAV_OPEN_SET = 'sidenav/open/set'
export const SIDENAV_OPEN_TOGGLE = 'sidenav/open/toggle'
export const SIDENAV_ACTIVE_ITEM_SET = 'sidenav/activeItem/set'
export const SIDENAV_SECONDARY_ACTIVE_ITEM_SET = 'sidenav/activeItemSecondary/set'

export const setSideNavOpen = (isOpen: boolean): AnyAction => {
  return {
    type: SIDENAV_OPEN_SET,
    isOpen
  }
}

export const toggleSideNavOpen = (): AnyAction => {
  return {
    type: SIDENAV_OPEN_TOGGLE
  }
}

export const setSideNavActiveItem = (activeItem: string): AnyAction => {
  return {
    type: SIDENAV_ACTIVE_ITEM_SET,
    activeItem
  }
}

export const setSideNavSecondaryActiveItem = (activeItemSecondary: string): AnyAction => {
  return {
    type: SIDENAV_SECONDARY_ACTIVE_ITEM_SET,
    activeItemSecondary
  }
}
