import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { UserRole } from '../../types/Auth'
import { withForceLandscape } from '../hoc'
import Sidenav from '../Sidenav/Sidenav'

import { AdminRouteProps } from './AdminRoute.models'
import useAdminRouteStyles from './AdminRoute.styles'
import { useIsMobileDevice } from '../../hooks/useIsMobileDevice'
import { ThemeProvider } from '@mui/material'
import { defaultTheme } from '../../design-system/themes'
import { UoNWarning } from '../../components2.0/UoNWarning'
import { selectUserDetails } from '../../store/slices/user'
import { selectAccessToken } from '../../store/slices/auth'

const RawAdminRoute = ({ children, path, exact, ...rest }: AdminRouteProps): JSX.Element => {
  const accessToken = useSelector(selectAccessToken)
  const userDetails = useSelector(selectUserDetails)
  const classes = useAdminRouteStyles()

  const { isMobile } = useIsMobileDevice()

  const isAdminAccess = () => {
    if (accessToken && userDetails?.role === UserRole.ADMIN && !isMobile) {
      return true
    }
    return false
  }

  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={({ location }) =>
        isAdminAccess() ? (
          <>
            <Sidenav />
            <div className={classes.adminRouteChildrenContainer}>{children}</div>
            <ThemeProvider theme={defaultTheme}>
              <UoNWarning />
            </ThemeProvider>
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export const AdminRoute = withForceLandscape(RawAdminRoute)
