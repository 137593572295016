import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { getIndexFromCurrentTime, getSegmentsFromTimeline, getTimeFromIndex } from '../../utils'
import { selectQueueCurrentIndex, selectVideoPlayerSegments } from '../selectors'
import { addClipCorrectDuration } from '../slices/assets'
import { State } from '../'

import { doSetQueueCurrentIndex, setVideoPlayerTimeline } from './'

const doSetVideoCurrentTime = (time: number): ThunkAction<void, State, unknown, Action<any>> => {
  return (dispatch, getState) => {
    const segments = selectVideoPlayerSegments(getState())

    if (time === 0) {
      dispatch(doSetQueueCurrentIndex(0))
    }

    if (time > 0 && segments.length) {
      const index = getIndexFromCurrentTime(time, segments)
      dispatch(doSetQueueCurrentIndex(index))
    }
  }
}

const doSetVideoTimeline = (timeline: any[]): ThunkAction<void, State, unknown, Action<any>> => {
  return (dispatch, getState) => {
    if (!timeline) {
      return
    }

    // @ts-ignore

    // Split timeline of segments into an array of clips with each clip containing an array of its segments' durations
    let timelineClips: any[] = []
    timeline.forEach(segment => {
      if (segment.discontinuity) {
        timelineClips.push([segment])
      } else {
        timelineClips[timelineClips.length - 1].push(segment)
      }
    })

    // Reduce each clip's array of durations into one total duration value for each clip
    const clipDurations: number[] = timelineClips.map(clip =>
      clip.reduce((accumulator: number, segment: any) => accumulator + segment.duration, 0)
    )

    // If the search results are still clean (haven't had durations updated yet), update them with the more accurate durations

    dispatch(addClipCorrectDuration(clipDurations))

    const currentIndex = selectQueueCurrentIndex(getState())
    dispatch(setVideoPlayerTimeline(timeline))

    // When in clipView there is only one segment and the index will be 0, so can skip this part.
    const segments = getSegmentsFromTimeline(timeline)

    return segments.length > 1 ? getTimeFromIndex(currentIndex, segments) : 0
  }
}

export { doSetVideoTimeline, doSetVideoCurrentTime }
