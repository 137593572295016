export const wordAsPluralOrSingular = (count: number, singularWord: string) => {
  return `${count} ${singularWord}${count === 1 ? '' : 's'}`
}

export const formatCount = (count: number) => {
  if (count >= 1000000000) {
    return `${(count / 1000000000).toFixed(1).replace(/\.0$/, '')}B`
  }
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1).replace(/\.0$/, '')}M`
  }
  if (count >= 10000) {
    return `${(count / 1000).toFixed(1).replace(/\.0$/, '')}K`
  }
  return `${count}`
}
