import React, { useCallback, useLayoutEffect, useState } from 'react'
import { AuthLayout } from '../../../layouts/AuthLayout'
import colours from '../../../theme/colours'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useIsMobileDevice } from '../../../hooks/useIsMobileDevice'
import { Box } from '@mui/material'
import { Breakpoint } from '../../../design-system-legacy/breakpoints'
import { toResponsiveUnitFromPixels } from '../../../theme/toResponsiveUnitFromPixels'

const withForceLandscape = <P extends object>(Component: React.ComponentType<P>) => {
  const WrappedComponent = (props: P) => {
    const { height, width } = useWindowDimensions()
    const { isMobileOrTablet } = useIsMobileDevice()
    const [isLandscape, setIsLandscape] = useState(false)

    const checkIfPortrait = useCallback(() => {
      setIsLandscape(width > height || !isMobileOrTablet)
    }, [isMobileOrTablet, width, height])

    useLayoutEffect(() => {
      window.addEventListener('orientationchange', checkIfPortrait)
      checkIfPortrait()

      return () => window.removeEventListener('orientationchange', checkIfPortrait)
    }, [checkIfPortrait])

    // since this is just temporary I've used a style attribute.
    if (isLandscape) {
      return <Component {...props} />
    }
    return (
      <AuthLayout>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: `calc(100% - ${toResponsiveUnitFromPixels(90)})`,
            flexDirection: 'column',
            padding: 0,
            [Breakpoint.MOBILELANDSCAPE]: {
              height: '100vh'
            },
            [Breakpoint.SM]: {
              padding: toResponsiveUnitFromPixels(35)
            }
          }}
        >
          <h1 style={{ color: colours.white }}>Please use in landscape mode.</h1>
        </Box>
      </AuthLayout>
    )
  }

  return WrappedComponent
}

export { withForceLandscape }
