/* eslint-disable */ /* legacy */
import axios from 'axios'

import { store } from './store'

const axiosInstance = axios.create()
const { getState } = store

axiosInstance.interceptors.request.use(
  config => {
    if (!config.headers.common.hasOwnProperty('Authorization')) {
      config.headers.common.Authorization = `Bearer ${getState().authSlice.accessToken}`
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(response => {
  // Do something with response data

  return response
})

export type { AxiosResponse } from 'axios'

export default axiosInstance
