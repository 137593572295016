import { Theme } from '@mui/material'
import colours from '../../theme/colours'

export const getSearchStyles = (theme: Theme): StylesProps => ({
  pageContainer: {
    backgroundColor: colours.martinique,
    py: 10.5,
    [theme.breakpoints.down('lg')]: { py: 3 }
  },
  headerContainer: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 2,
      paddingRight: 2
    }
  },
  pageTitle: {
    ...theme.typography.head500,
    [theme.breakpoints.down('lg')]: { ...theme.typography.head400 }
  },
  mainHeading: {
    mb: 1,
    ...theme.typography.head900,
    [theme.breakpoints.down('lg')]: { ...theme.typography.head700 }
  },
  subHeading: {
    mb: 3,
    ...theme.typography.body2,
    [theme.breakpoints.down('lg')]: { ...theme.typography.body1 }
  },
  mainContentContainer: {
    mb: 5,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 2,
      paddingRight: 2,
      mb: 3
    }
  },
  tutorialHeading: {
    mt: 8,
    mb: 2,
    ...theme.typography.head800,
    [theme.breakpoints.down('lg')]: { ...theme.typography.head600, mt: 5 }
  },
  tutorialContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: { flexDirection: 'row-reverse' },
    [theme.breakpoints.down('md')]: { flexDirection: 'column-reverse' }
  },
  tutorialInfoContainer: {
    width: '48%',
    [theme.breakpoints.down('md')]: { width: '100%' }
  },
  tutorialInfo: {
    mb: 3,
    ...theme.typography.body2,
    [theme.breakpoints.down('lg')]: { ...theme.typography.body1 }
  },
  learnMoreButton: { [theme.breakpoints.down('md')]: { width: '100%', mb: 3 } },
  tutorialPlayerContainer: {
    width: '48%',
    [theme.breakpoints.down('md')]: { width: '100%', mb: 2 }
  }
})
