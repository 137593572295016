import { ReactNode } from 'react'
import { Box, useTheme } from '@mui/material'
import background from './login-background.svg'
import backgroundTablet from './login-tablet-background.svg'
import backgroundMobile from './login-mobile-background.svg'

interface LoginLayoutProps {
  children: ReactNode
}

const backgroundStyles = {
  backgroundSize: 'cover',
  WebkitBackgroundSize: 'cover',
  MozBackgroundSize: 'coveger',
  OBackgroundSize: 'cover'
}

export const LoginLayout = ({ children }: LoginLayoutProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.legacy.dark,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${background}) no-repeat center center fixed`,
        ...backgroundStyles,
        '@media only screen and (max-width: 1000px)': {
          background: `url(${backgroundTablet}) no-repeat center center fixed`,
          ...backgroundStyles,
          '@media only screen and (max-width: 600px)': {
            background: `url(${backgroundMobile}) no-repeat center center fixed`,
            ...backgroundStyles
          }
        }
      }}
    >
      {children}
    </Box>
  )
}
