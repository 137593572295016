import { createUseStyles } from 'react-jss'

const useConfirmableActionStyles = createUseStyles({
  content: {
    minWidth: 400,
    textAlign: 'center',
    fontSize: '0.9rem'
  },
  buttons: {
    margin: '2rem 0 0',
    display: 'flex',
    justifyContent: 'space-around'
  }
})

export default useConfirmableActionStyles
