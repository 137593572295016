import { useCallback, useState, ChangeEvent } from 'react'
import { Autocomplete, Popper, PopperProps, Chip } from '@mui/material'

import { Checkbox } from '../low-level/Checkbox/Checkbox'
import { StyledTextField } from '../low-level/TextField/StyledComponents'
import { useDebounce } from '../../hooks/useDebounce/useDebounce'

interface Props {
  tags?: boolean
  category: string
  name: string
  value: string[]
  options: string[]
  onSelect: (category: string, value: string[]) => void
  onGetOptions: (category: string, text?: string) => Promise<unknown>
  testid?: string
}

const ellipsisLetterCount = 25

const CustomPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      style={{
        width: 299,
        whiteSpace: 'nowrap'
      }}
      placement="bottom-start"
    />
  )
}

export const FilterAutocomplete = ({ tags, category, name, options, value, onSelect, onGetOptions, testid }: Props) => {
  const debounce = useDebounce()
  const [loading, setLoading] = useState(false)

  const getOptions = useCallback(
    (text?: string) => {
      setLoading(true)
      onGetOptions(category, text).then(() => setLoading(false))
    },
    [onGetOptions, category]
  )

  const handleChange = useCallback(
    (_: unknown, values: string[]) => {
      getOptions()
      onSelect(category, values)
    },
    [onSelect, getOptions, category]
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debounce(() => getOptions(event.target instanceof HTMLInputElement ? event.target.value : ''))
    },
    [debounce, getOptions]
  )

  const handleFocus = useCallback(() => {
    !options.length && getOptions()
  }, [getOptions, options])

  const handleBlur = useCallback(() => {
    getOptions()
  }, [getOptions])

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      id={category}
      value={value}
      loading={loading}
      options={options}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      filterOptions={_ => _}
      PopperComponent={tags ? undefined : CustomPopper}
      renderTags={(tag, getProps) => {
        if (!tags) return null
        return tag.map((option, index) => (
          <Chip {...getProps({ index })} label={option} color="primary" data-testid="selected-filter" />
        ))
      }}
      renderOption={(props, option, { selected }) => {
        const listStyle = tags ? { padding: '4px auto' } : { padding: '2px 4px' }
        const checkboxStyle = tags ? { marginRight: 8 } : { marginRight: 0, paddingLeft: 4 }
        return (
          <li {...props} style={listStyle}>
            <Checkbox style={{ padding: 0, ...checkboxStyle }} checked={selected} />
            {!tags && option.length > ellipsisLetterCount ? `${option.substring(0, ellipsisLetterCount)}...` : option}
          </li>
        )
      }}
      renderInput={params => {
        const sx = tags
          ? {
              '& .MuiOutlinedInput-root': {
                height: 'auto'
              }
            }
          : {
              minWidth: 150,
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: '4px 4px 7.5px 6px'
              }
            }
        const len = value?.length
        const placeholder = tags ? '' : `${len} ${name}${len < 2 ? '' : 's'}`

        return (
          <StyledTextField {...params} onChange={handleInputChange} placeholder={len ? placeholder : name} sx={sx} />
        )
      }}
      data-testid={testid}
    />
  )
}
