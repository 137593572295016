import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { S3BucketLogo } from './S3BucketLogo'

const integrationCards = [
  { title: 'S3 Bucket', description: 'Select and discover content from an AWS S3 bucket.', onClick: () => {} }
]

export const Integrations = () => {
  const theme = useTheme()

  return (
    <Box sx={{ padding: 8 }}>
      <Grid container spacing={3}>
        {integrationCards.map(({ title, description, onClick }) => (
          <Grid item sx={{ width: 315 }} key={title}>
            <Card
              sx={{
                boxShadow: 'none',
                border: `1px solid ${theme.palette.grey[150]}`,
                borderRadius: 2,
                '&:hover': {
                  backgroundColor: theme.palette.grey[25]
                }
              }}
            >
              <CardContent sx={{ paddingX: 3, paddingY: 3, '&:last-child': { paddingBottom: 3 } }}>
                <S3BucketLogo sx={{ fontSize: 72, mb: 3 }} />
                <Typography variant="head600" color={theme.palette.grey[800]} mb={3}>
                  {title}
                </Typography>
                <Typography mb={3}>{description}</Typography>
                <Button fullWidth variant="contained" onClick={onClick}>
                  Connect
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
