export const regexValidation = {
  containsOnlyLettersAndUnderscore: /^[a-zA-Z_]+$/,
  containsOnlyLettersAndCommas: /^[a-zA-Z,]+$/,
  containsOnlyLettersAndSemiColons: /^[a-zA-Z;]+$/,
  containsOnlyLettersNumbersHyphensInvertCommasSpacesAndSemiColons: /^[a-zA-Z0-9 '"”’;-]+$/,
  containsOnlyLettersSpacesNumbersAndSpecificSpecialChars: /^[a-zA-Z0-9 !"#$%&'()_*+,-./:`]+$/,
  containsOnlyLettersSpacesNumbersSemiColonAndSpecificSpecialChars: /^[a-zA-Z0-9 !"#$%&'”’()_*+,-./:;`]+$/,
  isValidUrl:
    // eslint-disable-next-line max-len
    /^((https?):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i
}

export enum RequiredHeadings {
  VIDEO_URL = 'VIDEO_URL',
  TITLE = 'TITLE',
  ASSET_START_DATE = 'ASSET_START_DATE',
  VOCABULARY_LIST_NAME = 'VOCABULARY_LIST_NAME',
  PRESENTER = 'PRESENTER'
}
