import { Box, useTheme } from '@mui/material'
import { AllFilters } from '../AllFilters'
import { FilterAutocomplete } from '../FilterAutocomplete'
import { SearchSlice } from '../../store/slices/search/types'
import { getFilterBarStyles } from './FilterBar.styles'

interface Props {
  filters: Record<string, SearchSlice.Filter>
  query: SearchSlice.Query
  onSelect: (k: string, v: string[]) => void
  onChange: (k: string, v?: string) => Promise<unknown>
}

export const FilterBar = ({ filters, query, onChange, onSelect }: Props) => {
  const theme = useTheme()
  const styles = getFilterBarStyles(theme)
  const primaryFilters = Object.values(filters).slice(0, 5)

  return (
    <Box sx={styles.filterBarContainer} data-testid="filters-container">
      <Box sx={styles.filtersContainer}>
        {primaryFilters.map(filter => (
          <FilterAutocomplete
            key={filter.key}
            category={filter.key}
            name={filter.text}
            options={filter.options}
            value={query.filter[filter.key]}
            onSelect={onSelect}
            onGetOptions={onChange}
            testid="filter-dropdown"
          />
        ))}
      </Box>
      <AllFilters onGetOptions={onChange} />
    </Box>
  )
}
