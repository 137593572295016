import { useState, useCallback, useEffect } from 'react'
import { faPause, faPlay, faVolumeMute, faVolumeUp, faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'rc-slider'

import { usePlayer } from '.'
import { useStyles } from './Player.styles'

export const isTouch: boolean =
  'ontouchstart' in window ||
  window.navigator.maxTouchPoints ||
  ((window as any).DocumentTouch && window.document instanceof (window as any).DocumentTouch) ||
  /iPhone/i.test(window.navigator.userAgent)

export const PlayerControls = () => {
  const { player, state } = usePlayer()
  const classes = useStyles()
  const [playerTime, setPlayerTime] = useState(0)

  const duration = player?.duration() || 0

  const setSeekTo = useCallback(
    (seek: number) => {
      player?.currentTime((seek / 10000) * duration)
    },
    [duration, player]
  )

  const setVolume = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      player?.volume(parseFloat(event.currentTarget.value))
    },
    [player]
  )

  const togglePlay = useCallback(() => {
    state === 'playing' ? player?.pause() : player?.play()
  }, [state, player])

  const [muted, setMuted] = useState(false)

  const toggleMuted = useCallback(() => {
    const value = !player?.muted()
    player?.muted(value)
    setMuted(value)
  }, [player])

  const toggleFullScreen = () => {
    return player?.isFullscreen() ? player?.exitFullWindow() : player?.requestFullscreen()
  }

  useEffect(() => {
    let mounted = true
    player?.on(['timeupdate', 'seeking'], () => {
      if (mounted) {
        setPlayerTime(player.currentTime())
      }
    })

    return () => {
      mounted = false
    }
  }, [player])
  useEffect(() => {
    const handleFullscreenChange = () => {
      document.fullscreenElement ? player?.controls(true) : player?.controls(false)
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [player])

  if (isTouch) {
    // The logic to determine a touch device is taken from: https://github.com/videojs/video.js/blob/main/src/js/tech/html5.js#L104
    // this allows us to hide our custom controls using the same logic that VideoJS will use for its `nativeControlsOnTouch` option
    return null
  }

  return (
    <div className={classes.playerControls}>
      <button onClick={togglePlay} className={classes.playPause}>
        <FontAwesomeIcon size="lg" icon={state === 'playing' ? faPause : faPlay} />
      </button>

      <Slider
        className="video-progress-slider"
        min={0}
        max={10000}
        value={Math.floor((playerTime / duration) * 10000)}
        onChange={setSeekTo}
      />
      <div className={classes.volumeContainer}>
        <button className={classes.volumeButton} onClick={toggleMuted}>
          <FontAwesomeIcon size="lg" icon={muted ? faVolumeMute : faVolumeUp} />
        </button>
        <div className={classes.volumeSliderWrapper}>
          <input
            className={classes.volumeSlider}
            type="range"
            id="vol"
            name="vol"
            min="0"
            max="1"
            step="0.1"
            defaultValue={player?.volume() || 0.5}
            onChange={setVolume}
          />
        </div>
      </div>
      <FontAwesomeIcon size="lg" className={classes.expandButton} icon={faExpand} onClick={toggleFullScreen} />
    </div>
  )
}
