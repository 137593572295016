import { createSelector } from 'reselect'

import { Playlist } from '../../../types'
import { State } from '../..'

import { PlaylistState } from './reducer'

const selectPlaylistState = (state: State): PlaylistState => state.playlist

const selectPlaylists = createSelector(
  selectPlaylistState,
  (state: PlaylistState): Playlist[] =>
    state.playlists.filter(playlist => !playlist.isShared)
)

const selectAllPlaylists = createSelector(
  selectPlaylistState,
  (state: PlaylistState): Playlist[] => state.playlists
)

const selectSharedPlaylists = createSelector(
  selectPlaylistState,
  (state: PlaylistState): Playlist[] => state.playlistsShared
)

const selectUserPlaylistsFetched = createSelector(
  selectPlaylistState,
  (state: PlaylistState): boolean => state.userPlaylistsFetched
)

const selectUserPlaylistsIsSharedFetched = createSelector(
  selectPlaylistState,
  (state: PlaylistState): boolean => state.userPlaylistsSharedFetched
)

export {
  selectPlaylists,
  selectAllPlaylists,
  selectUserPlaylistsFetched,
  selectSharedPlaylists,
  selectUserPlaylistsIsSharedFetched
}
