export enum ROUTES {
  LOGIN = '/login',
  RESET_PASSWORD_REQUESTED = '/password-reset-request',
  ROOT = '/',
  SEARCH = '/search',
  PLAYLISTS = '/playlists',
  PLAY = '/playlist/:id',
  CLIP = '/playlist/:playlistId/clip/:clipId',
  CLIP_EDIT = 'path="/playlist/:playlistId/clip/:clipId/edit',
  ASSET = '/asset/:assetId',
  ADMIN_MY_CONTENT = '/admin/myContent',
  ADMIN_USERMANAGER = '/admin/userManager',
  ADMIN_INTEGRATIONS = '/admin/integrations',
  // ADMIN_ZOOM_INTEGRATIONS = '/admin/integrations/zoom',
  ADMIN_MEDIA_UPLOAD = '/admin/internal-tools/media-upload/',
  ACCOUNT_NOT_VERIFIED = '/not-verified',
  RESET_PASSWORD_REQUESTED_CONFIRMED = '/password-reset-requested',
  SEARCH_RESULTS = '/search/search-results',
  SEARCH_RESULTS_PLAY_ALL = '/search/search-results/play-all',
  SEARCH_RESULTS_PLAY_FULL_VIDEO = '/search/search-results/play-full-video/:assetId',
  SEARCH_RESULTS_PLAY_ALL_PLAY_FULL_VIDEO = '/search/search-results/play-all/play-full-video/:assetId',
  PLAYLISTS_VIEW_PLAYLIST_PLAY_FULL_VIDEO = '/playlists/view-playlist/play-full-video/:assetId',
  PLAY_FULL_VIDEO = '/play-full-video/:assetId',
  PLAYLIST_VIEW_PLAYLIST = '/playlists/view-playlist/:playlistId',
  SEARCH_RESULTS_EDIT_CLIP = '/search/search-results/edit-clip/:temporaryClipId',
  PLAY_ALL_EDIT_CLIP = '/search/search-results/play-all/edit-clip/:temporaryClipId',
  VIEW_PLAYLIST_EDIT_CLIP = '/playlists/view-playlist/:playlistId/edit-clip/:clipId',
  OAUTH_CALLBACK = '/oauth/callback',
  SUPPORT = '/support'
}
