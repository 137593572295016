import { createApi } from '@reduxjs/toolkit/query/react'
import { createQueryWithReauth } from '../authenticated-query'
import { UserApi } from './types'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: createQueryWithReauth(process.env.REACT_APP_LVS_API),
  endpoints: builder => ({
    getUserDetails: builder.query<UserApi.UserDetails, number>({
      query: userId => `/v2/iam/user/${userId}`
    }),
    getTenantApiKeys: builder.query<UserApi.TenantResponse, string>({
      query: tenantId => `v2/iam/tenant/${tenantId}`
    }),
    signOut: builder.query<void, void>({
      query: () => ({
        url: '/v2/iam/auth/signout',
        method: 'POST'
      })
    })
  })
})
