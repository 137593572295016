import { createApi } from '@reduxjs/toolkit/query/react'
import { createQueryWithReauth } from '../authenticated-query'
import { LVSApi } from './types'

import { createLuceneString, stringify } from '../../slices/search/helpers'
import { parseClip } from '../../../utils/clip'
import { getSpellingSuggestions, loadDictionary } from '../../../utils/spellcheck'

import {
  selectSearchQuery,
  selectSearchResults,
  selectSearchSortDesc,
  selectSearchSortMode,
  selectSearchTerm
} from '../../slices/search'

export const lvsApi = createApi({
  reducerPath: 'lvsApi',
  baseQuery: createQueryWithReauth(process.env.REACT_APP_LVS_API),
  tagTypes: ['payloadCategories'],
  endpoints: ({ query }) => ({
    getPayloadCategories: query<LVSApi.Aggregation, string>({
      query: () => '/v3/reporting/clip/agg?aggregation=PAYLOAD_CATEGORY',
      providesTags: ['payloadCategories']
    }),
    getPayloadValues: query<LVSApi.Aggregation, { category: string; value: string }>({
      query: ({ category, value }) => {
        const q = value ? stringify(category, [`${value}*`]) : `(payload.category:${category})`
        return `/v3/reporting/clip/agg?aggregation=PAYLOAD_VALUE&query=${q}&size=100`
      }
    }),
    getAsset: query<LVSApi.Asset, number>({
      query: assetId => `v3/asset/${assetId}`
    }),
    getAssetThumbnail: query<string, number>({
      query: assetId => ({
        url: `/v2/assembly/thumbnail/${assetId}.jpg`,
        responseHandler: response => response.blob().then(blob => URL.createObjectURL(blob))
      })
    }),
    getAssetMetadata: query<LVSApi.AssetMetadata, number[]>({
      queryFn: async (assetIds, ___, __, baseQuery) => {
        if (!assetIds.length) {
          return { data: {} }
        }

        let page = 0
        const assetMetadata: LVSApi.AssetMetadata = {}
        const assetTerms = assetIds.map(assetId => `assetId:${assetId}`).join(' OR ')
        const metadataTerms = [
          'payload.indexerType:ASSET_KEYWORDS',
          'payload.indexerType:FromJson',
          'payload.category:ASSET_START_DATE'
        ]

        const queryString = [
          `(${assetTerms}) AND `,
          `((${metadataTerms.join(' OR ')}) `,
          'NOT payload.category:TITLE)'
        ].join('')
        const path = '/v3/search/grouped'

        const fetchResults = async () => {
          const params = new URLSearchParams({
            query: queryString,
            page: String(page),
            groupMode: 'Asset'
          })

          const { data } = (await baseQuery(`${path}?${params}`)) as { data: LVSApi.SearchResultsGrouped }

          data.content.forEach(({ key, group }) => {
            const tags: string[] = []
            let startDate = ''

            group.forEach(clip => {
              clip.payload.forEach(payload => {
                if (['ASSET_KEYWORDS', 'FromJson'].includes(payload.indexerType)) {
                  tags.push(payload.value)
                }
                if (payload.category === 'ASSET_START_DATE') {
                  startDate = payload.value
                }
              })
            })

            assetMetadata[key] = {
              tags: Array.from(new Set(tags)),
              startDate
            }
          })

          if (Object.keys(assetMetadata).length !== assetIds.length && !data.last) {
            page += 1
            await fetchResults()
          }
        }

        try {
          await fetchResults()
          return { data: assetMetadata }
        } catch (e) {
          return {
            error: {
              status: 400,
              data: (e as Error).message
            }
          }
        }
      }
    }),
    getAssetTranscript: query<LVSApi.AssetTranscriptData, number>({
      queryFn: async (assetId, ___, __, baseQuery) => {
        const path = '/v3/search'
        const assetRespose = await baseQuery(`v3/asset/${assetId}`)
        const asset = assetRespose.data as LVSApi.Asset

        const getParams = (pageNumber: number) =>
          new URLSearchParams({
            query: `(assetId:${assetId} AND startTime:[0 TO ${asset.duration * 1000}] AND payload.category:TRANSCRIPT)`,
            sortDesc: String(false),
            pageSize: String(100),
            sortMode: 'StartTime',
            page: String(pageNumber)
          })

        const initialResponse = (await baseQuery(`${path}?${getParams(0)}`)) as {
          data: LVSApi.AssetTranscriptResponse
        }

        const allPageAssetTranscriptContentItems = [] as LVSApi.AssetTranscriptContentItem[]

        for (let i = 0; i < initialResponse.data.totalPages; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const pageResponse = (await baseQuery(`${path}?${getParams(i)}`)) as {
            data: LVSApi.AssetTranscriptResponse
          }

          allPageAssetTranscriptContentItems.push(...pageResponse.data.content)
        }

        // Transcript items overlap. Hence the need to replace the endTime with
        // start time of next item.
        const assetTranscript = allPageAssetTranscriptContentItems.map((item, index) => {
          if (index === allPageAssetTranscriptContentItems.length - 1) return { ...item.payload[0] }
          return {
            ...item.payload[0],
            endTime: allPageAssetTranscriptContentItems[index + 1].payload[0].startTime - 0.01
          }
        }) as LVSApi.AssetTranscriptItem[]

        return { data: { asset, assetTranscript } }
      }
    }),
    getBufferedSearch: query<LVSApi.BufferedResults, boolean>({
      queryFn: async (initialRequest, { getState }, __, baseQuery) => {
        const path = '/v3/search/advanced'
        const store = getState() as any
        const pageSize = 16

        const prevData = initialRequest ? ({} as LVSApi.BufferedResults) : selectSearchResults(store)
        const { pages = [], uniqueResults = [] } = prevData
        let { cursor = 0, count = 0, complete = false } = prevData
        let totalResults = 0
        if (complete === true) {
          return {
            error: {
              status: 400,
              data: 'No more results'
            }
          }
        }

        const searchQuery = selectSearchQuery(store)
        const searchTerm = selectSearchTerm(store)
        const sortDesc = selectSearchSortDesc(store)
        const sortMode = selectSearchSortMode(store)

        const allClips = [...pages]
        const uniqueClips = [...uniqueResults]
        const newResults: LVSApi.BufferedResults['pages'][number] = []

        if (!searchQuery.category?.length) {
          // if there are no categories (no content ingested yet)
          // dont make a request as it will fail, just treat as no results
          return {
            data: {
              pages: [],
              cursor: 1,
              uniqueResults: [],
              count: 0,
              complete: true,
              totalResults: 0,
              spellingSuggestions: null
            }
          }
        }

        if ((count / pageSize) % 2) {
          // we need to finish filling the last page before creating more
          newResults.push(...(allClips.pop() || []))
        }

        const luceneString = createLuceneString({ ...searchQuery, value: searchTerm })

        const fetchResults = async () => {
          const params = new URLSearchParams({
            query: luceneString,
            page: String(cursor),
            sortDesc: String(sortDesc),
            pageSize: String(pageSize),
            sortMode
          })

          const result = (await baseQuery(`${path}?${params}`)) as { data: LVSApi.SearchResults }

          cursor += 1
          complete = result.data.last
          totalResults = result.data.totalElements

          const parseOpts = {
            searchTerm: searchQuery.value,
            exactMatch: searchQuery.exact,
            searchCategories: searchQuery.category
          }

          result.data.content.forEach(clip => {
            const parsedClip = parseClip(clip, parseOpts)

            if (!parsedClip.insights.length) {
              return
            }

            const matchesString = `${parsedClip.assetId}-${parsedClip.duration}-${parsedClip.startTime}`
            if (uniqueClips.includes(matchesString)) {
              return
            }
            uniqueClips.push(matchesString)
            newResults.push(parsedClip)
          })

          if (newResults.length < pageSize * 2 && !complete) {
            await fetchResults()
          }
        }

        try {
          await fetchResults()

          count += newResults.length

          while (newResults.length) {
            allClips.push(newResults.splice(0, pageSize))
          }

          const spellingSuggestions = await loadDictionary().then(() => getSpellingSuggestions(searchTerm))

          return {
            data: {
              pages: allClips,
              cursor,
              uniqueResults: uniqueClips,
              count,
              complete,
              totalResults,
              spellingSuggestions
            }
          }
        } catch (e) {
          return {
            error: {
              status: 400,
              data: (e as Error).message
            }
          }
        }
      }
    })
  })
})

export const {
  useGetAssetQuery,
  useGetAssetTranscriptQuery,
  useGetAssetMetadataQuery,
  useGetAssetThumbnailQuery
} = lvsApi
