import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { lvsApi } from '../../apis/lvs'
import { SearchSlice } from './types'
import { categoryMapping } from './constants'

export const name = 'searchResults'

const initialState: SearchSlice.State = {
  categories: [],
  filters: {},
  sortDesc: true,
  sortMode: 'Relevance',
  grouped: false,
  data: {
    pages: [],
    count: 0,
    cursor: 0,
    uniqueResults: [],
    complete: false,
    spellingSuggestions: null,
    totalResults: 0
  },
  query: {
    value: '',
    category: [],
    exact: false,
    filter: {},
    dateRange: null
  }
}

const getInitialCategoryQuery = (state: SearchSlice.State) => {
  return state.categories.reduce((accum, category) => {
    if (!category.disabled && (!state.query.category.length || state.query.category.includes(category.key))) {
      return [...accum, category.key]
    }
    return accum
  }, [] as string[])
}

export const search = createSlice({
  name,
  initialState,
  reducers: {
    setSearchTerm(state, action: PayloadAction<string>) {
      state.query.value = action.payload
    },
    setSearchCategory(state, action: PayloadAction<string[]>) {
      state.query.category = action.payload
    },
    setSearchFilter(state, action: PayloadAction<[string, string[]]>) {
      const [category, values] = action.payload

      state.query.filter = {
        ...state.query.filter,
        [category]: values
      }
    },
    clearSearchFilters(state) {
      state.query.filter = Object.keys(state.filters).reduce((accum, key) => {
        return { ...accum, [key]: [] }
      }, {})
    },
    setSearchDateRange(state, action: PayloadAction<SearchSlice.DateRange | null>) {
      state.query.dateRange = action.payload
    },
    setSearchExact(state, action: PayloadAction<boolean>) {
      state.query.exact = action.payload
    },
    clearSearchResults(state) {
      state.data = initialState.data
    },
    clearSearchQuery(state) {
      state.query = { ...initialState.query }
      state.query.category = getInitialCategoryQuery(state)
    }
  },
  extraReducers: builder => {
    builder.addMatcher(lvsApi.endpoints.getPayloadValues.matchFulfilled, (state, action) => {
      const { category } = action.meta.arg.originalArgs
      state.filters[category].options = action.payload.map(({ key }) => key)
    })
    builder.addMatcher(lvsApi.endpoints.getBufferedSearch.matchFulfilled, (state, action) => {
      // We save a copy of the most recent buffered search results here because...
      // 1. Historically we rely on results being in local storage so a page refresh doesnt get rid of them
      // 2. there seems to be an issue with RTK Query whereby as soon as the component unmounts / unsubscribes
      //    the data is thrown away, without waiting for the timeout (60 secs)
      // TODO: Remove this neccessity as we dont want to be saving potentially huge amounts of results in local storage
      //       Also investigate why we lose query results so soon, or move query subscription up a level to avoid unmount
      state.data = action.payload
    })
    builder.addMatcher(lvsApi.endpoints.getPayloadCategories.matchFulfilled, (state, action) => {
      const tenantId = action.meta.arg.originalArgs
      state.categories = []
      state.filters = {}

      action.payload.forEach(category => {
        // loop through all categories and map to the relevant category or filter field
        const { key } = category
        const mapping = categoryMapping[key]

        if (mapping && mapping.exclude) return

        const text = mapping?.text || key.charAt(0) + key.slice(1).replace('_', ' ').toLowerCase()
        const disabled = Array.isArray(mapping?.disabled) ? mapping.disabled.includes(tenantId) : !!mapping?.disabled

        if (mapping?.primary) {
          state.categories.push({
            ...category,
            disabled,
            text
          })
          return
        }

        state.filters[key] = { ...category, disabled, text, options: [] }
        state.query.filter[key] = []
      })

      state.query.category = getInitialCategoryQuery(state)
    })
  }
})

export const selectSearchSlice = (state: { search: SearchSlice.State }): SearchSlice.State => state[name]

export const selectSearchQuery = createSelector(selectSearchSlice, state => state.query)
export const selectSearchTerm = createSelector(selectSearchSlice, state => {
  return Array.isArray(state.query.value) ? state.query.value.join() : state.query.value
})
export const selectCategories = createSelector(selectSearchSlice, state => state.categories)
export const selectFilters = createSelector(selectSearchSlice, state => state.filters)
export const selectSearchSortDesc = createSelector(selectSearchSlice, state => state.sortDesc)
export const selectSearchSortMode = createSelector(selectSearchSlice, state => state.sortMode)
export const selectSearchResults = createSelector(selectSearchSlice, state => state.data || initialState.data)

export const {
  setSearchTerm,
  setSearchCategory,
  setSearchDateRange,
  setSearchFilter,
  setSearchExact,
  clearSearchFilters,
  clearSearchResults,
  clearSearchQuery
} = search.actions
