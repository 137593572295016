import { createSelector } from 'reselect'

import { Video } from '../../../types'
import { State } from '../../'

import { IndividualAssetsState } from './reducer'

const selectIndividualAssetsState = (state: State): IndividualAssetsState =>
  state.individualAsset

const selectIndividualAsset = createSelector(
  [selectIndividualAssetsState],
  (state: IndividualAssetsState): Video | null => state.item
)

export { selectIndividualAsset }
