import { useTheme, Box, Typography } from '@mui/material'
import Highlighter from 'react-highlight-words'

import { useHighlights } from '../../hooks/highlights/useHighlights'
import { Insight } from '../../utils/clip'
import { getMinIntegerDigitString } from '../../utils/stringFunctions'
import { getInsightIcon, insightIconMap } from '../low-level/InsightCount/InsightCount'
import { HighlightedWords } from '../HighlightedWords'

interface PlayerQueueClipInsightProps {
  insight: Insight
}

export const PlayerQueueClipInsight = ({ insight }: PlayerQueueClipInsightProps) => {
  const theme = useTheme()
  const hightlights = useHighlights(insight?.additionalProperties?.highlights || [], insight.value)

  return (
    <>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          pb: 0.5,
          borderBottom: `1px solid ${theme.palette.grey[150]}`
        }}>
        {getInsightIcon(insight.type, insightIconMap, theme, true)}
        <Typography sx={{ fontWeight: 700, color: theme.palette.grey[800], ml: 1 }}>
          {insight.type} ({getMinIntegerDigitString(insight.count, 2)})
        </Typography>
      </Box>

      <Typography
        sx={{ mt: 1, ...theme.typography.body2, [theme.breakpoints.down('lg')]: { ...theme.typography.body1 } }}
        color={theme.palette.grey[800]}>
        <Highlighter
          highlightTag={HighlightedWords}
          autoEscape
          textToHighlight={insight.value}
          searchWords={hightlights}
        />
      </Typography>
    </>
  )
}
