import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import assets from './slices/assets'
import auth from './slices/auth'
import individualAsset from './slices/individualAsset'
import playlist from './slices/playlists'
import playlistsModal from './slices/playlistsModal'
import queue from './slices/queue'
import sideNav from './slices/sideNav/index'
import videoPlayer from './slices/videoPlayer'

import { search } from '../store/slices/search'
import { lvsApi } from '../store/apis/lvs'
import { authApi } from '../store/apis/auth'
import { user } from '../store/slices/user'
import { authSlice } from '../store/slices/auth'
import { middlewareApi } from '../store/apis/middleware'
import { clearStore } from '../store/slices/auth'
import { userApi } from '../store/apis/user'

const persistConfig = {
  key: 'root',
  storage,
  // TODO: exclude search results as they could get very large
  whitelist: ['auth', 'integrations', authSlice.name, search.name, user.name, 'subscription', 'queue', 'playlist']
}

const appReducer = combineReducers({
  auth,
  playlist,
  playlistsModal,
  assets,
  individualAsset,
  queue,
  videoPlayer,
  sideNav,
  [search.name]: search.reducer,
  [user.name]: user.reducer,
  [authSlice.name]: authSlice.reducer,
  [lvsApi.reducerPath]: lvsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [middlewareApi.reducerPath]: middlewareApi.reducer
})

const rootReducer = (state: any, action: any) => {
  if (action.type === clearStore.type) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export type State = ReturnType<typeof appReducer>

export default persistReducer(persistConfig, rootReducer)
