import { createTheme } from '@mui/material'
import { defaultBreakpoints } from './breakpoints'
import { defaultComponents, skyComponents } from './components'
import { defaultPalette, skyPalette } from './palettes'
import { defaultTypography, typography } from './typography'

// New version of theme to be used on new designs
export const defaultTheme = createTheme({
  typography: defaultTypography,
  palette: defaultPalette,
  components: defaultComponents,
  breakpoints: defaultBreakpoints
})

// Original version of theme used on current designs
export const skyTheme = createTheme({
  typography,
  palette: skyPalette,
  components: skyComponents
})
