import { SvgIcon, SvgIconProps } from '@mui/material'

export const S3BucketLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 72 86" {...props}>
      <svg width="72" height="86" viewBox="0 0 72 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.5888 16.5918L49.6262 43.0717L63.5888 69.5516L69.3084 66.3673V19.7761L63.5888 16.5918Z"
          fill="#E25444"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.5888 16.5918L35.6636 19.7761L21.4486 43.0717L35.6636 66.3673L63.5888 69.5516V16.5918Z"
          fill="#7B1D13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.23364 16.5918L2.69159 18.6029V67.5404L7.23364 69.5516L35.6635 43.0717L7.23364 16.5918Z"
          fill="#58150D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.17258 16.536L35.7012 24.4315V62.4194L7.17258 69.5702V16.536Z"
          fill="#E25444"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7162 28.6028L23.6027 26.6661L35.7162 12.9606L47.7998 26.6661L35.7162 28.6028Z"
          fill="#58150D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.7998 26.6661L35.7012 28.6325L23.6027 26.6661V12.9606"
          fill="#58150D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7162 57.3544L23.6027 59.5891L35.7162 71.3579L47.7998 59.5891L35.7162 57.3544Z"
          fill="#58150D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6635 2.68152L23.5514 9.0501V26.6475L35.7012 23.2398L35.6635 2.68152Z"
          fill="#7B1D13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7012 32.9229L23.6027 34.2339V51.8042L35.7012 53.1831V32.9229Z"
          fill="#7B1D13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7012 62.5685L23.6027 59.5463V76.819L35.7012 83.1268V62.5685Z"
          fill="#7B1D13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.7998 59.5463L35.7012 62.5693V83.1268L47.7998 76.819V59.5463Z"
          fill="#E25444"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7012 32.9229L47.7998 34.2339V51.8042L35.7012 53.1831V32.9229Z"
          fill="#E25444"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6635 2.68152L47.7757 9.0501V26.6475L35.6635 23.2956V2.68152Z"
          fill="#E25444"
        />
      </svg>
    </SvgIcon>
  )
}
