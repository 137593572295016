import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Skeleton,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

import { PlaylistCardSkeleton } from './PlaylistCardSkeleton'
import { getPlaylistCardStyles } from './PlaylistCard.styles'
import { formatTime } from '../../utils'
import { useGetAssetThumbnailQuery } from '../../store/apis/lvs'
import { VIDEO_POSTER_DEFAULT } from '../../constants'
import { wordAsPluralOrSingular } from '../../utils/count'

export interface PlaylistCardProps {
  sx?: SxProps
  assetId?: string
  isLoading?: boolean
  title: string
  modifiedOnDate: string
  duration: number
  playOnClick: () => void
  numberOfClips: number
}

export const PlaylistCard = ({
  assetId,
  isLoading,
  title,
  modifiedOnDate,
  duration,
  numberOfClips,
  playOnClick,
  sx = {}
}: PlaylistCardProps) => {
  const theme = useTheme()
  const styles = getPlaylistCardStyles(theme)
  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))
  const { data: imageUrl, isLoading: isThumbnailLoading, isError: isThumbnailError } = useGetAssetThumbnailQuery(
    Number(assetId),
    { skip: !assetId }
  )

  if (isLoading) return <PlaylistCardSkeleton />

  return (
    <Card sx={{ ...styles.card, ...sx } as SxProps}>
      <CardActionArea onClick={playOnClick} sx={{ position: 'relative' }}>
        {isThumbnailLoading ? (
          <Skeleton
            variant="text"
            width="auto"
            sx={{ backgroundColor: theme.palette.grey[250], aspectRatio: '16/9' }}
          />
        ) : (
          <CardMedia
            sx={{ aspectRatio: '16/9' }}
            component="img"
            image={isThumbnailError || !imageUrl ? VIDEO_POSTER_DEFAULT : imageUrl}
            alt="clip thumbnail"
            data-testid="playlist-thumbnail"
          />
        )}
        <PlayCircleOutlineIcon sx={styles.playIcon} />
        <Chip sx={styles.duration} label={formatTime(duration)} data-testid="playlist-duration" />
      </CardActionArea>

      <CardContent sx={styles.cardContent}>
        <Typography
          mb={2}
          variant={isBelowLgBreakpoint ? 'body50' : 'body1'}
          color={theme.palette.grey[400]}
          data-testid="clip-recorded-date"
        >
          Modified on {modifiedOnDate}
        </Typography>

        <Typography
          sx={styles.title}
          mb={0.5}
          variant={isBelowLgBreakpoint ? 'head500' : 'head600'}
          color={theme.palette.grey[800]}
          data-testid="playlist-title"
        >
          {title}
        </Typography>

        <Typography
          variant={isBelowLgBreakpoint ? 'body1' : 'body2'}
          color={theme.palette.grey[500]}
          data-testid="playlist-card-clip-count"
        >
          {wordAsPluralOrSingular(numberOfClips, 'video clip')}
        </Typography>
      </CardContent>
    </Card>
  )
}
