import classnames from 'classnames'
import useTablesStyles from './TableHeader.styles'
import { ITable } from '../types'

export const TableHeader = ({
  headers,
  direction = 'row',
  divider,
  checkbox,
  handleSelectAll,
  isChecked = false,
  gridTemplateColumns = `repeat(${headers.length}, 1fr)`,
  className = ''
}: ITable.HeaderProps) => {
  const classes = useTablesStyles({ direction, divider, gridTemplateColumns })

  return (
    <tr data-testid="divider" className={classnames(classes.headerContent, className)}>
      {checkbox && (
        <th>
          <input className={classes.headerCheckbox} type="checkbox" onChange={handleSelectAll} checked={isChecked} />
        </th>
      )}
      {headers.map((header, index) => (
        <th key={index} style={{ width: header === 'Videos' ? '30px' : '' }} className={classes.header}>
          {header}
        </th>
      ))}
    </tr>
  )
}
