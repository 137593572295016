import { createUseStyles } from 'react-jss'
import { Breakpoint } from '../../design-system-legacy/breakpoints'
import colours from '../../theme/colours'
import { IModal } from './types'

const modalType = () => {
  return {
    defaultModalBackground: {
      position: 'fixed',
      zIndex: 1900,
      width: '100%',
      height: '100vh',
      backgroundColor: colours.martinique,
      opacity: 0.9,
      top: 0,
      left: 0
    },
    defaultModalContent: {
      backgroundColor: colours.alabaster,
      borderRadius: 10,
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '600px',
      height: 'auto',
      padding: 40,
      zIndex: 2000,
      [Breakpoint.MD]: {
        width: () => '100vw'
      }
    },
    mobileModalBackground: {
      top: 0,
      position: 'fixed',
      height: '100vh',
      width: '100%',
      backgroundColor: colours.dune,
      opacity: 0.5,
      zIndex: 1002
    },
    mobileModalContent: {
      height: 'auto',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      zIndex: 1003
    },
    mobileBottomModalBackground: {
      top: 0,
      position: 'fixed',
      height: '100vh',
      width: '100%',
      backgroundColor: colours.dune,
      opacity: 0.5,
      zIndex: 1002
    },
    mobileBottomModalContent: {
      height: 'auto',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: 1003
    }
  }
}

const useModalStyles = createUseStyles({
  rootBackground: (props: Partial<IModal.StyleProps>) => ({
    ...(props.modalType && modalType()[`${props.modalType}ModalBackground`])
  }),
  rootContent: (props: Partial<IModal.StyleProps>) => ({
    ...(props.modalType && modalType()[`${props.modalType}ModalContent`])
  })
})

export default useModalStyles
