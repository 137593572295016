import { AnyAction } from 'redux'

import { Video } from '../../../types'

import {
  INDIVIDUAL_ASSET_ITEM_RESET,
  INDIVIDUAL_ASSET_ITEM_SET
} from './actions'

export interface IndividualAssetsState {
  item: Video | null
}

const initialState: IndividualAssetsState = {
  item: null
}

const reducer = (
  state: IndividualAssetsState = initialState,
  action: AnyAction
): IndividualAssetsState => {
  switch (action.type) {
    case INDIVIDUAL_ASSET_ITEM_SET:
      return {
        ...state,
        item: action.payload
      }
    case INDIVIDUAL_ASSET_ITEM_RESET:
      return {
        ...state,
        item: null
      }
    default:
      return state
  }
}

export default reducer
export { initialState }
