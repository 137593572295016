import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { notify } from 'react-notify-toast'
import { Box } from '@mui/material'
import { AxiosError } from 'axios'
import { AuthLayout } from '../../../layouts/AuthLayout'
import { PasswordResetHeader } from '../PasswordResetHeader/PasswordResetHeader'
import { useRoutes } from '../../../hooks/useRoutes'
import { Button } from '../../../components/Button'
import { lvsService } from '../../../services/lvs'
import { toResponsiveUnitFromPixels } from '../../../theme/toResponsiveUnitFromPixels'
import { Breakpoint } from '../../../design-system-legacy/breakpoints'

export const NewPasswordLinkSent = ({ heading, subHeading, isNotVerified, isResendVerifcation }) => {
  const location: any = useLocation()
  const userName = location.search.replace('?u=', '')
  const { setRoute, LOGIN, RESET_PASSWORD_REQUESTED } = useRoutes()
  useEffect(() => {
    if (!userName && isNotVerified) {
      setRoute(LOGIN)
    }
  }, [userName, isNotVerified, setRoute, LOGIN])

  const handleResend = () => {
    lvsService.verificationResend
      .get(userName)
      .then(() => {
        notify.show('Verification email sent', 'success')
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.message === 'User is already confirmed.') {
          notify.show(`${error.response?.data.message}`, 'error')
        } else {
          notify.show('Something went wrong', 'error')
        }
      })
  }

  const handleOnClick = () => {
    if (isResendVerifcation) return handleResend()
    return setRoute(RESET_PASSWORD_REQUESTED)
  }

  return (
    <AuthLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `calc(100% - ${toResponsiveUnitFromPixels(90)})`,
          flexDirection: 'column',
          padding: 0,
          [Breakpoint.MOBILELANDSCAPE]: {
            height: '100vh'
          },
          [Breakpoint.SM]: {
            padding: toResponsiveUnitFromPixels(35)
          }
        }}
      >
        <PasswordResetHeader heading={heading} subheading={subHeading} />

        <Button variant="solid" color="primary" style={{ marginTop: 30 }} onClick={handleOnClick}>
          {isResendVerifcation ? 'Resend Verification email' : 'Reset password'}
        </Button>
      </Box>
    </AuthLayout>
  )
}
