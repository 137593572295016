import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import { Integrations } from '../Integrations/Integrations'
import { AdminConsoleUserManager } from '../../components/AdminConsoleUserManager/AdminConsoleUserManager'
import { doGetPaginatedUserList, doGetUserList } from '../../redux/actions/auth'
import { setSideNavActiveItem } from '../../redux/slices/sideNav'
import useAdminConsoleStyles from './AdminConsole.styles'
import { IAdminConsole } from './types'
import { useRoutes } from '../../hooks/useRoutes'
import MyContentPage from '../../components/Integrations/MyContent/MyContentPage'
import { INavOption } from '../../components/NavOption/types'
import { SecondaryNavPanel } from '../../components/SecondaryNavPanel'
import { defaultTheme } from '../../design-system/themes'

export const AdminConsole = ({ isUserManagerPage, isMyContentPage, isIntegrationsPage }: IAdminConsole.Props) => {
  const dispatch = useDispatch()
  const classes = useAdminConsoleStyles()

  const { setRoute, ADMIN_USERMANAGER, ADMIN_MY_CONTENT } = useRoutes()

  const adminConsoleNavOptions: INavOption.Props[] = [
    {
      text: 'User Manager',
      isActive: isUserManagerPage,
      buttonClick: () => setRoute(ADMIN_USERMANAGER)
    },
    // {
    //   text: 'Integrations',
    //   isActive: isIntegrationsPage,
    //   buttonClick: () => setRoute(ADMIN_INTEGRATIONS)
    // },
    {
      text: 'My Content',
      isActive: isMyContentPage,
      buttonClick: () => setRoute(ADMIN_MY_CONTENT)
    }
  ]

  useEffect(() => {
    dispatch(doGetUserList())
    dispatch(doGetPaginatedUserList(0, ''))
    dispatch(setSideNavActiveItem('AdminConsole'))
  }, [dispatch])

  return (
    <div className={classes.adminConsoleContainer}>
      <SecondaryNavPanel title="Admin Console" navOptions={adminConsoleNavOptions} />
      <div className={classes.flexContainer}>
        <div className={classes.adminConsoleMainContentContainer}>
          {isUserManagerPage && <AdminConsoleUserManager />}
          <ThemeProvider theme={defaultTheme}>{isIntegrationsPage && <Integrations />}</ThemeProvider>
          {isMyContentPage && <MyContentPage />}
        </div>
      </div>
    </div>
  )
}
