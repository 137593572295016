import { notify } from 'react-notify-toast'
import { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import axiosInstance from '../../axios'
import { IAdduserResponse, IUserBillingData, IUserListData, UserRole } from '../../types/Auth'
import { getUserBillingInfo, getUserList, getPaginatedUserList } from '../../utils/auth'
import { setUserBillingInfo, setUserList, setPaginatedUserList } from '../slices/auth'

import { selectApiKey, selectUserDetails } from '../../store/slices/user'

export const addNewUser = (newUserEmail: string, tenantId: string | undefined) => {
  const email = newUserEmail.toLowerCase()
  const addUserResponse = axiosInstance.post<IAdduserResponse>(`${process.env.REACT_APP_LVS_API}/v2/iam/user`, {
    userName: `${email.replace('@', '_at_')}`,
    email,
    password: 'Ch@ngeMe!',
    role: UserRole.CONSUMER,
    tenantId: tenantId,
    platformName: 'WHIZZARDMEET'
  })
  return addUserResponse
}

export const doDeleteUser = (userId: number | null) => {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    axiosInstance
      .delete(`${process.env.REACT_APP_LVS_API}/v2/iam/user/${userId}`)
      .then(response => {
        notify.show('User was successfully removed', 'success')
        dispatch(doGetPaginatedUserList(0, ''))
      })
      .catch(error => {
        notify.show('Something went wrong', 'error')
      })
  }
}

export const doGetUserBillingInfo = () => {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const apiKey = selectApiKey(getState())
    const userFreeTrialInfo: AxiosResponse<IUserBillingData> = await getUserBillingInfo(apiKey || '')
    dispatch(setUserBillingInfo(userFreeTrialInfo.data))
  }
}

export const doGetUserList = (callbackFunction?: () => void) => {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const userDetails = selectUserDetails(getState())
    const apiKey = selectApiKey(getState())
    const userList: AxiosResponse<IUserListData> = await getUserList(userDetails?.tenantId, apiKey || '')

    if (userList?.data) dispatch(setUserList(userList.data))
  }
}
export const doGetPaginatedUserList = (
  paginationPageNumber?: number,
  searchTerm?: string,
  callbackFunction?: () => void
) => {
  return async (dispatch: Dispatch<any>, getState: Function) => {
    const userDetails = selectUserDetails(getState())
    const apiKey = selectApiKey(getState())
    console.log(apiKey)

    const userList: AxiosResponse<IUserListData> = await getPaginatedUserList(
      userDetails?.tenantId,
      apiKey || '',
      paginationPageNumber,
      searchTerm
    )

    if (userList?.data) dispatch(setPaginatedUserList(userList.data))
  }
}
