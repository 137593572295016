import { createUseStyles } from 'react-jss'

import colours from '../../theme/colours'

const useErrorMessageStyles = createUseStyles({
  errorMessage: {
    color: `${colours.red}`,
    fontSize: '12px',
    marginLeft: '5px',
    left: '188px',
    display: 'block'
  }
})

export default useErrorMessageStyles
