import { cloneElement, useState, useCallback } from 'react'
import classnames from 'classnames'
import { Button } from '../Button'
import { Modal } from '../Modal'
import useConfirmableActionStyles from './ConfirmableAction.styles'
import { ConfirmableActionTypes } from './types'

export const ConfirmableAction = ({ children, Action, onConfirm, onCancel, classes }: ConfirmableActionTypes.Props) => {
  const styles = useConfirmableActionStyles()
  const [open, setOpen] = useState(false)

  const handleConfirm = useCallback(() => {
    onConfirm()
    setOpen(false)
  }, [onConfirm])

  const handleCancel = useCallback(() => {
    onCancel && onCancel()
    setOpen(false)
  }, [onCancel])

  const handleActionClick = useCallback(() => setOpen(true), [])

  return (
    <>
      <Modal isOpen={open} setOpen={setOpen}>
        <div className={classnames(styles.content, classes?.content)}>{children}</div>
        <div className={classnames(styles.buttons, classes?.buttons)}>
          <Button color="primary" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" variant="solid" onClick={handleConfirm}>
            OK
          </Button>
        </div>
      </Modal>
      {cloneElement(Action, { onClick: handleActionClick })}
    </>
  )
}
