import { format } from 'date-fns'
import { DATE_FORMATS } from '../../constants/dates'
import { numberToDouble } from '..'
import { LVSApi } from '../../store/apis/lvs/types'
import { MiddlewareApi } from '../../store/apis/middleware/types'

interface AdditionalProps {
  highlights?: string[]
}

export type InsightPayloadCategory = 'TRANSCRIPT' | 'OCR' | 'PEOPLE'

export type WhizzardInsightType = 'Transcript' | 'On-screen text' | 'People'

export const insightTypeMap: Map<InsightPayloadCategory, WhizzardInsightType> = new Map([
  ['TRANSCRIPT', 'Transcript'],
  ['OCR', 'On-screen text'],
  ['PEOPLE', 'People']
])
export interface Insight {
  type: WhizzardInsightType
  value: string
  count: number
  additionalProperties?: AdditionalProps
}
export interface WhizzardSearchResultClip {
  assetId: string
  id: string
  title: string
  createdDate: string
  tags: string[]
  startTime: number
  duration: number
  endTime: number
  insights: Insight[]
}

export interface ParseOpts {
  searchTerm: string
  exactMatch: boolean
  searchCategories: string[]
}

interface ClipTimes {
  start: number
  end: number
}

interface InsightPayloadItem {
  category: InsightPayloadCategory
  value: string
  startTime?: number
  endTime?: number
  additionalProperties?: AdditionalProps
}

interface InsightPayloadItemWithCount {
  insightPayloadItem: InsightPayloadItem
  count: number
}

// https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-stop-tokenfilter.html#analysis-stop-tokenfilter
const stopWords = [
  '',
  'a',
  'an',
  'and',
  'are',
  'as',
  'at',
  'be',
  'but',
  'by',
  'for',
  'if',
  'in',
  'into',
  'is',
  'it',
  'no',
  'not',
  'of',
  'on',
  'or',
  'such',
  'that',
  'the',
  'their',
  'then',
  'there',
  'these',
  'they',
  'this',
  'to',
  'was',
  'will',
  'with'
]

export const generateTempClipId = (assetId: string, startTime: number, duration: number) => {
  return `${assetId}-${startTime}-${startTime + duration}`
}

const findMostOccurences = (arr: InsightPayloadItem[], predicate: (current: InsightPayloadItem) => number) => {
  return arr.reduce(
    (accum, curr, index) => {
      const occurences = predicate(curr)
      return accum[0] >= occurences ? accum : [occurences, index]
    },
    [0, 0]
  )
}

const matchInsightPayloadItems = (
  insightPayloadItems: InsightPayloadItem[],
  searchTerm: string[],
  exactMatch: boolean
) => {
  let matchCount = 0
  let matchIndex = 0

  if (exactMatch) {
    ;[matchCount, matchIndex] = findMostOccurences(insightPayloadItems, current => {
      return current.value.match(new RegExp(`${searchTerm.join(' ')}`, 'gi'))?.length || 0
    })
  }

  if (!exactMatch || matchCount === 0) {
    ;[matchCount, matchIndex] = findMostOccurences(insightPayloadItems, current => {
      return searchTerm.reduce((occurence, term) => {
        const count = !stopWords.includes(term) ? current.value.match(new RegExp(`${term}`, 'gi'))?.length || 0 : 0
        return occurence + count
      }, 0)
    })
  }

  if (matchCount > 0) {
    return { count: matchCount, insightPayloadItem: insightPayloadItems[matchIndex] }
  }

  return null
}

const getRelevantInsightPayloadItems = (
  insightPayloadItems: InsightPayloadItem[],
  options: ParseOpts
): InsightPayloadItemWithCount[] => {
  if (!insightPayloadItems.length) {
    return []
  }

  const { searchTerm, searchCategories } = options

  const relevantInsightPayloadItems: InsightPayloadItemWithCount[] = []

  const exactMatch = options.exactMatch || /^".*"$/.test(searchTerm)
  const terms = exactMatch ? [searchTerm.trim().replace(/^"|"$/g, '')] : searchTerm.trim().split(' ')

  if (searchCategories.includes('TRANSCRIPT')) {
    const transcripts = insightPayloadItems.filter(insightPayloadItem => insightPayloadItem.category === 'TRANSCRIPT')
    const transcriptMatch = matchInsightPayloadItems(transcripts, terms, exactMatch)
    if (transcriptMatch) {
      relevantInsightPayloadItems.push(transcriptMatch)
    }
  }

  if (searchCategories.includes('PEOPLE')) {
    const people = insightPayloadItems.filter(insightPayloadItem => insightPayloadItem.category === 'PEOPLE')
    const peopleMatch = matchInsightPayloadItems(people, terms, exactMatch)
    if (peopleMatch) {
      relevantInsightPayloadItems.push(peopleMatch)
    }
  }

  if (searchCategories.includes('OCR')) {
    const ocr = insightPayloadItems.filter(insightPayloadItem => insightPayloadItem.category === 'OCR')
    const ocrMatch = matchInsightPayloadItems(ocr, terms, exactMatch)
    if (ocrMatch) {
      relevantInsightPayloadItems.push(ocrMatch)
    }
  }

  return relevantInsightPayloadItems
}

const getMostRelevantClipTimes = (insightPayloadItems: InsightPayloadItemWithCount[]) => {
  const transcriptPayloadItem = insightPayloadItems.find(item => item.insightPayloadItem.category === 'TRANSCRIPT')
  const ocrPayloadItem = insightPayloadItems.find(item => item.insightPayloadItem.category === 'OCR')
  const peoplePayloadItem = insightPayloadItems.find(item => item.insightPayloadItem.category === 'PEOPLE')

  if (transcriptPayloadItem) {
    return {
      start: transcriptPayloadItem.insightPayloadItem.startTime,
      end: transcriptPayloadItem.insightPayloadItem.endTime
    } as ClipTimes
  }

  if (ocrPayloadItem) {
    return {
      start: ocrPayloadItem.insightPayloadItem.startTime,
      end: ocrPayloadItem.insightPayloadItem.endTime
    } as ClipTimes
  }

  if (peoplePayloadItem) {
    return {
      start: peoplePayloadItem.insightPayloadItem.startTime,
      end: peoplePayloadItem.insightPayloadItem.endTime
    } as ClipTimes
  }

  return null
}

export const parseClip = (clip: LVSApi.Clip, options: ParseOpts): WhizzardSearchResultClip => {
  const { payload, assetId, id } = clip

  const tags: string[] = []
  const insightPayloadItems: InsightPayloadItem[] = []

  let title = id
  let createdDate = ''

  payload.forEach(payloadItem => {
    const { category, value, additionalProperties } = payloadItem

    if (!value) {
      return
    }

    if (category === 'ASSET_TAG' && /^title\|/.test(value)) {
      title = value.replace(/^.*\|/, '')
    }

    if (category === 'TITLE' && title === id) {
      title = value
    }

    if (category === 'ASSET_START_DATE') {
      createdDate = format(new Date(value), DATE_FORMATS.CALENDAR)
    }

    if (!['ASSET_TAG', 'TITLE', 'ASSET_START_DATE', 'TRANSCRIPT', 'OCR', 'PEOPLE'].includes(category)) {
      tags.push(value)
    }

    if (additionalProperties?.highlights?.length && ['TRANSCRIPT', 'OCR', 'PEOPLE'].includes(category)) {
      insightPayloadItems.push(payloadItem as InsightPayloadItem)
    }
  })

  const relevantInsightPayloadItems = getRelevantInsightPayloadItems(insightPayloadItems, options)

  const clipTimes = getMostRelevantClipTimes(relevantInsightPayloadItems)

  const insights: Insight[] = relevantInsightPayloadItems.map(item => ({
    count: item.count,
    value: item.insightPayloadItem.value,
    type: insightTypeMap.get(item.insightPayloadItem.category)!,
    additionalProperties: item.insightPayloadItem.additionalProperties
  }))

  return {
    assetId,
    id,
    createdDate,
    tags,
    title,
    startTime: (clipTimes ? clipTimes.start : clip.startTime) / 1000,
    endTime: (clipTimes ? clipTimes.end : clip.endTime) / 1000,
    duration: (clipTimes ? clipTimes.end - clipTimes.start : clip.duration) / 1000,
    insights
  }
}

export const createVideoString = (clips: WhizzardSearchResultClip[] = []) => {
  return clips
    .map(clip => {
      const o = numberToDouble(Number(clip.startTime))
      const d = numberToDouble(Number(clip.duration))
      return `id=${clip.assetId}&o=${o}&d=${d}`
    })
    .join('&')
}

export const createPlaylistVideoString = (clips: MiddlewareApi.PlaylistClip[] = []) => {
  return clips
    .map(clip => {
      const o = numberToDouble(Number(clip.offset))
      const d = numberToDouble(Number(clip.duration))
      return `id=${clip.asset_id}&o=${o}&d=${d}`
    })
    .join('&')
}
