import { createUseStyles } from 'react-jss'
import colours from '../../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../../theme/toResponsiveUnitFromPixels'
import { ITable } from '../types'

export const useTableRowStyles = createUseStyles({
  TableRow: {
    borderBottom: ({ divider }: ITable.TableRowStyles) => divider && `${toResponsiveUnitFromPixels(1)} solid #E0E0E0`,
    display: ({ isEmptyAccount }: ITable.TableRowStyles) => (isEmptyAccount ? 'none' : 'grid'),
    gridTemplateColumns: ({ gridTemplateColumns }: ITable.TableRowStyles) => gridTemplateColumns,
    justifyContent: 'flex-start',
    paddingLeft: toResponsiveUnitFromPixels(40),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    paddingTop: toResponsiveUnitFromPixels(10),
    paddingBottom: toResponsiveUnitFromPixels(10),
    color: ({ isChecked }: ITable.TableRowStyles) => (isChecked ? colours.metallicSeaweed : ''),
    fontSize: toResponsiveUnitFromPixels(12, true)
  },
  childContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
})
