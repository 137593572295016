import { useEffect, useRef, useState } from 'react'
import { notify } from 'react-notify-toast'
import { useDispatch, useSelector } from 'react-redux'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminConsoleUserListItem from '../AdminConsoleUserListItem/AdminConsoleUserListItem'
import { addNewUser, doGetPaginatedUserList } from '../../redux/actions/auth'
import { selectPaginatedUserList } from '../../redux/selectors'
import useAdminConsoleUserManagerStyles from './AdminConsoleUserManager.styles'
import { Button } from '../Button'
import { useValidation } from '../../hooks/useValidation/useValidation'
import { selectUserDetails } from '../../store/slices/user'

export const AdminConsoleUserManager = () => {
  const dispatch = useDispatch()
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId
  const paginatedUserList = useSelector(selectPaginatedUserList)

  const classes = useAdminConsoleUserManagerStyles({ paginatedUserList })

  const [searchTerm, setSearchTerm] = useState('')
  const [paginationPageNumber, setPaginationPageNumber] = useState(0)
  const [newUser, setNewUser] = useState('')
  const [adding, setAdding] = useState(false)
  const newUserInputRef = useRef<HTMLInputElement>(null)
  const { validateEmail } = useValidation()

  useEffect(() => {
    dispatch(doGetPaginatedUserList(0, ''))
  }, [dispatch])

  useEffect(() => {
    const timeOut = setTimeout(() => dispatch(doGetPaginatedUserList(paginationPageNumber, searchTerm)), 250)
    return () => clearTimeout(timeOut)
  }, [dispatch, paginationPageNumber, searchTerm])

  useEffect(() => {
    setPaginationPageNumber(paginatedUserList.number)
  }, [paginatedUserList.number])

  const addUser = async (email: string) => {
    if (!validateEmail(email)) {
      notify.show('Email is not valid', 'error')
      return
    }
    try {
      setAdding(true)
      await addNewUser(newUser, tenantId)
      notify.show('User was added successfully', 'success')
      setNewUser('')
    } catch (error: any) {
      if (error?.response?.data?.status === 409) {
        notify.show('This user already exists', 'error')
      } else {
        notify.show('Something went wrong', 'error')
      }
    } finally {
      setAdding(false)
      setPaginationPageNumber(0)
      dispatch(doGetPaginatedUserList(0, ''))
    }
  }

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationPageNumber(0)
    setSearchTerm(e.target.value)
  }

  const getPaginationPageRange = () => {
    const { size, number, totalElements } = paginatedUserList

    if (number === 0) {
      return (
        <p>
          1-{Math.min(size, totalElements)} of {totalElements}
        </p>
      )
    } else {
      return (
        <p>
          {number * size + 1}-{Math.min(number * size + size, totalElements)} of {totalElements}
        </p>
      )
    }
  }

  return (
    <>
      <div className={classes.userManagerContainer}>
        <div className={classes.headingSearchContainer}>
          <h1 className={classes.userManagerHeading} data-testid="page-title">
            User Manager
          </h1>

          <input
            className={classes.searchUsersInput}
            value={searchTerm}
            onChange={handleSearchInputChange}
            placeholder="Search Users"
          />
        </div>

        <div className={classes.userManagerTableHeadings}>
          <p className={classes.text}>Username</p>
          <div className={classes.flexContainer}>
            <p className={classes.text}>Verified?</p>
            <p className={classes.text}>Date Added</p>
            <p className={classes.text}>Admin?</p>
            <p className={classes.text}>Action</p>
          </div>
        </div>

        <div className={classes.userManagerTable}>
          {paginatedUserList.totalElements > 0 ? (
            <div ref={newUserInputRef} className={classes.addUserRowContainer}>
              <div className={classes.addUserInputContainer}>
                <input
                  onChange={e => setNewUser(e.target.value)}
                  placeholder={'Add New User'}
                  value={newUser}
                  className={classes.addUserInput}
                />
              </div>
              <div className={classes.dateAndSendContainer}>
                <p className={classes.mockDate}>dd/mm/yyyy</p>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={newUser.length <= 0 ? true : adding}
                  onClick={() => {
                    addUser(newUser)
                  }}
                >
                  {adding ? 'Adding...' : 'Send'}
                </Button>
              </div>
            </div>
          ) : (
            <p style={{ margin: 'auto' }}>No results</p>
          )}
          {paginatedUserList?.content?.map((user, index) => {
            return (
              <AdminConsoleUserListItem
                key={index}
                index={index}
                length={paginatedUserList?.content.length}
                user={user}
                setSearchTerm={setSearchTerm}
                setPaginationPageNumber={setPaginationPageNumber}
              />
            )
          })}
        </div>
        <div className={classes.paginationContainer}>
          <p>Viewing {paginatedUserList.size} results per page</p>
          {paginatedUserList.totalElements > 0 && (
            <div className={classes.paginationControls}>
              {getPaginationPageRange()}
              <button
                onClick={() => setPaginationPageNumber(Math.max(paginationPageNumber - 1, 0))}
                data-testid="paginate-prev"
                disabled={paginationPageNumber === 0}
                className={classes.paginationControlsButtonLeft}
              >
                <FontAwesomeIcon icon={faChevronLeft} size="sm" />
              </button>
              <button
                onClick={() =>
                  setPaginationPageNumber(Math.min(paginationPageNumber + 1, paginatedUserList.totalPages))
                }
                data-testid="paginate-next"
                disabled={paginationPageNumber === paginatedUserList.totalPages - 1}
                className={classes.paginationControlsButtonRight}
              >
                <FontAwesomeIcon icon={faChevronRight} size="sm" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
