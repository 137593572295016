import { Switch, Route } from 'react-router-dom'
import { routes, adminRoutes, betaPrivateRoutes, externalRedirects, betaRoutes } from './Routes'
import { AdminRoute } from '../components/AdminRoute'
import { PublicRoute } from '../components/publicRoute'
import { PrivateRoute as BetaPrivateRoute } from './components/PrivateRoute/index'
import { PublicRoute as BetaPublicRoute } from './components/PublicRoute/index'

const RouteConfig = () => {
  return (
    <Switch>
      {routes.map(route => {
        const { path, ...props } = route
        return (
          <PublicRoute key={path} path={path}>
            <route.component {...(props as any)} />
          </PublicRoute>
        )
      })}
      {betaRoutes.map(route => {
        const { path, ...props } = route
        return (
          <BetaPublicRoute key={path} path={path}>
            <route.component {...(props as any)} />
          </BetaPublicRoute>
        )
      })}
      {betaPrivateRoutes.map(route => {
        const { path } = route
        return (
          <BetaPrivateRoute key={path} path={path} exact>
            <route.component />
          </BetaPrivateRoute>
        )
      })}
      {adminRoutes.map(route => {
        const { path } = route
        return (
          <AdminRoute key={path} path={path} exact>
            <route.component
              isUserManagerPage={route.isUserManagerPage}
              isMyContentPage={route.isMyContentPage}
              isMediaUploadPage={route.isMediaUploadPage}
              isIntegrationsPage={route.isIntegrationsPage}
            />
          </AdminRoute>
        )
      })}
      {externalRedirects.map(({ path, to }) => {
        const render = () => {
          window.location.replace(to)
          return null
        }
        return <Route exact key={path} path={path} render={render} />
      })}
    </Switch>
  )
}

export default RouteConfig
