import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { usePlayer } from '.'
import { VIDEO_POSTER_DEFAULT } from '../../constants'
import { doSetVideoTimeline, doSetVideoCurrentTime, resetVideoPlayer } from '../../redux/actions'

import { PlayerComponent } from './types'
import '../../../node_modules/linius-video-player/dist/lvp.css'
import classNames from 'classnames'

export const Player = ({
  src,
  autoplay = false,
  type = 'application/x-mpegURL',
  poster = VIDEO_POSTER_DEFAULT,
  styleOverides = {}
}: PlayerComponent.Props) => {
  const { player, setRef } = usePlayer()
  const dispatch = useDispatch()

  useEffect(() => {
    player?.ready(() => {
      player.src({ src, type })
      player.on('loadedmetadata', () => {
        const tech = player.tech(true) as any
        const seekTo = dispatch<any>(doSetVideoTimeline(tech?.vhs?.playlists?.media()?.segments))
        if (seekTo) {
          player.currentTime(seekTo)
        }
      })

      player.on(['timeupdate', 'seeking'], () => {
        dispatch(doSetVideoCurrentTime(player.currentTime()))
      })
    })
  }, [player, src, dispatch, type])

  useEffect(() => {
    return () => {
      dispatch(resetVideoPlayer())
    }
  }, [dispatch])

  return (
    <div data-vjs-player>
      <video
        playsInline
        preload="auto"
        className={classNames('video-js lvp', 'vjs-16-9')}
        style={styleOverides}
        autoPlay={autoplay}
        ref={setRef}
        poster={poster}
      />
    </div>
  )
}
