import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subDays } from 'date-fns'
import { Box, Button, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import FilterListIcon from '@mui/icons-material/FilterList'

import { AllFiltersFilter } from '../AllFiltersFilter/AllFiltersFilter'
import { FilterAutocomplete } from '../FilterAutocomplete'
import { getAllFiltersStyles } from './AllFilters.styles'
import { Checkbox } from '../low-level/Checkbox/Checkbox'
import { Dropdown, Option } from '../low-level/Dropdown'
import { CustomDateRange } from '../CustomDateRange/CustomDateRange'

import { convertToUTC } from '../../utils/date'

import {
  selectCategories,
  selectFilters,
  selectSearchQuery,
  setSearchCategory,
  setSearchDateRange,
  setSearchExact,
  setSearchFilter
} from '../../store/slices/search'
import { lvsApi } from '../../store/apis/lvs'

const dateRangeOptions: Option[] = [
  { text: 'All Dates' },
  { text: 'Today' },
  { text: 'Last 7 Days' },
  { text: 'Last 14 Days' },
  { text: 'Last Month' },
  { text: 'Custom Range' }
]

const dateRanges = {
  'All Dates': {
    startDate: null,
    endDate: new Date()
  },
  Today: {
    startDate: new Date(),
    endDate: new Date()
  },
  'Last 7 Days': {
    startDate: subDays(new Date(), 6),
    endDate: new Date()
  },
  'Last 14 Days': {
    startDate: subDays(new Date(), 13),
    endDate: new Date()
  },
  'Last Month': {
    startDate: subDays(new Date(), 30),
    endDate: new Date()
  }
}

interface Props {
  onGetOptions: (category: string, text?: string) => Promise<unknown>
}

export const AllFilters = ({ onGetOptions }: Props) => {
  const theme = useTheme()
  const styles = getAllFiltersStyles(theme)

  const dispatch = useDispatch()

  const isBelowMDBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const searchQuery = useSelector(selectSearchQuery)
  const searchCategories = useSelector(selectCategories)
  const searchFilters = Object.values(useSelector(selectFilters))
  const [fetchSearchResults] = lvsApi.endpoints.getBufferedSearch.useLazyQuery()

  const [localCategories, setLocalCategories] = useState(searchQuery.category)
  const [localFilters, setLocalFilters] = useState(searchQuery.filter)
  const [localIsExact, setLocalIsExact] = useState<boolean>(searchQuery.exact)
  const [dateRangeDropdownValue, setDateRangeDropdownValue] = useState<string[]>(['All Dates'])

  const activeCategories = searchCategories.reduce((a, c) => {
    return c.disabled ? a : [...a, c.key]
  }, [] as string[])

  const allFiltersCleared = Object.keys(searchQuery.filter).reduce((accum, key) => {
    return { ...accum, [key]: [] }
  }, {})

  useEffect(() => {
    setLocalFilters(searchQuery.filter)
  }, [searchQuery.filter])

  useEffect(() => {
    setLocalCategories(searchQuery.category)
  }, [searchQuery.category])

  const allCategoriesSelected = searchCategories.length === localCategories.length

  const handleDateRangeOnChange = (values: string[]) => {
    setDateRangeDropdownValue(values)
  }

  const [startDate, setStartDate] = useState<Date | null>(dateRanges.Today.startDate)
  const [endDate, setEndDate] = useState<Date | null>(dateRanges.Today.endDate)

  const handleCustomDateRangeChange = (newValue: unknown, startOrEnd: string) => {
    const typedNewValue = newValue as Date | null
    if (startOrEnd === 'start') {
      setStartDate(typedNewValue)
    } else {
      setEndDate(typedNewValue)
    }
  }

  const handleClearAll = () => {
    setLocalCategories(activeCategories)
    setLocalFilters(allFiltersCleared)
    setDateRangeDropdownValue(['All Dates'])
    setLocalIsExact(false)
  }

  const handleApplyFilters = () => {
    Object.keys(localFilters).forEach(key => {
      dispatch(setSearchFilter([key, localFilters[key]]))
    })
    if (localCategories.length) {
      dispatch(setSearchCategory(localCategories))
    } else {
      setLocalCategories(searchQuery.category)
    }
    dispatch(setSearchExact(localIsExact))
    if (dateRangeDropdownValue.includes('Custom Range')) {
      if (startDate !== null && endDate !== null) {
        dispatch(
          setSearchDateRange({
            category: 'ASSET_START_DATE',
            value: [convertToUTC(startDate), convertToUTC(endDate, true)]
          })
        )
      } else {
        dispatch(setSearchDateRange(null))
      }
    } else if (dateRangeDropdownValue.includes('All Dates')) {
      dispatch(setSearchDateRange(null))
    } else {
      dispatch(
        setSearchDateRange({
          category: 'ASSET_START_DATE',
          value: [
            convertToUTC(dateRanges[dateRangeDropdownValue[0]].startDate),
            convertToUTC(dateRanges[dateRangeDropdownValue[0]].endDate, true)
          ]
        })
      )
    }

    setIsDrawerOpen(false)
    fetchSearchResults(true)
  }

  const handleFiltersSelect = useCallback(
    (key: string, value: string[]) => {
      setLocalFilters({ ...localFilters, [key]: value })
    },
    [localFilters]
  )

  return (
    <>
      <Button
        sx={{ minWidth: isBelowMDBreakpoint ? '100%' : 133 }}
        variant={isBelowMDBreakpoint ? 'text' : 'outlined'}
        onClick={() => setIsDrawerOpen(true)}
        data-testid="all-filters-btn"
      >
        <FilterListIcon sx={{ mr: 1 }} />
        All filters
      </Button>
      <Drawer
        sx={styles.drawer}
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setLocalCategories(searchQuery.category)
          setLocalIsExact(searchQuery.exact)
          setLocalFilters(searchQuery.filter)
          setDateRangeDropdownValue(['All Dates'])
          setIsDrawerOpen(false)
        }}
        data-testid="all-filters-panel"
      >
        <Box sx={styles.header}>
          <Typography variant="head500" color={theme.palette.grey[800]} data-testid="all-filters-header">
            All filters
          </Typography>

          <Button
            onClick={() => {
              setLocalCategories(searchQuery.category)
              setLocalIsExact(searchQuery.exact)
              setLocalFilters(searchQuery.filter)
              setIsDrawerOpen(false)
            }}
            variant="text"
            sx={{ minWidth: 'auto', height: 'auto', padding: 0, color: theme.palette.grey[400] }}
          >
            <ClearIcon />
          </Button>
        </Box>

        <Box sx={styles.drawerContentContainer}>
          <AllFiltersFilter
            sx={{ mb: 3 }}
            title="Search type"
            clearOnClick={() => setLocalCategories(searchQuery.category)}
          >
            <Checkbox
              sx={{ px: 2 }}
              label="All insights"
              indeterminate={allCategoriesSelected}
              checked={allCategoriesSelected}
              onChange={() => {
                setLocalCategories(activeCategories)
              }}
              data-testid="search-type"
            />
            {searchCategories.map(category => (
              <Checkbox
                key={category.key}
                sx={{ px: 2 }}
                label={category.text}
                onChange={() => {
                  const categories = [...localCategories]
                  if (localCategories.includes(category.key) && localCategories.length === 1) {
                    setLocalCategories(activeCategories)
                  } else if (localCategories.includes(category.key)) {
                    setLocalCategories(localCategories.filter(localCategory => localCategory !== category.key))
                  } else {
                    categories.push(category.key)
                    setLocalCategories(categories)
                  }
                }}
                disabled={category.disabled}
                checked={localCategories.includes(category.key) || allCategoriesSelected}
                data-testid="search-type"
              />
            ))}
          </AllFiltersFilter>

          <AllFiltersFilter sx={{ mb: 3 }} title="Exact match">
            <Checkbox
              sx={{ px: 2 }}
              label="Match exact keywords"
              onChange={() => {
                setLocalIsExact(!localIsExact)
              }}
              checked={localIsExact}
            />
          </AllFiltersFilter>

          <AllFiltersFilter title="Date" clearOnClick={() => setDateRangeDropdownValue(['All Dates'])} sx={{ mb: 3 }}>
            <Dropdown
              sx={{ width: '100%', mb: 2 }}
              onSelection={handleDateRangeOnChange}
              options={dateRangeOptions}
              size="medium"
              value={dateRangeDropdownValue}
              data-testid="date-dropdown"
            />
            <CustomDateRange
              dateRange={{ startDate, endDate }}
              handleDateRangeChange={handleCustomDateRangeChange}
              sx={{ display: dateRangeDropdownValue.includes('Custom Range') ? 'flex' : 'none' }}
            />
          </AllFiltersFilter>
          {searchFilters.map(filter => (
            <AllFiltersFilter
              key={filter.key}
              sx={{ mb: 3 }}
              title={filter.text}
              clearOnClick={() => setLocalFilters({ ...localFilters, [filter.key]: [] })}
            >
              <FilterAutocomplete
                tags
                key={filter.key}
                category={filter.key}
                name={filter.text}
                options={filter.options}
                value={localFilters[filter.key]}
                onSelect={handleFiltersSelect}
                onGetOptions={onGetOptions}
              />
            </AllFiltersFilter>
          ))}
        </Box>

        <Box sx={styles.footer}>
          <Button variant="outlined" onClick={handleClearAll} data-testid="clear-all-btn">
            Clear all
          </Button>
          <Button sx={{ ml: 1 }} variant="contained" onClick={handleApplyFilters} data-testid="apply-filters-btn">
            Apply filters
          </Button>
        </Box>
      </Drawer>
    </>
  )
}
