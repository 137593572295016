import { NavLink, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '../../design-system-legacy/icons/IcoMoon/icoMoonFontIcons'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Avatar, Typography, useTheme } from '@mui/material'
import { ExternalLink } from '../ExternalLink'
import { EIntegrationsHelpLinkUrl } from '../IntegrationsHelpLink/IntegrationsHelpLink.consts'
import { useTopNav } from './useTopNav'
import { getTopnavStyles } from './TopNavigation.styles'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { selectUserDetails } from '../../store/slices/user'
import { clearStore } from '../../store/slices/auth'
import { userApi } from '../../store/apis/user'
import { ROUTES } from '../../RouteConfig/constants'

const Topnav = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const classes = getTopnavStyles(theme)
  const userDetails = useSelector(selectUserDetails)

  const [signOut, { isLoading: isSignOutLoading }] = userApi.endpoints.signOut.useLazyQuery()

  const { toolbar, openDrawer, pages, setOpenDrawer } = useTopNav()
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {/* cant find this colour in the palette */}
        <AppBar position="static" sx={{ background: '#292E4D' }}>
          {toolbar(<MenuIcon />, true)}
        </AppBar>
      </Box>
      <Drawer anchor={'right'} open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box sx={{ maxWidth: 375, width: '100vw', background: '#292E4D', height: '100%' }} role="presentation">
          {toolbar(<CloseOutlinedIcon />, false)}
          <List>
            {pages.map(({ icon, route, text, isActive }) => (
              <NavLink
                style={isActive => ({
                  color: isActive ? theme.palette.primary.main : theme.palette.common.white,
                  textDecoration: 'none'
                })}
                key={route}
                isActive={isActive}
                exact
                to={route}
              >
                <ListItem button key={route} sx={{ color: 'inherit' }}>
                  <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </NavLink>
            ))}
            <ExternalLink to={EIntegrationsHelpLinkUrl.ZOOM}>
              <ListItem button sx={{ borderBottom: '1px solid #343C60', textDecoration: 'none' }}>
                <ListItemIcon sx={{ color: theme.palette.common.white }}>
                  <Icon color={theme.palette.common.white} size={36} icon="question-outline" />
                </ListItemIcon>
                <ListItemText primary={'Help'} sx={{ color: theme.palette.common.white, textDecoration: 'none' }} />
              </ListItem>
            </ExternalLink>

            <Box
              style={{ textDecoration: 'none' }}
              onClick={async () => {
                await signOut()
                history.push({ pathname: ROUTES.LOGIN, state: undefined })
                dispatch(clearStore())
              }}
            >
              <ListItem button>
                <ListItemIcon sx={{ color: theme.palette.common.white }}>
                  <Avatar sx={classes.avatar}>
                    <PersonOutlineIcon />
                  </Avatar>
                </ListItemIcon>
                <Box>
                  <ListItemText sx={{ color: theme.palette.grey[200] }}>
                    <Typography variant="body1"> {userDetails?.email}</Typography>
                  </ListItemText>
                  <ListItemText
                    primary={isSignOutLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Log Out'}
                    sx={{ color: theme.palette.common.white, minWidth: 2 }}
                  />
                </Box>
              </ListItem>
            </Box>
          </List>
        </Box>
      </Drawer>
    </>
  )
}

export default Topnav
