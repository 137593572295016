const numberToDouble = (n: number): number => {
  return Math.floor(n * 100) / 100
}

const numberToTwoDigits = (n: number): string => {
  return n.toString().length < 2 ? `0${n}` : `${n}`
}

const timeToHours = (time: number): number => {
  return Math.floor(time / 60 / 60)
}

const timeToHoursString = (time: number): string => {
  return numberToTwoDigits(timeToHours(time))
}

const timeToMinutes = (time: number, roundUp?: boolean): number => {
  if (roundUp) return Math.round(time / 60) % 60
  return Math.floor(time / 60) % 60
}

const secondsToMinutes = (timespan: number, roundUp?: boolean): number => {
  // return Math.ceil(timespan / 60)
  if (roundUp) return Math.ceil(timespan / 60)
  return Math.floor(timespan / 60)
}
const timeToMinutesString = (time: number): string => {
  return numberToTwoDigits(timeToMinutes(time))
}

const timeToSeconds = (time: number): number => {
  return Math.floor(time % 60)
}

const timeToSecondsString = (time: number): string => {
  return numberToTwoDigits(timeToSeconds(time))
}

const hoursMinutesSecondsToTime = (hours: number, minutes: number, seconds: number): number => {
  return hours * 60 * 60 + minutes * 60 + seconds
}

const formatTime = (time: number, showHoursOveride = false): string => {
  return timeToHoursString(time) === '00' && !showHoursOveride
    ? `${timeToMinutesString(time)}:${timeToSecondsString(time)}`
    : `${timeToHoursString(time)}:${timeToMinutesString(time)}:${timeToSecondsString(time)}`
}

export {
  numberToDouble,
  numberToTwoDigits,
  hoursMinutesSecondsToTime,
  formatTime,
  timeToHours,
  timeToHoursString,
  timeToMinutes,
  timeToMinutesString,
  timeToSeconds,
  timeToSecondsString,
  secondsToMinutes
}
