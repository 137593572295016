import { FunctionComponent } from 'react'

import { WhizzardLogoNoTextProps } from './WhizzardLogoNoText.model'

export const WhizzardLogoNoText: FunctionComponent<WhizzardLogoNoTextProps> = ({ size = '36px' }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 38 31.38">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.345"
        y1="0.595"
        x2="1.379"
        y2="0.148"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#3dbad7" />
        <stop offset="1" stopColor="#064d69" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.139"
        y1="-0.142"
        x2="0.834"
        y2="1.096"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#064d69" />
        <stop offset="0.298" stopColor="#3dbad7" />
        <stop offset="0.791" stopColor="#3dbad7" />
        <stop offset="1" stopColor="#064d69" />
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="0.58" y1="0.415" x2="-0.107" y2="1.085" xlinkHref="#linear-gradient" />
    </defs>
    <g id="Group_3569" data-name="Group 3569" transform="translate(21 9.903)">
      <g id="Group_3568" data-name="Group 3568" transform="translate(-21 -9.903)">
        <path
          id="Path_69996"
          data-name="Path 69996"
          d="M196.583,262.294v0a12.683,12.683,0,0,1-2.832-13.844,12.571,12.571,0,0,1,2.685-4.066h0a12.665,12.665,0,0,1,1.767-1.493c.1-.069.2-.137.3-.2l-.915-1.555-.011-.019a4.866,4.866,0,0,0-1.511-1.588c-.034-.023-.068-.045-.1-.066a5.076,5.076,0,0,0-3.372-.7,4.67,4.67,0,0,0-3.886,3.464,18.995,18.995,0,0,0,2.538,15.44c1.358,2.041,3.266,3.232,5.341,4.631Z"
          transform="translate(-188.053 -231.382)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_69997"
          data-name="Path 69997"
          d="M222.438,240.343a16.8,16.8,0,0,0,2.06-2.546,18.961,18.961,0,0,0,2.635-5.858,4.875,4.875,0,0,1-7.3,2.911q-.052-.032-.1-.066a4.85,4.85,0,0,1-1.372-1.367,4.78,4.78,0,0,1-.274-.467l-10.259-17.438a8.075,8.075,0,0,0-12.745-1.543l-.027.028a18.9,18.9,0,0,0-4.7,8.409,4.875,4.875,0,0,1,7.3-2.91c.035.021.069.043.1.066a4.866,4.866,0,0,1,1.511,1.588l.011.019.915,1.555,2.2,3.734,3.867,6.575.025.042.648,1.1,2.721,4.624a8.092,8.092,0,0,0,12.627,1.7Z"
          transform="translate(-189.745 -211.42)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_69998"
          data-name="Path 69998"
          d="M293.841,211.121a18.976,18.976,0,0,1,7.791,20.411,4.963,4.963,0,0,1-5.976,3.6,4.875,4.875,0,0,1-1.347-.579q-.052-.032-.1-.066a4.849,4.849,0,0,1-1.371-1.367l-1.059-1.8c.1-.064.2-.13.292-.2a12.675,12.675,0,0,0,1.767-1.493h0a16.744,16.744,0,0,0,2.684-4.066,15.973,15.973,0,0,0,.544-1.637,12.6,12.6,0,0,0-.052-6.554,15.326,15.326,0,0,0-3.2-6.246"
          transform="translate(-264.217 -211.121)"
          fill="url(#linear-gradient-3)"
        />
      </g>
    </g>
  </svg>
)
