import { Box, Typography, useTheme } from '@mui/material'
import Highlighter from 'react-highlight-words'

import { formatTime } from '../../utils'
import { HighlightedWords } from '../HighlightedWords'

interface VideoTranscriptItemProps {
  text: string
  textToHighlight?: string[]
  isActive?: boolean
  onClick?: () => void
  timeStamp: number
}

export const VideoTranscriptItem = ({
  text,
  textToHighlight,
  isActive,
  onClick,
  timeStamp
}: VideoTranscriptItemProps) => {
  const theme = useTheme()
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        p: 1,
        backgroundColor: isActive ? theme.palette.primary[50] : theme.palette.common.white,
        '&:hover': { backgroundColor: theme.palette.grey[25], cursor: 'pointer' },
        [theme.breakpoints.down('md')]: { flexDirection: 'column', p: 0 }
      }}
      data-testid="transcript-item"
    >
      <Typography
        variant="body2"
        color={isActive ? theme.palette.grey[800] : theme.palette.grey[350]}
        sx={{ fontWeight: 510, mr: 4.5, [theme.breakpoints.down('md')]: { mb: 0.5 } }}
        data-testid="transcript-timestamp"
      >
        {formatTime(timeStamp, true)}
      </Typography>

      <Typography variant="body2" color={theme.palette.grey[800]} data-testid="transcript">
        <Highlighter highlightTag={HighlightedWords} autoEscape textToHighlight={text} searchWords={textToHighlight} />
      </Typography>
    </Box>
  )
}
