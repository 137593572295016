import { useCallback, useEffect, useRef, useState } from 'react'
import { generatePath, Prompt, useHistory, useParams } from 'react-router-dom'
import { notify } from 'react-notify-toast'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Container,
  DialogContentText,
  Grid,
  Skeleton,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import LinearProgress from '@mui/material/LinearProgress'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import CircleIcon from '@mui/icons-material/Circle'

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DropResult,
  DroppableProvided
} from 'react-beautiful-dnd'
import { Player, usePlayer } from '../../components/Player'
import { PageHeader } from '../../components2.0/PageHeader'
import { PlayerQueueClip, QueueAction } from '../../components2.0/PlayerQueueClip'
import { DATE_FORMATS } from '../../constants'
import { doSetQueueCurrentIndex } from '../../redux/actions'
import { selectQueueCurrentIndex, selectVideoPlayerSegments } from '../../redux/selectors'
import { formatTime, getTimeFromIndex } from '../../utils'
import { sendVideoPlay } from '../../utils/googleAnalytics'
import { TextField } from '../../components2.0/low-level/TextField/TextField'
import { ExtraActions } from '../../components2.0/low-level/ExtraActions/ExtraActions'
import { SharePlaylistDialog } from '../../components2.0/PlaylistsDialogs/SharePlaylistDialog/SharePlaylistDialog'
import { wordAsPluralOrSingular } from '../../utils/count'
import { parseUsername } from '../../utils/user'
import { TabObject, Tabs } from '../../components2.0/low-level/Tabs/Tabs'
import {
  useRemoveClipMutation,
  useDeletePlaylistMutation,
  useGetPlaylistQuery,
  useUpdateClipIndexMutation,
  useUpdatePlaylistMutation
} from '../../store/apis/middleware'
import { useGetAssetMetadataQuery, lvsApi } from '../../store/apis/lvs'
import SuccessAlert from '../../components2.0/Alert/SuccessAlert'
import {
  AddToPlaylistClip,
  AddToPlaylistDialog
} from '../../components2.0/PlaylistsDialogs/AddToPlaylistDialog/AddToPlaylistDialog'
import { formatDate, formatDateWithoutTimezone } from '../../utils/date'
import { ROUTES } from '../../RouteConfig/constants'
import { createPlaylistVideoString } from '../../utils/clip'
import { MiddlewareApi } from '../../store/apis/middleware/types'
import { ActionConfirmationDialog } from '../../components2.0/ActionConfirmationDialog'
import { hideEditClipActionOnMobile } from '../../utils/search'
import { useIsMobileDevice } from '../../hooks/useIsMobileDevice'
import { NoResultsMessage } from '../../components2.0/NoResultsMessage/NoResultsMessage'
import { NoPlaylistClipsIcon } from '../../design-system/icons'
import { selectUserDetails } from '../../store/slices/user'

const SCROLL_BUFFER = 15

export const MAX_PLAYLIST_TITLE_LENGTH = 100
export const MAX_PLAYLIST_DESCRIPTION_LENGTH = 5000

export const ViewPlaylist = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''
  const userId = userDetails?.id

  const currentIndex = useSelector(selectQueueCurrentIndex)
  const historyState = history.location.state as {
    tab: number
    isEditMode: boolean
  }

  const { playlistId } = useParams<Record<string, string>>()
  const { data: playlist, isLoading, isFetching, isError } = useGetPlaylistQuery({
    tenantId: tenantId || '',
    playlistId
  })

  const isPlaylistShared = playlist?.user_id !== userId

  const assetIds = playlist ? playlist.clips.map(c => c.asset_id) : []
  const { data: assetData = {} } = useGetAssetMetadataQuery(assetIds)

  const [tabValue, setTabValue] = useState(0)
  const [isEditMode, setIsEditMode] = useState(false)
  const [sharePlaylistOpen, setSharePlaylistOpen] = useState(false)
  const [deletePlaylistOpen, setDeletePlaylistOpen] = useState(false)
  const [isRemoveClipDialogOpen, setRemoveClipDialogOpen] = useState(false)
  const [addToPlaylistDialogOpen, setAddToPlaylistDialogOpen] = useState(false)

  const [addToPlaylistClip, setAddToPlaylistClip] = useState<AddToPlaylistClip>()
  const [clipToRemove, setClipToRemove] = useState<MiddlewareApi.PlaylistClip | null>(null)

  const [
    updatePlaylist,
    { isLoading: isUpdatingPlaylistDetails, isError: isUpdatePlaylistError, isSuccess: isUpdatePlaylistSuccess }
  ] = useUpdatePlaylistMutation()
  const [deletePlaylist, { isLoading: isDeletingPlaylist }] = useDeletePlaylistMutation()
  const [removeClip, { isLoading: isRemovingClip }] = useRemoveClipMutation()
  const [updateClipIndex, { isLoading: isUpdatingClipIndex }] = useUpdateClipIndexMutation()

  const [getAsset, { data: asset }] = lvsApi.endpoints.getAsset.useLazyQuery()

  const [playlistTitle, setPlaylistTitle] = useState<string>('')
  const [playlistDescription, setPlaylistDescription] = useState<string>('')

  useEffect(() => {
    if (playlist) {
      setPlaylistTitle(playlist.title)
      setPlaylistDescription(playlist.description || '')
    }
  }, [playlist])

  useEffect(() => {
    const assetId = playlist?.clips?.[currentIndex]?.asset_id
    if (assetId) {
      getAsset(assetId)
    }
  }, [playlist, currentIndex, getAsset])

  useEffect(() => {
    dispatch(doSetQueueCurrentIndex(0))
  }, [dispatch])

  useEffect(() => {
    if (historyState?.tab && historyState?.isEditMode) {
      setIsEditMode(historyState.isEditMode)
      setTabValue(historyState.tab)
    }
  }, [historyState])

  useEffect(() => {
    if (isError) {
      history.push(ROUTES.PLAYLISTS)
      notify.show('Playlist not found.', 'error')
    }
  }, [history, isError, playlistId])

  const handleChange = useCallback((_, newValue: number) => {
    return setTabValue(newValue)
  }, [])

  useEffect(() => {
    if (!playlist) return
    setPlaylistTitle(playlist.title)
    if (playlist.description) setPlaylistDescription(playlist.description)
  }, [playlist])

  useEffect(() => {
    if (isEditMode) {
      setTabValue(1)
    }
  }, [isEditMode])

  const videoString = createPlaylistVideoString(playlist?.clips)

  const segments = useSelector(selectVideoPlayerSegments)

  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))

  const isUnsavedChanges = playlistTitle !== playlist?.title || playlistDescription !== playlist?.description

  const handleEditCancel = () => {
    if (playlist) setPlaylistTitle(playlist?.title)
    if (playlist?.description || playlist?.description?.length === 0) setPlaylistDescription(playlist?.description)
    setIsEditMode(false)
  }

  const creator = parseUsername(playlist?.user)

  const createdDate = playlist?.created_at
    ? formatDate(playlist.created_at, DATE_FORMATS.CALENDAR_WITH_TIME)
    : '--/--/----'

  const playlistDetails = [
    {
      key: 'Title',
      value: isLoading ? '...' : playlistTitle,
      onChange: e => setPlaylistTitle(e.target.value),
      maxLength: MAX_PLAYLIST_TITLE_LENGTH
    },
    {
      key: 'Description',
      value: isLoading ? '...' : playlistDescription || '',
      onChange: e => setPlaylistDescription(e.target.value),
      maxLength: MAX_PLAYLIST_DESCRIPTION_LENGTH
    },
    {
      key: creator ? 'Created by' : 'Created',
      value: creator ? `${creator} on ${createdDate}` : createdDate,
      maxLength: 0
    },
    {
      key: 'Last modified on',
      value: playlist?.updated_at
        ? formatDateWithoutTimezone(playlist.updated_at, DATE_FORMATS.CALENDAR_WITH_TIME)
        : '--/--/----',
      maxLength: 0
    }
  ]

  const currentAssetId = playlist?.clips?.[currentIndex]?.asset_id || 0

  const isSaveDisabled =
    playlist &&
    (playlistTitle.length === 0 ||
      playlistTitle.length > MAX_PLAYLIST_TITLE_LENGTH ||
      playlistDescription.length > MAX_PLAYLIST_DESCRIPTION_LENGTH ||
      isUpdatingPlaylistDetails ||
      (!isUnsavedChanges && isEditMode))

  const clipDetails: {
    details: string
    sx: SxProps
    variant: 'body1' | 'body2'
    testid?: string
  }[] = [
    {
      details: asset?.name || '',
      sx: { color: theme.palette.primary.main },
      variant: 'body2',
      testid: 'asset-name'
    },
    {
      details: playlist?.clips[currentIndex]?.offset
        ? `Clip starts at ${formatTime(playlist.clips[currentIndex].offset)}`
        : '',
      sx: { pt: 1 },
      variant: 'body1',
      testid: 'clip-start-time'
    },
    {
      details: assetData?.[currentAssetId]?.startDate
        ? `Recorded on ${formatDate(assetData[currentAssetId].startDate, DATE_FORMATS.CALENDAR_WITH_TIME)} (UTC)`
        : '',
      sx: { mt: 1, [theme.breakpoints.down('md')]: { mt: 1, mb: 1 } },
      variant: 'body1',
      testid: 'asset-start-date'
    }
  ]

  const handleUpdateClipIndex = (clipId: string, index: number) => {
    if (tenantId && playlist) {
      updateClipIndex({
        tenantId,
        playlistId: playlist.id,
        clipId,
        index
      })
    }
  }

  const handleDeletePlaylist = useCallback(() => {
    if (tenantId && playlist) {
      deletePlaylist({
        tenantId,
        playlistId: playlist.id
      })
        .then(() => setDeletePlaylistOpen(false))
        .then(() => notify.show('Playlist successfully deleted.', 'success'))
        .then(() => history.push('/playlists'))
    }
  }, [deletePlaylist, history, playlist, tenantId])

  const updatePlaylistDetails = () => {
    if (tenantId && playlist) {
      updatePlaylist({
        tenantId,
        playlistId: playlist.id,
        body: {
          private: false,
          title: playlistTitle,
          description: playlistDescription
        }
      }).then(() => setIsEditMode(false))
    }
  }

  useEffect(() => {
    if (isUpdatePlaylistSuccess) {
      notify.show('Playlist details successfully updated.', 'success')
    } else if (isUpdatePlaylistError) {
      notify.show('You may not have the correct permissions to update the playlist details.', 'error')
    }
  }, [isUpdatePlaylistSuccess, isUpdatePlaylistError])

  const scrollBodyRef = useRef<HTMLDivElement>(null)

  const scrollToClip = useCallback(
    (index: number) => {
      if (playlist) {
        setTimeout(() => {
          const $itemToScrollTo = document.getElementById(`clip-${playlist.clips[index]?.id || ''}`)
          const anchorPoint =
            ($itemToScrollTo?.offsetTop as number) - (scrollBodyRef.current?.offsetTop as number) - SCROLL_BUFFER

          anchorPoint >= -SCROLL_BUFFER &&
            scrollBodyRef.current?.scroll({
              top: anchorPoint,
              left: 0,
              behavior: 'smooth'
            })
        }, 0)
      }
    },
    [playlist]
  )

  const src = `${process.env.REACT_APP_LVS_API}/v3/assembly/hls?${videoString}`

  const { player, state } = usePlayer()

  const selectClip = useCallback(
    (id: string) => {
      if (!playlist?.clips) return

      const index = playlist?.clips?.findIndex(clip => clip.id === id)
      setIsEditMode(false)
      setTabValue(0)

      if (index === currentIndex) {
        if (state === 'playing') {
          player?.pause()
          return
        }

        player?.play()
      } else {
        dispatch(doSetQueueCurrentIndex(index))

        if (segments.length) {
          player?.currentTime(getTimeFromIndex(index, segments))
        }

        player?.play()
      }
    },
    [currentIndex, dispatch, player, playlist, segments, state]
  )

  const addClipToPlaylist = useCallback((clip: AddToPlaylistClip): void => {
    setAddToPlaylistClip(clip)
    setAddToPlaylistDialogOpen(true)
  }, [])

  const playFullVideo = useCallback(
    (clip: MiddlewareApi.PlaylistClip): void => {
      const seekQuery = clip.offset ? `?seekToTime=${clip.offset.toFixed()}` : ''
      const endTime = clip.offset + clip.duration
      sendVideoPlay(clip.asset_id.toString(), clip.title, clip.offset, endTime, 'play_full_video')
      const baseUrl = generatePath(ROUTES.PLAYLISTS_VIEW_PLAYLIST_PLAY_FULL_VIDEO, {
        assetId: clip.asset_id
      })
      history.push(`${baseUrl}${seekQuery}`, {
        prevPagePlaylistId: playlistId
      })
    },
    [history, playlistId]
  )

  const editClip = useCallback(
    (clip: MiddlewareApi.PlaylistClip): void => {
      const { duration } = clip
      const assetId = clip.asset_id.toString()
      const endTime = clip.offset + duration
      sendVideoPlay(assetId, clip.title, clip.offset, endTime, 'edit_video')
      history.push(
        generatePath(ROUTES.VIEW_PLAYLIST_EDIT_CLIP, {
          playlistId: clip.playlist_id,
          clipId: clip.id
        })
      )
    },
    [history]
  )

  const handleOpenRemoveClipDialog = (clip: MiddlewareApi.PlaylistClip) => {
    setRemoveClipDialogOpen(true)
    setClipToRemove(clip)
  }

  const handleRemoveClip = useCallback(
    (clip: MiddlewareApi.PlaylistClip | null) => {
      if (tenantId && playlist && clip) {
        removeClip({
          tenantId,
          playlistId: playlist.id,
          clipId: clip.id
        })
          .then(() => {
            setRemoveClipDialogOpen(false)
            setClipToRemove(null)
          })
          .then(() => notify.show('Clip successfully removed from playlist.', 'success'))
          .catch(error => console.error(error))
      }
    },
    [playlist, removeClip, tenantId]
  )

  const playlistDuration = playlist?.clips?.reduce((accum, clip) => accum + Number(clip?.duration), 0)

  useEffect(() => {
    scrollToClip(currentIndex)
  }, [currentIndex, scrollToClip, playlist])

  const { isMobileOrTablet } = useIsMobileDevice()

  const getQueueItemActions = (clip: MiddlewareApi.PlaylistClip): QueueAction[] => {
    const ownPlaylistActions = [
      { title: 'Edit clip', onClick: () => editClip(clip) },
      { title: 'Remove clip', onClick: () => handleOpenRemoveClipDialog(clip), color: theme.palette.error.main }
    ]

    const sharedPlaylistActions = [
      {
        title: 'Add clip to playlist',
        onClick: () =>
          addClipToPlaylist({
            assetId: clip.asset_id,
            offset: clip.offset,
            duration: clip.duration,
            title: clip.title,
            description: ''
          })
      },
      { title: 'Play full video', onClick: () => playFullVideo(clip) }
    ]

    const allActions = [...sharedPlaylistActions, ...ownPlaylistActions]

    return isPlaylistShared ? sharedPlaylistActions : allActions
  }

  const searchResultsClipTitles: string[] = []

  const dragEnd = ({ destination, source, draggableId }: DropResult) => {
    if (!destination || destination.index === source.index) {
      return
    }

    handleUpdateClipIndex(draggableId, destination.index)
  }

  const playlistClips = playlist
    ? playlist.clips.map((playlistClip, index) => {
        searchResultsClipTitles.push(playlistClip.title)
        const actions = getQueueItemActions(playlistClip)

        const clipAsset = assetData?.[playlistClip.asset_id]
        const tags = clipAsset?.tags || []
        const creationDate = formatDate(clipAsset?.startDate, DATE_FORMATS.CALENDAR)

        return (
          <Grid xs={12} item key={playlistClip.id} id={`clip-${playlistClip.id}`}>
            {isUpdatingClipIndex || isFetching ? (
              <Skeleton width="100%" height="126px" sx={{ mb: 1 }} />
            ) : (
              <Draggable draggableId={playlistClip.id} key={playlistClip.id} index={index}>
                {(provided: DraggableProvided) => (
                  <PlayerQueueClip
                    assetId={playlistClip.asset_id.toString()}
                    isActive={index === currentIndex}
                    isPlaying={index === currentIndex && state === 'playing'}
                    title={playlistClip.title}
                    creationDate={creationDate}
                    startsAtTime={playlistClip.offset}
                    actions={hideEditClipActionOnMobile(actions, isMobileOrTablet)}
                    tags={tags}
                    duration={playlistClip.duration}
                    playClip={() => selectClip(playlistClip.id)}
                    provided={!isPlaylistShared ? provided : undefined}
                  />
                )}
              </Draggable>
            )}
          </Grid>
        )
      })
    : null

  if (isLoading && !playlist) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
        <PageHeader>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ [theme.breakpoints.down('md')]: { flexDirection: 'column', alignItems: 'flex-start' } }}
          >
            <Skeleton width="70%" height="80px" />

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  [theme.breakpoints.down('md')]: { mt: 1 }
                }}
              >
                <Button variant="contained" sx={{ mr: 1 }} disabled />
                <Button variant="contained" sx={{ mr: 1 }} disabled />
              </Box>
              <ExtraActions sx={{ [theme.breakpoints.down('md')]: { mt: 1 } }} buttonSize="medium" actions={[]} />
            </Box>
          </Box>
        </PageHeader>
        <Container
          sx={{
            [theme.breakpoints.down('lg')]: { paddingLeft: 0, paddingRight: 0 }
          }}
        >
          <Grid
            sx={{ '& > .MuiGrid-item': { [theme.breakpoints.down('lg')]: { paddingTop: 0 } }, mt: 2 }}
            container
            columnSpacing={2}
          >
            <Grid item xs={12} lg={7} sx={{ paddingLeft: 0 }}>
              <Skeleton width="100%" height="410px" sx={{ mb: 1 }} />

              <List>
                <ListItem
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-start',
                    py: 2,
                    alignItems: 'flex-start',
                    borderBottom: `1px solid ${theme.palette.grey[50]}`
                  }}
                />
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    py: 2,
                    alignItems: 'flex-start'
                  }}
                >
                  <Skeleton width="100%" height="110px" sx={{ mb: 1 }} />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} lg={5}>
              <Grid
                ref={scrollBodyRef}
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    maxHeight: 'calc(100vh - 190px)',
                    overflowY: 'auto'
                  }
                }}
                container
              >
                <Skeleton width="100%" height="110px" sx={{ mb: 1 }} />
                <Skeleton width="100%" height="110px" sx={{ mb: 1 }} />
                <Skeleton width="100%" height="110px" sx={{ mb: 1 }} />
                <Skeleton width="100%" height="110px" sx={{ mb: 1 }} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }

  const viewPlaylistTabs: TabObject[] = [
    {
      title: 'Playlist clips',
      component: playlist?.clips?.length ? (
        <Grid
          sx={{ '& > .MuiGrid-item': { [theme.breakpoints.down('lg')]: { paddingTop: 0 } }, mt: 2 }}
          container
          columnSpacing={2}
        >
          <Grid item xs={12} lg={7} sx={{ paddingLeft: 0 }}>
            <Player autoplay src={src} />

            <Typography
              sx={{
                [theme.breakpoints.down('lg')]: { marginLeft: 2 },
                borderBottom: `1px solid ${theme.palette.grey[50]}`
              }}
              mt={2}
              pb={2}
              variant="body2"
              data-testid="playing-clip-x-of-y"
            >
              Playing clip {currentIndex + 1} of {playlist.clips.length}
            </Typography>

            <List>
              <ListItem
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-start',
                  py: 2,
                  alignItems: 'flex-start',
                  borderBottom: `1px solid ${theme.palette.grey[50]}`,
                  [theme.breakpoints.down('md')]: { flexDirection: 'column' }
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    minWidth: '184px',
                    color: theme.palette.grey[450],
                    [theme.breakpoints.down('md')]: { mb: 2 }
                  }}
                >
                  Clip details
                </Typography>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                  data-testid="playing-clip-details"
                >
                  <Typography color={theme.palette.grey[800]} variant="head600" data-testid="playing-clip-title">
                    {playlist?.clips[currentIndex].title}
                  </Typography>
                  <Typography
                    color={theme.palette.grey[800]}
                    variant="body2"
                    pt={1}
                    data-testid="playing-clip-description"
                  >
                    {playlist?.clips[currentIndex]?.description}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  py: 2,
                  alignItems: 'flex-start',
                  [theme.breakpoints.down('lg')]: { flexDirection: 'column' }
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ minWidth: '184px', color: theme.palette.grey[450], [theme.breakpoints.down('md')]: { mb: 2 } }}
                >
                  Source video
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} data-testid="playing-clip-source-video-details">
                  {clipDetails.map(({ details, sx, variant, testid }) => (
                    <Typography key={details} variant={variant} sx={sx} data-testid={testid}>
                      {details}
                    </Typography>
                  ))}
                </Box>
                <Button
                  onClick={() => playFullVideo(playlist.clips[currentIndex])}
                  size="small"
                  variant="outlined"
                  sx={{
                    alignSelf: 'center',
                    right: 0,
                    position: 'absolute',
                    [theme.breakpoints.down('lg')]: { position: 'initial', alignSelf: 'flex-start', mt: 1 }
                  }}
                >
                  Play full video
                </Button>
              </ListItem>
            </List>
          </Grid>
          <DragDropContext onDragEnd={dragEnd}>
            <Grid item xs={12} lg={5}>
              <Droppable droppableId="droppable">
                {({ droppableProps, innerRef }: DroppableProvided) => (
                  <Grid
                    ref={scrollBodyRef}
                    sx={{
                      [theme.breakpoints.up('lg')]: {
                        maxHeight: 'calc(100vh - 190px)',
                        overflowY: 'auto',
                        height: '100%'
                      },
                      [theme.breakpoints.down('md')]: {
                        paddingX: 2
                      }
                    }}
                    container
                  >
                    <Box {...droppableProps} ref={innerRef} style={{ width: '100%' }}>
                      {playlistClips}
                    </Box>
                  </Grid>
                )}
              </Droppable>
            </Grid>
          </DragDropContext>
        </Grid>
      ) : (
        <NoResultsMessage
          variant="vertical"
          Icon={NoPlaylistClipsIcon}
          header="No clips yet!"
          description={
            !isPlaylistShared
              ? 'Search using keywords and select clips you would like to add to this playlist.'
              : 'This playlist that has been shared with you is empty.'
          }
          action={!isPlaylistShared ? { title: 'Search', onClick: () => history.push(ROUTES.SEARCH) } : undefined}
          sx={{ mt: 16, [theme.breakpoints.down('md')]: { mt: 10 } }}
        />
      )
    },
    {
      title: 'Playlist details',
      component: (
        <List sx={{ paddingTop: 0, paddingBottom: 0, mt: 1 }}>
          {playlistDetails.map(({ key, value, onChange, maxLength }, index) => (
            <ListItem
              divider={index + 1 !== playlistDetails.length}
              sx={{ display: 'flex', justifyContent: 'flex-start', py: 2 }}
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{ minWidth: '184px', fontWeight: 700, color: theme.palette.grey[450] }}
                  data-testid="playlist-detail-label"
                >
                  {key}
                </Typography>
                {((isEditMode && key === 'Title') || (isEditMode && key === 'Description')) && (
                  <Typography
                    sx={{
                      minWidth: '184px',
                      color:
                        value.length > maxLength || (value.length === 0 && key === 'Title')
                          ? theme.palette.error.main
                          : theme.palette.grey[400]
                    }}
                    data-testid="under-playlist-text"
                  >
                    {key === 'Title'
                      ? (value.length === 0 && 'Playlist title required') || `${playlistTitle.length} / ${maxLength}`
                      : `${playlistDescription.length} / ${maxLength}`}
                  </Typography>
                )}
              </Box>

              {(isEditMode && key === 'Title') || (isEditMode && key === 'Description') ? (
                <TextField
                  onChange={onChange}
                  defaultValue={value}
                  fullWidth
                  multiline
                  error={value.length > maxLength || (key === 'Title' && playlistTitle.length === 0)}
                  data-testid="playlist-detail-value"
                />
              ) : (
                <Typography variant="body2" data-testid="playlist-detail-value">
                  {value}
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      )
    }
  ]
  if (!playlist) return null
  return (
    <>
      <Prompt when={isUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />
      <PageHeader>
        <Prompt
          when={isUnsavedChanges}
          message={location => {
            return location.pathname.startsWith('/login')
              ? false
              : 'You have unsaved changes, are you sure you want to leave?'
          }}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ [theme.breakpoints.down('md')]: { flexDirection: 'column', alignItems: 'flex-start' } }}
        >
          <Box>
            <Typography
              variant={isBelowLgBreakpoint ? 'head600' : 'head800'}
              color={theme.palette.grey[800]}
              mb={1}
              data-testid="page-title"
              sx={{
                maxWidth: '500px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                [theme.breakpoints.down('md')]: { maxWidth: '200px' }
              }}
            >
              {playlist.title}
            </Typography>

            <Box display="flex" alignItems="center">
              <Typography
                variant={isBelowLgBreakpoint ? 'body1' : 'body2'}
                color={theme.palette.grey[500]}
                sx={{ mr: 2, [theme.breakpoints.down('lg')]: { mr: 1 } }}
                data-testid="playlist-duration"
              >
                Duration {formatTime(playlistDuration || 0)}
              </Typography>

              <CircleIcon sx={{ fontSize: 5, mr: 2, [theme.breakpoints.down('lg')]: { mr: 1 } }} />
              <Typography
                variant={isBelowLgBreakpoint ? 'body1' : 'body2'}
                color={theme.palette.grey[500]}
                sx={{ mr: 2, [theme.breakpoints.down('lg')]: { mr: 1 } }}
                data-testid="playlist-clip-count"
              >
                {wordAsPluralOrSingular(playlist.clips.length, 'video clip')}
              </Typography>
              {/* BA not ready */}
              {/* <CircleIcon sx={{ fontSize: 5, mr: 2, [theme.breakpoints.down('lg')]: { mr: 1 } }} /> */}
              <Typography variant={isBelowLgBreakpoint ? 'body1' : 'body2'} color={theme.palette.grey[500]}>
                {/* {formatCount()} views */}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: { mt: 1 }
              }}
            >
              {isPlaylistShared ? (
                ''
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: { flexDirection: 'column-reverse' }
                  }}
                >
                  {isUnsavedChanges && (
                    <Typography
                      variant="body2"
                      color={theme.palette.warning[500]}
                      sx={{ mr: 2, fontWeight: 510 }}
                      data-testid="unsaved-changes"
                    >
                      Unsaved changes
                    </Typography>
                  )}
                  <Box data-testid="button-container">
                    <Button
                      variant="outlined"
                      onClick={!isEditMode ? () => setIsEditMode(true) : handleEditCancel}
                      sx={{ mr: 1, [theme.breakpoints.down('md')]: { display: 'none' } }}
                      disabled={isUpdatingPlaylistDetails}
                      data-testid="edit-cancel-button"
                    >
                      {isEditMode ? (isUnsavedChanges && 'Discard') || 'Cancel' : 'Edit'}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={!isEditMode ? () => setIsEditMode(true) : handleEditCancel}
                      sx={{ mr: 1, [theme.breakpoints.up('md')]: { display: 'none' } }}
                      disabled={isUpdatingPlaylistDetails}
                    >
                      {isEditMode ? 'Cancel' : 'Edit'}
                    </Button>
                    <Button
                      variant="contained"
                      disabled={isSaveDisabled}
                      sx={{ mr: 1, [theme.breakpoints.down('md')]: { display: 'none' } }}
                      onClick={isEditMode ? () => updatePlaylistDetails() : () => setSharePlaylistOpen(true)}
                      startIcon={!isEditMode && <ShareOutlinedIcon />}
                      data-testid={isEditMode ? 'save-btn' : 'share-btn'}
                    >
                      {isEditMode ? 'Save' : 'Share'}
                    </Button>
                    <Button
                      sx={{ mr: 1, [theme.breakpoints.up('md')]: { display: 'none' } }}
                      size="small"
                      variant="contained"
                      disabled={isSaveDisabled}
                      onClick={isEditMode ? () => updatePlaylistDetails() : () => setSharePlaylistOpen(true)}
                      startIcon={!isEditMode && <ShareOutlinedIcon />}
                    >
                      {isEditMode ? 'Save' : 'Share'}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            {!isPlaylistShared && (
              <ExtraActions
                sx={{ [theme.breakpoints.down('md')]: { mt: 1 } }}
                buttonSize="medium"
                actions={[
                  {
                    onClick: () => setDeletePlaylistOpen(true),
                    title: 'Delete Playlist',
                    color: theme.palette.error.main
                  }
                ]}
              />
            )}
          </Box>
        </Box>
      </PageHeader>
      <Container
        sx={{
          [theme.breakpoints.down('lg')]: { paddingLeft: 0, paddingRight: 0 }
        }}
      >
        <Tabs tabs={viewPlaylistTabs} value={tabValue} handleChange={handleChange} sx={{ mb: 2, mt: -1 }} />
      </Container>

      <ActionConfirmationDialog
        open={deletePlaylistOpen}
        title="Delete playlist"
        actionCopy="Delete"
        isConfirmationLoading={isDeletingPlaylist}
        handleConfirmAction={handleDeletePlaylist}
        handleClose={() => setDeletePlaylistOpen(false)}
        ariaLabelledBy="delete-playlist-confirmation-dialog"
        ariaDescribedBy="delete-playlist-confirmation-dialog-content"
      >
        <DialogContentText mb={2}>
          Are you sure you want to delete the playlist <strong>&quot;{playlist.title}&quot;</strong> containing{' '}
          {wordAsPluralOrSingular(playlist.clips.length, 'clip')}?
        </DialogContentText>

        <DialogContentText>
          <strong>Note: </strong>
          Deleting a playlist is a permanent action and can’t be undone.
        </DialogContentText>
      </ActionConfirmationDialog>

      <ActionConfirmationDialog
        open={isRemoveClipDialogOpen}
        title="Remove clip"
        actionCopy="Remove"
        isConfirmationLoading={isRemovingClip}
        handleConfirmAction={() => handleRemoveClip(clipToRemove)}
        handleClose={() => setRemoveClipDialogOpen(false)}
        ariaLabelledBy="delete-clip-confirmation-dialog"
        ariaDescribedBy="delete-clip-confirmation-dialog-content"
      >
        <DialogContentText mb={2}>
          Are you sure you want to remove the clip <strong>&quot;{clipToRemove?.title}&quot;</strong> from the playlist{' '}
          <strong>&quot;{playlist.title}&quot;</strong>?
        </DialogContentText>

        <DialogContentText>
          <strong>Note: </strong>
          Removing a clip from a playlist is a permanent action and can’t be undone.
        </DialogContentText>
      </ActionConfirmationDialog>

      <SharePlaylistDialog
        open={sharePlaylistOpen}
        setClose={() => setSharePlaylistOpen(false)}
        playlistId={playlist.id}
        playlistTitle={playlist.title}
      />
      {addToPlaylistClip && (
        <AddToPlaylistDialog
          open={addToPlaylistDialogOpen}
          setClose={() => setAddToPlaylistDialogOpen(false)}
          clip={addToPlaylistClip}
        />
      )}
      <SuccessAlert open={false} message="Successfully updated" onClose={() => {}} />
    </>
  )
}
