import { createUseStyles } from 'react-jss'

export const useMediaUploadStyles = createUseStyles({
  allValidIcon: {
    width: '206px',
    height: '206px',
    borderRadius: '100px',
    fontWeight: 100
  },
  fileSelectContainer: {
    height: '240px',
    width: '475px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  resultContainer: { backgroundColor: '#F3F6F8', overflowY: 'scroll', height: '100vh', width: '50%' },
  results: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
