import { useState, useCallback, useEffect, useRef } from 'react'
import { Box, Button, Chip, SxProps, useTheme } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { useResizeObserver } from '../../../hooks/useResizeObserver'
import { getClipTagListStyles } from './ClipTagList.styles'

interface ClipTagListProps {
  tags: string[]
  tagSize?: 'small' | 'medium'
  sx?: SxProps
}

export const TAG_SPACING_HORIZONTAL = 4
export const TAG_SPACING_VERTICAL = TAG_SPACING_HORIZONTAL * 2

export const ClipTagList = ({ tags, tagSize = 'medium', sx = {} }: ClipTagListProps) => {
  const theme = useTheme()

  const [canToggle, setCanToggle] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const [toggleButtonOffset, setToggleButtonOffset] = useState(0)
  const [hiddenCount, setHiddenCount] = useState(0)

  const containerRef = useRef<HTMLDivElement>()

  const clipTagListStyles = getClipTagListStyles({
    theme,
    canToggle,
    isExpanded,
    toggleButtonOffset,
    tagSize
  })

  const calculateToggle = useCallback(() => {
    if (!containerRef?.current) {
      return
    }

    const tagHeight = tagSize === 'medium' ? 32 : 24
    const $container = containerRef.current

    if ($container.scrollHeight > tagHeight + TAG_SPACING_VERTICAL) {
      setCanToggle(true)

      const children = Array.from($container.children) as HTMLDivElement[]
      let visibleTagsWidth = 0
      let visibleTagsCount = 0

      for (const $elem of children) {
        if ($elem.offsetTop === $container.offsetTop) {
          visibleTagsWidth = visibleTagsWidth + $elem.offsetWidth + TAG_SPACING_HORIZONTAL
          visibleTagsCount += 1
        } else {
          break
        }
      }

      setHiddenCount(children.length - visibleTagsCount)
      setToggleButtonOffset(visibleTagsWidth)
    } else {
      setHiddenCount(0)
      setCanToggle(false)
    }
  }, [containerRef, tagSize])

  useEffect(() => {
    calculateToggle()
  }, [calculateToggle])

  useResizeObserver(containerRef, calculateToggle)

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(state => !state)
  }, [])

  return (
    <Box sx={{ position: 'relative', ...sx }} display="flex" aria-expanded={isExpanded}>
      <Box sx={clipTagListStyles.tagList} ref={containerRef} data-testid="tags-container">
        {tags?.map((tag, index) => (
          <Chip
            color="primary"
            size={tagSize}
            label={tag}
            // eslint-disable-next-line react/no-array-index-key
            key={`${tag}-${index}`}
            sx={clipTagListStyles.tag}
            data-testid="tag"
          />
        ))}
      </Box>
      {canToggle && (
        <Button sx={clipTagListStyles.button} aria-pressed={isExpanded} onClick={toggleIsExpanded}>
          {isExpanded ? <ExpandLessIcon fontSize="small" /> : `+${hiddenCount}`}
        </Button>
      )}
    </Box>
  )
}
