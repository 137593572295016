import { createUseStyles } from 'react-jss'
import { Breakpoint } from '../../design-system-legacy/breakpoints'

import background from '../../design-system-legacy/ThanksWhizzardBackground.svg'
import mobileBackGround from '../../design-system-legacy/whizzardBackgroundMobile.svg'

import colours from '../../theme/colours'
import { toResponsiveUnitFromPixels } from '../../theme/toResponsiveUnitFromPixels'

const useSuccessfullyRegisteredStyles = createUseStyles({
  wrapper: {
    backgroundColor: colours.martinique,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    position: 'relative',
    background: `url(${background}) no-repeat center fixed`,
    backgroundSize: 'cover',
    [Breakpoint.SM]: {
      backgroundColor: colours.martinique,
      background: `url(${mobileBackGround}) no-repeat center fixed`,
      backgroundSize: 'cover'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '85%',
    textAlign: 'center',
    paddingBottom: toResponsiveUnitFromPixels(4)
  },
  routerLink: {
    paddingLeft: toResponsiveUnitFromPixels(30),
    paddingTop: toResponsiveUnitFromPixels(30),
    [Breakpoint.SM]: {
      position: 'absolute',
      left: ' 50%',
      top: 0,
      transform: ' translateX(-50%)',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: toResponsiveUnitFromPixels(30),
      paddingLeft: 0
    }
  },
  heading: {
    color: colours.alto,
    fontWeight: 'bold',
    paddingTop: toResponsiveUnitFromPixels(14)
  },
  text: {
    fontWeight: 'lighter',
    color: colours.white,
    fontSize: '1rem'
  },
  h3: {
    color: colours.white,
    margin: 0,
    fontSize: toResponsiveUnitFromPixels(24, true)
  },
  lightBlue: {
    color: `${colours.scooter}`
  },
  resend: {
    marginTop: 30
  }
})

export default useSuccessfullyRegisteredStyles
