import { useEffect, useState } from 'react'

export const useHighlights = (highlights: string[], content: string) => {
  const [highlightedContent, setHighlightedContent] = useState<string[]>([])

  useEffect(() => {
    const highlightIndexes: number[][] = highlights?.map(highlight => {
      return highlight.split(':').map(item => parseInt(item, 10))
    })

    setHighlightedContent(highlightIndexes?.map(highlightIndex => content.slice(highlightIndex[0], highlightIndex[1])))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return highlightedContent
}
