import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'

import { Player } from '../../components/Player'
import { CardCarousel } from '../../components2.0/CardCarousel'
import { PlaylistCard } from '../../components2.0/PlaylistCard'
import { SearchBar } from '../../components2.0/SearchBar'
import { useSearchBar } from '../../components2.0/SearchBar/useSearchBar'
import { useGetSearchPlaylistsQuery } from '../../store/apis/middleware'
import { TUTORIAL_POSTER, TUTORIAL_VIDEO_LINK } from '../../constants'
import { ROUTES } from '../../RouteConfig/constants'
import { formatDate } from '../../utils/date'
import { getSearchStyles } from './Search.styles'
import { selectUserDetails } from '../../store/slices/user'

export const Search = () => {
  const theme = useTheme()
  const history = useHistory()
  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))
  const styles = getSearchStyles(theme)
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId || ''

  const {
    searchTerm,
    searchQuery,
    categoryOptions,
    handleSearchSubmit,
    handleSearchTermChange,
    handleSearchReset,
    categoryOnChange,
    handleSearchOnClear
  } = useSearchBar()

  const { data: playlistsData, isLoading: isPlaylistsLoading, isError: isPlaylistsError } = useGetSearchPlaylistsQuery({
    tenantId,
    searchQuery: '',
    pageLimit: 100,
    page: 1
  })

  const playlists = playlistsData?.playlists

  const playlistCards =
    playlists &&
    playlists.map((playlist, index) => {
      const playlistDuration = playlist.clips.reduce((accum, clip) => accum + clip.duration, 0)
      const firstClipAssetId = playlist.clips.length === 0 ? undefined : String(playlist.clips[0].asset_id)
      return (
        <Box key={playlist.id} sx={{ pr: index === playlists.length - 1 ? 0 : 2 }}>
          <PlaylistCard
            assetId={firstClipAssetId}
            modifiedOnDate={formatDate(playlist.updated_at)}
            title={playlist.title}
            duration={playlistDuration}
            numberOfClips={playlist.clips.length}
            playOnClick={() =>
              history.push(
                generatePath(ROUTES.PLAYLIST_VIEW_PLAYLIST, {
                  playlistId: playlist.id
                })
              )
            }
            sx={{ minWidth: 276, width: 276 }}
          />
        </Box>
      )
    })

  return (
    <>
      <Box sx={styles.pageContainer}>
        <Container sx={styles.headerContainer}>
          <Typography mb={3} color={theme.palette.grey[250]} sx={styles.pageTitle}>
            SEARCH
          </Typography>
          <Typography sx={styles.mainHeading} color={theme.palette.common.white}>
            Search for the moments that matter
          </Typography>
          <Typography sx={styles.subHeading} color={theme.palette.grey[150]}>
            Search by keyword, subject, date, people, on-screen texts, and more to find precise video segments to create
            your highlights videos.
          </Typography>
          <SearchBar
            value={searchTerm}
            onSubmit={handleSearchSubmit}
            onChange={handleSearchTermChange}
            onReset={handleSearchReset}
            onClear={handleSearchOnClear}
            dropdownValue={searchQuery.category}
            dropdownOnChange={categoryOnChange}
            dropdownOptions={categoryOptions}
          />
        </Container>
      </Box>

      <Container sx={styles.mainContentContainer}>
        <CardCarousel
          title="My playlists"
          noResultsTitle="No playlists"
          noResultsDescription={
            isPlaylistsError
              ? 'Playlists not found.'
              : `You have not created any playlists yet.
                Search across and within all your videos to add selected clips to new playlists.`
          }
          isLoading={isPlaylistsLoading}
        >
          {playlistCards}
        </CardCarousel>
        <Typography color={theme.palette.grey[800]} sx={styles.tutorialHeading}>
          Tutorial
        </Typography>
        <Box sx={styles.tutorialContainer}>
          <Box sx={styles.tutorialInfoContainer}>
            <Typography color={theme.palette.grey[500]} sx={styles.tutorialInfo}>
              Watch this short video to see how easy it is to search your videos by keyword, subject, date, presenter,
              and more, and create a personalised highlights video with your moments that matter, instantly.
            </Typography>
            <Button
              href={ROUTES.SUPPORT}
              target="_blank"
              variant="contained"
              size={isBelowLgBreakpoint ? 'medium' : 'large'}
              sx={styles.learnMoreButton}
            >
              Learn more
            </Button>
          </Box>

          <Box sx={styles.tutorialPlayerContainer}>
            <Player
              type="video/mp4"
              poster={TUTORIAL_POSTER}
              styleOverides={{ borderRadius: 12 }}
              src={TUTORIAL_VIDEO_LINK}
            />
          </Box>
        </Box>
      </Container>
    </>
  )
}
