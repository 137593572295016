const config = {
  BASE_PIXEL: 16,
  PRECISION: 4,
  SPLITTER: ' ',
  PIXEL: 'px',
  REM: 'rem',
  EM: 'em'
}

/**
 * converts pixel to rem
 * @param pixels example '16px solid red', '-10px', '20px, 30px', [10, 20, 30], 10
 * @param isEm default is false. If true, then return 'em'
 * @returns converted rem values (base pixel value is 16). example '1rem solid red'
 */
export const toResponsiveUnitFromPixels = (pixels: string | number | number[], isEm?: boolean) => {
  let splitValues = typeof pixels === 'string' && pixels.split(config.SPLITTER)

  if (!splitValues) {
    splitValues = Array.isArray(pixels) ? pixels.map(pixel => `${pixel}${config.PIXEL}`) : [`${pixels}${config.PIXEL}`]
  }
  const convertedValues = splitValues.map(pixel => {
    const endsWithPixel = pixel.toLowerCase().endsWith(config.PIXEL)
    const endsWithPixelWithComma = pixel.toLowerCase().endsWith(`${config.PIXEL},`)
    if (endsWithPixel || endsWithPixelWithComma) {
      const value = Number((parseFloat(pixel) / config.BASE_PIXEL).toFixed(config.PRECISION))
      if (value) {
        return `${value}${isEm ? config.EM : config.REM}${endsWithPixelWithComma ? ',' : ''}`
      }
      return value
    }
    return pixel
  })
  return convertedValues.join(config.SPLITTER)
}
