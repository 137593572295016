import Typo from 'typo-js'

interface Word {
  word: string
  suggestion?: string
}

let dictionary: InstanceType<typeof Typo> | undefined

export const loadDictionary = async () => {
  if (!dictionary) {
    try {
      const [affData, wordsData] = await Promise.all([
        /* eslint-disable import/no-webpack-loader-syntax */
        import('!!raw-loader!../../../node_modules/typo-js/dictionaries/en_US/en_US.aff'),
        import('!!raw-loader!../../../node_modules/typo-js/dictionaries/en_US/en_US.dic')
      ])

      dictionary = new Typo('en_US', affData.default, wordsData.default)
    } catch (e) {
      console.error('Could not load dictionary', (e as Error)?.message)
    }
  }

  return dictionary
}

export const getSpellingSuggestions = (value: string) => {
  if (!dictionary || !value) {
    return null
  }

  const suggestions = value.split(' ').map(word => {
    if (!dictionary || dictionary.check(word)) {
      return { word }
    }

    const [suggestion] = dictionary.suggest(word, 1)
    return { word, suggestion }
  })

  return suggestions.some(s => !!s.suggestion) ? (suggestions as Word[]) : null
}
