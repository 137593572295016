import { Palette } from '@mui/material'
import { skyTheme } from './themes'

const colourTranslation = (palette: Palette) => ({
  primary: palette.secondary[800],
  secondary: palette.primary.main,
  success: palette.success.main,
  error: palette.error.main,
  warning: palette.warning[600],
  primaryText: palette.primary.contrastText,
  secondaryText: palette.secondary.contrastText,
  inherit: 'inherit'
})

export default colourTranslation(skyTheme.palette)
