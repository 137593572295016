import { MutableRefObject, useEffect } from 'react'

export const useResizeObserver = (ref: MutableRefObject<HTMLElement | undefined>, onResize: ResizeObserverCallback) => {
  useEffect(() => {
    const elem = ref.current
    let observer: ResizeObserver | undefined

    if (elem) {
      observer = new ResizeObserver(onResize)
      observer.observe(elem)
    }

    return () => {
      elem && observer?.unobserve(elem)
    }
  }, [ref, onResize])
}
