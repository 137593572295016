import { Box, Tabs as MuiTabs, Tab, SxProps } from '@mui/material'
import { ReactNode, SyntheticEvent } from 'react'
import { TabPanel } from './TabPanel'

export interface TabObject {
  title: string
  component: ReactNode
}
interface TabsProps {
  value: number
  handleChange: (event: SyntheticEvent, newValue: number) => void
  tabs: TabObject[]
  sx?: SxProps
}

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

export const Tabs = ({ value, handleChange, tabs, sx }: TabsProps) => {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab key={tab.title} label={tab.title} {...a11yProps(index)} />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.title} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  )
}
