import { Theme } from '@mui/material'

export const getFilterBarStyles = (theme: Theme): StylesProps => ({
  filterBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    mt: 0.25,
    px: 2,
    pt: 1.5,
    pb: 0.5,
    [theme.breakpoints.down('md')]: { p: 0, justifyContent: 'center' }
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiAutocomplete-root': { mr: 2, mb: 1 },
    [theme.breakpoints.down('md')]: { display: 'none' }
  }
})
