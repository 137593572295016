/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AuthApi } from './types'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LVS_API }),
  endpoints: builder => ({
    authenticate: builder.query<AuthApi.AuthResponse, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: '/v2/iam/auth/signin',
        method: 'POST',
        body: {
          userName: email,
          password,
          platform: 'WHIZZARDMEET'
        }
      })
    }),
    getUserType: builder.query<AuthApi.UserTypeResponse, string>({
      query: email => `/signup-auth0/email/${email}`
    }),
    putCode: builder.query<AuthApi.AuthResponse, { code: string; redirectUrl: string; tenantId: string }>({
      query: ({ code, redirectUrl, tenantId }) => ({
        url: `/signup-auth0/code`,
        params: { tenantId, code, redirectUrl },
        method: 'PUT',
        body: {}
      })
    })
  })
})
