import * as Yup from 'yup'

export default Yup.object({
  // TODO: Add back in once new LVS auth API uses email addresses for login
  email: Yup.string()
    // .email('Please enter a valid e-mail.')
    .required('Email required.')
    .strict(),

  password: Yup.string().required('Password required.')
})
