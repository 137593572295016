import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NavLink, RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import Icon from '../../design-system-legacy/icons/IcoMoon/icoMoonFontIcons'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined'
import { ExternalLink } from '../ExternalLink'
import { ProfileMenu } from '../ProfileMenu'
import { WhizzardLogoNoText } from '../../design-system-legacy/core/WhizzardLogoNoText/WhizzardLogoNotext'
import colours from '../../theme/colours'
import { Avatar, Box, IconButton, List, useTheme } from '@mui/material'
import { ROUTES } from '../../RouteConfig/constants'
import { getNavigationStyles } from './Navigation.styles'
import CogIcon from '../icons/CogIcon/CogIcon'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { selectUserDetails } from '../../store/slices/user'
import { clearStore } from '../../store/slices/auth'
import { userApi } from '../../store/apis/user'

interface SidenavProps extends RouteComponentProps {
  openTopNavToggle?: React.MouseEventHandler<HTMLButtonElement>
}
const Sidenav: React.FC<SidenavProps> = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const userDetails = useSelector(selectUserDetails)

  const isAdmin = userDetails?.role === 'ADMIN'
  const { pathname } = useLocation()

  const [signOut, { isLoading: isSignOutLoading }] = userApi.endpoints.signOut.useLazyQuery()

  const pages = [
    {
      route: ROUTES.SEARCH,
      icon: <SearchOutlinedIcon fontSize="large" />,
      isAdminRoute: false,
      dataTestId: 'search-icon',

      isActive: () =>
        [ROUTES.SEARCH as string, ROUTES.SEARCH_RESULTS as string, ROUTES.SEARCH_RESULTS_PLAY_ALL as string].includes(
          pathname
        ) || pathname.includes(ROUTES.SEARCH_RESULTS_PLAY_ALL_PLAY_FULL_VIDEO.replace(/\/:.*/, ''))
    },
    {
      route: ROUTES.PLAYLISTS,
      isAdminRoute: false,
      icon: <PlaylistPlayOutlinedIcon fontSize="large" />,
      dataTestId: 'playlists-icon',
      isActive: () =>
        [ROUTES.PLAYLISTS as string].includes(pathname) ||
        pathname.includes(ROUTES.PLAYLIST_VIEW_PLAYLIST.replace(/\/:.*/, ''))
    },
    {
      route: ROUTES.ADMIN_USERMANAGER,
      isAdminRoute: true,
      icon: <CogIcon />,
      dataTestId: 'admin-console-icon',
      isActive: () =>
        [
          ROUTES.ADMIN_USERMANAGER as string,
          ROUTES.ADMIN_MY_CONTENT as string,
          ROUTES.ADMIN_INTEGRATIONS as string
          // ROUTES.ADMIN_ZOOM_INTEGRATIONS as string,
        ].includes(pathname)
    }
  ]

  const email = userDetails?.email

  useOnClickOutside(ref, () => {
    setIsProfileMenuOpen(false)
  })

  const {
    navigationStyles,
    navLinkStyles,
    listStyles,
    logoStyles,
    profileMenuStyles,
    avatarStyles,
    iconButtonStyles
  } = getNavigationStyles(theme)

  return (
    <Box sx={navigationStyles} ref={ref}>
      <List sx={listStyles} data-testid="sidenav-options">
        <Box sx={logoStyles}>
          <WhizzardLogoNoText />
        </Box>
        {pages.map(({ route, icon, isActive, isAdminRoute, dataTestId }) => {
          return (
            <NavLink
              style={isActive => ({
                display: !isAdmin && isAdminRoute ? 'none' : 'flex',
                backgroundColor: isActive ? theme.palette.primary.main : 'none',
                ...navLinkStyles
              })}
              key={route}
              isActive={isActive}
              exact
              to={route}
            >
              <IconButton sx={iconButtonStyles} data-testid={dataTestId}>
                {icon}
              </IconButton>
            </NavLink>
          )
        })}
      </List>
      <List sx={listStyles}>
        <ExternalLink to={ROUTES.SUPPORT}>
          <IconButton sx={{ ...iconButtonStyles, mb: 1 }}>
            <Icon color={colours.white} size={36} icon="question-outline" />
          </IconButton>
        </ExternalLink>
        <Box sx={profileMenuStyles}>
          <Avatar onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)} sx={avatarStyles}>
            <PersonOutlineIcon />
          </Avatar>
          {isProfileMenuOpen && (
            <ProfileMenu
              isLoading={isSignOutLoading}
              handleLogout={async () => {
                await signOut()
                history.push({ pathname: ROUTES.LOGIN, state: undefined })
                dispatch(clearStore())
              }}
              name={email}
            />
          )}
        </Box>
      </List>
    </Box>
  )
}

export default withRouter(Sidenav)
