import React, { useState } from 'react'
import { TableHeader } from '../../Table/TableHeader'
import { useMyContentTable } from '../../../hooks/useMyContentTable'
import { timeToHours, timeToMinutes, timeToSeconds } from '../../../utils'
import Skeleton from 'react-loading-skeleton'
import { TablePagination } from '../../Pagination/TablePagination/TablePagination'
import { TableRow } from '../../Table/TableRow/TableRow'
import { useMyContentStyles } from './MyContent.styles'
import { IMyContent } from './types'
import Icon from '../../../design-system-legacy/icons/IcoMoon/icoMoonFontIcons'
import CachedIcon from '@mui/icons-material/Cached'
import ReactTooltip from 'react-tooltip'
import colours from '../../../theme/colours'
import { Button } from '../../Button'

export const MyContent = ({ pageSize }: IMyContent.Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const {
    isCheck,
    handleClick,
    handleSelectAll,
    myContent,
    displayVideoState,
    getTableData,
    loading
  } = useMyContentTable(currentPage, pageSize)

  const classes = useMyContentStyles()
  const emptyDatePlaceholder = '-- / -- / ----'
  const formatDate = (startDate: string) => {
    return new Date(startDate?.slice(0, 10).replaceAll('-', '/')).toLocaleDateString()
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }
  const displayHours = (duration: number) => {
    const hours = timeToHours(duration)
    if (hours) {
      return `${hours}hr ${hours === 1 ? '' : 's'}`
    }
    return ''
  }

  return (
    <div className={classes.myContentContainer}>
      <div className={classes.myContentHeadingContainer}>
        <h1 className={classes.myContentHeading} data-testid="page-title">
          My Content
        </h1>
        <Button
          color="primary"
          variant={'icon'}
          tooltipText={'Refresh Table'}
          tooltipId="resync"
          style={{ marginLeft: 8 }}
          onClick={() => getTableData(true)}
        >
          <Icon
            size={20}
            icon="refresh"
            style={{
              animation: loading ? 'spin 1s reverse infinite' : 'none',
              cursor: loading ? 'not-allowed' : 'pointer',
              opacity: loading ? 0.8 : 1
            }}
          />
          {!loading && (
            <ReactTooltip backgroundColor={colours.tuna} effect="solid" id="resync">
              {'Refresh Table'}
            </ReactTooltip>
          )}
        </Button>
      </div>
      {myContent?.content?.length ? (
        <>
          {myContent ? (
            <TableHeader
              handleSelectAll={handleSelectAll}
              gridTemplateColumns={'1fr 2fr repeat(4, 1fr)'}
              headers={['ID', 'Title', 'Duration', 'Date Recorded', 'Source', 'Status']}
              divider
            />
          ) : (
            <Skeleton height={50} />
          )}

          {myContent ? (
            myContent.content.map(({ name, duration, startDate, id, representations }, index) => (
              <TableRow
                gridTemplateColumns={'1fr 2fr repeat(4, 1fr)'}
                divider
                key={index}
                id={id}
                onChange={handleClick}
                isChecked={isCheck.includes(id)}
              >
                <td>#{id}</td>
                <td className={classes.myContentName}>{name}</td>
                <td>{`${displayHours(duration)}${timeToMinutes(duration)}min${
                  timeToMinutes(duration) === 1 ? '' : 's'
                } ${timeToSeconds(duration)}s`}</td>
                <td>{formatDate(startDate) || emptyDatePlaceholder}</td>
                <td className={classes.myContentTBC}>
                  {representations[0]?.sourceIdentifier?.toUpperCase() || 'UNKNOWN'}
                </td>
                <td className={classes.myContentTBC}>{displayVideoState(id)}</td>
              </TableRow>
            ))
          ) : (
            <Skeleton count={25} height={30} />
          )}
        </>
      ) : (
        <p>{'No Content Available.'}</p>
      )}

      {myContent && (
        <TablePagination
          currentPage={currentPage}
          numberOfElements={myContent?.numberOfElements}
          totalCount={myContent.totalElements}
          pageSize={pageSize}
          onPageChange={page => onPageChange(page)}
          countPrefixText="Total number of videos:"
        />
      )}
    </div>
  )
}
