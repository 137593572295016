import { format, isValid } from 'date-fns'
import { DATE_FORMATS } from '../../constants'

enum EMonth {
  JAN = '01',
  FEB = '02',
  MAR = '03',
  APR = '04',
  MAY = '05',
  JUN = '06',
  JUL = '07',
  AUG = '08',
  SEP = '09',
  OCT = '10',
  NOV = '11',
  DEC = '12'
}

export const formatDateString = (
  dateString: string | undefined | null,
  locale = 'en-GB',
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
) => {
  if (!dateString) {
    console.error('date undefined')
    return ''
  }
  const date = new Date(dateString)
  return date.toLocaleString(locale, options)
}

export const formatDate = (date: Date | string | number, dateFormat = DATE_FORMATS.CALENDAR) => {
  if (!isValid(new Date(date))) {
    return ''
  }

  return format(typeof date === 'string' ? new Date(date) : date, dateFormat)
}

export const compareDate = (date: string) => date === new Date().toISOString().slice(0, 10)

// Format an ISO date for the backend
export const formatISODate = (date: Date = new Date()) => {
  return date.toISOString().replace('Z', '')
}

export const formatDateWithoutTimezone = (date: string, dateFormat = DATE_FORMATS.CALENDAR) => {
  return formatDate(date.replace('Z', ''), dateFormat)
}

export const convertToUTC = (date: Date, isEndTime: boolean = false) => {
  const padZero = (n: number) => `${n < 10 ? 0 : ''}${n}`

  const toUtc = (d: Date) => {
    d.setHours(isEndTime ? 23 : 0)
    d.setMinutes(isEndTime ? 59 : 0)
    d.setSeconds(isEndTime ? 59 : 0)
    const [, dd, MM, yyyy, t] = d.toUTCString().split(' ')
    return {
      dd: padZero(Number(dd)),
      MM: EMonth[MM.toUpperCase()],
      yyyy,
      t
    }
  }
  const { yyyy, MM, dd, t } = toUtc(date)
  return `${yyyy}-${MM}-${dd}T${t}.${isEndTime ? '999' : '000'}Z`
}
