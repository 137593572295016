import { tableCellClasses, Theme } from '@mui/material'

export const getPlaylistTableStyles = (theme: Theme, isSharedPlaylistTable: boolean): StylesProps => ({
  headerLabel: { whiteSpace: 'nowrap', py: 1 },
  description: { [theme.breakpoints.down('lg')]: { display: 'none' } },
  descriptionLabel: {
    whiteSpace: 'nowrap',
    maxWidth: '380px',
    pr: 1
  },
  sharedOnLastModified: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: { display: 'none' }
  },
  sharedOnLastModifiedLabel: { pr: 1 },
  views: { display: isSharedPlaylistTable ? 'none' : 'table-cell' },
  viewsLabel: { pr: 1, [theme.breakpoints.down('md')]: { display: 'none' } },
  table: {
    [`& .${tableCellClasses.root}`]: {
      borderBottom: `1px solid ${theme.palette.grey[25]}`
    }
  },
  paper: { width: '100%', mb: 2 }
})
