import { Theme } from '@mui/material'

import { TAG_SPACING_HORIZONTAL, TAG_SPACING_VERTICAL } from './ClipTagList'

interface ClipTagListStylesProps {
  theme: Theme
  canToggle: boolean
  isExpanded: boolean
  tagSize: 'small' | 'medium'
  toggleButtonOffset: number
}

export const getClipTagListStyles = ({
  theme,
  canToggle,
  isExpanded,
  toggleButtonOffset,
  tagSize
}: ClipTagListStylesProps): StylesProps => {
  const tagSizes = {
    small: {
      fontSize: theme.typography.body50.fontSize,
      height: 24,
      minWidth: 24
    },
    medium: {
      fontSize: theme.typography.body1.fontSize,
      height: 32,
      minWidth: 32
    }
  }

  const sizes = tagSizes[tagSize]

  return {
    tagList: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'hidden',
      width: `calc(100% - ${sizes.minWidth}px)`,
      maxHeight: canToggle && isExpanded ? 'auto' : sizes.height + TAG_SPACING_VERTICAL
    },
    tag: {
      mr: `${TAG_SPACING_HORIZONTAL}px`,
      mb: `${TAG_SPACING_VERTICAL}px`,
      maxWidth: 200,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 140
      }
    },
    button: {
      ...sizes,
      transform: `translateX(${toggleButtonOffset}px)`,
      position: 'absolute',
      padding: 0,
      margin: 0,
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[500]
    }
  }
}
