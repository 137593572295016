import { Box, SvgIconProps, Theme, Typography, useTheme } from '@mui/material'

import { OCRIcon, PeopleIcon, TranscriptIcon } from '../../../design-system/icons'
import { WhizzardInsightType } from '../../../utils/clip'

export const insightIconMap: Map<WhizzardInsightType, (props: SvgIconProps) => JSX.Element> = new Map([
  ['Transcript', TranscriptIcon],
  ['People', PeopleIcon],
  ['On-screen text', OCRIcon]
])

export const getInsightIcon = (
  type: WhizzardInsightType,
  iconMap: Map<WhizzardInsightType, (props: SvgIconProps) => JSX.Element>,
  theme: Theme,
  isActive: boolean
) => {
  const Icon = iconMap.get(type)!
  return <Icon sx={{ color: isActive ? theme.palette.grey[800] : theme.palette.grey[450], fontSize: 18 }} />
}

interface InsightCountProps {
  insightType: WhizzardInsightType
  count: number
  isActive: boolean
}

export const InsightCount = ({ insightType, count, isActive }: InsightCountProps) => {
  const theme = useTheme()
  return (
    <Box display="flex" alignItems="center">
      {getInsightIcon(insightType, insightIconMap, theme, isActive)}
      <Typography ml={0.5} sx={{ color: isActive ? theme.palette.grey[800] : theme.palette.grey[450] }}>
        {count}
      </Typography>
    </Box>
  )
}
