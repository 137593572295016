import { Box, Button, SvgIconProps, SxProps, Typography, useTheme } from '@mui/material'

interface SearchPromptProps {
  header: string
  description: string
  Icon: (props: SvgIconProps) => JSX.Element
  sx?: SxProps
  variant?: 'horizontal' | 'vertical'
  action?: { title: string; onClick: () => void }
}

export const NoResultsMessage = ({
  header,
  description,
  Icon,
  sx,
  variant = 'horizontal',
  action
}: SearchPromptProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 1,
        mt: 3,
        py: 7.5,
        px: 10,
        flexDirection: variant === 'horizontal' ? 'row' : 'column',
        [theme.breakpoints.down('lg')]: { flexDirection: 'column', py: 4, px: 4 },
        ...sx
      }}
      data-testid="no-matching-results-message"
    >
      <Icon
        sx={{
          width: 'auto',
          fontSize: 160,
          [theme.breakpoints.up('lg')]: { mr: variant === 'horizontal' ? 10 : 0 },
          [theme.breakpoints.down('lg')]: { fontSize: 100 }
        }}
        data-testid="no-playlists-icon"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="head600" sx={{ mb: 1, mt: 3, textAlign: 'center' }}>
          {header}
        </Typography>
        <Typography sx={{ textAlign: 'center' }} variant="body2" data-testid="no-playlists-text">
          {description}
        </Typography>
        {action && (
          <Button onClick={action.onClick} variant="outlined" sx={{ mt: 4, width: 310 }}>
            {action.title}
          </Button>
        )}
      </Box>
    </Box>
  )
}
