import { SearchSlice } from './types'

export const groupify = (queries: string[], operator = 'OR') => {
  return queries.length > 1 ? `(${queries.join(` ${operator} `)})` : queries[0]
}

export const stringify = (category: string, values: string[], operator?: string) => {
  return `(payload.category:${category} AND payload.value:${groupify(values, operator)})`
}

export const createLuceneString = ({ value, category, filter, dateRange, exact }: SearchSlice.Query) => {
  const query: string[] = []

  const exactMatch = exact || /^".*"$/.test(value)
  const terms = exactMatch ? [`"${value.trim().replace(/^"|"$/g, '')}"`] : value.trim().split(' ')

  const baseQuery = category.map(cat => stringify(cat, terms, 'AND'))
  query.push(groupify(baseQuery))

  const filters = Object.entries(filter).filter(([, val]) => val.length)

  if (filters.length) {
    const filterQuery = filters.map(([cat, values]) => {
      const queries = values.map(i => `"${i}"`)
      return stringify(cat, queries, 'OR')
    })

    query.push(filterQuery.join(' AND '))
  }

  if (dateRange) {
    const [start, end] = dateRange.value.map(date => new Date(date).toISOString())
    query.push(stringify(dateRange.category, [`[${start} TO ${end}]`]))
  }

  return query.join(' AND ')
}
