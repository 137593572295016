import { ReactNode } from 'react'

import { Box, Button, SxProps, Typography, useTheme } from '@mui/material'

interface AllFiltersFilterProps {
  title: string
  clearOnClick?: () => void
  sx?: SxProps
  children: ReactNode
}

export const AllFiltersFilter = ({ title, clearOnClick, children, sx }: AllFiltersFilterProps) => {
  const theme = useTheme()
  return (
    <Box sx={sx} data-testid={title.toLowerCase().replaceAll(' ', '-').concat('-filter')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body2" color={theme.palette.grey[450]} sx={{ fontWeight: 500 }} data-testid="filter-name">
          {title}
        </Typography>
        {clearOnClick && (
          <Button
            onClick={clearOnClick}
            size="small"
            sx={{ ...theme.typography.body50, color: theme.palette.grey[400], p: 0, minWidth: 'auto' }}
            data-testid={title.toLowerCase().replaceAll(' ', '-').concat('-clear-btn')}
          >
            Clear
          </Button>
        )}
      </Box>
      {children}
    </Box>
  )
}
