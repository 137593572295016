import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Typography } from '@mui/material'

import { Modal } from '../Modal'

import { selectHasInitialWarningShown, selectUserDetails, setHasInitialWarningShown } from '../../store/slices/user'

export const UoNWarning = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId
  const isShown = useSelector(selectHasInitialWarningShown)

  const isUoN = tenantId === process.env.REACT_APP_UON_TENANT_ID

  const handleClose = () => {
    setOpen(false)
    dispatch(setHasInitialWarningShown(true))
  }

  if (!isUoN) return null

  if (isShown) return null

  return (
    <Modal title="Warning" open={open} setClose={handleClose} testid="warning-dialog">
      <Typography variant="head600" sx={{ fontWeight: 700, mb: 1 }} data-testid="warning-heading">
        Cultural Sensitivity Warning
      </Typography>
      <Typography variant="body2" data-testid="warning-body">
        This site includes images, audio/visual recordings and names of deceased people that may cause sadness or
        distress to Aboriginal and Torres Strait Islander peoples.
      </Typography>
      <Typography variant="head600" sx={{ fontWeight: 700, mt: 3, mb: 1 }} data-testid="warning-heading">
        Offensive Content Warning
      </Typography>
      <Typography variant="body2" data-testid="warning-body">
        This site includes items and language that represent historical perspectives that may be considered offensive
        and biased.
      </Typography>
      <Typography variant="body2" sx={{ mt: 3 }} data-testid="copyright-statement">
        © NBN Pty Limited. This material has been reproduced and communicated to you by or on behalf of the University
        of Newcastle with permission from the copyright owner. Any further reproduction or communication of this
        material by you may be the subject of copyright protection under the Copyright Act 1968.
      </Typography>
      <Typography variant="body2" mb={5} sx={{ mt: 3 }} data-testid="contact-statement">
        University of Newcastle staff should contact{' '}
        <Link href="mailto:copyright@newcastle.edu.au" underline="hover">
          copyright@newcastle.edu.au
        </Link>{' '}
        for educational re-use. For all other re-use permissions, contact{' '}
        <Link href="mailto:archives@nine.com.au" underline="hover">
          archives@nine.com.au
        </Link>
        .
      </Typography>
    </Modal>
  )
}
