import { useRef, useState, useEffect } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { getCardCarouselStyles } from './CardCarousel.styles'
import { NoResultsMessage } from '../NoResultsMessage/NoResultsMessage'
import { PlaylistCardSkeleton } from '../PlaylistCard/PlaylistCardSkeleton'
import { SearchPromptIcon } from '../../design-system/icons'

interface CardCarouselProps {
  title: string
  children: JSX.Element[] | undefined
  noResultsTitle: string
  noResultsDescription: string
  isLoading?: boolean
}

const FULL_CARDS_IN_VIEW = 4

const CardsCarouselSkeleton = () => {
  return (
    <Box display="flex">
      {[0, 1, 2, 3].map(index => (
        <Box key={index} pr={index === 3 ? 0 : 2}>
          <PlaylistCardSkeleton sx={{ width: 276 }} />
        </Box>
      ))}
    </Box>
  )
}

export const CardCarousel = ({
  title,
  children,
  noResultsTitle,
  noResultsDescription,
  isLoading
}: CardCarouselProps) => {
  const theme = useTheme()
  const styles = getCardCarouselStyles(theme)

  const [postionIndex, setPostionIndex] = useState(0)

  const totalCards = children ? children.length : 0
  const cardsRemainder = totalCards % FULL_CARDS_IN_VIEW
  const maxPositionIndex = totalCards - (cardsRemainder === 0 ? FULL_CARDS_IN_VIEW : cardsRemainder)
  const scrollBodyRef = useRef<HTMLDivElement>(document.createElement('div'))

  const handleScrollRight = () => {
    if (postionIndex < scrollBodyRef.current.children.length - FULL_CARDS_IN_VIEW) {
      setPostionIndex(prev => prev + FULL_CARDS_IN_VIEW)
    }
  }
  const handleScrollLeft = () => {
    if (postionIndex > 0) {
      setPostionIndex(prev => prev - FULL_CARDS_IN_VIEW)
    }
  }

  useEffect(() => {
    const $itemToScrollTo = scrollBodyRef.current.children[postionIndex] as HTMLElement
    const anchorPoint = ($itemToScrollTo?.offsetLeft as number) - (scrollBodyRef.current?.offsetLeft as number)

    scrollBodyRef.current?.scroll({
      top: 0,
      left: anchorPoint,
      behavior: 'smooth'
    })
  }, [postionIndex])

  return (
    <>
      <Typography sx={styles.title}>{title}</Typography>
      {totalCards === 0 && !isLoading ? (
        <NoResultsMessage
          sx={{ backgroundColor: theme.palette.grey[25] }}
          header={noResultsTitle}
          description={noResultsDescription}
          Icon={SearchPromptIcon}
        />
      ) : (
        <Box position="relative">
          <Box ref={scrollBodyRef} sx={styles.cards} data-testid="card-carousel">
            {isLoading ? <CardsCarouselSkeleton /> : children}
          </Box>
          {totalCards > FULL_CARDS_IN_VIEW && (
            <>
              {postionIndex !== 0 && (
                <Button
                  disableRipple
                  sx={{ ...styles.button, left: -24 }}
                  onClick={handleScrollLeft}
                  variant="outlined"
                >
                  <ArrowBackIosNewIcon sx={{ fontSize: 12, color: theme.palette.grey[800] }} />
                </Button>
              )}
              {postionIndex !== maxPositionIndex && (
                <Button
                  disableRipple
                  sx={{ ...styles.button, right: -32 }}
                  onClick={handleScrollRight}
                  variant="outlined"
                >
                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: theme.palette.grey[800] }} />
                </Button>
              )}
            </>
          )}
        </Box>
      )}
    </>
  )
}
