import React from 'react'
import IcomoonReact from 'icomoon-react'

import iconSet from './selection.json'
const Icon: React.FC<{
  color?: string
  size?: string | number
  icon: string
  onClick?: () => void
  cursor?: string
  style?: React.CSSProperties
  className?: string
}> = props => {
  const { color, size = '100%', icon, onClick, cursor, style, className } = props
  return (
    <IcomoonReact
      onClick={onClick}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      cursor={cursor}
      style={style}
      className={className}
    />
  )
}

export default Icon
