import { AlertTitle, Alert, Snackbar, useTheme } from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'

interface SuccessAlertProps {
  message: string
  open: boolean
  onClose: (event: React.SyntheticEvent | Event, reason?: string | undefined) => void
}

// TODO: Make this a generic alert component

const SuccessAlert = ({ message, open, onClose }: SuccessAlertProps) => {
  const theme = useTheme()

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={onClose}>
      <Alert
        severity="success"
        sx={{
          backgroundColor: theme.palette.success[50],
          borderRadius: 1,
          border: `1px solid ${theme.palette.success[300]}`
        }}
        icon={<CheckCircleOutlinedIcon sx={{ color: theme.palette.success[600] }} />}
      >
        <AlertTitle>Success</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SuccessAlert
