import { useRouteMatch, useLocation, Link } from 'react-router-dom'
import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps, Link as MuiLink, Typography } from '@mui/material'

interface Props extends BreadcrumbsProps {
  overrides?: Record<string, string | {}>
}

interface Crumb {
  to: string
  label: string
}

export const Breadcrumbs = ({ overrides, ...props }: Props) => {
  const match = useRouteMatch()
  const { pathname } = useLocation()

  const pathParts = pathname.split('/').slice(1)

  const crumbs = pathParts.reduce((accum, pathPart, index) => {
    if (match.path.split('/')[index + 1]?.startsWith(':')) {
      // ignore dynamic paths
      return accum
    }

    const to = overrides && overrides.to[index] ? overrides.to[index] : `/${pathParts.slice(0, index + 1).join('/')}`

    const label = pathPart
      .split('-')
      .join(' ')
      .replace(/\w/, (c: string) => c.toUpperCase())

    return [...accum, { to, label }]
  }, [] as Crumb[])

  return (
    <MuiBreadcrumbs {...props} data-testid="breadcrumbs">
      {crumbs.map((crumb, index) => {
        if (index + 1 === crumbs.length) {
          return (
            <Typography key={crumb.to} variant="body2" color="text.primary">
              {crumb.label}
            </Typography>
          )
        }

        return (
          <MuiLink key={crumb.to} component={Link} variant="body2" underline="hover" color="inherit" to={crumb.to}>
            {crumb.label}
          </MuiLink>
        )
      })}
    </MuiBreadcrumbs>
  )
}
