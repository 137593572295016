export const BreakpointQuery = {
  XL: '(min-width: 1201px)',
  LG: '(max-width: 1200px)',
  MOBILELANDSCAPE: '(max-width: 930px) and (orientation: landscape)', // Temporary until sign up is made accessible on mobile with designs made
  MD: '(max-width: 900px)', // Temporary as there are currently no medium screen designs.
  SM: '(max-width: 900px)',
  XS: '(max-width: 480px)',
  LANDSCAPE: '(orientation: landscape)'
}

export const Breakpoint = {
  XL: `@media${BreakpointQuery.XL}`,
  LG: `@media${BreakpointQuery.LG}`,
  MOBILELANDSCAPE: `@media${BreakpointQuery.MOBILELANDSCAPE}`,
  MD: `@media${BreakpointQuery.MD}`,
  SM: `@media${BreakpointQuery.SM}`,
  XS: `@media${BreakpointQuery.XS}`,
  LANDSCAPE: `@media${BreakpointQuery.LANDSCAPE}`
}
