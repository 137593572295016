import { createUseStyles } from 'react-jss'
import colours from '../../theme/colours'

import { ITagStyleProps } from './Tag'

export const useTagStyles: (props: ITagStyleProps) => Record<string, any> = createUseStyles({
  tag: (props: ITagStyleProps) => ({
    backgroundColor: props.tagColor,
    padding: '4px 13px 2px 10px',
    borderRadius: 50,
    color: colours.white,
    fontSize: 12,
    display: 'inline-block',
    textTransform: 'uppercase',
    lineHeight: 1
  })
})
