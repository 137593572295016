import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { notify } from 'react-notify-toast'
import { lvsService } from '../services/lvs'
import { LVSService } from '../services/lvs/types'
import { selectApiKey, selectUserDetails } from '../store/slices/user'

export interface IMyContent {
  activated: true
  created: string
  duration: number
  id: string
  name: string
  representations: any[]
  tags: any[]
  thumbnailUrl: string
}

export const useMyContentTable = (pageNumber: number, pageSize: number) => {
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<string[]>([])
  const [myContent, setMyContent] = useState<LVSService.IAssetResponse>()
  const [loading, setLoading] = useState(false)
  const [idStatus, setIdStatus] = useState<{ status: string; assetId: string }[]>()
  const userDetails = useSelector(selectUserDetails)
  const tenantId = userDetails?.tenantId
  const apiKey = useSelector(selectApiKey)
  const createdLastYear: string = '?createdWhen=LAST_YEAR'
  const pageNumberToIndex: number = pageNumber - 1
  const pageNumberString: string = pageNumberToIndex.toString()
  const pageSizeString: string = pageSize.toString()

  const allIds = myContent?.content.map(item => item.id)

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll)
    if (myContent) setIsCheck(myContent.content.map(li => li.id))

    if (isCheckAll) {
      setIsCheck([])
    }
  }
  const handleClick = event => {
    const { id, checked } = event.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id))
    }
  }
  const getTableData = (showLoader: boolean) => {
    if (tenantId) setLoading(true)
    lvsService.asset.content
      .get(apiKey, `${createdLastYear}&size=${pageSizeString}&page=${pageNumberString}&tagsOperator=ALL`)
      .then(response => {
        setMyContent(response?.data)
        notify.show('Table successfully updated.', 'success')
      })
      .catch(() => notify.show('Something went wrong fetching table content.', 'error'))
      .finally(() => {
        if (showLoader) {
          // this timeout is here so the user can briefly see the loading animation
          setTimeout(() => {
            setLoading(false)
          }, 600)
        } else {
          setLoading(false)
        }
      })
  }
  useEffect(() => {
    if (tenantId) {
      lvsService.asset.content
        .get(apiKey, `${createdLastYear}&size=${pageSizeString}&page=${pageNumberString}&tagsOperator=ALL`)
        .then(response => {
          setMyContent(response?.data)
        })
        .catch(() => notify.show('Something went wrong fetching table content.', 'error'))
    }
  }, [tenantId, apiKey, pageNumberString, pageSizeString])

  useEffect(() => {
    if (myContent && allIds) {
      lvsService.asset.status
        .get(allIds)
        .then(response => setIdStatus(response?.data))
        .catch(() => notify.show('Something went wrong fetching video status.', 'error'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myContent, apiKey])

  const displayVideoState = (videoId: string) => {
    return idStatus?.find(item => item.assetId === videoId)?.status || 'UNKNOWN'
  }

  return {
    handleClick,
    isCheck,
    isCheckAll,
    handleSelectAll,
    myContent,
    displayVideoState,
    getTableData,
    loading
  }
}
