import { TypographyOptions } from '@mui/material/styles/createTypography'
import { defaultFont } from './fonts'

export const typography: TypographyOptions = {
  fontFamily: ['Muli', 'Open Sans'].join(','),
  head900: {
    fontFamily: 'Muli',
    fontSize: '2.25rem',
    fontWeight: 590,
    letterSpacing: '-0.0001em',
    lineHeight: 1.11,
    marginTop: '3.25rem'
  },
  head800: {
    fontFamily: 'Muli',
    fontSize: '1.75rem',
    fontWeight: 600,
    letterSpacing: '-0.0001em',
    lineHeight: 1.14,
    marginTop: '3rem'
  },
  head700: {
    fontFamily: 'Muli',
    fontSize: '1.5rem',
    fontWeight: 590,
    letterSpacing: '-0.0001em',
    lineHeight: 1.17,
    marginTop: '2.5rem'
  },
  head600: {
    fontFamily: 'Muli',
    fontSize: '1.25rem',
    fontWeight: 590,
    letterSpacing: '-0.0008em',
    lineHeight: 1.2,
    marginTop: '1.75rem'
  },
  head500: {
    fontFamily: 'Muli',
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '-0.0006em',
    lineHeight: 1.25,
    marginTop: '1.5rem'
  },
  head400: {
    fontFamily: 'Muli',
    fontSize: '0.875rem',
    fontWeight: 600,
    letterSpacing: '-0.0003em',
    lineHeight: 1.14,
    marginTop: '1rem'
  },
  head300: {
    fontFamily: 'Muli',
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.33,
    marginTop: '1.25rem'
  },
  head200: {
    fontFamily: 'Muli',
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.33,
    marginTop: '1rem'
  },
  head100: {
    fontFamily: 'Muli',
    fontSize: '0.688rem',
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 1.45,
    marginTop: '1rem'
  },
  body1: {
    fontFamily: 'Muli',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.0025em',
    lineHeight: 1.23
  },
  body2: {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.0025em',
    lineHeight: 1.43
  },
  body3: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.005em',
    lineHeight: 1.34
  },
  body4: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.005em',
    lineHeight: 1.5
  },
  Labe100: {
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '0.005em',
    lineHeight: 1
  },
  caption: {
    fontFamily: 'Open Sans',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.04em',
    lineHeight: 1.33
  },
  overline: {
    fontFamily: 'Open Sans',
    fontSize: '0.625rem',
    fontWeight: 400,
    letterSpacing: '0.15em',
    lineHeight: 1.2
  },
  button: {
    fontFamily: 'Open Sans',
    lineHeight: 1.7,
    letterSpacing: '0.015em',
    fontWeight: 600,
    textTransform: 'none'
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined
}

export const defaultTypography: TypographyOptions = {
  fontFamily: defaultFont,
  head900: {
    fontFamily: defaultFont,
    fontSize: '2.25rem',
    fontWeight: 590,
    lineHeight: 1.11
  },
  head800: {
    fontFamily: defaultFont,
    fontSize: '1.75rem',
    fontWeight: 590,
    lineHeight: 1.14
  },
  head700: {
    fontFamily: defaultFont,
    fontSize: '1.5rem',
    fontWeight: 590,
    lineHeight: 1.17
  },
  head600: {
    fontFamily: defaultFont,
    fontSize: '1.25rem',
    fontWeight: 590,
    lineHeight: 1.2
  },
  head500: {
    fontFamily: defaultFont,
    fontSize: '1.125rem',
    fontWeight: 590,
    lineHeight: 1.33
  },
  head400: {
    fontFamily: defaultFont,
    fontSize: '0.875rem',
    fontWeight: 590,
    lineHeight: 1.43
  },
  head300: {
    fontFamily: defaultFont,
    fontSize: '0.75rem',
    fontWeight: 590,
    lineHeight: 1.33
  },
  body50: {
    fontFamily: defaultFont,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.33
  },
  body50Semi: {
    fontFamily: defaultFont,
    fontWeight: 590,
    fontSize: '0.75rem',
    lineHeight: 1.33
  },
  body1: {
    fontFamily: defaultFont,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.43
  },
  body2: {
    fontFamily: defaultFont,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body3: {
    fontFamily: defaultFont,
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.56
  },
  caption: {
    fontFamily: defaultFont,
    fontSize: '0.625rem',
    fontWeight: 510,
    lineHeight: 1,
    textTransform: 'uppercase'
  },
  caption200: {
    fontFamily: defaultFont,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1,
    textTransform: 'uppercase'
  },
  button: {
    fontFamily: defaultFont,
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 510,
    textTransform: 'none'
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined
}
