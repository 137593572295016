import { Button, Box, Tooltip, Typography, SvgIcon, useTheme } from '@mui/material'
import { useCSVReader } from 'react-papaparse'

import { Fragment, useState } from 'react'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import { CsvValidation } from './types'
import { useCsvValidator } from './hooks/useCsvValidator'
import { RequiredHeadings } from './constants'
import { ReactComponent as csvSplash } from './csvSplash.svg'
import { useMediaUploadStyles } from './useMediaUpload.styles'

export const MediaUpload = () => {
  const { CSVReader } = useCSVReader()
  const [csvFile, setCsvFile] = useState<CsvValidation.CsvFile>()
  const { VIDEO_URL, TITLE, ASSET_START_DATE } = RequiredHeadings
  const { palette } = useTheme()
  const { secondary, error, success, background } = palette

  const {
    validatedHeadings,
    allValidatedHeadings,
    isMissingRequiredHeadings,
    duplicateElements,
    duplicateHeaders,
    allErrors,
    totalErrorsCount
  } = useCsvValidator(csvFile)

  const classes = useMediaUploadStyles()

  return (
    <Box width="100%" minWidth="1300px" sx={{ display: 'flex' }}>
      <Box width="50%">
        <Typography variant="head900" sx={{ mt: 12, ml: 15, mb: 4 }} color={palette.secondary.dark}>
          CSV Validation
        </Typography>
        <CSVReader
          onUploadAccepted={(results: { data: string[][] }) => {
            setCsvFile(results)
          }}
        >
          {({ getRootProps, acceptedFile }: any) => (
            <Box
              className={classes.fileSelectContainer}
              sx={{
                ml: 15,
                mt: 5,
                p: 6,
                border: `2px dashed ${secondary.light}`,
                borderRadius: 4
              }}
            >
              {csvFile ? (
                <ClearOutlinedIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCsvFile(undefined)
                  }}
                  fontSize="large"
                />
              ) : (
                <CloudUploadOutlinedIcon {...getRootProps()} fontSize="large" sx={{ cursor: 'pointer' }} />
              )}

              <Typography variant="body1" color={secondary.dark} marginTop={csvFile ? 4 : 'auto'}>
                {csvFile ? acceptedFile?.name : 'Select a file or drag and drop here'}
              </Typography>
              <Typography variant="body1" color={secondary.light} marginTop={1}>
                {csvFile
                  ? `${csvFile.data.length - 1} row${csvFile.data.length - 1 === 1 ? '' : 's'} processed`
                  : 'File size no more than 10MB'}
              </Typography>
              {!csvFile && (
                <Button variant="contained" sx={{ marginTop: 2 }} {...getRootProps()}>
                  Select CSV file
                </Button>
              )}
            </Box>
          )}
        </CSVReader>
      </Box>

      <Box className={classes.resultContainer} sx={{ pt: 4, pb: 4 }}>
        {allErrors && allValidatedHeadings && csvFile ? (
          <>
            <Box sx={{ backgroundColor: background.paper, mx: 4, p: 2, borderRadius: 1 }} className={classes.results}>
              <Typography variant="body3" margin={0}>
                Results
              </Typography>
              {/* <Button
                variant="contained"
                size="small"
                disabled={!csvFile.data.length || totalErrorsCount > 0}
                onClick={() => {
                  convertToJSON(csvFile)
                  setJsonCsvFile(JSON.stringify(csvFile?.data))
                  navigator.clipboard.writeText(JSON.stringify(csvFile?.data))
                  jsonCsvFile && notify.show('CSV converted to JSON', 'success')
                }}>
                this feature is for a future sprint
                Convert to JSON
              </Button> */}

              {/* ) : (
                ''
              )} */}
            </Box>
            {totalErrorsCount ? (
              <Typography variant="body1" color={error.main} sx={{ ml: 4, mt: 2, fontWeight: 600 }}>
                {`${totalErrorsCount} Error${totalErrorsCount === 1 ? '' : 's'}`}
              </Typography>
            ) : (
              ''
            )}

            {!totalErrorsCount && !isMissingRequiredHeadings && (
              <Box sx={{ display: 'flex' }} alignItems="center" flexDirection="column">
                {csvFile.data.length ? (
                  <>
                    <DoneRoundedIcon
                      className={classes.allValidIcon}
                      sx={{
                        backgroundColor: success[50],
                        color: success.main,
                        mt: 30
                      }}
                    />

                    <Typography variant="head500" color={success.main} fontWeight={600}>
                      The CSV file appears to be valid!
                    </Typography>
                  </>
                ) : (
                  <Typography variant="head500" fontWeight={600}>
                    This file has no content to validate
                  </Typography>
                )}
              </Box>
            )}
            <Box marginLeft={4}>
              {allValidatedHeadings.find(item => item.isError) ? (
                <Typography variant="head400">Heading errors</Typography>
              ) : (
                ''
              )}
              {isMissingRequiredHeadings && (
                <Tooltip title={`Required headings are ${VIDEO_URL}, ${TITLE}, ${ASSET_START_DATE}`} placement="bottom">
                  <Typography variant="head400" color={error.main}>
                    MISSING 1 OR MORE REQUIRED HEADINGS
                  </Typography>
                </Tooltip>
              )}
              {duplicateHeaders?.length ? (
                <Typography variant="head400" color={error.main}>
                  Duplicate required headers
                </Typography>
              ) : (
                ''
              )}

              {allValidatedHeadings?.map(
                ({ title, errorType, isError, id }) =>
                  isError && (
                    <Typography variant="body1" color={error.main} fontWeight={400} marginTop={2} key={id}>
                      {title || 'N/A'} | <strong>{errorType}</strong>
                    </Typography>
                  )
              )}
              {duplicateElements?.length ? (
                <>
                  <Typography variant="head400" color={error.main}>
                    Duplicates Found
                  </Typography>
                  {duplicateElements.map((...args) => {
                    const [element, index] = args
                    return (
                      <Typography key={element + index} variant="body1" marginTop={2} color={error.main}>
                        {element}
                      </Typography>
                    )
                  })}
                </>
              ) : null}
            </Box>
            {allErrors &&
              allErrors.map(({ element, row, errorType, index, id }, i) => (
                <Box marginLeft={4} key={id}>
                  {allErrors[i - 1]?.row !== allErrors[i]?.row && (
                    <Typography variant="body1" marginTop={2}>
                      Row # {row}
                    </Typography>
                  )}
                  <Typography variant="body1" color={error.main} fontWeight={400} marginTop={2}>
                    {element
                      ? `${validatedHeadings[index]?.title} | ${element}`
                      : `${validatedHeadings[index]?.title} | N/A`}{' '}
                    | <strong>{errorType}</strong>
                  </Typography>
                </Box>
              ))}
          </>
        ) : (
          <Box sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <Typography variant="head700" marginTop={38} fontWeight={600}>
              Validation Results
            </Typography>
            <Typography variant="body2" marginTop={1} marginBottom={3} fontWeight={400}>
              Once you upload the CSV file, you will see the validation results here
            </Typography>

            <SvgIcon component={csvSplash} viewBox="0 0 329 219" sx={{ width: '329px', height: '219px' }} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
