import React from 'react'
import classnames from 'classnames'
import { ITable } from '../types'
import { useTableRowStyles } from './TableRow.styles'

export const TableRow = ({
  divider,
  children,
  isEmptyAccount,
  checkbox,
  isChecked,
  gridTemplateColumns = `repeat(${children && Array.isArray(children) ? children.length : 0}, 1fr)`,
  className = '',
  id,
  onChange
}: ITable.RowProps) => {
  const classes = useTableRowStyles({ divider, gridTemplateColumns, isChecked, isEmptyAccount })
  return (
    <tr data-testid={'divider'} className={classnames(classes.TableRow, className)}>
      {checkbox && (
        <td>
          <input style={{ marginRight: 50 }} type="checkbox" onChange={onChange} checked={isChecked} id={id} />
        </td>
      )}
      {children}
    </tr>
  )
}
