import {
  numberToTwoDigits,
  timeToHours,
  timeToMinutes,
  timeToSeconds
} from '../../../utils'

import useTimelineStyles from './Timeline.style'

export interface ITimelineProps {
  zoomLevel: number
  secondToggle: number
  assetDuration: number
}

const Timeline = ({
  zoomLevel,
  secondToggle,
  assetDuration
}: ITimelineProps) => {
  const classes = useTimelineStyles({ zoomLevel, secondToggle, assetDuration })

  return (
    <div className={classes.container}>
      {[...Array(zoomLevel)].map((e, i) => (
        <p key={i}>
          {numberToTwoDigits(
            timeToHours((assetDuration * i) / (zoomLevel - 1))
          )}
          :
          {numberToTwoDigits(
            timeToMinutes((assetDuration * i) / (zoomLevel - 1))
          )}
          :
          {numberToTwoDigits(
            timeToSeconds((assetDuration * i) / (zoomLevel - 1))
          )}
        </p>
      ))}
    </div>
  )
}

export default Timeline
