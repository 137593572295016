import { Theme } from '@mui/material'

export const getCardCarouselStyles = (theme: Theme): StylesProps => ({
  title: {
    color: theme.palette.grey[800],
    mt: 4,
    mb: 2,
    ...theme.typography.head800,
    [theme.breakpoints.down('lg')]: { ...theme.typography.head600 }
  },
  cards: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    [theme.breakpoints.up('lg')]: { '::-webkit-scrollbar': { display: 'none' } }
  },
  button: {
    opacity: 0.8,
    position: 'absolute',
    boxShadow: '0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13)',
    top: '35%',
    backgroundColor: theme.palette.common.white,
    border: 'none',
    padding: 0,
    marginRight: 1,
    minWidth: 48,
    height: 48,
    borderRadius: '50%',
    pr: 0.25,
    '&:hover': {
      opacity: 0.7,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13)',
      border: 'none'
    },
    [theme.breakpoints.down('lg')]: { display: 'none' }
  }
})
