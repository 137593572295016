import { useEffect, useState } from 'react'
import { BreakpointQuery } from '../design-system-legacy/breakpoints'
import useMediaQuery from './useMediaQuery'

export function useIsMobileDevice() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)

  const isMobilePortrait = useMediaQuery(BreakpointQuery.XS)
  const isMobileLandscape = useMediaQuery(BreakpointQuery.MOBILELANDSCAPE)
  const isMobile = (isMobilePortrait || isMobileLandscape) && isMobileOrTablet

  useEffect(() => {
    // As  per: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    const results = /Mobi/i.test(navigator.userAgent)
    setIsMobileOrTablet(results)
  }, [])

  return { isMobileOrTablet, isMobile, isMobileLandscape, isMobilePortrait }
}
