import { PaletteOptions } from '@mui/material'
import { common } from '@mui/material/colors'
import { semanticColours, colors, brandColours, greyScaleColours } from './colors'

export const skyPalette: PaletteOptions = {
  primary: {
    dark: brandColours.liniusBlue[900],
    main: brandColours.liniusBlue[600],
    light: brandColours.liniusBlue[200],
    50: brandColours.liniusBlue[50],
    contrastText: colors.black
  },
  secondary: {
    dark: colors.MART[900],
    800: colors.MART[800],
    700: colors.MART[700],
    main: colors.MART[500],
    light: colors.MART[200],
    50: colors.MART[50],
    contrastText: colors.white
  },
  grey: {
    900: '#232120',
    800: '#3B3C42',
    500: '#94969A',
    400: '#BFBFC0',
    300: '#E0E0E0',
    200: '#efefef',
    100: '#F8F8F8'
  },
  error: {
    900: colors.CARN[900],
    dark: colors.CARN[800],
    500: colors.CARN[500],
    main: colors.CARN[400],
    light: colors.CARN[200],
    50: colors.CARN[50]
  },
  warning: {
    dark: colors.SAFF[800],
    600: colors.SAFF[600],
    main: colors.SAFF[400],
    300: colors.SAFF[300],
    light: colors.SAFF[200],
    50: colors.SAFF[50]
  },
  success: {
    dark: colors.PERS[800],
    main: colors.PERS[400],
    light: colors.DEYO[500],
    50: colors.PERS[50]
  },
  info: {
    dark: colors.AZUR[800],
    main: colors.AZUR[400],
    light: colors.AZUR[200],
    50: colors.AZUR[50]
  },
  tags: {
    presenter: colors.STEE[700],
    topic: colors.SCOO[500],
    people: colors.PERS[400],
    ocr: colors.SAFF[800]
  }
}

export const defaultPalette: PaletteOptions = {
  primary: {
    900: brandColours.liniusBlue[900],
    800: brandColours.liniusBlue[800],
    dark: brandColours.liniusBlue[700],
    main: brandColours.liniusBlue[600],
    500: brandColours.liniusBlue[500],
    400: brandColours.liniusBlue[400],
    300: brandColours.liniusBlue[300],
    200: brandColours.liniusBlue[200],
    100: brandColours.liniusBlue[100],
    50: brandColours.liniusBlue[50],
    contrastText: common.white
  },
  secondary: {
    dark: brandColours.liniusBlue[300],
    main: brandColours.liniusBlue[100],
    contrastText: brandColours.liniusBlue[600]
  },
  grey: greyScaleColours.defualt,
  legacy: {
    dark: brandColours.legacyPurple[900],
    800: brandColours.legacyPurple[800],
    700: brandColours.legacyPurple[700],
    main: brandColours.legacyPurple[500],
    light: brandColours.legacyPurple[200],
    50: brandColours.legacyPurple[50],
    contrastText: colors.white
  },
  focus: {
    main: '#3FF5F5'
  },
  error: {
    900: semanticColours.errorRed[900],
    800: semanticColours.errorRed[800],
    700: semanticColours.errorRed[700],
    dark: semanticColours.errorRed[600],
    main: semanticColours.errorRed[500],
    400: semanticColours.errorRed[400],
    300: semanticColours.errorRed[300],
    200: semanticColours.errorRed[200],
    100: semanticColours.errorRed[100],
    50: semanticColours.errorRed[50],
    contrastText: common.white
  },
  errorSubtle: {
    dark: semanticColours.errorRed[100],
    main: semanticColours.errorRed[50],
    contrastText: semanticColours.errorRed[500]
  },
  warning: {
    900: semanticColours.warningYellow[900],
    800: semanticColours.warningYellow[800],
    700: semanticColours.warningYellow[700],
    600: semanticColours.warningYellow[600],
    500: semanticColours.warningYellow[500],
    400: semanticColours.warningYellow[400],
    300: semanticColours.warningYellow[300],
    200: semanticColours.warningYellow[200],
    100: semanticColours.warningYellow[100],
    50: semanticColours.warningYellow[50],
    contrastTextLight: common.black,
    contrastTextDark: common.white
  },
  success: {
    900: semanticColours.successGreen[900],
    800: semanticColours.successGreen[800],
    700: semanticColours.successGreen[700],
    600: semanticColours.successGreen[600],
    500: semanticColours.successGreen[500],
    400: semanticColours.successGreen[400],
    300: semanticColours.successGreen[300],
    200: semanticColours.successGreen[200],
    100: semanticColours.successGreen[100],
    50: semanticColours.successGreen[50],
    contrastTextLight: common.black,
    contrastTextDark: common.white
  },
  info: {
    900: semanticColours.infoBlue[900],
    800: semanticColours.infoBlue[800],
    700: semanticColours.infoBlue[700],
    600: semanticColours.infoBlue[600],
    500: semanticColours.infoBlue[500],
    400: semanticColours.infoBlue[400],
    300: semanticColours.infoBlue[300],
    200: semanticColours.infoBlue[200],
    100: semanticColours.infoBlue[100],
    50: semanticColours.infoBlue[50],
    contrastTextLight: common.black,
    contrastTextDark: common.white
  }
}
