import { createUseStyles } from 'react-jss'
import { toResponsiveUnitFromPixels } from '../../../theme/toResponsiveUnitFromPixels'

export const useMyContentStyles = createUseStyles({
  myContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px 40px 0px 40px',
    marginTop: 20,
    minWidth: toResponsiveUnitFromPixels(900)
  },
  myContentHeadingContainer: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'flex-start'
  },
  myContentHeading: {
    fontSize: 25,
    margin: 0
  },
  myContentName: {
    lineHeight: 1.2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  myContentTBC: {
    opacity: 1
  }
})
