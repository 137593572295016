import { MenuProps as IMenuProps } from '@mui/material'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8
interface ExtendedMenuProps extends IMenuProps {
  getcontentanchorel: null
}

export const defaultMenuProps: Partial<ExtendedMenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getcontentanchorel: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}
